import React, { useState, useEffect } from 'react';
import './Home.css';
import { useNavigate, Link } from 'react-router-dom';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
import { fetchTodayTotalSales, fetchBranches, fetchTopProducts, fetchLowStockItems, fetchSalesTrend } from './DashboardData';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker'; // Add a date picker library
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

function Home() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID

    const [selectedBranch, setSelectedBranch] = useState("all");
    const [branches, setBranches] = useState([]);

    const [todayTotalSales, setTodayTotalSales] = useState(0);
    const [topProducts, setTopProducts] = useState([]);
    const [lowStockItems, setLowStockItems] = useState([]);
    const [salesTrend, setSalesTrend] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('month').toDate()); // Start of current month
    const [endDate, setEndDate] = useState(moment().endOf('month').toDate()); // End of current month

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchSalesTrend(selectedBranch);  // Pass selectedBranch as is
    }, [selectedBranch, startDate, endDate]);

    const handleFetchSalesTrend = () => {
        fetchSalesTrend(startDate, endDate, selectedBranch).then(setSalesTrend);
    };

    useEffect(() => {
        // Fetch list of branches for dropdown
        fetchBranches().then(data => {
            setBranches(data || []);
            console.log("Branches State Data:", data); // Log the branches data set in state
        });
    }, []);

    useEffect(() => {
        // Fetch low stock items for selected branch
        if (selectedBranch) {
            fetchLowStockItems(selectedBranch).then(data => setLowStockItems(data || []));
        }
    }, [selectedBranch]);

    const handleBranchChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedBranch(selectedValue);  // No need to use null for 'all'
    };
    
    


    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');

        if (storedUsername) setUsername(storedUsername);
        if (storedBranchName) setBranchName(storedBranchName);
        if (storedBranchID) setBranchID(storedBranchID);
        else navigate('/');

        // Fetch branches for dropdown
        fetchBranches().then(data => setBranches(data || []));

        // Fetch data for each report card, passing selectedBranch where applicable
        fetchTodayTotalSales(selectedBranch).then(setTodayTotalSales);
        fetchTopProducts(selectedBranch).then(setTopProducts);
        fetchLowStockItems(selectedBranch).then(setLowStockItems);
        handleFetchSalesTrend(); // Fetch initial sales trend data
    }, [navigate, selectedBranch]);


    const handleLogout = () => {
        localStorage.removeItem('username'); // Remove username from local storage
        localStorage.removeItem('branchName'); // Remove branchName from local storage
        localStorage.removeItem('branchID'); // Remove branchID from local storage
        navigate('/'); // Redirect to login page
    };

    return (
        <div className="home-container">
            <TopContainer />

            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
                
            >

                {/* Main Content */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <div className="branch-selector-container">
                        {/* Branch Selector */}
                        <select
                            className="low-stock-branch-select"
                            onChange={handleBranchChange}
                            value={selectedBranch || "all"}
                        >
                            <option value="all">All Branches</option>
                            {(branches || []).map(branch => (
                                <option key={branch.branchID} value={branch.branchID}>
                                    {branch.branchName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="dashboard-reports">

                        {/* Total Sales with Period Filter */}
                        <div className="report-card total-sales-card">
                            <h3>Daily Sales Revenue</h3>
                            <div className="total-sales-amount">
                                <span className="currency-symbol">₱</span>
                                <span className="amount">{todayTotalSales.toFixed(2)}</span>
                            </div>
                        </div>



                        {/* Top Products */}
                        <div className="report-card top-products-card">
                            <h3>Top Products</h3>
                            <ul className="top-products-list">
                                {topProducts.length > 0 ? (
                                    topProducts.map((product, index) => (
                                        <li key={index} className="top-product-item">
                                            <span className="product-rank">{index + 1}</span>
                                            <span className="product-name">{product.name}</span>
                                            <span className="product-sales">{product.quantity} sold</span>
                                        </li>
                                    ))
                                ) : (
                                    <p className="no-data">No data available</p>
                                )}
                            </ul>
                        </div>



                        {/* Low Stock Alert */}
                        <div className="report-card low-stock-card">
                            <h3>Low Stock Alert</h3>
                            {/* Low Stock Items List */}
                            <ul className="low-stock-list">
                                {(lowStockItems || []).length > 0 ? (
                                    lowStockItems.map((item, index) => (
                                        <li key={index} className="low-stock-item">
                                            <span className="stock-product-name">{item.name}</span>
                                            <span className="stock-branch-name">{item.branch}</span>
                                            <span className="stock-count">{item.stock} left</span>
                                        </li>
                                    ))
                                ) : (
                                    <li className="low-stock-item">No low stock items found.</li>
                                )}
                            </ul>
                        </div>


                        {/* Sales Trend */}
                        <div className="report-card">
                            <h3>Sales Trend</h3>
                            <div className="date-range-filter">
                                <label className="date-label">Start Date:</label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    className="date-picker-input"
                                    dateFormat="MM/dd/yyyy"
                                />
                                <label className="date-label">End Date:</label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    className="date-picker-input"
                                    dateFormat="MM/dd/yyyy"
                                />
                                <button className="apply-filter-button" onClick={handleFetchSalesTrend}>Apply Filter</button>
                            </div>

                            <div className="sales-trend-container">
                                <ResponsiveContainer>
                                <LineChart
                                    data={salesTrend}
                                    margin={{ top: 10, right: 30, left: 60, bottom: 0 }} // Adjust left margin
                                >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis 
                                            dataKey="date" 
                                            tickFormatter={(date) => moment(date).format("MM-DD")} 
                                        />
                                        <YAxis
                                            tickLine={false}
                                            tickFormatter={(value) => `₱ ${value.toFixed(2)}`}
                                            tick={{ fontSize: 14, fill: '#333' }}
                                            label={{
                                                position: 'insideLeft',
                                                offset: -5,
                                                style: { fontSize: 16, fontWeight: 'bold', fill: '#333' },
                                            }}
                                            width={80} // Ensures the Y-axis stays outside of the chart area
                                        />
                                        <Tooltip
                                            formatter={(value) => `₱${parseFloat(value).toFixed(2)}`}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="total"
                                            stroke="#8884d8"
                                            dot={{ r: 5 }} // Sets data point radius to 5 for larger points
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default Home;
