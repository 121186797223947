import React, { useState, useEffect } from 'react';
import './ReportSales.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from './Pagination';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';
import DatePickerModal from './DatePickerModal';
import { FaCalendarAlt } from 'react-icons/fa';

import {
    InventorySummaryReport,
    LowStockReport,
    InventoryMovementLogReport,
    StockTransferReport,
    ReorderReport
} from './ReportComponents';
import DatePicker from 'react-datepicker';  // Install: npm install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

function ReportInventory() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [users, setUsers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [regularCustomers, setRegularCustomers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [products, setProducts] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedEmployee, setSelectedEmployee] = useState('all');
    const [selectedUser, setSelectedUser] = useState('all');
    const [selectedCustomer, setSelectedCustomer] = useState('all');
    const [selectedProduct, setSelectedProduct] = useState('all');
    const [selectedBranch, setSelectedBranch] = useState('all');
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [activeTab, setActiveTab] = useState('inventory');
    const [stockTransfers, setStockTransfers] = useState([]);
    const navigate = useNavigate();


    // Fetch inventory report with any additional filtering logic (optional)
    useEffect(() => {
        const fetchInventoryReport = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/inventory');
                setInventory(response.data);
                console.log('Fetched Inventory Report:', response.data);
            } catch (error) {
                console.error('Error fetching inventory report:', error);
            }
        };

        fetchInventoryReport();
    }, []);
    
    useEffect(() => {
        const fetchStockTransfers = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/stocktransfers');
                setStockTransfers(response.data);
                console.log('Fetched Stock Transfers:', response.data);
            } catch (error) {
                console.error('Error fetching stock transfers:', error);
            }
        };
    
        fetchStockTransfers();
    }, []);

    const fetchData = async (url, setter) => {
        try {
            const response = await axios.get(url);
            setter(response.data);
            console.log(`Fetched data from ${url}:`, response.data);
        } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
        }
    };
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchID = localStorage.getItem('branchID');
        const storedBranchName = localStorage.getItem('branchName');
    
        if (storedUsername) setUsername(storedUsername);
        if (storedBranchID) setBranchID(storedBranchID);
        if (storedBranchName) setBranchName(storedBranchName);
    
        
        // Fetching customers
        fetchData('https://www.capstonelimenerp.xyz:8082/api/customers', setCustomers);
    
        // Fetching employees
        fetchData('https://www.capstonelimenerp.xyz:8082/api/employees', setEmployees);

        // Fetching employees
        fetchData('https://www.capstonelimenerp.xyz:8082/api/users', setUsers);
        
        // Fetching products
        fetchData('https://www.capstonelimenerp.xyz:8082/api/products', setProducts);
        
        // Fetching branches
        fetchData('https://www.capstonelimenerp.xyz:8082/api/branches', setBranches);

        // Fetching products
        fetchData('https://www.capstonelimenerp.xyz:8082/api/inventory', setInventory);

        // Fetching branches
        fetchData('https://www.capstonelimenerp.xyz:8082/api/stocktransfers', setStockTransfers);
    }, []);
    
    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleDateRangeApply = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleReportSelection = (reportType) => {
        setSelectedReport(reportType);
        setSelectedBranch('all');
        setSelectedEmployee('all');
        setSelectedProduct('all');
        setSelectedUser('all');
        setSelectedCustomer('all');
    };

    const filteredInventory = inventory.filter((item, index, self) => {
        const matchesBranch = selectedBranch === 'all' || item.branch_id_fk === selectedBranch;
        const matchesProduct = selectedProduct === 'all' || item.prod_No_fk === selectedProduct;
        const matchesUser = selectedUser === 'all' || item.createdBy === selectedUser;

        console.log(
            `Filtering: Product=${item.prod_No_fk}, Branch=${item.branch_id_fk}, User=${item.user_id_fk}, MatchesBranch=${matchesBranch}, MatchesProduct=${matchesProduct}, MatchesUser=${matchesUser}`
        );
        // Ensure uniqueness by checking invID and branch_id_fk
        return matchesBranch && matchesProduct && matchesUser &&
            index === self.findIndex((t) => t.invID === item.invID && t.branch_id_fk === item.branch_id_fk);
    });
    
    
    
    
    
    

    const generateReportContent = () => {
        switch (selectedReport) {
            case 'summary':
                return (
                    <InventorySummaryReport
                        inventory={filteredInventory}
                        selectedProduct={selectedProduct}
                        selectedBranch={selectedBranch}
                        branches={branches}
                        products={products}
                        startDate={startDate}
                        endDate={endDate}
                    />
                );
            case 'lowStock':
                return (
                    <LowStockReport
                        inventory={filteredInventory}
                        branches={branches}
                        startDate={startDate}
                        endDate={endDate}
                        products={products}
                        selectedBranch={selectedBranch}
                        selectedProduct={selectedProduct}
                    />
                );
            case 'movementLog':
                return (
                    <InventoryMovementLogReport
                        inventory={filteredInventory}
                        users={users}
                        branches={branches}
                        products={products}
                        startDate={startDate}
                        endDate={endDate}
                        selectedBranch={selectedBranch}
                        selectedProduct={selectedProduct}
                        selectedUser={selectedUser}
                    />
                );
            case 'stockTransfer':
                return (
                    <StockTransferReport
                        stockTransfers={stockTransfers}
                        branches={branches}
                        products={products}
                        users={users}
                        startDate={startDate}
                        endDate={endDate}
                    />
                );
            case 'reorder':
                return (
                    <ReorderReport
                        inventory={filteredInventory}
                        branches={branches}
                        products={products}
                        selectedBranch={selectedBranch}
                        selectedProduct={selectedProduct}
                        startDate={startDate}
                        endDate={endDate}
                    />
                );
            default:
                return <div className="select-report-message">Please select a report to display.</div>;
        }
    };
    
    // Identify the selected report
    const isInventorySummary = selectedReport === 'summary';
    const isLowStockReport = selectedReport === 'lowStock';
    const isMovementLogReport = selectedReport === 'movementLog';
    const isStockTransferReport = selectedReport === 'stockTransfer';
    const isReorderReport = selectedReport === 'reorder';

    // Disable dropdowns based on the selected report
    const isBranchDisabled =
        isStockTransferReport;

    const isProductDisabled =
        isStockTransferReport;

    const isUserDisabled =
        isInventorySummary ||
        isLowStockReport || 
        isStockTransferReport ||
        isReorderReport;

    const isDateDisabled =
        isInventorySummary ||
        isLowStockReport ||
        isReorderReport;


    return (
        <div className="report-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* DatePicker Modal */}
                    <DatePickerModal 
                        isOpen={isDatePickerModalOpen} 
                        onClose={() => setIsDatePickerModalOpen(false)} 
                        onApply={handleDateRangeApply} 
                    />
                    <div className="tab-navigation">
                        <Link to="/report/sales" className={activeTab === 'sales' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Sales Reports
                        </Link>
                        <Link to="/report/inventory" className={activeTab === 'inventory' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                        Inventory Reports
                        </Link>
                        <Link to="/report/purchases" className={activeTab === 'purchases' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Reports
                        </Link>
                        <Link to="/report/payroll" className={activeTab === 'payroll' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Salary Reports
                        </Link>
                    </div>
                    
                    <div className="page-content">
                        <div className="report-section"> 
                            <div className="left-panel">
                                <div className="first-left-panel">
                                    <div className="filter-section">
                                        <h3>Filter</h3>
                                        <div className="filter-group">
                                            <label>Branch:</label>
                                            <select
                                                value={selectedBranch}
                                                onChange={(e) => setSelectedBranch(e.target.value)}
                                                disabled={isBranchDisabled}
                                            >
                                                <option value="all">All</option>
                                                {branches.map((branch) => (
                                                    <option key={branch.branchID} value={branch.branchID}>
                                                        {branch.branchName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>User:</label>
                                            <select
                                                value={selectedUser}
                                                onChange={(e) => setSelectedUser(e.target.value)}
                                                disabled={isUserDisabled}
                                            >
                                                <option value="all">All</option>
                                                {users.map((user) => (
                                                    <option key={user.userID} value={user.userID}>
                                                        {user.userName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Product:</label>
                                            <select
                                                value={selectedProduct}
                                                onChange={(e) => setSelectedProduct(e.target.value)}
                                                disabled={isProductDisabled}
                                            >
                                                <option value="all">All</option>
                                                {products.map((prod) => (
                                                    <option key={prod.prodNo} value={prod.prodNo}>
                                                        {prod.prodName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Date Range:</label>
                                            <button className="date-range-button" onClick={() => setIsDatePickerModalOpen(true)} disabled={isDateDisabled}>
                                                <FaCalendarAlt className="calendar-icon" /> {/* Calendar Icon */}
                                                {`${startDate ? startDate.toLocaleDateString() : 'Select Start Date'} - ${endDate ? endDate.toLocaleDateString() : 'Select End Date'}`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="second-left-panel">
                                    <div className="report-selection">
                                        <h3>Select Report</h3>
                                        <ul>
                                            <li
                                                onClick={() => handleReportSelection('summary')}
                                                className={selectedReport === 'summary' ? 'active' : ''}
                                            >
                                                Inventory Summary
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('lowStock')}
                                                className={selectedReport === 'lowStock' ? 'active' : ''}
                                            >
                                                Low Stock Report
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('movementLog')}
                                                className={selectedReport === 'movementLog' ? 'active' : ''}
                                            >
                                                Inventory Movement Log
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('stockTransfer')}
                                                className={selectedReport === 'stockTransfer' ? 'active' : ''}
                                            >
                                                Stock Transfer Report
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('reorder')}
                                                className={selectedReport === 'reorder' ? 'active' : ''}
                                            >
                                                Reorder Report
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className="right-panel">
                            {/* Require date range selection */}
                            {(!startDate || !endDate) ? (
                                <div className="select-report-message">
                                    <p>Please select a date range to view reports.</p>
                                </div>
                            ) : (
                                generateReportContent()
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer
                username={username}
                branchID={branchID}
                branchName={branchName}
                handleLogout={handleLogout}
            />
        </div>
    );
}

export default ReportInventory;
