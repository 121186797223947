import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SupplierModal.css';

function SupplierModal({ isOpen, onClose, onSubmit, supplier }) {
    const [formData, setFormData] = useState({
        supName: '',
        supContNo: '',
        supPerson: '',
        supAddr: '',
        supDesc: ''
    });
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (supplier) {
            setIsEditing(true);
            setFormData({
                supName: supplier.supName || '',
                supContNo: supplier.supContNo || '',
                supPerson: supplier.supPerson || '',
                supAddr: supplier.supAddr || '',
                supDesc: supplier.supDesc || ''
            });
        } else {
            setIsEditing(false);
            setFormData({
                supName: '',
                supContNo: '',
                supPerson: '',
                supAddr: '',
                supDesc: ''
            });
        }
    }, [supplier]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/suppliers/${supplier.supID}` // Ensure user.userID is defined
            : 'https://www.capstonelimenerp.xyz:8082/api/suppliers';

        const method = isEditing ? 'put' : 'post';

        const data = { 
            supName: formData.supName,
            supContNo: formData.supContNo,
            supPerson: formData.supPerson,
            supAddr: formData.supAddr,
            supDesc: formData.supDesc
        };

        axios({ method, url, data })
        .then(response => {
            setMessage(isEditing ? 'Supplier updated successfully!' : 'Supplier added successfully!');
            setFormData({
                supName: '',
                supContNo: '',
                supPerson: '',
                supAddr: '',
                supDesc: ''
            });
            onSubmit(data, isEditing);
        })
        .catch(error => {
            console.error(isEditing ? 'Error updating supplier:' : 'Error adding supplier:', error.response || error.message);
            setMessage((isEditing ? 'Error updating supplier: ' : 'Error adding supplier: ') + (error.response?.data?.message || error.message));
        });
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="supplier-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{isEditing ? 'Update Existing Supplier' : 'Add Supplier'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor="supName"><strong>Supplier Name <span style={{ color: 'red' }}>*</span></strong></label>
                        <input
                            type="text"
                            name="supName"
                            value={formData.supName}
                            onChange={handleChange}
                            required={!isEditing}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="supContNo"><strong>Contact Number <span style={{ color: 'red' }}>*</span></strong></label>
                        <div className="input-group">
                            <input
                                type="text" // Keep type as text
                                inputMode="numeric" // Suggests a numeric keypad on mobile devices
                                pattern="[0-9]*" // Allows only digits
                                name='supContNo'
                                onChange={handleChange}
                                value={formData.supContNo}
                                required={!isEditing}
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="supPerson"><strong>Contact Person</strong></label>
                        <div className="input-group">
                            <input
                                type="text"
                                name='supPerson'
                                onChange={handleChange}
                                value={formData.supPerson}
                            />
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="supAddr"><strong>Address <span style={{ color: 'red' }}>*</span></strong></label>
                            <input
                                name="supAddr"
                                value={formData.supAddr}
                                onChange={handleChange}
                                required={!isEditing}
                            />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="supDesc"><strong>Description</strong></label>
                            <textarea
                                name="supDesc"
                                value={formData.supDesc}
                                onChange={handleChange}
                                rows="4" // Adjust the number of rows as needed
                                style={{ width: '100%' }} // Ensure it takes full width
                            />
                    </div>
                    <div className='button-container'>
                        <button type="submit" className='user-button'>{isEditing ? 'Save Supplier' : 'Add Supplier'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SupplierModal;