import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import CustomerModal from './CustomerModal';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import MessageModal from './MessageModal';
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';

function Customer() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [regulars, setRegulars] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [customerToDelete, setcustomerToDelete] = useState(null);
    const [activeTab, setActiveTab] = useState('customers');

    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');

        if (storedUsername) {
            setUsername(storedUsername);
        }

        if (storedBranchName) {
            setBranchName(storedBranchName);
        }

        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        const fetchData = async () => {
            try {
                const [customersResponse, usersResponse, branchesResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/regularcustomers'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/users'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                ]);
                setRegulars(customersResponse.data);
                setUsers(usersResponse.data);
                setBranches(branchesResponse.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            axios.get('https://www.capstonelimenerp.xyz:8082/api/regularcustomers')
                .then(response => {
                    console.log('Customer data refreshed successfully:', response.data);
                    setRegulars(response.data);
                    setRefreshTable(false);
                })
                .catch(error => {
                    console.error('There was an error refreshing the customer data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const formatCustName = (regular) => {
        const middleInitial = regular.custMName ? `${regular.custMName.charAt(0)}.` : '';
        return `${regular.custFName} ${middleInitial} ${regular.custLName}`;
    };


    const handleAddCustomer = () => {
        console.log('Add Customer button clicked');
        setSelectedCustomer(null);
        setIsModalOpen(true);
        setIsViewMode(false);
    };

    const handleViewRequest = (customer) => {
        setSelectedCustomer(customer); 
    };

    const handleEditCustomer = (regular) => {
        setSelectedCustomer(regular);
        setIsModalOpen(true);
    };
    

    const confirmDeleteCustomer= (custNo) => {
        console.log("Delete button clicked for custNo:", custNo);
        setcustomerToDelete(custNo);
        setMessage('Are you sure you want to delete this customer?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };

    const handleDeleteCustomer = () => {
        if (customerToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/customers/${customerToDelete}`)
                .then(() => {
                    setRegulars(regulars.filter(regular => regular.custNo !== customerToDelete));
                    setMessage('Customer has been successfully deleted.');
                    setIsMessageModalOpen(true); // Show success message modal
                    setDeletionConfirmed(true);
                    setRefreshTable(true); // Trigger table refresh
                })
                .catch(error => {
                    console.error('There was an error deleting the customer!', error);
                    setMessage('Error deleting customer');
                    setIsMessageModalOpen(true); // Show error message
                    setDeletionConfirmed(true);
                });
        }
    };
    

    const handleModalClose = () => {
        setIsModalOpen(false);
        setRefreshTable(true);
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
        const successMessage = isEditing ? 'Customer updated successfully!' : 'Customer added successfully!';
        setMessage(successMessage);
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
        setIsConfirmation(false);
        setRefreshTable(true);
    };

    const filteredCustomers = regulars
        .filter(regular => 
            (filter === 'all' || regular.custNo === filter) &&
            (regular.custFName || '').toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1);
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedCustomers = filteredCustomers.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredCustomers.length / recordsPerPage);

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'N/A';
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const userMap = new Map(users.map(user => [user.userID, user.userName]));
    const branchMap = new Map(branches.map(branch => [branch.branchID, branch.branchName]));

    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <CustomerModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        regular={selectedCustomer} // Ensure this prop is correctly passed
                    />

                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false);
                            setIsConfirmation(false);
                        }}
                        message={message}
                        isConfirmation={isConfirmation}
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteCustomer();
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/customer/information" className={activeTab === 'customers' ? 'active' : ''} onClick={() => setActiveTab('customers')}>
                            Customer Information
                        </Link>
                        <Link to="/customer/balance" className={activeTab === 'balance' ? 'active' : ''} onClick={() => setActiveTab('balance')}>
                            Customer Sales History
                        </Link>
                    </div>
                    <div className="form-actions">
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search customer name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <button className="action-button" onClick={handleAddCustomer}>Add Customer</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                                <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>ID</th>
                                    <th>Customer Name</th>
                                    <th>Address</th>
                                    <th>Email</th>
                                    <th style={{width: '100px'}}>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {displayedCustomers.length > 0 ? (
                                        displayedCustomers.map((regular, index) => (
                                            <tr key={regular.custNo}>
                                                <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                                <td>{regular.custNo}</td>
                                                <td>{formatCustName(regular)}</td>
                                                <td>{regular.custAddr}</td>
                                                <td>{regular.custEmail}</td>
                                                <td style={{width: '100px'}}>
                                                    <button className="edit-button" onClick={() => handleEditCustomer(regular)}>
                                                        <FaEdit />
                                                    </button>
                                                    <button className="delete-button" onClick={() => confirmDeleteCustomer(regular.custNo)}>
                                                        <FaTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="14" style={{ textAlign: 'center' }}>No customers found</td>
                                        </tr>
                                    )}
                                </tbody>
                        </table>
                    </div>
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default Customer;
