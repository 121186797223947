import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductModal.css';
import EditModal from './EditModal';
import { FaPlusCircle, FaEdit } from 'react-icons/fa';
import MessageModal from './MessageModal';

function ProductModal({ isOpen, onClose, onSubmit, product }) {
    const [formData, setFormData] = useState({
        prodName: '',
        categoryID: '',
        brandID: '',
        prodSKU: '',
        prodBarcode: '',
        prodCost: '',
        prodPrice: '',
        prodUOM: '',
        prodStatus: 'Active',
        prodDesc: ''
    });
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editItemData, setEditItemData] = useState(null);
    const [editItemType, setEditItemType] = useState('');
    const [editType, setEditType] = useState('');
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    // Fetch categories and brands on component load
    useEffect(() => {
        axios.get('https://www.capstonelimenerp.xyz:8082/api/categories')
            .then(response => setCategories(response.data))
            .catch(error => console.error('Error fetching categories:', error));

        axios.get('https://www.capstonelimenerp.xyz:8082/api/brands')
            .then(response => setBrands(response.data))
            .catch(error => console.error('Error fetching brands:', error));
    }, []);

    // Handle opening the edit modal
    const handleEdit = (type) => {
        const isCreatingNew = formData[`${type}ID`] === '';
        const itemData = isCreatingNew
            ? { id: null, name: '' } // Create new item
            : {
                id: formData[`${type}ID`],
                name:
                    type === 'category'
                        ? categories.find(c => c.categoryID === formData.categoryID)?.categoryName
                        : brands.find(b => b.brandID === formData.brandID)?.brandName
            };

        setEditItemType(type);
        setEditItemData(itemData);
        setIsEditModalOpen(true);
    };

    // Handle success from EditModal
    const handleSuccess = () => {
        axios.get('https://www.capstonelimenerp.xyz:8082/api/categories')
            .then(response => setCategories(response.data))
            .catch(error => console.error('Error refreshing categories:', error));

        axios.get('https://www.capstonelimenerp.xyz:8082/api/brands')
            .then(response => setBrands(response.data))
            .catch(error => console.error('Error refreshing brands:', error));
    };

    useEffect(() => {
        if (product) {
            setIsEditing(true);
            setFormData({
                prodName: product.prodName || '',
                categoryID: product.categoryID || '',
                brandID: product.brandID || '',
                prodSKU: product.prodSKU || '',
                prodBarcode: product.prodBarcode || '',
                prodCost: product.prodCost || '',
                prodPrice: product.prodPrice || '',
                prodUOM: product.prodUOM || '',
                prodStatus: product.prodStatus || 'Active',
                prodDesc: product.prodDesc || ''
            });
        } else {
            setIsEditing(false);
            setFormData({
                prodName: '',
                categoryID: '',
                brandID: '',
                prodSKU: '',
                prodBarcode: '',
                prodCost: '',
                prodPrice: '',
                prodUOM: '',
                prodStatus: 'Active',
                prodDesc: ''
            });
        }
    }, [product]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    
    const handleBarcodeGeneration = async () => {
        try {
            // Ensure a category is selected
            if (!formData.categoryID) {
                setMessage('Please select a category before generating a barcode.');
                setMessageModalOpen(true);
                return;
            }
    
            // Extract numeric part of categoryID (if prefixed with non-numeric characters)
            const numericCategoryID = formData.categoryID.replace(/\D/g, ''); // Remove all non-numeric characters
    
            if (!numericCategoryID) {
                setMessage('The category ID must contain numeric values only.');
                setMessageModalOpen(true);
                return;
            }
    
            // Generate the barcode parts
            const timestampSuffix = Date.now().toString().slice(-7); // Last 7 digits of timestamp
            const randomSuffix = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
    
            // Combine to create a raw barcode
            let rawBarcode = `${numericCategoryID}${timestampSuffix}${randomSuffix}`;
    
            // Remove leading zeros
            rawBarcode = rawBarcode.replace(/^0+/, '');
    
            // Ensure the barcode is exactly 13 digits
            while (rawBarcode.length < 13) {
                rawBarcode += Math.floor(Math.random() * 10); // Append random digits
            }
    
            // Validate barcode uniqueness
            const response = await axios.post(
                'https://www.capstonelimenerp.xyz:8082/api/check-barcode',
                { barcode: rawBarcode }
            );
    
            if (response.data.isUnique) {
                setFormData(prevState => ({
                    ...prevState,
                    prodBarcode: rawBarcode
                }));
                setMessage('Barcode generated successfully!');
            } else {
                setMessage('Generated barcode already exists. Please try again.');
            }
        } catch (error) {
            console.error('Error generating barcode:', error);
            setMessage('Failed to generate barcode. Please try again.');
        } finally {
            setMessageModalOpen(true); // Open the modal with the result
        }
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if the barcode is unique
        try {
            const barcodeResponse = await axios.post(
                'https://www.capstonelimenerp.xyz:8082/api/check-barcode',
                { barcode: formData.prodBarcode }
            );
    
            if (!barcodeResponse.data.isUnique) {
                setMessage('The entered barcode already exists. Please provide a unique barcode.');
                setMessageModalOpen(true); // Show the message modal
                return; // Stop form submission
            }
        } catch (error) {
            console.error('Error validating barcode:', error);
            setMessage('Failed to validate the barcode. Please try again.');
            setMessageModalOpen(true); // Show the message modal
            return; // Stop form submission
        }
    
        // Prepare data to send
        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/products/${product.prodNo}`
            : 'https://www.capstonelimenerp.xyz:8082/api/products';
        const method = isEditing ? 'put' : 'post';
    
        const data = {
            prodName: formData.prodName,
            categoryID: formData.categoryID,
            brandID: formData.brandID,
            prodSKU: formData.prodSKU,
            prodBarcode: formData.prodBarcode,
            prodCost: formData.prodCost,
            prodPrice: formData.prodPrice,
            prodUOM: formData.prodUOM,
            prodStatus: formData.prodStatus,
            prodDesc: formData.prodDesc,
        };
    
        try {
            await axios({ method, url, data });
            setMessage(isEditing ? 'Product updated successfully!' : 'Product added successfully!');
            setFormData({
                prodName: '',
                categoryID: '',
                brandID: '',
                prodSKU: '',
                prodBarcode: '',
                prodCost: '',
                prodPrice: '',
                prodUOM: '',
                prodStatus: 'Active',
                prodDesc: '',
            });
            setMessageModalOpen(true); // Show success message
            onClose();
            onSubmit(data, isEditing);
        } catch (error) {
            console.error(isEditing ? 'Error updating product:' : 'Error adding product:', error.response || error.message);
            setMessage(
                (isEditing ? 'Error updating product: ' : 'Error adding product: ') +
                (error.response?.data?.message || error.message)
            );
            setMessageModalOpen(true); // Show error message
        }
    };
    

    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="product-modal-overlay">
            <div className="product-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{isEditing ? 'Update Existing Product' : 'Add New Product'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <div className="left-section" style={{width: '60%', height:'100%'}}>
                            <h4 className="section-title">Product Information</h4>
                            <div className="depth">
                                <div className="form-group" style={{maxWidth: '70%'}}>
                                    <label htmlFor="prodName"><strong>Product Name <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        name="prodName"
                                        value={formData.prodName}
                                        onChange={handleChange}
                                        required={!isEditing}
                                    />
                                </div>
                                <div className="form-group" style={{maxWidth: '30%'}}>
                                    <label htmlFor="prodStatus"><strong>Status <span style={{ color: 'red' }}>*</span></strong></label>
                                    <select
                                        name="prodStatus"
                                        value={formData.prodStatus}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <div className="depth">
                                {/* Category Dropdown */}
                                <div className="form-group">
                                    <label>Category <span style={{ color: 'red' }}>*</span></label>
                                    <div className="dropdown-container" style={{display: 'flex', gap: '10px'}}>
                                        <select
                                            value={formData.categoryID}
                                            onChange={(e) => handleChange({ target: { name: 'categoryID', value: e.target.value } })}
                                            required
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.categoryID} value={category.categoryID}>
                                                    {category.categoryName}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            type="button"
                                            className="edit-dropdown-button"
                                            onClick={() => handleEdit('category')}
                                        >
                                            <FaEdit className="edit-icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="depth">
                                {/* Brand Dropdown */}
                                <div className="form-group">
                                    <label>Brand <span style={{ color: 'red' }}>*</span></label>
                                    <div className="dropdown-container" style={{display: 'flex', gap: '10px'}}>
                                        <select
                                            value={formData.brandID}
                                            onChange={(e) => handleChange({ target: { name: 'brandID', value: e.target.value } })}
                                            required
                                        >
                                            <option value="">Select Brand</option>
                                            {brands.map(brand => (
                                                <option key={brand.brandID} value={brand.brandID}>
                                                    {brand.brandName}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            type="button"
                                            className="edit-dropdown-button"
                                            onClick={() => handleEdit('brand')}
                                        >
                                            <FaEdit className="edit-icon" />
                                        </button>
                                    </div>
                                </div>          
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="prodSKU"><strong>SKU</strong></label>
                                    <input
                                        type="text"
                                        name="prodSKU"
                                        value={formData.prodSKU}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="prodBarcode"><strong>Barcode</strong></label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="text"
                                            name="prodBarcode"
                                            value={formData.prodBarcode}
                                            onChange={handleChange}
                                        />
                                        <button className='generate-btn' type="button" onClick={handleBarcodeGeneration}>
                                            <FaPlusCircle />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="prodDesc"><strong>Description</strong></label>
                                    <textarea
                                        name="prodDesc"
                                        value={formData.prodDesc}
                                        onChange={handleChange}
                                        className="description-textarea"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="right-section" style={{width: '40%', height:'100%', overflowY: 'hidden'}}>
                                <h4 className="section-title">Pricing Information</h4>
                                <div className="form-group">
                                    <label htmlFor="prodCost"><strong>Cost <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        placeholder='0.00'
                                        name="prodCost"
                                        value={formData.prodCost}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="prodPrice"><strong>Sale Price <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        placeholder='0.00'
                                        name="prodPrice"
                                        value={formData.prodPrice}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="prodUOM"><strong>Unit of Measure <span style={{ color: 'red' }}>*</span></strong></label>
                                    <select
                                        name="prodUOM"
                                        value={formData.prodUOM}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select UOM</option>
                                        <option value="Piece">Piece</option>
                                        <option value="Set">Set</option>
                                        <option value="Box">Box</option>
                                        <option value="Pair">Pair</option>
                                        <option value="Roll">Roll</option>
                                        <option value="Liter">Liter</option>
                                        <option value="Milliliter">Milliliter</option>
                                        <option value="Kilogram">Kilogram</option>
                                        <option value="Gram">Gram</option>
                                        <option value="Dozen">Dozen</option>
                                        <option value="Pack">Pack</option>
                                    </select>
                                </div>
                                <div className='button-container'>
                                    <button type="submit" className="product-button">{isEditing ? 'Save Product' : 'Add Product'}</button>
                                </div>
                        </div>
                    </div>
                </form>
            </div>
            <EditModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                itemType={editItemType}
                itemData={editItemData}
                onSuccess={handleSuccess}
            />
            <MessageModal 
                isOpen={messageModalOpen} 
                onClose={closeMessageModal} 
                message={message} 
                showCloseButton={showCloseButton} // Pass showCloseButton to MessageModal
            />
        </div>
    );
}

export default ProductModal;
