import React, { useState, useEffect } from 'react';
import './SalesReturnModal.css';
import AuthorizeModal from './AuthorizeModal';
import MessageModal from './MessageModal';
import { FaArrowRight, FaAngleDoubleRight, FaArrowLeft, FaAngleDoubleLeft } from 'react-icons/fa';
function SalesReturnModal({ isOpen, onClose, userID }) {
    const [oRNo, setORNo] = useState('');
    const [originalLineItems, setOriginalLineItems] = useState([]); // Items in the left box
    const [returnLineItems, setReturnLineItems] = useState([]); // Items in the right box
    const [activeOriginalItem, setActiveOriginalItem] = useState(null); // Track active item in the original list
    const [activeReturnItem, setActiveReturnItem] = useState(null); // Track active item in the return list
    const [totalRefundAmount, setTotalRefundAmount] = useState(0);
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [shouldCloseParentModal, setShouldCloseParentModal] = useState(false);

    console.log("Logged-in User ID:", userID);

    useEffect(() => {
        if (isOpen) {
            setActiveOriginalItem(null);
            setActiveReturnItem(null);
        }
    }, [isOpen]);

    useEffect(() => {
        const total = returnLineItems.reduce((sum, item) => sum + item.prodQty * item.prodPrice, 0);
        setTotalRefundAmount(total);
    }, [returnLineItems]);
    if (!isOpen) return null;

    const handleFetchLineItems = async () => {
        // Clear the original and return line items before fetching new ones
        setOriginalLineItems([]);
        setReturnLineItems([]);
        try {
            const response = await fetch(`https://www.capstonelimenerp.xyz:8082/api/sales/${oRNo}/line-items`);
            if (response.ok) {
                const data = await response.json();
                console.log("Fetched Data:", data); // Log fetched data
    
                // Filter out items with quantity 0
                const filteredData = data.filter(item => item.prodQty > 0);

                if (filteredData.length === 0) {
                    setMessage("All items for this transaction have already been refunded.");
                    setIsMessageModalOpen(true);
                    return;
                }

                // Map the fetched data properly
                const mappedData = filteredData.map(item => ({
                    lineItemNo: item.lineItemNo,
                    prodNo: item.prodNo,
                    prodName: item.prodName,
                    prodQty: item.prodQty,
                    prodPrice: item.prodPrice,
                    amount: item.amount || item.prodQty * item.prodPrice, // Calculate amount if not provided
                }));
    
                    setOriginalLineItems(mappedData);
                    console.log("Set Original Line Items:", mappedData);
            } else {
                console.error("Error fetching line items:", response.statusText);
                setMessage("Error fetching sales line items. Please try again.");
                setIsMessageModalOpen(true);
            }
        } catch (error) {
            console.error("Error fetching line items:", error);
            setMessage("Error fetching sales line items. Please try again.");
            setIsMessageModalOpen(true);
        }
    };
    
    const handleConfirmReturn = async () => {
        const requestBody = {
            originalORNo: oRNo,          // Original sale's ORNo
            returnLineItems: returnLineItems, // Items being returned
            refundAmount: totalRefundAmount, // Total refund amount
            userID: userID,              // User performing the refund
        };
    
        console.log("Request Body:", requestBody);  // Log request body for debugging
    
        try {
            const response = await fetch('https://www.capstonelimenerp.xyz:8082/api/sales/refund', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody),
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Refund transaction created successfully:', data.newORNo);
                // Show success message in MessageModal
                setMessage('Return processed successfully!');
                setIsMessageModalOpen(true);
                setShouldCloseParentModal(true); // Mark the parent modal for closure after the message modal is closed
            } else {
                const errorData = await response.json();  // Get error details from the response
                console.error('Error creating refund transaction:', errorData);
                // Set error message
                setMessage('Error processing return. Please try again.');
                setIsMessageModalOpen(true);
            }
        } catch (error) {
            console.error('Error creating refund transaction:', error);
            alert('An error occurred. Please try again.');
        }
    };
    


    const handleActiveOriginalItem = (item) => {
        console.log("Selecting Original Item:", item);
        setActiveOriginalItem(item);
        setActiveReturnItem(null);
    };
    
    const handleActiveReturnItem = (item) => {
        console.log("Selecting Return Item:", item);
        setActiveReturnItem(item);
        setActiveOriginalItem(null);
    };

    // Handlers to move quantities between original and return line items
    const handleMoveOneToReturn = () => {
        if (!activeOriginalItem) return;

        const item = activeOriginalItem;
        const updatedOriginal = originalLineItems.map(i => 
            i.lineItemNo === item.lineItemNo ? { ...i, prodQty: i.prodQty - 1 } : i
        ).filter(i => i.prodQty > 0);

        const existingReturnItem = returnLineItems.find(i => i.lineItemNo === item.lineItemNo);
        const updatedReturn = existingReturnItem
            ? returnLineItems.map(i => 
                i.lineItemNo === item.lineItemNo ? { ...i, prodQty: i.prodQty + 1 } : i
            )
            : [...returnLineItems, { ...item, prodQty: 1 }];

        setOriginalLineItems(updatedOriginal);
        setReturnLineItems(updatedReturn);
        setActiveOriginalItem(null); // Clear active item after moving
    };

    const handleMoveAllToReturn = () => {
        if (!activeOriginalItem) return;
    
        const item = activeOriginalItem;
    
        // Remove the item from the original list
        setOriginalLineItems(originalLineItems.filter(i => i.lineItemNo !== item.lineItemNo));
    
        // Check if the item already exists in the return list
        const existingReturnItem = returnLineItems.find(i => i.lineItemNo === item.lineItemNo);
    
        if (existingReturnItem) {
            // If the item exists, update its quantity by adding the original item quantity
            const updatedReturn = returnLineItems.map(i => 
                i.lineItemNo === item.lineItemNo 
                    ? { ...i, prodQty: i.prodQty + item.prodQty } 
                    : i
            );
            setReturnLineItems(updatedReturn);
        } else {
            // If the item doesn't exist, add it to the return list
            setReturnLineItems([...returnLineItems, { ...item }]);
        }
    
        // Clear the active item after moving
        setActiveOriginalItem(null);
    };
    

    const handleMoveOneToOriginal = () => {
        if (!activeReturnItem) return;

        const item = activeReturnItem;
        const updatedReturn = returnLineItems.map(i => 
            i.lineItemNo === item.lineItemNo ? { ...i, prodQty: i.prodQty - 1 } : i
        ).filter(i => i.prodQty > 0);

        const existingOriginalItem = originalLineItems.find(i => i.lineItemNo === item.lineItemNo);
        const updatedOriginal = existingOriginalItem
            ? originalLineItems.map(i => 
                i.lineItemNo === item.lineItemNo ? { ...i, prodQty: i.prodQty + 1 } : i
            )
            : [...originalLineItems, { ...item, prodQty: 1 }];

        setReturnLineItems(updatedReturn);
        setOriginalLineItems(updatedOriginal);
        setActiveReturnItem(null); // Clear active item after moving
    };

    const handleMoveAllToOriginal = () => {
        if (!activeReturnItem) return;
    
        const item = activeReturnItem;
    
        // Remove the item from the return list
        setReturnLineItems(returnLineItems.filter(i => i.lineItemNo !== item.lineItemNo));
    
        // Check if the item already exists in the original list
        const existingOriginalItem = originalLineItems.find(i => i.lineItemNo === item.lineItemNo);
    
        if (existingOriginalItem) {
            // If the item exists, update its quantity by adding the return item quantity
            const updatedOriginal = originalLineItems.map(i => 
                i.lineItemNo === item.lineItemNo 
                    ? { ...i, prodQty: i.prodQty + item.prodQty } 
                    : i
            );
            setOriginalLineItems(updatedOriginal);
        } else {
            // If the item doesn't exist, add it to the original list
            setOriginalLineItems([...originalLineItems, { ...item }]);
        }
    
        // Clear the active item after moving
        setActiveReturnItem(null);
    };
    

    return (
        <div className="return-modal-overlay">
            <div className="return-modal">
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2>Process Return</h2>
                <div className="modal-header">
                    
                    <div className="search-or-no">
                        <input 
                            type="text"
                            placeholder="Enter OR No."
                            value={oRNo}
                            style={{width: '80%'}}
                            onChange={(e) => setORNo(e.target.value)}
                        />
                        <button onClick={handleFetchLineItems}>Fetch Sale</button>
                        
                    </div>
                    <div className="total-refund">₱{totalRefundAmount.toFixed(2)}</div>
                </div>

                <div className="return-container">
                    {/* Left Box - Original Line Items */}
                    <div className="line-items-box">
                        <h3>Sales Line Items</h3>
                        <ul>
                            {originalLineItems.map((item) => (
                                <li 
                                    key={item.lineItemNo}
                                    className={`line-item ${activeOriginalItem?.lineItemNo === item.lineItemNo ? 'active' : ''}`}
                                    onClick={() => handleActiveOriginalItem(item)} // Set active item for original list
                                >
                                    <div className="product-details">
                                        <div className="left-details">
                                            <div className="prod-name">{item.prodName}</div>
                                            <div className="product-qty-price">
                                                <span>{item.prodQty} x {item.prodPrice.toFixed(2)}</span>
                                            </div>
                                        </div>
                                        <div className="right-details">
                                            <div className="product-amount">₱{(item.prodQty * item.prodPrice).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Button Panel */}
                    <div className="button-panel">
                        {/* Group for moving to return */}
                        <div className="move-to-return">
                            <button onClick={handleMoveOneToReturn} disabled={!activeOriginalItem}>
                                <FaArrowRight />
                            </button>
                            <button onClick={handleMoveAllToReturn} disabled={!activeOriginalItem}>
                                <FaAngleDoubleRight />
                            </button>
                        </div>

                        {/* Group for moving to original */}
                        <div className="move-to-original">
                            <button onClick={handleMoveOneToOriginal} disabled={!activeReturnItem}>
                                <FaArrowLeft />
                            </button>
                            <button onClick={handleMoveAllToOriginal} disabled={!activeReturnItem}>
                                <FaAngleDoubleLeft />
                            </button>
                        </div>
                    </div>

                    {/* Right Box - Selected Items for Return */}
                    <div className="line-items-box">
                        <h3>Items to Return</h3>
                        <ul>
                            {returnLineItems.map((item) => (
                                <li 
                                    key={item.lineItemNo}
                                    className={`line-item ${activeReturnItem?.lineItemNo === item.lineItemNo ? 'active' : ''}`}
                                    onClick={() => handleActiveReturnItem(item)} // Set active item for return list
                                >
                                    <div className="product-details">
                                        <div className="left-details">
                                            <div className="prod-name">{item.prodName}</div>
                                            <div className="product-qty-price">
                                                <span>{item.prodQty} x {item.prodPrice.toFixed(2)}</span>
                                            </div>
                                        </div>
                                        <div className="right-details">
                                            <div className="product-amount">₱{(item.prodQty * item.prodPrice).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                
                <div className="modal-footer">
                    <button 
                        className="confirm-return-button"
                        onClick={() => {
                            if (returnLineItems.length === 0) {
                                // Show error message if return box is empty
                                setMessage("No items to return. Please add items to refund.");
                                setIsMessageModalOpen(true);
                            } else {
                                // Open the authorization modal if return box is not empty
                                setAuthModalOpen(true);
                            }
                        }}
                    >
                        Confirm Return
                    </button>
                </div>
            </div>
            <AuthorizeModal 
                isOpen={authModalOpen}
                onClose={() => setAuthModalOpen(false)}
                onAuthorize={handleConfirmReturn}
            />
            <MessageModal
                isOpen={isMessageModalOpen}
                onClose={() => {
                    setIsMessageModalOpen(false);
                    if (shouldCloseParentModal) {
                        setShouldCloseParentModal(false);
                        onClose(); // Close the parent modal (SalesReturnModal)
                    }
                }}
                message={message}
            />
        </div>
    );
}

export default SalesReturnModal;
