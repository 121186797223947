import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';   
import axios from 'axios';
import { FaEye, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import MessageModal from './MessageModal';
import PurchaseOrderModal from './PurchaseOrderModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';

function PurchaseOrder() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [orderData, setOrderData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('orders'); // Default tab

    const [recordsCount, setRecordsCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');

        if (storedUsername) {
            setUsername(storedUsername);
        }

        if (storedBranchName) {
            setBranchName(storedBranchName);
        }

        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch purchase order data
        const fetchData = async () => {
            try {
                const [orderResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/purchaseorders')
                ]);
                setOrderData(orderResponse.data); // Set purchase order data
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch purchase orders again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/purchaseorders')
                .then(response => {
                    setOrderData(response.data); // Set updated purchase order data
                    setRefreshTable(false); // Reset refresh trigger
                })
                .catch(error => {
                    console.error('There was an error refreshing the purchase order data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddOrder = () => {
        setSelectedOrder(null); // Clear selected order
        setLineItems([]); // Clear line items
        setIsModalOpen(true);     // Open the modal for adding a new order
        setIsViewMode(false);     // Ensure the mode is set to add
    };

    const handleViewOrder = (order) => {
        setSelectedOrder(order);
    
        // Fetch the line items for the order
        axios.get(`https://www.capstonelimenerp.xyz:8082/api/purchaseorder/${order.purReqID}/lineitems`)
            .then(async (response) => {
                const items = response.data;
    
                // Map the product data into line items
                const itemsWithNames = await Promise.all(items.map(async (item) => {
                    try {
                        const productResponse = await axios.get(
                            `https://www.capstonelimenerp.xyz:8082/api/products/${item.prodNo}`
                        );
                        return {
                            ...item,
                            prodName: productResponse.data.prodName, // Add product name
                        };
                    } catch (error) {
                        console.error(`Error fetching product: ${item.prodNo}`, error);
                        return { ...item, prodName: '' }; // Fallback
                    }
                }));
    
                setLineItems(itemsWithNames); // Set line items with product names
                setIsModalOpen(true);         // Open the modal
                setIsViewMode(true);          // Set view mode
            })
            .catch((error) => {
                console.error('Error fetching line items:', error);
            });
    };
    

    const confirmDeleteOrder = (purOrderID) => {
        setOrderToDelete(purOrderID);
        setMessage('Are you sure you want to delete this purchase order?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false);
        setIsConfirmation(true);
    };

    const handleDeleteOrder = () => {
        if (orderToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/purchaseorder/${orderToDelete}`)
                .then(() => {
                    setOrderData(orderData.filter(order => order.purOrderID !== orderToDelete));
                    setMessage('Purchase order and associated line items successfully deleted.');
                    setIsMessageModalOpen(true);
                    setRefreshTable(true);
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || 'Error deleting purchase order. Please try again later.';
                    setMessage(errorMessage);
                    setIsMessageModalOpen(true);
                });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
        const successMessage = isEditing ? 'Purchase order updated successfully!' : 'Order created successfully!';
        setMessage(successMessage);
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
        setIsConfirmation(false);
        setRefreshTable(true);
    };

    const filteredOrders = orderData.filter(order => {
        const matchesSearchQuery = (
            order.branchName?.toLowerCase().includes(searchQuery.toLowerCase()) || // Add branch name to search
            order.purRequester?.toLowerCase().includes(searchQuery.toLowerCase()) || 
            false
        );
        const matchesFilter = filter === 'all' || order.requestStatus === filter; // Ensure filter comparison is correct
        return matchesSearchQuery && matchesFilter;
    });
    

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedOrders = filteredOrders.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredOrders.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <PurchaseOrderModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        lineItems={lineItems}
                        order={selectedOrder}
                        isViewMode={isViewMode}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setIsConfirmation(false);
                        }}
                        message={message}
                        isConfirmation={isConfirmation}
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteOrder();
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/purchase/transaction" className={activeTab === 'transactions' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Purchase Transaction
                        </Link>
                        <Link to="/purchase/order" className={activeTab === 'orders' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                            Purchase Order
                        </Link>
                        <Link to="/purchase/suggestion" className={activeTab === 'suggestions' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Suggestion
                        </Link>
                    </div>

                    <div className='form-actions'>
                    <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search branch name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Partially Fulfilled">Partially Fulfilled</option>
                                <option value="Fulfilled">Fulfilled</option>
                            </select>
                            <button className="action-button" onClick={handleAddOrder}>Create Order</button>
                        </div>
                    </div>

                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>

                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Request ID</th>
                                    <th>Requester</th>
                                    <th>Order Date</th>
                                    <th>Status</th>
                                    <th style={{width: '100px'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedOrders.length > 0 ? (
                                    displayedOrders.map((order, index) => (
                                        <tr key={order.purReqID}>  {/* Updated key to purReqID */}
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{order.purReqID}</td>  {/* Correctly displaying purReqID */}
                                            <td>{order.branchName}</td>  {/* Correctly displaying branch name */}
                                            <td>{new Date(order.reqDate).toLocaleDateString()}</td>
                                            <td>{order.requestStatus}</td>  {/* Correctly displaying orderStatus */}
                                            <td style={{width: '100px'}}>
                                                <button className="view-button" onClick={() => handleViewOrder(order)}>
                                                    <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} />
                                                </button>
                                                <button className="delete-button" onClick={() => confirmDeleteOrder(order.purReqID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center' }}>No purchase orders found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>


                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default PurchaseOrder;
