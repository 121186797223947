// DashboardData.js
import axios from 'axios';
import moment from 'moment-timezone';

export const fetchBranches = async () => {
    try {
        const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/branches');
        console.log("Fetched Branches Data:", response.data); // Log the data for debugging
        return response.data;
    } catch (error) {
        console.error("Error fetching branches:", error);
        return [];
    }
};


export const fetchTodayTotalSales = async (branchID) => {
    try {
        const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/sales/transactions');
        const transactions = response.data;

        // Define start and end of today in UTC
        const todayStart = moment().utc().startOf('day');
        const todayEnd = moment().utc().endOf('day');

        // Filter transactions for today
        const filteredTransactions = transactions.filter(transaction => {
            const transactionDate = moment(transaction.dateOfSale).utc();
            const isToday = transactionDate.isBetween(todayStart, todayEnd, null, '[]');
            const matchesBranch = branchID === "all" || transaction.branch_ID_fk === branchID;
            return isToday && matchesBranch;
        });

        // Calculate total sales (sum of grandTotal for "Sale" transactions)
        const totalSales = filteredTransactions
            .filter(transaction => transaction.paymentStatus === 'Paid' && transaction.transactionType === 'Sale')
            .reduce((acc, transaction) => acc + transaction.grandTotal, 0);

        // Calculate total refunds (sum of grandTotal for "Refund" transactions)
        const totalRefunds = filteredTransactions
            .filter(transaction => transaction.transactionType === 'Refund')
            .reduce((acc, transaction) => acc + Math.abs(transaction.grandTotal), 0); // Use absolute value to avoid sign issues

        // Calculate net total sales (sales - refunds)
        const netSales = totalSales - totalRefunds;

        console.log("Total Sales:", totalSales, "Total Refunds:", totalRefunds, "Net Sales:", netSales);

        return netSales;
    } catch (error) {
        console.error("Error fetching today's total sales:", error);
        return 0;
    }
};




export const fetchTopProducts = async (branchID = null) => {
    try {
        const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/sales/top-products');
        const transactions = response.data;

        // Filter transactions by branchID if provided
        const filteredTransactions = branchID === "all" || !branchID
            ? transactions
            : transactions.filter(transaction => transaction.branch_ID_fk === branchID);

        // Calculate top products by summing up quantities of each product
        const productQuantities = filteredTransactions.reduce((acc, transaction) => {
            if (transaction.paymentStatus === "Paid" && transaction.prodName) {
                acc[transaction.prodName] = (acc[transaction.prodName] || 0) + transaction.prodQty;
            }
            return acc;
        }, {});

        // Convert to an array and sort by quantity in descending order
        const topProducts = Object.entries(productQuantities)
            .map(([name, quantity]) => ({ name, quantity }))
            .sort((a, b) => b.quantity - a.quantity)
            .slice(0, 5); // Limit to top 5 products

        return topProducts;
    } catch (error) {
        console.error("Error fetching top products:", error);
        return [];
    }
};


export const fetchLowStockItems = async (branchID) => {
    try {
        const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/stocks');
        const inventoryData = response.data;

        // Filter for low stock items based on branchID and stock levels
        const lowStockItems = inventoryData.filter(item => {
            const isLowStock = item.stockOnHand <= item.reorderQty;
            const isMatchingBranch = branchID === "all" || item.branch_id_fk === branchID;
            return isLowStock && isMatchingBranch;
        });

        return lowStockItems.map(item => ({
            name: item.prodName,  // Product name from joined product table
            stock: item.stockOnHand,
            branch: item.branchName  // Branch name for reference
        }));
    } catch (error) {
        console.error("Error fetching low stock items:", error);
        return [];
    }
};

export const fetchSalesTrend = async (startDate, endDate, branchID = 'all') => {
    try {
        const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/sales/transactions');
        const transactions = response.data;

        console.log("Fetching sales trend data...");  // Debugging log
        console.log("Selected branch ID:", branchID); // Log selected branch ID
        console.log("Transactions fetched:", transactions.length); // Log the number of transactions fetched

        // Define start and end of date range in UTC
        const start = moment(startDate).utc().startOf('day');
        const end = moment(endDate).utc().endOf('day');

        // Generate date range array for every day between start and end date
        let salesTrend = [];
        let currentDate = start.clone();
        while (currentDate.isSameOrBefore(end)) {
            salesTrend.push({ date: currentDate.format('YYYY-MM-DD'), total: 0 });
            currentDate.add(1, 'days');
        }

        // Filter and accumulate transaction totals for each date in the range
        transactions.forEach(transaction => {
            const transactionDate = moment(transaction.dateOfSale).utc().format('YYYY-MM-DD');
            const isPaid = transaction.paymentStatus === 'Paid';
            const isMatchingBranch = branchID === 'all' || transaction.branch_ID_fk === branchID;

            // Debugging log to check if condition is matching the selected branch
            console.log(`Transaction Branch: ${transaction.branch_ID_fk}, isMatchingBranch: ${isMatchingBranch}`);

            if (moment(transactionDate).isBetween(start, end, null, '[]') && isMatchingBranch) {
                const dateEntry = salesTrend.find(entry => entry.date === transactionDate);

                if (dateEntry) {
                    if (transaction.transactionType === 'Sale') {
                        dateEntry.total += transaction.grandTotal; // Add sales
                    } else if (transaction.transactionType === 'Refund') {
                        dateEntry.total -= Math.abs(transaction.grandTotal); // Subtract refunds
                    }
                }
            }
        });

        console.log("Sales Trend:", salesTrend);  // Debugging log to inspect the result
        return salesTrend;
    } catch (error) {
        console.error("Error fetching sales trend:", error);
        return [];
    }
};
