import React, { useState, useEffect } from 'react';
import './DiscountModal.css';

function DiscountModal({ subtotal, onClose, onSave, discount, isPercentage }) {
    const [amount, setAmount] = useState(0);
    const [discountValue, setDiscountValue] = useState(discount);
    const [isPercentageMode, setIsPercentageMode] = useState(isPercentage); // Default to mode passed from parent

    // Recalculate the discounted amount whenever subtotal, discount, or mode changes
    useEffect(() => {
        const numericDiscount = parseFloat(discountValue) || 0;
        let calculatedAmount;
        if (isPercentageMode) {
            calculatedAmount = subtotal * (1 - numericDiscount / 100);
        } else {
            calculatedAmount = subtotal - numericDiscount;
        }
        setAmount(calculatedAmount);
    }, [subtotal, discountValue, isPercentageMode]);

    // Set the discountValue and isPercentageMode when the modal opens
    useEffect(() => {
        setDiscountValue(discount);
        setIsPercentageMode(isPercentage);
    }, [discount, isPercentage]);


    const handleSave = () => {
        if (subtotal <= 0) {
            // If no line items, reset the discount to 0
            console.log('No items in cart. Resetting discount to 0.');
            onSave({ discount: 0, isPercentage: false, amount: 0 });
            onClose();
            return;
        }
    
        const numericDiscount = parseFloat(discountValue) || 0;
        const discountAmount = isPercentageMode 
            ? (subtotal * numericDiscount / 100) 
            : numericDiscount;
    
        const finalAmount = subtotal - discountAmount; // Final amount after applying the discount
    
        // Log the discount data being sent back
        console.log('Saving discount:', {
            discount: numericDiscount,
            isPercentage: isPercentageMode,
            finalAmount,
        });
    
        onSave({ discount: numericDiscount, isPercentage: isPercentageMode, amount: finalAmount });
        onClose();
    };
    


    
    
    

    return (
        <div className="discount-modal-overlay">
            <div className="discount-modal-content">
                <div className="discount-header">
                    <h3>Discount</h3>
                    <button className="close-button" onClick={onClose}>✕</button>
                </div>
                
                <div className="discount-body">
                    <div className="discount-field">
                        <label>Subtotal:</label>
                        <input
                            type="text"
                            value={subtotal.toFixed(2)}
                            readOnly
                        />
                    </div>

                    <div className="discount-field">
                        
                        <label>Discount:</label>
                            <div className="discount-input-container">
                                <input
                                    type="text"
                                    value={discountValue}
                                    placeholder="Enter amount or percentage"
                                    onChange={(e) => setDiscountValue(e.target.value)}
                                />
                                <div className="toggle-buttons">
                                    <button
                                        className={`toggle-button ${isPercentageMode ? '' : 'active'}`}
                                        onClick={() => setIsPercentageMode(false)} // This should set it to false for exact amount
                                    >
                                        ₱
                                    </button>
                                    <button
                                        className={`toggle-button ${isPercentageMode ? 'active' : ''}`}
                                        onClick={() => setIsPercentageMode(true)} // This should set it to true for percentage
                                    >
                                        %
                                    </button>
                                </div>
                            </div>
                    </div>

                    <div className="discount-field">
                        <label>Amount:</label>
                        <input
                            type="text"
                            value={amount.toFixed(2)}
                            readOnly
                        />
                    </div>
                </div>
                
                <div className="discount-actions">
                    <button className="save-button" onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default DiscountModal;
