import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BranchModal.css';
import MessageModal from './MessageModal';

function BranchModal({ isOpen, onClose, onSubmit, branch, employee }) {
    const [formData, setFormData] = useState({
        branchName: '',
        branchAddr: '',
        branchContNo: '',
        branchEmail: '',
        branchType: '',
        branchManager: ''
    });
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    useEffect(() => {
        // Fetch employees
        axios.get('https://www.capstonelimenerp.xyz:8082/api/employees')
            .then(response => setEmployees(response.data))
            .catch(error => console.error('Error fetching employees:', error));  
    }, []);

    useEffect(() => {
        if (branch) {
            console.log('Branch being edited:', branch); // Debugging
            console.log('Branch Type:', branch.branchType); // Confirm the branchType
            setIsEditing(true);
            setFormData({
                branchName: branch.branchName || '',
                branchAddr: branch.branchAddr || '',
                branchContNo: branch.branchContNo || '',
                branchEmail: branch.branchEmail || '',
                branchType: branch.branchType?.charAt(0).toUpperCase() + branch.branchType.slice(1).toLowerCase() || 'Sub', // Normalize case
                branchManager: branch.branchManager || ''
            });
        } else {
            setIsEditing(false);
            setFormData({
                branchName: '',
                branchAddr: '',
                branchContNo: '',
                branchEmail: '',
                branchType: 'Sub',
                branchManager: ''
            });
        }
        console.log('FormData:', formData);
        console.log('Selected Branch Manager:', formData.branchManager);
    }, [branch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/branches/${branch.branchID}` // Ensure branch.branchID is defined
            : 'https://www.capstonelimenerp.xyz:8082/api/branches';

        const method = isEditing ? 'put' : 'post';

        const data = {
            branchName: formData.branchName,
            branchAddr: formData.branchAddr,
            branchContNo: formData.branchContNo,
            branchEmail: formData.branchEmail,
            branchType: formData.branchType,
            branchManager: formData.branchManager === '' ? null : formData.branchManager
        };

        axios({ method, url, data })
            .then(response => {
                setMessage(isEditing ? 'Branch updated successfully!' : 'Branch added successfully!');
                setFormData({
                    branchName: '',
                    branchAddr: '',
                    branchContNo: '',
                    branchEmail: '',
                    branchType: 'Sub',
                    branchManager: ''
                });
                onClose();
                onSubmit(data, isEditing);
            })
            .catch(error => {
                console.error(isEditing ? 'Error updating branch:' : 'Error adding branch:', error.response || error.message);
                setMessage((isEditing ? 'Error updating branch: ' : 'Error adding branch: ') + (error.response?.data?.message || error.message));
            });
    };

    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="branch-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{isEditing ? 'Update Branch' : 'Add New Branch'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor="branchName"><strong>Branch Name</strong></label>
                        <input
                            type="text"
                            name="branchName"
                            value={formData.branchName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="branchAddr"><strong>Branch Address</strong></label>
                        <input
                            type="text"
                            name="branchAddr"
                            value={formData.branchAddr}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="branchContNo"><strong>Branch Contact Number</strong></label>
                        <input
                            type="text"
                            name="branchContNo"
                            value={formData.branchContNo}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="branchEmail"><strong>Branch Email</strong></label>
                        <input
                            type="email"
                            name="branchEmail"
                            value={formData.branchEmail}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="branchType"><strong>Branch Type</strong></label>
                        <select
                            name="branchType"
                            value={formData.branchType}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a Branch Type</option>
                            <option value="Main">Main</option>
                            <option value="Sub">Sub</option>
                        </select>
                        {/* Add logic to restrict only one main branch */}
                    </div>
                    <div className='form-group'>
                        <label htmlFor="branchManager"><strong>Branch Manager</strong></label>
                        <select
                            name="branchManager"
                            value={formData.branchManager}
                            onChange={handleChange}
                        >
                            <option value="">Select Manager</option>
                            {employees.map(employee => (
                                <option key={employee.empID} value={employee.empID}>
                                    {employee.empFName} {employee.empLName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='button-container'>
                        <button type="submit" className='branch-button'>{isEditing ? 'Save Branch' : 'Add Branch'}</button>
                    </div>
                </form>
            </div>
            <MessageModal 
                isOpen={messageModalOpen} 
                onClose={closeMessageModal} 
                message={message} 
                showCloseButton={showCloseButton} // Pass showCloseButton to MessageModal
            />
        </div>
    );
}

export default BranchModal;
