import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import Home from './Home';
import User from './User';
import Employee from './Employee';
import EmployeeAttendance from './EmployeeAttendance';
import EmployeePayroll from './EmployeePayroll';
import Branch from './Branch';
import Customer from './Customer';
import CustomerPayment from './CustomerPayment';
import Supplier from './Supplier';
import BranchEmployee from './BranchEmployee';
import InventoryProduct from './InventoryProduct';
import InventoryStock from './InventoryStock';
import InventoryRequest from './InventoryRequest';
import InventoryTransfer from './InventoryTransfer';
import PurchaseOrder from './PurchaseOrder';
import PurchaseTransaction from './PurchaseTransaction';
import PurchaseSuggestion from './PurchaseSuggestion';
import SalesNewCart from './SalesNewCart';
import SalesCustomer from './SalesCustomer';
import SalesHistory from './SalesHistory';
import ReportSales from './ReportSales';
import ReportInventory from './ReportInventory';
import ReportPurchases from './ReportPurchases';
import ReportPayroll from './ReportPayroll';
import { CartProvider } from './CartContext'; // Import CartProvider
function App() {
  return (
    <BrowserRouter>
    <CartProvider>
      <Routes>
        {/* Login Route */}
        <Route path="/" element={<Login />} />

        {/* Admin and Branch Manager Dashboard */}
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <Home />
            </ProtectedRoute>
          } 
        />

        {/* New Cart */}
        <Route 
          path="/sales/new" 
          element={
            <ProtectedRoute allowedPositions={['Cashier', 'Branch Manager']}>
              <SalesNewCart />
            </ProtectedRoute>
          } 
        />

        <Route 
          path="/sales/customer" 
          element={
            <ProtectedRoute allowedPositions={['Cashier', 'Branch Manager']}>
              <SalesCustomer />
            </ProtectedRoute>
          } 
        />

        {/* Sales History */}
        <Route 
          path="/sales/history" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <SalesHistory />
            </ProtectedRoute>
          } 
        />

        {/* Product List */}
        <Route 
          path="/inventory/products" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Salesperson', 'Purchase Officer', 'Branch Manager']}>
              <InventoryProduct />
            </ProtectedRoute>
          } 
        />

        {/* Stocks */}
        <Route 
          path="/inventory/stocks" 
          element={
            <ProtectedRoute allowedPositions={['Salesperson', 'Purchase Officer', 'Branch Manager']}>
              <InventoryStock />
            </ProtectedRoute>
          } 
        />

        {/* Stock Request */}
        <Route 
          path="/inventory/request" 
          element={
            <ProtectedRoute allowedPositions={['Salesperson', 'Branch Manager']}>
              <InventoryRequest />
            </ProtectedRoute>
          } 
        />

        {/* Stock Transfer */}
        <Route 
          path="/inventory/transfer" 
          element={
            <ProtectedRoute allowedPositions={['Salesperson', 'Branch Manager']}>
              <InventoryTransfer />
            </ProtectedRoute>
          } 
        />

        {/* Returns and Damages */}


        {/* Branch Information */}
        <Route 
          path="/branch/information" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Salesperson', 'Branch Manager']}>
              <Branch />
            </ProtectedRoute>
          } 
        />

        {/* Branch Employees */}
        <Route 
          path="/branch/employees" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <BranchEmployee />
            </ProtectedRoute>
          } 
        />

        {/* Customer Information */}
        <Route 
          path="/customer/information" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager', 'Salesperson']}>
              <Customer />
            </ProtectedRoute>
          } 
        />

        {/* Customer Balance */}
        <Route 
          path="/customer/balance" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager', 'Salesperson']}>
              <CustomerPayment />
            </ProtectedRoute>
          } 
        />


        {/* Supplier Information */}
        <Route 
          path="/supplier/information" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager', 'Salesperson', 'Purchase Officer']}>
              <Supplier />
            </ProtectedRoute>
          } 
        />

        {/* User Accounts */}
        <Route 
          path="/user/information" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <User />
            </ProtectedRoute>
          } 
        />

        {/* Employee Information */}
        <Route 
          path="/employee/information" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <Employee />
            </ProtectedRoute>
          } 
        />

        {/* Attendance */}
        <Route 
          path="/employee/attendance" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <EmployeeAttendance />
            </ProtectedRoute>
          } 
        />

        {/* Payroll */}
        <Route 
          path="/employee/pay" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <EmployeePayroll />
            </ProtectedRoute>
          } 
        />

        {/* Purchase Transaction */}
        <Route 
          path="/purchase/transaction" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Purchase Officer', 'Branch Manager']}>
              <PurchaseTransaction />
            </ProtectedRoute>
          } 
        />
        {/* Purchase Orders */}
        <Route 
          path="/purchase/order" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Purchase Officer', 'Branch Manager']}>
              <PurchaseOrder />
            </ProtectedRoute>
          } 
        />

        {/* Purchase Suggestions */}
        <Route 
          path="/purchase/suggestion" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Purchase Officer', 'Branch Manager']}>
              <PurchaseSuggestion />
            </ProtectedRoute>
          } 
        />

        {/* Sales Report */}
        <Route 
          path="/report/sales" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <ReportSales />
            </ProtectedRoute>
          } 
        />

        {/* Inventory Report */}
        <Route 
          path="/report/inventory" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <ReportInventory />
            </ProtectedRoute>
          } 
        />

        {/* Purchase Report */}
        <Route 
          path="/report/purchases" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <ReportPurchases />
            </ProtectedRoute>
          } 
        />

        {/* Payroll Report */}
        <Route 
          path="/report/payroll" 
          element={
            <ProtectedRoute allowedRoles={['Admin']} allowedPositions={['Branch Manager']}>
              <ReportPayroll />
            </ProtectedRoute>
          } 
        />

      </Routes>
      </CartProvider>
    </BrowserRouter>
  );
}

export default App;
