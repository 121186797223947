import React from 'react';
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageInputChange = (e) => {
        const value = e.target.value;

        // Allow empty input to enable backspace
        if (value === '' || /^[1-9]\d*$/.test(value)) {
            setCurrentPage(value === '' ? '' : Number(value));
        }
    };

    return (
        <div className="pagination-container">
            <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
                <BiChevronsLeft /> {/* Double Left Arrow Icon */}
            </button>
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
                <BiChevronLeft /> {/* Left Arrow Icon */}
            </button>
            <input
                type="text" // Keep as text
                value={currentPage} // Controlled input
                onChange={handlePageInputChange}
                style={{ width: '60px', textAlign: 'center' }} // Updated styles
            />
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                <BiChevronRight /> {/* Right Arrow Icon */}
            </button>
            <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                <BiChevronsRight /> {/* Double Right Arrow Icon */}
            </button>
        </div>
    );
};

export default Pagination;
