import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCartPlus, FaTimes, FaCoins } from 'react-icons/fa';
import './SalesNewCart.css'; 
import axios from 'axios';
import CheckOutModal from './CheckOutModal'
import DiscountModal from './DiscountModal';
import SalesReturnModal from './SalesReturnModal';
import MessageModal from './MessageModal';

import { useCart } from './CartContext'; // Import useCart from CartContext
function SalesNewCart() {
    const { cartItems, addToCart, removeFromCart, clearCart } = useCart();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [isCheckOutModalOpen, setIsCheckOutModalOpen] = useState(false);
    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [discountApplied, setDiscountApplied] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isSalesReturnModalOpen, setIsSalesReturnModalOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState(() => () => {});
    const [isConfirmation, setIsConfirmation] = useState(false);


    const navigate = useNavigate();
    const location = useLocation();
    const userID = localStorage.getItem('userID');
    const branchID = localStorage.getItem('branchID');
    // Retrieve user details from localStorage
    const username = localStorage.getItem('username');
    const branchName = localStorage.getItem('branchName');

    function handleNavigation(route) {
        navigate(route); // Redirect to the specified route
    }

    // Create a reference for the search bar and dropdown
    const searchContainerRef = useRef(null);

    const [customer, setCustomer] = useState(null); // Customer state

    useEffect(() => {
        // Retrieve the customer data from localStorage when the component mounts
        const storedCustomer = JSON.parse(localStorage.getItem('selectedCustomer')) || null;
        setCustomer(storedCustomer); // Set the retrieved customer data to state
    }, []);

    // Update totalDue whenever cartItems change
    useEffect(() => {
        setTotalDue(calculateTotal());
    }, [cartItems]);

    useEffect(() => {
        console.log("searchTerm:", searchTerm); // Debugging search term
        console.log("selectedProduct:", selectedProduct); // Check if a product is already selected
    
        if (selectedProduct) return; // Don't fetch if a product is already selected
    
        if (searchTerm.trim()) {
            const fetchProducts = async () => {
                try {
                    const response = await axios.get(
                        `https://www.capstonelimenerp.xyz:8082/api/products/search?q=${searchTerm}`
                    );
                    console.log("API Response:", response.data); // Debug API response
                    setSearchResults(response.data);
                } catch (error) {
                    console.error("Error searching for products:", error); // Debug API error
                }
            };
            fetchProducts();
        } else {
            setSearchResults([]); // Clear results if the search term is empty
            console.log("Cleared search results"); // Debug clearing dropdown
        }
    }, [searchTerm, selectedProduct]);
    
    
    
    // Hide the dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setSearchResults([]);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle selecting a product from search results
    const handleSelectProduct = (product) => {
        console.log("Selected product:", product); // Debugging selected product
        setSelectedProduct(product); // Save the selected product
        setSearchTerm(product.prodName); // Set the search bar text to the selected product's name
        setSearchResults([]); // Clear the dropdown
    };
    
    
    const handleAddToCart = async () => {
        if (!selectedProduct || quantity <= 0) {
            setModalMessage("Please select a valid product and quantity.");
            setIsMessageModalOpen(true);
            setIsConfirmation(false);
            return;
        }
    
        try {
            // Fetch inventory details for the selected product and branch
            const response = await axios.get(
                `https://www.capstonelimenerp.xyz:8082/api/inventory/${selectedProduct.prodNo}?branchID=${branchID}`
            );
            console.log("API Response:", response.data);
    
            // Handle inventory data
            const inventoryData = response.data[0];
            if (!inventoryData) {
                console.error("No inventory data found.");
                setModalMessage("No inventory record found for this product in the branch.");
                setIsMessageModalOpen(true);
                return;
            }
    
            const { stockOnHand } = inventoryData;
            console.log("Stock on Hand:", stockOnHand, "Quantity:", quantity);
    
            // Calculate remaining stock by subtracting items already in the cart
            const cartQuantity = cartItems
                .filter((item) => item.prodNo === selectedProduct.prodNo)
                .reduce((total, item) => total + item.quantity, 0); // Sum up quantities in cart
    
            const remainingStock = stockOnHand - cartQuantity;
            console.log("Remaining Stock:", remainingStock);
    
            // Add to cart based on stock availability
            if (remainingStock >= quantity) {
                console.log("Condition met: Remaining stock is sufficient.");
                const cartItem = { ...selectedProduct, quantity };
                addToCart(cartItem); // Add to cart context
                resetInputFields();
            } else if (remainingStock > 0 && remainingStock < quantity) {
                console.log("Condition met: Insufficient remaining stock.");
                setModalMessage(
                    `Available stock is less than requested quantity. Available: ${remainingStock}, Requested: ${quantity}. Do you want to proceed?`
                );
                setIsMessageModalOpen(true);
                setIsConfirmation(true);
                setOnConfirm(() => () => {
                    const cartItem = { ...selectedProduct, quantity };
                    addToCart(cartItem);
                    resetInputFields();
                    setIsMessageModalOpen(false);
                });
            } else if (remainingStock === 0) {
                console.log("Condition met: No remaining stock.");
                setModalMessage("No stock available for this item. Do you want to proceed?");
                setIsMessageModalOpen(true);
                setIsConfirmation(true);
                setOnConfirm(() => () => {
                    const cartItem = { ...selectedProduct, quantity };
                    addToCart(cartItem);
                    resetInputFields();
                    setIsMessageModalOpen(false);
                });
            } else if (remainingStock < 0) {
                console.log("Condition met: Negative stock.");
                setModalMessage(`Stock is negative (${remainingStock}). Do you still want to proceed?`);
                setIsMessageModalOpen(true);
                setIsConfirmation(true);
                setOnConfirm(() => () => {
                    const cartItem = { ...selectedProduct, quantity };
                    addToCart(cartItem);
                    resetInputFields();
                    setIsMessageModalOpen(false);
                });
            }
        } catch (error) {
            // Handle errors from API call
            if (error.response && error.response.status === 404) {
                console.error("No inventory record found.");
                setModalMessage("No inventory record found for this product in the branch. Do you want to proceed?");
                setIsMessageModalOpen(true);
                setIsConfirmation(true);
                setOnConfirm(() => () => {
                    const cartItem = { ...selectedProduct, quantity };
                    addToCart(cartItem);
                    resetInputFields();
                    setIsMessageModalOpen(false);
                });
            } else {
                console.error("Error checking inventory:", error);
                setModalMessage("Error checking inventory. Please try again.");
                setIsMessageModalOpen(true);
                setIsConfirmation(false);
            }
        }
    };
    
    
    // Helper function to reset input fields after adding to cart
    const resetInputFields = () => {
        setSelectedProduct(null);
        setSearchTerm('');
        setQuantity(1);
    };

    const handleOpenCheckoutModal = () => {
        setIsCheckOutModalOpen(true);
    };

    
    const handleCloseCheckoutModal = () => {
        console.log('Closing checkout modal'); // Debug log
        setIsCheckOutModalOpen(false);
    };

    // Handle checkout and commit sale
    const handleCheckout = async () => {
        try {
            const salesData = {
                customerID: customer?.custNo || 'CUST000000001', // Use selected customer or default
                empID: 'EMP00003',          // Example; replace with logged-in employee ID
                branchID: 'BID01',          // Example; replace with branch ID
                paymentStatus: 'Paid',      // Set as 'Pending' or 'Paid' based on actual transaction
                paymentType: 'Cash',        // Example; replace with selected payment type
                items: cartItems.map(item => ({
                    prodNo: item.prodNo,
                    quantity: item.quantity,
                    amount: item.prodPrice * item.quantity,
                    prodCost: item.prodCost
                })),
            };

            const response = await axios.post('/api/sales/commit', salesData);
            if (response.status === 200) {
                console.log("Sale committed successfully:", response.data);
                clearCart(); // Clear cart after successful sale
            }
        } catch (error) {
            console.error("Error committing sale:", error);
        }
    };
    
    
    const handlePay = () => {
        if (!cartItems || cartItems.length === 0) {
            setModalMessage("Your cart is empty. Please add items before proceeding to payment.");
            setIsConfirmation(false); // Explicitly set confirmation to false for this case
            setIsMessageModalOpen(true); // Open the message modal
            return;
        }
        setIsConfirmation(false); // Explicitly set confirmation to false for this case
        setIsCheckOutModalOpen(true); // Open the CheckOutModal
    };
    

    const handleCloseMessageModal = () => {
        setIsMessageModalOpen(false); // Close the message modal
    };

    const resetCustomerSelection = () => {
        localStorage.removeItem('selectedCustomer'); // Clear from localStorage
        setCustomer(null);  // Clear customer state in SalesNewCart
    };
    
    const handleCancelSale = () => {
        clearCart(); // Clear cart globally
        setDiscountData({ discount: 0, isPercentage: false }); // Reset discount to 0
        localStorage.setItem('discountData', JSON.stringify({ discount: 0, isPercentage: false })); // Update localStorage
        setDiscountedTotal(0); // Reset the discounted total
        setOriginalTotalDue(0); // Reset the original total due
    };
    

    const handleOpenSalesReturnModal = () => {
        console.log("Opening discount modal");
        setIsSalesReturnModalOpen(true);
    };
    
    const handleCloseSalesReturnModal = () => {
        setIsSalesReturnModalOpen(false);
    };
    
    // Update totalDue whenever cartItems change
    useEffect(() => {
        const newTotal = calculateTotal();
        setTotalDue(newTotal); // Ensure this is set as a number
    }, [cartItems]);

    // Function to calculate the total due
    const calculateTotal = () => {
        return cartItems.reduce((acc, item) => {
            const itemTotal = (item.prodPrice || 0) * (item.quantity || 0);
            return acc + itemTotal;
        }, 0); // This returns a number
    };
    
    const [originalTotalDue, setOriginalTotalDue] = useState(calculateTotal());
    const [totalDue, setTotalDue] = useState(originalTotalDue);
    const [discountedTotal, setDiscountedTotal] = useState(originalTotalDue);
    const [discountData, setDiscountData] = useState({ discount: 0, isPercentage: false });
    
    useEffect(() => {
        const newTotal = calculateTotal();
        setOriginalTotalDue(newTotal); // Set original total once based on cart items
        setTotalDue(newTotal); // Reset totalDue whenever cart items change
        setDiscountedTotal(newTotal); // Reset discountedTotal to match original total
    }, [cartItems]);

    // Calculate the discounted total based on the original total and the applied discount
    const calculateDiscountedTotal = () => {
        const discountAmount = discountData.isPercentage 
            ? originalTotalDue * (discountData.discount / 100)
            : discountData.discount;
        return originalTotalDue - discountAmount;
    };

    // Update discountedTotal whenever discountData changes
    useEffect(() => {
        setDiscountedTotal(calculateDiscountedTotal());
    }, [discountData]);

    
    // Load discount data and discounted total from localStorage on mount
    useEffect(() => {
        const savedDiscountData = JSON.parse(localStorage.getItem('discountData')) || { discount: 0, isPercentage: false };
        const savedDiscountedTotal = parseFloat(localStorage.getItem('discountedTotal')) || calculateTotal();
        setDiscountData(savedDiscountData);
        setDiscountedTotal(savedDiscountedTotal);
        setOriginalTotalDue(calculateTotal());
    }, [cartItems]);

    // Save discount data and discounted total to localStorage whenever discount is applied
    const handleSaveDiscount = (data) => {
        setDiscountData(data);
        const newDiscountedTotal = calculateDiscountedTotal();
        setDiscountedTotal(newDiscountedTotal);
        
        localStorage.setItem('discountData', JSON.stringify(data));
        localStorage.setItem('discountedTotal', newDiscountedTotal);
    };

    const handleOpenDiscountModal = () => {
        setIsDiscountModalOpen(true);
    };

    const handleCloseDiscountModal = () => {
        setIsDiscountModalOpen(false);
    };

    // Handles closing the cart
    function handleClose() {
        const empPosition = localStorage.getItem('empPosition'); // Retrieve empPosition from localStorage
        const userType = localStorage.getItem('userType');

        if (empPosition === 'Cashier') {
            navigate('/'); // Navigate to home if the user is a cashier
        } else if (userType === 'Admin') {
            navigate('/inventory/products'); // Navigate to products page if the user is an admin
        } else if (empPosition === 'Salesperson') {
            navigate('/inventory/products'); // Salesperson navigates to products page
        } else if (empPosition === 'Purchase Officer') {
            navigate('/purchase/order'); // Purchase Officer navigates to purchase order page
        } else if (empPosition === 'Branch Manager') {
            navigate('/dashboard'); // Branch Manager navigates to dashboard
        }
    }

    return (
        <div className="sales-cart-container">
            <div className="sales-tab-container">
                <div className="sales-tab-navigation">
                    <button 
                        className="tab active" 
                        onClick={() => handleNavigation('/sales/new')}
                    >
                        Checkout
                    </button>
                    <button 
                        className="tab" 
                        onClick={() => handleNavigation('/sales/customer')}
                    >
                        Customer
                    </button>
                    <button className="tab" onClick={handleOpenDiscountModal}>
                        Discount
                    </button>
                </div>

                {/* Customer Info Section */}
                <div className="customer-info-container">
                    <div className="user-info">
                        <p><strong>User:</strong> {username}</p>
                        <p><strong>Branch:</strong> {branchName}</p>
                    </div>
                    {customer ? (
                        <div className="customer-info">
                            <p><strong>Customer ID:</strong> {customer.custNo}</p>
                            <p><strong>CustomerName:</strong> {customer.custFName} {customer.custLName}</p>
                        </div>
                    ) : (
                        <p>No customer selected</p>
                    )}
                </div>

                <button className="close-button" onClick={handleClose}>
                    <FaTimes />
                </button>
            </div>

            <div className="product-details-container" ref={searchContainerRef}>
                <div className="product-search-bar">
                <input
    type="text"
    placeholder="Search product by name or SKU"
    value={searchTerm}
    onChange={(e) => {
        setSelectedProduct(null); // Reset selection if typing a new search term
        setSearchTerm(e.target.value); // Update the search term
    }}
    onBlur={() => {
        setSearchResults([]);
        console.log("Input blurred, dropdown hidden");
    }}
/>
                    {/* Display search results dropdown */}
                    {searchResults.length > 0 && (
                        <div className="search-results-dropdown">
                            {searchResults.map((product) => (
                                <div
                                    key={product.prodNo}
                                    onMouseDown={() => handleSelectProduct(product)}
                                    className="search-result-item"
                                >
                                    <div className="search-result-sku">#SKU: {product.prodSKU} / Barcode: {product.prodBarcode}</div>
                                    <div className="search-result-details">
                                        <span>{product.prodName}</span>
                                        <span className="price">₱{product.prodPrice.toFixed(2)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* Quantity and Price Selection - Always Visible */}
                <div className="product-quantity-price">
                    <div className="product-field">
                        <label className="selection-label">Qty:</label>
                        <input
                            id="quantity"
                            type="number"
                            value={quantity}
                            min="1"
                            onChange={(e) => setQuantity(parseInt(e.target.value))}
                        />
                    </div>
                    <div className="product-field">
                        <label className="selection-label">Price:</label>
                        <input
                            id="price"
                            type="text"
                            value={selectedProduct ? `${selectedProduct.prodPrice.toFixed(2)}` : "₱0.00"}
                            disabled
                        />
                    </div>
                    <div className="product-field">
                        <label className="selection-label">Total:</label>
                        <input
                            id="total"
                            type="text"
                            value={selectedProduct ? `${(quantity * selectedProduct.prodPrice).toFixed(2)}` : "₱0.00"} // Format as currency
                            disabled
                        />
                    </div>
                </div>
                <button onClick={handleAddToCart} className="send-button side-icon">
                    <FaCartPlus className="button-icon" />
                    Add to Cart
                </button>
            </div>


            <div className="cart-items-list">
                <table className="cart-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>SKU</th>
                            <th>Barcode</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems.map((item, index) => (
                            <tr key={`${item.prodNo}-${index}`}>
                                <td>{index + 1}</td>
                                <td>{item.prodName}</td>
                                <td>{item.prodSKU}</td>
                                <td>{item.prodBarcode}</td>
                                <td>{item.quantity}</td>
                                <td>₱{item.prodPrice}</td>
                                <td>₱{(item.prodPrice * item.quantity).toFixed(2)}</td>
                                <td>
                                    <button onClick={() => removeFromCart(index)}>Remove</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            <div className="cart-footer">
                <div className="transaction-controls">
                    <button className="cancel-sale-button" onClick={handleCancelSale}>
                        Cancel Sale
                    </button>
                    <button className="refund-button" onClick={handleOpenSalesReturnModal}>
                        Refund
                    </button>
                </div>
                <div className="total-container">
                    <label className="total-label">Total: </label>
                    <div className="total-display">
                        {discountData.discount > 0 ? (
                            <>
                                <span className="discounted-total">₱{discountedTotal.toFixed(2)}</span>
                                <sup className="original-total">₱{originalTotalDue.toFixed(2)}</sup>
                            </>
                        ) : (
                            <span className="total-amount">₱{originalTotalDue.toFixed(2)}</span>
                        )}
                    </div>
                    <button className="pay-button" onClick={handlePay}>
                        <FaCoins style={{ marginRight: '8px', fontSize: '25px' }} />
                        Pay
                    </button>
                </div>
            </div>
            {isCheckOutModalOpen && (
                console.log('Rendering CheckOutModal with totalDue:', totalDue), // Log before rendering
                <CheckOutModal 
                    totalDue={discountedTotal} // Pass the discounted total to checkout
                    originalTotal={originalTotalDue} // Optionally, pass original total for reference
                    onClose={handleCloseCheckoutModal} 
                    onCheckout={handleCheckout} 
                    resetCustomerSelection={resetCustomerSelection}
                    onSaveDiscount={handleSaveDiscount} 
                    discountData={discountData}
                    loggedInUserID={userID}
                    branchID={branchID}
                    customer={customer}
                    setCustomer={setCustomer}
                    cartItems={cartItems}
                    clearCart={clearCart}
                />
            )}
            {isDiscountModalOpen && (
                <DiscountModal
                    subtotal={originalTotalDue} // Use the discounted total
                    onClose={handleCloseDiscountModal}
                    onSave={handleSaveDiscount}
                    discount={discountData.discount} // Pass saved discount value
                    isPercentage={discountData.isPercentage} // Pass percentage mode
                />
            )}
            {isMessageModalOpen && (
                <MessageModal
                    isOpen={isMessageModalOpen}
                    onClose={handleCloseMessageModal}
                    message={modalMessage}
                    isConfirmation={isConfirmation}
                    onConfirm={onConfirm}
                />
            )}
            {isSalesReturnModalOpen && (
                <SalesReturnModal
                    isOpen={isSalesReturnModalOpen}
                    onClose={handleCloseSalesReturnModal}
                    userID={userID} // Pass the logged-in user's ID
                />
            )}
        </div>
    );
}

export default SalesNewCart;
