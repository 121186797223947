import React, { useState } from 'react';
import './DatePickerModal.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const DatePickerModal = ({ isOpen, onClose, onApply }) => {
    const [startDate, setStartDate] = useState(new Date()); // Set default to today's date
    const [endDate, setEndDate] = useState(new Date()); // Set default to today's date
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const handlePeriodChange = () => {
        // Set time to midnight for startDate
        const adjustedStartDate = new Date(startDate);
        adjustedStartDate.setHours(0, 0, 0, 0);
    
        // Set time to the end of the day for endDate
        const adjustedEndDate = new Date(endDate);
        adjustedEndDate.setHours(23, 59, 59, 999);
    
        // Log adjusted dates for debugging
        console.log("Adjusted Start Date:", adjustedStartDate);
        console.log("Adjusted End Date:", adjustedEndDate);
    
        // Call the onApply function passed as a prop
        onApply(adjustedStartDate, adjustedEndDate);
        onClose(); // Close modal after selection
    };
    
    

    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
    };

    const selectStartDate = (date) => {
        // Set time to midnight (start of day) for the start date
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0); // Set to 00:00:00
        setStartDate(startOfDay);
        setEndDate(null); // Reset end date when a new start date is selected
        console.log("Selected Start Date (Without Time):", startOfDay.toDateString()); // Log the selected start date without time
    };
    
    const selectEndDate = (date) => {
        if (startDate && date >= startDate) {
            // Set time to end of day for the end date (so that full day is counted)
            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59 for the end date
            setEndDate(endOfDay);
            console.log("Selected End Date (Without Time):", endOfDay.toDateString()); // Log the selected end date without time
        }
    };
    
    
    const setPredefinedPeriod = (period) => {
        const today = new Date();
        let start, end;

        switch (period) {
            case 'Today':
                start = end = new Date(today);
                start.setHours(0, 0, 0, 0); // Set to midnight
                end.setHours(23, 59, 59, 999); // Set to end of day
                break;
            case 'Yesterday':
                start = end = new Date(today.setDate(today.getDate() - 1));
                break;
            case 'This week':
                const weekStart = new Date(today);
                weekStart.setDate(today.getDate() - today.getDay()); // Get the start of the week (Sunday)
                end = today; // End date is today
                start = weekStart; // Start date is the start of the week
                break;
            case 'Last week':
                const lastWeekStart = new Date(today.setDate(today.getDate() - today.getDay() - 7));
                start = lastWeekStart;
                end = new Date(lastWeekStart);
                end.setDate(end.getDate() + 6);
                break;
            case 'This month':
                start = new Date(today.getFullYear(), today.getMonth(), 1);
                end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case 'Last month':
                start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                end = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'This year':
                start = new Date(today.getFullYear(), 0, 1);
                end = new Date(today.getFullYear(), 11, 31);
                break;
            case 'Last year':
                start = new Date(today.getFullYear() - 1, 0, 1);
                end = new Date(today.getFullYear() - 1, 11, 31);
                break;
            default:
                break;
        }

        setStartDate(start);
        setEndDate(end);
    };

    const renderCalendar = (date, isStart) => {
        const month = date.getMonth();
        const year = date.getFullYear();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const daysInMonth = lastDay.getDate();

        // Calculate the starting day of the week for the first day of the month
        const startDay = firstDay.getDay(); // Day of week (0 - Sunday, 6 - Saturday)
        const weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

        // Create an array to hold the days to display
        const calendarDays = [];
        
        // Fill in the empty slots before the first day of the month
        for (let i = 0; i < startDay; i++) {
            calendarDays.push(null);
        }

        // Fill in the actual days of the month
        for (let i = 1; i <= daysInMonth; i++) {
            calendarDays.push(new Date(year, month, i));
        }

        return (
            <div className="calendar">
                <div className="calendar-header">
                    <button onClick={handlePrevMonth} className="chevron-button"><FaChevronLeft /></button>
                    <span>{`${firstDay.toLocaleString('default', { month: 'long' })} ${year}`}</span>
                    <button onClick={handleNextMonth} className="chevron-button"><FaChevronRight /></button>
                </div>
                <div className="calendar-days">
                    {weekDays.map((day) => (
                        <div key={day} className="day-header">{day}</div>
                    ))}
                    {calendarDays.map((day, index) => (
                        <div
                            key={index}
                            className={`day ${day && (day.toDateString() === startDate?.toDateString() ? 'selected' : day.toDateString() === endDate?.toDateString() ? 'selected' : '')}`}
                            onClick={() => {
                                if (isStart) {
                                    selectStartDate(day);
                                } else {
                                    selectEndDate(day);
                                }
                            }}
                        >
                            {day ? day.getDate() : ''}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        isOpen && (
            <div className="modal-overlay">
                <div className="calendar-modal-content">
                    <h2>Period</h2>
                    <div className="date-range">
                        <span>{`${startDate ? startDate.toLocaleDateString() : 'Select Start Date'} - ${endDate ? endDate.toLocaleDateString() : 'Select End Date'}`}</span>
                    </div>
                    <div className="date-pickers-container">
                        <div className="date-pickers">
                            <div className="date-picker-group">
                                <label className="date-label">Start</label>
                                {renderCalendar(currentMonth, true)}
                            </div>
                            <div className="date-picker-group">
                                <label className="date-label">End</label>
                                {renderCalendar(currentMonth, false)}
                            </div>
                        </div>
                        <div className="period-section">
                            <div className="predefined-periods">
                                <h5>Predefined period</h5>
                                <div className="periods-grid">
                                    {['Today', 'Yesterday', 'This week', 'Last week', 'This month', 'Last month', 'This year', 'Last year'].map((period) => (
                                        <button 
                                            key={period} 
                                            className="period-button" 
                                            onClick={() => setPredefinedPeriod(period)}
                                        >
                                            {period}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="modal-actions">
                                <div className="button-container">
                                    <button onClick={handlePeriodChange} className="confirm-button">OK</button>
                                    <button onClick={onClose} className="cancel-button">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default DatePickerModal;
