import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StockModal.css';
import MessageModal from './MessageModal';

function StockModal({ isOpen, onClose, onSubmit, product, username, userID }) {
    const branchID = localStorage.getItem('branchID'); // Retrieve branchID
    const branchName = localStorage.getItem('branchName'); // Retrieve branchName
    const [formData, setFormData] = useState({
        stockOnHand: '',
        reorderQty: '',
        preferredQty: '',
        stockLocation: '',
        prodNo: '',
        branch_id_fk: branchID || '',
        selectedProductName: '',
    });
    const [branches, setBranches] = useState([]);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    useEffect(() => {
        // Fetch branches for the dropdown
        axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
            .then(response => setBranches(response.data))
            .catch(error => console.error('Error fetching branches:', error));

        // If editing a product, set initial form data
        if (product) {
            setIsEditing(true);
            setFormData({
                stockOnHand: product.stockOnHand || '',
                reorderQty: product.reorderQty || '',
                preferredQty: product.preferredQty || '',
                stockLocation: product.stockLocation || '',
                prodNo: product.prod_No_fk || '',
                branch_id_fk: branchID || '',
                selectedProductName: product.prodName || '',
            });
            // Clear suggestions when editing
            console.log('Editing product, setting formData:', product);
            setSuggestedProducts([]);
        } else {
            setIsEditing(false);
            setFormData({
                stockOnHand: '',
                reorderQty: '',
                preferredQty: '',
                stockLocation: '',
                prodNo: '',
                branch_id_fk: branchID || '',
                selectedProductName: '',
            });
        }
    }, [product]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    
        if (name === 'selectedProductName') { // Trigger search on product name input
            fetchProductSuggestions(value);
        }
    };

    const fetchProductSuggestions = (query) => {
        if (query.length > 2) { // Only search if more than 2 characters
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/search?q=${query}`)
                .then(response => setSuggestedProducts(response.data))
                .catch(error => console.error('Error fetching product suggestions:', error));
        } else {
            setSuggestedProducts([]); // Clear suggestions if query is too short
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Validate if stockOnHand, reorderQty, and preferredQty are non-negative
        if (
            formData.reorderQty < 0 ||
            formData.preferredQty < 0
        ) {
            setMessage('Values for Reorder Quantity, and Preferred Quantity cannot be negative.');
            setMessageModalOpen(true);
            return;
        }

        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/stocks/${product.invID}`
            : 'https://www.capstonelimenerp.xyz:8082/api/stocks';
        const method = isEditing ? 'put' : 'post';
    
        // Get the logged-in userID from localStorage
        const userID = localStorage.getItem('userID'); 

        // Calculate the difference in quantity when editing
        const qtyDifference = isEditing 
            ? parseInt(formData.stockOnHand, 10) - parseInt(product.stockOnHand, 10)
            : parseInt(formData.stockOnHand, 10);
    
        const logType = isEditing 
            ? 'Readjust Quantity'
            : 'Addition';
    
        const adjustedQty = isEditing && qtyDifference !== 0 ? qtyDifference : formData.stockOnHand;
    
        // Prepare data to send
        const data = {
            stockOnHand: formData.stockOnHand,
            reorderQty: formData.reorderQty,
            preferredQty: formData.preferredQty,
            stockLocation: formData.stockLocation,
            prod_No_fk: formData.prodNo,
            branch_id_fk: formData.branch_id_fk,
            createdBy: userID, // Use the username from localStorage
            logType: logType,
            qty: adjustedQty,
            logDateTime: new Date().toISOString()
        };
    
        // Submit the stock data
        axios({ method, url, data })
            .then(response => {
                const invID = response.data.invID;
                console.log('Retrieved invID:', invID);
    
                if (!invID) {
                    throw new Error('invID is undefined after inventory insertion.');
                }
    
                // Prepare the log data for readjustment
                const logData = {
                    logType: logType,
                    qty: adjustedQty,
                    logDateTime: data.logDateTime,
                    createdBy: data.createdBy,
                    inv_id_fk: invID
                };
                console.log('Log Data being sent:', logData); // Check the data before sending
                // Make a separate call to log the entry in inventory_log
                return axios.post('https://www.capstonelimenerp.xyz:8082/api/inventory_log', logData);
            })
            .then(() => {
                setMessage(isEditing ? 'Stock updated successfully!' : 'Stock added successfully!');
                onClose();
                onSubmit(data, isEditing);
            })
            .catch(error => {
                console.error(isEditing ? 'Error updating stock:' : 'Error adding stock:', error);
                setMessage((isEditing ? 'Error updating stock: ' : 'Error adding stock: ') + error.message);
                setMessageModalOpen(true);
            });
    };
    
    
    

    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="stock-modal-overlay">
            <div className="stock-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section" style={{width: '100%', height: '100%'}}>
                        <h2>{isEditing ? 'Readjust Existing Stock' : 'Add New Stock'}</h2>
                            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <div className="form-group">
                                    <label><strong>Branch</strong></label>
                                    <input
                                        type="text"
                                        value={branchName || ''}
                                        disabled
                                        readOnly
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="prodNo"><strong>Product (prodNo or Name)</strong></label>
                                    <input
                                        type="text"
                                        name="selectedProductName" // Display the product name
                                        value={formData.selectedProductName}
                                        onChange={handleChange}
                                        style={{ maxWidth: '100%' }}
                                        required
                                        disabled={isEditing}
                                    />
                                    {suggestedProducts.length > 0 && (
                                        <ul className="stock-suggestion-list" style={{ maxWidth: '100%'}}>
                                            {suggestedProducts.map(product => (
                                                <li
                                                    key={product.prodNo}
                                                    onClick={() => {
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            prodNo: product.prodNo, // Internally store prodNo
                                                            selectedProductName: product.prodName // Display the product name
                                                        }));
                                                        setSuggestedProducts([]); // Clear suggestions after selection
                                                    }}
                                                >
                                                    {product.prodName} (Prod No: {product.prodNo})
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="stockOnHand"><strong>{isEditing ? 'Readjust Quantity' : 'Stock On Hand'}</strong> <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        type="number"
                                        name="stockOnHand"
                                        value={formData.stockOnHand}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="reorderQty"><strong>Reorder Quantity <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="number"
                                        name="reorderQty"
                                        value={formData.reorderQty}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="preferredQty"><strong>Preferred Quantity <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="number"
                                        name="preferredQty"
                                        value={formData.preferredQty}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="stockLocation"><strong>Stock Location</strong></label>
                                    <input
                                        type="text"
                                        name="stockLocation"
                                        value={formData.stockLocation}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className='button-container'>
                                    <button type="submit" className="stock-button">{isEditing ? 'Save Stock' : 'Add Stock'}</button>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
            <MessageModal 
                isOpen={messageModalOpen} 
                onClose={closeMessageModal} 
                message={message} 
                showCloseButton={showCloseButton} 
            />
        </div>
    );
}

export default StockModal;
