import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import MessageModal from './MessageModal';
import StockModal from './StockModal';
import InventoryLogModal from './InventoryLogModal'; // Adjust the path if needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';

function InventoryStock() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [userID, setUserID] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState(() => localStorage.getItem('branchName') || 'all');
    const [inventoryData, setinventoryData] = useState([]);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [SelectedInventory, setSelectedInventory] = useState(null);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [inventoryToDelete, setInventoryToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);

    const [isLogModalOpen, setIsLogModalOpen] = useState(false);
    const [selectedLogInventory, setSelectedLogInventory] = useState(null);

    const [activeTab, setActiveTab] = useState('stocks'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedBranchName = localStorage.getItem('branchName');
        if (storedBranchName) {
            setFilter(storedBranchName);
        }
    }, []);

    
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedUserID = localStorage.getItem('userID');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedUserID) {
            setUserID(storedUserID);
        }

        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch inventory stock data
        const fetchData = async () => {
            try {
                const [inventoryResponse, productResponse, branchResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/stocks'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/products'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                ]);
                console.log('Inventory Data:', inventoryResponse.data); // Log inventory data
                
                setinventoryData(inventoryResponse.data);
                setProducts(productResponse.data);
                setBranches(branchResponse.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch stocks again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/stocks')
                .then(response => {
                    setinventoryData(response.data);
                    setRefreshTable(false);
                })
                .catch(error => {
                    console.error('There was an error refreshing the product data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);
    
    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleViewInventory = (inventory) => {
        setSelectedLogInventory(inventory);
        setIsLogModalOpen(true);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddInventory = () => {
        setSelectedInventory(null); // Clear selected inventory
        setIsModalOpen(true);       // Open the modal for adding new inventory
    };
    
    const handleViewInventoryLog = (inventory) => {
        setSelectedLogInventory(inventory); // Set the selected inventory data
        setIsLogModalOpen(true); // Open the modal
    };

    const handleEditInventory = (stocks) => {
        setSelectedInventory(stocks); // Set selected inventory for editing
        setIsModalOpen(true);            // Open the modal for editing
    };

    const confirmDeleteInventory = (invID) => {
        setInventoryToDelete(invID); 
        setMessage('Are you sure you want to delete this inventory record?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); 
        setIsConfirmation(true);  
    };

    const handleDeleteInventory = () => {
        if (inventoryToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/stocks/${inventoryToDelete}`)
                .then(() => {
                    setinventoryData(inventoryData.filter(inventory => inventory.invID !== inventoryToDelete));
                    setMessage('Inventory record successfully deleted.');
                    setDeletionConfirmed(true);
                    setRefreshTable(true);
                })
                .catch(error => {
                    setMessage('Error deleting inventory record.');
                    setDeletionConfirmed(true);
                });
        }
    };
    

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
    
        const successMessage = isEditing ? 'Inventory updated successfully!' : 'Inventory added successfully!';
        setMessage(successMessage);
    
        setShowCloseButton(true); 
        setIsMessageModalOpen(true);
        setIsConfirmation(false); 
        setRefreshTable(true);
    };

    const filteredInventory = inventoryData.filter(inventory => {
        const matchesSearchQuery = inventory.prodName.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesFilter = filter === 'all' || inventory.branchName === filter;
        return matchesSearchQuery && matchesFilter;
    });

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedInventory = filteredInventory.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredInventory.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <StockModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        product={SelectedInventory}
                        username={username}
                    />
                    <InventoryLogModal
                        isOpen={isLogModalOpen}
                        onClose={() => setIsLogModalOpen(false)}
                        inventory={selectedLogInventory}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteInventory(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/inventory/products" className={activeTab === 'products' ? 'active' : ''} onClick={() => setActiveTab('products')}>
                            Product List
                        </Link>
                        <Link to="/inventory/stocks" className={activeTab === 'stocks' ? 'active' : ''} onClick={() => setActiveTab('stocks')}>
                            Stocks
                        </Link>
                        <Link to="/inventory/request" className={activeTab === 'request' ? 'active' : ''} onClick={() => setActiveTab('request')}>
                            Stock Request
                        </Link>
                        <Link to="/inventory/transfer" className={activeTab === 'transfer' ? 'active' : ''} onClick={() => setActiveTab('transfer')}>
                            Stock Transfer
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search product name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange} id="branchFilter">
                                <option value="all">All Branches</option>
                                {branches.map(branch => (
                                    <option key={branch.branchID} value={branch.branchName}>
                                        {branch.branchName}
                                    </option>
                                ))}
                            </select>
                            <button className="action-button" onClick={handleAddInventory}>Adjust Stock</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container"> 
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Inventory ID</th>
                                    <th>Product Name</th>
                                    <th>Branch Name</th>
                                    <th>Stock On Hand</th>
                                    <th>Reorder Quantity</th>
                                    <th>Preferred Quantity</th>
                                    <th>Stock Location</th>
                                    <th style={{width: '150px'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedInventory.length > 0 ? (
                                    displayedInventory.map((inventory, index) => (
                                        <tr key={inventory.invID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{inventory.invID}</td>
                                            <td>{inventory.prodName}</td>
                                            <td>{inventory.branchName}</td>
                                            <td>{inventory.stockOnHand}</td>
                                            <td>{inventory.reorderQty}</td>
                                            <td>{inventory.preferredQty}</td>
                                            <td>{inventory.stockLocation}</td>
                                            <td style={{width: '150px'}}>
                                                <button className="view-button" onClick={() => handleViewInventoryLog(inventory)}>
                                                    <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} /> {/* Larger and colored icon */}
                                                </button>
                                                <button className="edit-button" onClick={() => handleEditInventory(inventory)}>
                                                    <FaEdit />
                                                </button>
                                                <button className="delete-button" onClick={() => confirmDeleteInventory(inventory.invID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: 'center' }}>No inventory records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>      

            <BottomContainer 
                username={username} 
                userID={userID}
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default InventoryStock;
