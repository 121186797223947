import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import UserModal from './UserModal';
import MessageModal from './MessageModal';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function User() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [showCloseButton, setShowCloseButton] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null); // State to hold the user to delete
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [deletionMessage, setDeletionMessage] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [activeTab, setActiveTab] = useState('users');

    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }


        // Fetch users from MySQL database
        console.log('Fetching users from API...');
        axios.get('https://www.capstonelimenerp.xyz:8082/api/users')
            .then(response => {
                console.log('User data fetched successfully:', response.data);
                setUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the user data!', error.response || error.message || error);
            });
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch users again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/users')
                .then(response => {
                    console.log('User data refreshed successfully:', response.data);
                    setUsers(response.data);
                    setRefreshTable(false);  // Reset the refreshTable state
                })
                .catch(error => {
                    console.error('There was an error refreshing the user data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddUser = () => {
        console.log('Add User button clicked');
        setSelectedUser(null); // Clear any selected user
        setIsModalOpen(true);
    };

    const handleEditUser = (user) => {
        setSelectedUser(user); // Set the user to be edited
        setIsModalOpen(true);
    };


    const confirmDeleteUser = (userID) => {
        console.log("Delete button clicked for user ID:", userID);
        setUserToDelete(userID);
        setMessage('Are you sure you want to delete this user?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };
    
    
    // In the render method:
    console.log("Message Modal State:", isMessageModalOpen);
    
    const handleDeleteUser = () => {
        if (userToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/users/${userToDelete}`)
                .then(() => {
                    setUsers(users.filter(user => user.userID !== userToDelete));
                    setMessage('User has been successfully deleted.');
                    setDeletionConfirmed(true); // This should trigger the close button
                })
                .catch(error => {
                    console.error('There was an error deleting the user!', error);
                    setMessage('Error deleting user');
                    setDeletionConfirmed(true);
                });
        }
    };
    

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        console.log('Add User Form Data:', data);
        setIsModalOpen(false);
        
        // Set message based on whether it's an edit or add
        const successMessage = isEditing ? 'User updated successfully!' : 'User added successfully!';
        setMessage(successMessage);
        
        setShowCloseButton(true); // Ensure the close button is shown
        setIsMessageModalOpen(true);
        setIsConfirmation(false); // Set to false for success messages
        setRefreshTable(true);
    };

    // Filter and search logic
    const filteredUsers = users
        .filter(user => 
            (filter === 'all' || user.userType === filter) &&
            (user.userName || '').toLowerCase().includes(searchQuery.toLowerCase())
        );

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedUsers = filteredUsers.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredUsers.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <UserModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        user={selectedUser}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteUser(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <Link to="/user/information" className={activeTab === 'users' ? 'active' : ''} onClick={() => setActiveTab('users')}>
                            Users
                        </Link>
                        <Link to="/employee/information" className={activeTab === 'employees' ? 'active' : ''} onClick={() => setActiveTab('employees')}>
                            Employees
                        </Link>
                        <Link to="/employee/attendance" className={activeTab === 'attendances' ? 'active' : ''} onClick={() => setActiveTab('attendances')}>
                            Attendances
                        </Link>
                        <Link to="/employee/pay" className={activeTab === 'paybook' ? 'active' : ''} onClick={() => setActiveTab('paybook')}>
                            Paybook
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search username" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Admin">Admin</option>
                                <option value="Employee">Employee</option>
                            </select>
                            <button className="action-button" onClick={handleAddUser}>Add User</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th style={{width: '100px'}}>Actions</th> {/* New Actions Column */} 
                                </tr>
                            </thead>
                            <tbody>
                                {displayedUsers.length > 0 ? (
                                    displayedUsers.map((user, index) => (
                                        <tr key={user.userID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{user.userID}</td>
                                            <td>{user.userName}</td>
                                            <td>{user.userType}</td>
                                            <td style={{width: '100px'}}>
                                                <button className="edit-button" onClick={() => handleEditUser(user)}>
                                                    <FaEdit />
                                                </button>
                                                <button className="delete-button" onClick={() => confirmDeleteUser(user.userID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No users found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default User;
