import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './PayrollModal.css';
import MessageModal from './MessageModal';
import AuthorizeModal from './AuthorizeModal';

function PayrollModal({ isOpen, onClose, onSubmit, user }) {
    const [formData, setFormData] = useState({
        paymentDate: moment.tz("Asia/Manila").format('YYYY-MM-DD'),
        payPeriodStart: null,
        payPeriodEnd: null,
        totalHours: 0,
        totalSalary: 0,
        handledBy: '',
        emp_id_fk: ''
    });
    const [employees, setEmployees] = useState([]); // State to store employee list
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [authorizeModalOpen, setAuthorizeModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [username, setUsername] = useState('');
    const [onConfirm, setOnConfirm] = useState(null);

    // Fetch list of employees on component mount
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/employees');
                setEmployees(response.data); // Assuming the response contains an array of employee objects
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
            setFormData(prevState => ({ ...prevState, handledBy: storedUsername }));
        }
    }, []);

    useEffect(() => {
        if (user) {
            setFormData(prevState => ({
                ...prevState,
                emp_id_fk: user.emp_id_fk || ''
            }));
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDateChange = (name, date) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: date
        }));
    };

    const setThisWeek = () => {
        const now = moment().startOf('isoWeek'); // Monday
        const startOfWeek = now.toDate(); // Convert to Date object
        const endOfWeek = now.add(6, 'days').toDate(); // Add 6 days for Sunday
    
        setFormData(prevState => ({
            ...prevState,
            payPeriodStart: startOfWeek,
            payPeriodEnd: endOfWeek
        }));
    };
    
    // Effect to recalculate salary when employee, start date, or end date changes
    useEffect(() => {
        const { emp_id_fk, payPeriodStart, payPeriodEnd } = formData;

        // Check if all required fields are present
        if (emp_id_fk && payPeriodStart && payPeriodEnd) {
            calculateSalary();
        } else {
            setFormData(prevState => ({
                ...prevState,
                totalHours: 'N/A',  // Set to "N/A" if missing data
                totalSalary: 0
            }));
        }
    }, [formData.emp_id_fk, formData.payPeriodStart, formData.payPeriodEnd]);

    // Calculate total hours and salary
    const calculateSalary = async () => {
        const { emp_id_fk, payPeriodStart, payPeriodEnd } = formData;

        try {
            // Fetch total hours from attendance logs
            const hoursResponse = await axios.get('https://www.capstonelimenerp.xyz:8082/api/attendance/total-hours', {
                params: { emp_id_fk, start: moment(payPeriodStart).format('YYYY-MM-DD'), end: moment(payPeriodEnd).format('YYYY-MM-DD') }
            });
            const totalHours = hoursResponse.data.totalHours || 0;

            // Fetch employee daily salary
            const empResponse = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/employees/${emp_id_fk}`);
            const empDailySalary = empResponse.data.empDailySalary || 0;

            // Calculate total salary
            const totalSalary = (totalHours / 8) * empDailySalary;

            // Update formData with calculated values
            setFormData(prevState => ({
                ...prevState,
                totalHours,
                totalSalary
            }));
        } catch (error) {
            console.error('Error fetching attendance or employee data:', error);
            setMessage('Error calculating salary. Please check your data.');
            setMessageModalOpen(true);
        }
    };

    const openAuthorizeModal = () => {
        setMessageModalOpen(false); // Close the MessageModal
        setAuthorizeModalOpen(true); // Open the AuthorizeModal
    };

    const closeAuthorizeClose = () => {
        setAuthorizeModalOpen(false); // Close the modal
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const { payPeriodStart, payPeriodEnd } = formData;
    
        if (!payPeriodStart || !payPeriodEnd) {
            setMessage("Please select both Pay Period Start and Pay Period End.");
            setOnConfirm(null); // Clear any existing confirmation handler
            setMessageModalOpen(true);
            return;
        }

        // Show confirmation message before proceeding to authorization
        setMessage(
            `You selected the following dates:\n\n` + // Add a new line after this sentence
            `Pay Period Start: ${moment(payPeriodStart).format('MM/DD/YYYY')}\n` + // Add a new line after the start date
            `Pay Period End: ${moment(payPeriodEnd).format('MM/DD/YYYY')}\n\n` + // Add a new line after the end date
            `Do you want to proceed?`
        );
        
        setOnConfirm(() => openAuthorizeModal); // Set the next step to open the authorization modal
        setMessageModalOpen(true); // Open the MessageModal for confirmation
    };
    
    
    const submitPayrollData = async () => {
        await calculateSalary(); // Ensure salary is calculated

        const overlapCheckUrl = 'https://www.capstonelimenerp.xyz:8082/api/salaries/check-overlap';
        const data = {
            paymentDate: formData.paymentDate,
            payPeriodStart: moment(formData.payPeriodStart).format('YYYY-MM-DD'),
            payPeriodEnd: moment(formData.payPeriodEnd).format('YYYY-MM-DD'),
            totalHours: formData.totalHours,
            totalSalary: formData.totalSalary,
            handledBy: formData.handledBy,
            emp_id_fk: formData.emp_id_fk,
        };

        if (formData.totalSalary === 0) {
            setMessage('Invalid Payslip: Total salary cannot be 0.');
            setOnConfirm(null); // Clear any existing confirmation handler
            setMessageModalOpen(true);
            return;
        }

        try {
            const overlapCheckResponse = await axios.get(overlapCheckUrl, {
                params: {
                    emp_id_fk: data.emp_id_fk,
                    payPeriodStart: data.payPeriodStart,
                    payPeriodEnd: data.payPeriodEnd,
                },
            });

            if (!overlapCheckResponse.data.success) {
                setMessage(overlapCheckResponse.data.message);
                setOnConfirm(null); // Clear any existing confirmation handler
                setMessageModalOpen(true);
                return;
            }

            const salaryUrl = 'https://www.capstonelimenerp.xyz:8082/api/salaries';
            await axios.post(salaryUrl, data);

            resetForm();
            onClose();
            onSubmit(data);
        } catch (error) {
            setMessage('Error adding salary record: ' + (error.response?.data?.message || error.message));
            setMessageModalOpen(true);
        }
    };

    
    
    
    
    
    const resetForm = () => {
        setFormData({
            paymentDate: moment.tz("Asia/Manila").format('YYYY-MM-DD'),
            payPeriodStart: null,
            payPeriodEnd: null,
            totalHours: 0,
            totalSalary: 0,
            handledBy: username,
            emp_id_fk: ''
        });
    };

    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="payroll-modal-overlay">
            <div className="payroll-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section" style={{width: '100%', height: '100%'}}>
                        <h2>Add Salary Record</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='depth'>
                                    <div className='form-group'>
                                        <label htmlFor="emp_id_fk"><strong>Employee</strong></label>
                                        <select
                                            name="emp_id_fk"
                                            value={formData.emp_id_fk}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Select Employee</option>
                                            {employees.map(employee => (
                                                <option key={employee.empID} value={employee.empID}>
                                                    {employee.empFName} {employee.empLName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='depth'>
                                    <div className='form-group'>
                                        <label htmlFor="handledBy"><strong>Handled By</strong></label>
                                        <input
                                            type="text"
                                            name="handledBy"
                                            value={formData.handledBy}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className='depth'>
                                    <div className='form-group'>
                                        <label htmlFor="paymentDate"><strong>Payment Date</strong></label>
                                        <input
                                            type="text"
                                            name="paymentDate"
                                            value={moment(formData.paymentDate).format('MM/DD/YYYY')}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className='depth'>
                                    <div className='form-group' style={{display: 'flex', flexDirection: 'column'}}>
                                        <label htmlFor="payPeriodStart"><strong>Pay Period Start</strong></label>
                                        <DatePicker
                                            className="custom-datepicker"
                                            selected={formData.payPeriodStart}
                                            onChange={(date) => handleDateChange('payPeriodStart', date)}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText='Choose a Date'
                                            required
                                        />
                                    </div>
                                    <div className='form-group' style={{maxWidth: '50px', textAlign: 'center'}}>
                                        <p style={{marginTop: '35px', fontWeight: 'bold'}}>To</p>
                                    </div>

                                    <div className='form-group' style={{display: 'flex', flexDirection: 'column'}}>
                                        <label htmlFor="payPeriodEnd"><strong>Pay Period End</strong></label>
                                        <DatePicker
                                            className="custom-datepicker"
                                            selected={formData.payPeriodEnd}
                                            onChange={(date) => handleDateChange('payPeriodEnd', date)}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText='Choose a Date'
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='depth'>
                                    <div className='form-group' style={{display: 'flex', flexDirection: 'column'}}>
                                        <button type="button" className="set-week-button" onClick={setThisWeek}>
                                            Set Pay Period to This Week
                                        </button>
                                    </div>
                                </div>
                                <div className='depth'>
                                    <div className='form-group'>
                                        <label htmlFor="totalHours"><strong>Total Hours</strong></label>
                                        <input
                                            type="text"
                                            name="totalHours"
                                            value={formData.totalHours === 'N/A' ? 'N/A' : formData.totalHours}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className='depth'>
                                    <div className='form-group'>
                                        <label htmlFor="totalSalary"><strong>Total Salary</strong></label>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="totalSalary"
                                            value={formData.totalSalary === 'N/A' ? 'N/A' : formData.totalSalary.toFixed(2)}
                                            readOnly
                                        />
                                    </div>
                                </div>





                                <div className='button-container'>
                                    <button type="submit" className='user-button'>Add Record</button>
                                </div>
                            </form>
                    </div>
                </div>
            </div>
            <MessageModal 
                isOpen={messageModalOpen}
                onClose={() => setMessageModalOpen(false)}
                message={message}
                isConfirmation={!!onConfirm} // Only show confirmation buttons if `onConfirm` is set
                onConfirm={onConfirm || (() => setMessageModalOpen(false))} // Fallback to close modal if `onConfirm` is null
            />
            <AuthorizeModal
                isOpen={authorizeModalOpen}
                onClose={closeAuthorizeClose} // Clear and close modal
                onAuthorize={submitPayrollData} // Proceed to submit payroll data after authorization
            />
        </div>
    );
}

export default PayrollModal;
