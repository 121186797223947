import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaTrash, FaTruck, FaCheckCircle } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import PasswordModal from './PasswordModal';
import MessageModal from './MessageModal';
import StockTransferModal from './StockTransferModal';
import StockReceiveModal from './StockReceiveModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function InventoryTransfer() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);

    const [username, setUsername] = useState('');
    const [userID, setUserID] = useState('');
    const [branchID, setBranchID] = useState('');

    const [branchName, setBranchName] = useState(''); // State to store branch name

    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [transferData, setTransferData] = useState([]);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [transferToDelete, setTransferToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('transfer'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [stkTransNoForUpdate, setStkTransNoForUpdate] = useState(null);

    const [isReceiveModalOpen, setIsReceiveModalOpen] = useState(false);
    const [stkTransNoForReceive, setStkTransNoForReceive] = useState(null);

    const loggedInBranchID = localStorage.getItem('branchID');

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedUserID = localStorage.getItem('userID');
        const storedBranchID = localStorage.getItem('branchID');
        const storedBranchName = localStorage.getItem('branchName');

        if (storedUsername) setUsername(storedUsername);
        if (storedUserID) setUserID(storedUserID);
        if (storedBranchID) setBranchID(storedBranchID);
        if (storedBranchName) setBranchName(storedBranchName);
        else navigate('/'); // Redirect to login if no branch found

            const fetchData = async () => {
                try {
                    const [transferResponse, branchResponse] = await Promise.all([
                        axios.get('https://www.capstonelimenerp.xyz:8082/api/stocktransfers'),
                        axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                    ]);
                    setTransferData(transferResponse.data);
                } catch (error) {
                    console.error('Error fetching stock transfers', error);
                }
            };
            fetchData();
        }, [navigate]);

        useEffect(() => {
        if (refreshTable) {
            // Fetch stock transfers again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/stocktransfers')
                .then(response => {
                    console.log('Stock Transfer API Response:', response.data);
                    setTransferData(response.data); // Set updated stock transfer data
                    setRefreshTable(false); // Reset refresh trigger
                })
                .catch(error => {
                    console.error('There was an error refreshing the stock transfer data!', error.response || error.message || error);
                });
        }
        }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddTransfer = () => {
        setSelectedTransfer(null);
        setLineItems([]);
        setIsModalOpen(true);
        setIsViewMode(false);
    };
    
    const handleViewTransfer = (transfer) => {
        setSelectedTransfer(transfer);
        
        // Fetch the line items for the transfer
        axios.get(`https://www.capstonelimenerp.xyz:8082/api/stocktransfer/${transfer.stkTransNo}/lineitems`)
            .then(async (response) => {
                const items = response.data;
    
                const itemsWithNames = await Promise.all(items.map(async item => {
                    if (!item.prodNo) {
                        console.warn('Missing prodNo for item:', item);
                        return {
                            ...item,
                            selectedProductName: 'Unknown Product'  // Fallback for missing prodNo
                        };
                    }
                
                    try {
                        const productResponse = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/${item.prodNo}`);
                        return {
                            ...item,
                            selectedProductName: productResponse.data.prodName // Set prodName from API response
                        };
                    } catch (error) {
                        console.error(`Error fetching product by prodNo: ${item.prodNo}`, error);
                        return {
                            ...item,
                            selectedProductName: 'Error fetching product' // Fallback if product fetching fails
                        };
                    }
                }));
    
                setLineItems(itemsWithNames);  // Set the line items with product names
                setIsModalOpen(true);          // Open the modal
                setIsViewMode(true);           // Set view mode to true
            })
            .catch(error => {
                console.error('Error fetching line items:', error);
            });
    };
    
    
    
    
    const confirmDeleteTransfer = (stkTransNo) => {
        setTransferToDelete(stkTransNo); 
        setMessage('Are you sure you want to delete this stock transfer?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); 
        setIsConfirmation(true);  
    }
    
    const handleDeleteTransfer = () => {
        if (transferToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/stocktransfer/${transferToDelete}`)
                .then(() => {
                    // Filter out the deleted transfer from the current transferData
                    setTransferData(transferData.filter(transfer => transfer.stkTransNo !== transferToDelete));
                    
                    // Show success message after deletion
                    setMessage('Stock transfer and associated line items successfully deleted.');
                    setDeletionConfirmed(true);
                    setIsMessageModalOpen(true); // Open the MessageModal to display success
                    
                    // Refresh the table to reflect changes
                    setRefreshTable(true);
                })
                .catch(error => {
                    // Handle errors during deletion
                    console.error('Error deleting stock transfer:', error);
                    setMessage('Error deleting stock transfer and associated line items.');
                    setDeletionConfirmed(true);
                    setIsMessageModalOpen(true); // Open the MessageModal to display error
                });
        }
    };
    
    
    

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
    
        const successMessage = isEditing ? 'Inventory updated successfully!' : 'Request created successfully!';
        setMessage(successMessage);
    
        setShowCloseButton(true); 
        setIsMessageModalOpen(true);
        setIsConfirmation(false); 
        setRefreshTable(true);
    };

    const handlePasswordConfirm = (password) => {
        const loggedInUserID = localStorage.getItem('userID'); // Fetch userID from localStorage
        const loggedInBranchID = localStorage.getItem('branchID'); // Fetch branchID
    
        console.log('UserID being passed to API:', loggedInUserID);
        console.log('Password being passed to API:', password);
    
        // Validate the user credentials via API
        axios.post('https://www.capstonelimenerp.xyz:8082/api/password', {
            userID: loggedInUserID,  // Send userID
            password: password       // Send entered password
        })
        .then(response => {
            console.log('Entered Password:', password); // Log entered password
            console.log('Validation Response:', response.data); // Log response
    
            if (response.data.valid) {
                // Check if this is an "In Transit" or "Complete" operation based on transfer state
                if (stkTransNoForUpdate) {
                    const transfer = transferData.find(t => t.stkTransNo === stkTransNoForUpdate);
    
                    if (transfer) {
                        console.log('Transfer found:', transfer);
    
                        if (transfer.transferStatus === 'Pending' && transfer.senderEmpID === loggedInUserID) {
                            // Update to "In Transit" if status is 'Pending' and user is the sender
                            updateTransferStatusToInTransit(stkTransNoForUpdate);
                        } else {
                            setMessage('You are not authorized to perform this action.');
                            setIsMessageModalOpen(true);
                        }
                    } else {
                        setMessage('Transfer not found.');
                        setIsMessageModalOpen(true);
                    }
                }
                setIsPasswordModalOpen(false); // Close the password modal
            } else {
                console.log('Password validation failed.');
                setMessage('Invalid password. Please try again.');
                setIsMessageModalOpen(true); // Show error message
            }
        })
        .catch(error => {
            console.error('Error validating password:', error);
            setMessage('Error validating password. Please try again.');
            setIsMessageModalOpen(true); // Show error message
        });
    };
    
    
    const openPasswordModal = (stkTransNo) => {
        setStkTransNoForUpdate(stkTransNo); // Store the transfer number to update
        setIsPasswordModalOpen(true);       // Open the password modal
    };
    
    const handleReceiveTransfer = (transfer) => {
        setSelectedTransfer(transfer);
    
        // Fetch the line items for the transfer
        axios.get(`https://www.capstonelimenerp.xyz:8082/api/stocktransfer/${transfer.stkTransNo}/lineitems`)
            .then(async (response) => {
                const items = response.data;
    
                // Map items to include product names and initialize qtyReceived
                const itemsWithNames = await Promise.all(
                    items.map(async (item) => {
                        if (!item.prodNo) {
                            console.warn('Missing prodNo for item:', item);
                            return {
                                ...item,
                                selectedProductName: 'Unknown Product', // Fallback for missing prodNo
                                qtyReceived: 0, // Initialize qtyReceived
                            };
                        }
    
                        try {
                            const productResponse = await axios.get(
                                `https://www.capstonelimenerp.xyz:8082/api/products/${item.prodNo}`
                            );
                            return {
                                ...item,
                                selectedProductName: productResponse.data.prodName, // Add prodName
                                qtyReceived: 0, // Initialize qtyReceived
                            };
                        } catch (error) {
                            console.error(`Error fetching product by prodNo: ${item.prodNo}`, error);
                            return {
                                ...item,
                                selectedProductName: 'Error fetching product', // Fallback if fetching fails
                                qtyReceived: 0, // Initialize qtyReceived
                            };
                        }
                    })
                );
    
                setLineItems(itemsWithNames); // Set the processed line items
                setIsReceiveModalOpen(true); // Open the StockReceiveModal
            })
            .catch((error) => {
                console.error('Error fetching line items:', error);
            });
    };
    

    const updateTransferStatusToInTransit = (stkTransNo) => {
        axios.patch(`https://www.capstonelimenerp.xyz:8082/api/stocktransfer/${stkTransNo}/status`, { status: 'In Transit' })
            .then(response => {
                console.log(response.data.message);
                setRefreshTable(true);  // Refresh the table after status change
            })
            .catch(error => {
                console.error('Error updating transfer status to In Transit:', error);
            });
    };
    
    const filteredTransfer = transferData.filter((transfer) => {
        const fromBranch = transfer.fromBranchName || ''; // Ensure it's a string
        const toBranch = transfer.toBranchName || ''; // Ensure it's a string
        const transferNo = transfer.stkTransNo || ''; // Ensure it's a string
        const referenceOrder = transfer.referenceOrder || ''; // Ensure it's a string
        const status = transfer.transferStatus || ''; // Ensure it's a string
    
        const matchesSearch =
            fromBranch.toLowerCase().includes(searchQuery.toLowerCase()) ||
            toBranch.toLowerCase().includes(searchQuery.toLowerCase()) ||
            transferNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
            referenceOrder.toLowerCase().includes(searchQuery.toLowerCase());
    
        const matchesFilter = filter === 'all' || status === filter;
    
        return matchesSearch && matchesFilter;
    });
    

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedTransfer = filteredTransfer.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredTransfer.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <PasswordModal
                        isOpen={isPasswordModalOpen}
                        onClose={() => setIsPasswordModalOpen(false)}
                        onConfirm={handlePasswordConfirm}
                        username={username} // Pass the username as a prop
                    />
                    <StockTransferModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        lineItems={lineItems}
                        transfer={selectedTransfer}
                        isViewMode={isViewMode} // Pass the view mode state
                    />
                    <StockReceiveModal
                        isOpen={isReceiveModalOpen}
                        onClose={() => setIsReceiveModalOpen(false)}
                        lineItems={lineItems}
                        transfer={selectedTransfer}
                        userID={userID}
                        setRefreshTable={setRefreshTable}
                    />

                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteTransfer(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/inventory/products" className={activeTab === 'products' ? 'active' : ''} onClick={() => setActiveTab('products')}>
                            Product List
                        </Link>
                        <Link to="/inventory/stocks" className={activeTab === 'stocks' ? 'active' : ''} onClick={() => setActiveTab('stocks')}>
                            Stocks
                        </Link>
                        <Link to="/inventory/request" className={activeTab === 'request' ? 'active' : ''} onClick={() => setActiveTab('request')}>
                            Stock Request
                        </Link>
                        <Link to="/inventory/transfer" className={activeTab === 'transfer' ? 'active' : ''} onClick={() => setActiveTab('transfer')}>
                            Stock Transfer
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search branch name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="In Transit">In Transit</option>
                                <option value="Completed">Completed</option>
                            </select>
                            <button className="action-button" onClick={handleAddTransfer}>Initiate Transfer</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container"> 
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>#</th>
                                    <th>Transfer No</th>
                                    <th>From Branch</th>
                                    <th>To Branch</th>
                                    <th>Transfer Date</th>
                                    <th>Status</th>
                                    <th>Sender Employee</th>
                                    <th>Receiver Employee</th>
                                    <th>Reference Order</th>
                                    <th style={{ width: '150px' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedTransfer.length > 0 ? (
                                    displayedTransfer.map((transfer, index) => (
                                        <tr key={transfer.stkTransNo}>
                                            <td style={{ width: '50px' }}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{transfer.stkTransNo}</td>
                                            <td>{transfer.fromBranchName}</td>
                                            <td>{transfer.toBranchName}</td>
                                            <td>{new Date(transfer.stkTransDate).toLocaleDateString()}</td>
                                            <td>{transfer.transferStatus}</td>
                                            <td>{transfer.senderName || 'N/A'}</td> 
                                            <td>{transfer.receiverName || 'N/A'}</td>
                                            <td>{transfer.referenceOrder}</td>
                                            <td style={{ width: '150px' }}>
                                                <div className="table-action-buttons">
                                                    <button className="view-button" onClick={() => handleViewTransfer(transfer)}>
                                                        <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} />
                                                    </button>
                                                    {transfer.transferStatus === 'Pending' && (
                                                        <button className="send-button" onClick={() => openPasswordModal(transfer.stkTransNo)}>
                                                            <FaTruck style={{ color: '#0056b3', fontSize: '1.2rem' }} />
                                                        </button>
                                                    )}
                                                    {transfer.transferStatus === 'In Transit' && transfer.toBranch === branchID && (
                                                        <button className="receive-button" onClick={() => handleReceiveTransfer(transfer)}>
                                                            <FaCheckCircle style={{ color: 'green', fontSize: '1.2rem' }} />
                                                        </button>
                                                    )}
                                                    <button className="delete-button" onClick={() => confirmDeleteTransfer(transfer.stkTransNo)}>
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: 'center' }}>No stock transfer records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>


                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>      

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default InventoryTransfer;
