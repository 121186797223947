import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StockReceiveModal.css';
import './StylesModal.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MessageModal from './MessageModal';
import PasswordModal from './PasswordModal';

function StockReceiveModal({ isOpen, onClose, lineItems, transfer, userID, setRefreshTable }) {
    const [formData, setFormData] = useState({
        stkTransNo: '',
        stkTransDate: '',
        transferStatus: '',
        fromBranchID: '',
        toBranchID: '',
        fromBranchName: '',
        toBranchName: '',
        senderEmpID: '',
        receiverEmpID: userID || '',
        referenceOrder: '',
        lineItems: [],
        discrepancyReason: '',
    });

    const [branches, setBranches] = useState([]);
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [stkTransNoForUpdate, setStkTransNoForUpdate] = useState(null);

    const loggedInUserID = localStorage.getItem('userID');
    const loggedInUsername = localStorage.getItem('username');

    useEffect(() => {
        if (isOpen) {
            // Fetch branches to map branch IDs to names
            axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                .then(response => {
                    setBranches(response.data);
                })
                .catch(error => {
                    console.error('Error fetching branches:', error);
                });

            setFormData({
                stkTransNo: transfer?.stkTransNo || '',
                stkTransDate: transfer?.stkTransDate || '',
                transferStatus: transfer?.transferStatus || '',
                fromBranchID: transfer?.fromBranch || '',
                toBranchID: transfer?.toBranch || '',
                fromBranchName: transfer?.fromBranchName || 'Unknown',
                toBranchName: transfer?.toBranchName || 'Unknown',
                senderEmpID: transfer?.senderEmpID || '',
                receiverEmpID: userID || '',
                referenceOrder: transfer?.referenceOrder || '',
                lineItems: lineItems.map(item => ({
                    ...item,
                    qtyReceived: item.qtyTransferred || 0, // Default received qty
                    notes: '', // Default notes
                })),
            });
        }
    }, [isOpen, transfer, lineItems, userID]);

    const handleQtyChange = (index, value) => {
        const updatedItems = [...formData.lineItems];
    
        if (value < 0) {
            updatedItems[index].qtyReceived = 0; // Reset to 0
            setMessage('Negative value is not allowed.'); // Set error message
            setMessageModalOpen(true); // Open the MessageModal
        } else {
            updatedItems[index].qtyReceived = value || 0; // Set value or reset to 0 if NaN
        }
    
        setFormData((prevState) => ({ ...prevState, lineItems: updatedItems }));
    };
    
    

    const handleNotesChange = (index, value) => {
        const updatedItems = [...formData.lineItems];
        updatedItems[index].notes = value;
        setFormData(prevState => ({ ...prevState, lineItems: updatedItems }));
    };

    const handleConfirmReceipt = () => {
        const discrepancies = formData.lineItems.filter(
            (item) => item.qtyReceived !== item.qtyTransferred
        );
    
        console.log('Receipt Confirmed:', {
            stkTransNo: formData.stkTransNo,
            lineItems: formData.lineItems,
            discrepancyReason: formData.discrepancyReason,
            discrepancies,
        });
    
        // Complete the transfer after logging discrepancies
        completeTransfer(formData.stkTransNo);
    };
    
    
    const handlePasswordConfirm = (password) => {
        const loggedInUserID = localStorage.getItem('userID'); // Fetch userID from localStorage
        const loggedInBranchID = localStorage.getItem('branchID'); // Fetch branchID
    
        console.log('UserID being passed to API:', loggedInUserID);
        console.log('Password being passed to API:', password);
    
        // Validate the user credentials via API
        axios.post('https://www.capstonelimenerp.xyz:8082/api/password', {
            userID: loggedInUserID,  // Send userID
            password: password       // Send entered password
        })
        .then(response => {
            console.log('Entered Password:', password); // Log entered password
            console.log('Validation Response:', response.data); // Log response
    
            if (response.data.valid) {
                // Check if there are discrepancies to log
                const discrepancies = formData.lineItems.filter(
                    item => item.qtyReceived !== item.qtyTransferred // Identify discrepancies
                );
    
                if (discrepancies.length > 0) {
                    logDiscrepancies(); // Call the logDiscrepancies function to log discrepancies
                }
    
                // Proceed with stock receipt confirmation
                handleConfirmReceipt();
    
                setIsPasswordModalOpen(false); // Close the password modal
            } else {
                console.log('Password validation failed.');
                setMessage('Invalid password. Please try again.');
                setMessageModalOpen(true); // Show error message
            }
        })
        .catch(error => {
            console.error('Error validating password:', error);
            setMessage('Error validating password. Please try again.');
            setMessageModalOpen(true); // Show error message
        });
    };
    
    const logDiscrepancies = () => {
        const discrepancies = formData.lineItems.filter(
            (item) => item.qtyReceived !== item.qtyTransferred // Identify discrepancies
        );
    
        console.log('Discrepancies to be logged:', discrepancies);
    
        discrepancies.forEach((item) => {
            const missingQty = item.qtyTransferred - item.qtyReceived;
            const logEntry = {
                stkTransNo_fk: formData.stkTransNo,
                prod_No_fk: item.prodNo,
                expectedQty: item.qtyTransferred,
                actualQty: item.qtyReceived,
                missingQty: missingQty,
                reason: item.notes || formData.discrepancyReason,
                recordedBy: loggedInUserID,
                loggedDateTime: new Date().toISOString().slice(0, 19).replace('T', ' '), // Format for MySQL DATETIME
            };
    
            console.log('Log Entry being sent:', logEntry);
    
            axios
                .post('https://www.capstonelimenerp.xyz:8082/api/discrepancylog', logEntry)
                .then((response) => {
                    console.log(`Discrepancy logged for product ${item.prodNo}:`, response.data);
                })
                .catch((error) => {
                    console.error(`Error logging discrepancy for product ${item.prodNo}:`, error.message);
                    console.error('Full Error Details:', error.response?.data || error);
                });
        });
    };
    
    const completeTransfer = (stkTransNo) => {
        const loggedInUserID = localStorage.getItem('userID'); // Fetch userID from localStorage
    
        axios.patch(`https://www.capstonelimenerp.xyz:8082/api/stocktransfer/${stkTransNo}/status`, {
            status: 'Completed',
            receiverEmpID: loggedInUserID, // Send receiver's userID
        })
        .then(response => {
            console.log('Transfer completed successfully:', response.data.message);
            setMessage('Transfer marked as completed successfully.');
            setMessageModalOpen(true); // Show success message
            setRefreshTable(true); // Trigger table refresh
            onClose(); // Close the modal after completion
        })
        .catch(error => {
            console.error('Error completing transfer:', error);
            setMessage('Error completing transfer. Please try again.');
            setMessageModalOpen(true); // Show error message
        });
    };
    

    const openPasswordModal = (stkTransNo) => {
        setStkTransNoForUpdate(stkTransNo); // Store the transfer number to update
        setIsPasswordModalOpen(true);       // Open the password modal
    };
    
    if (!isOpen) return null;

    return (
        <div className="transfer-modal-overlay">
            <div className="transfer-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>

                <form>
                    <div className="form-container">
                        <div className="left-section">
                            <h2>Receive Stock Transfer</h2>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="stkTransNo"><strong>Transfer No:</strong></label>
                                    <input
                                        type="text"
                                        name="stkTransNo"
                                        value={formData.stkTransNo || 'N/A'}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label>Transfer Date</label>
                                    <DatePicker
                                        selected={formData.stkTransDate ? new Date(formData.stkTransDate) : null}
                                        dateFormat="MM-dd-yyyy"
                                        disabled
                                        className="date-picker-input"
                                        placeholderText="MM-DD-YYYY"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="transferStatus"><strong>Transfer Status</strong></label>
                                    <input
                                        type="text"
                                        name="transferStatus"
                                        value={formData.transferStatus}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="fromBranchID"><strong>From Branch</strong></label>
                                    <input
                                        type="text"
                                        name="fromBranchID"
                                        value={formData.fromBranchName}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="toBranchID"><strong>To Branch</strong></label>
                                    <input
                                        type="text"
                                        name="toBranchID"
                                        value={formData.toBranchName}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="senderEmpID"><strong>Sender Employee</strong></label>
                                    <input
                                        type="text"
                                        name="senderEmpID"
                                        value={transfer?.senderName || 'N/A'}
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="receiverEmpID"><strong>Receiver Employee</strong></label>
                                    <input
                                        type="text"
                                        name="receiverEmpID"
                                        value={loggedInUsername}  // Auto-filled with logged-in user
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="referenceOrder"><strong>Reference Order</strong></label>
                                    <input
                                        type="text"
                                        name="referenceOrder"
                                        value={formData.referenceOrder || 'N/A'}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                            <div className="form-group">
                                <label htmlFor="discrepancyReason"><strong>Discrepancy Reason</strong></label>
                                <textarea
                                    id="discrepancyReason"
                                    placeholder="Enter the reason for any discrepancy"
                                    value={formData.discrepancyReason || ''}
                                    onChange={(e) => setFormData(prevState => ({ ...prevState, discrepancyReason: e.target.value }))}
                                    className="general-notes-textarea"
                                />
                            </div>
                        </div>
                        </div>

                        <div className="right-section">
                            <div className="inventory-line-items-section">
                                <table className="inventory-line-items-table">
                                    <thead>
                                        <tr>
                                            <th style={{width: '5%'}}>#</th>
                                            <th style={{width: '45%'}}>Product Name</th>
                                            <th style={{width: '10%'}}>Expected</th>
                                            <th style={{width: '10%'}}>Actual</th>
                                            <th style={{width: '30%'}}>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.lineItems.length > 0 ? (
                                            formData.lineItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td style={{textAlign: 'left'}}>{item.prodName || 'Unknown'}</td>
                                                    <td>{item.qtyTransferred}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={item.qtyReceived}
                                                            onChange={(e) => handleQtyChange(index, parseInt(e.target.value, 10))}
                                                            style={{ textAlign: 'center' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <textarea
                                                            value={item.notes}
                                                            placeholder="Enter a note"
                                                            onChange={(e) =>
                                                                handleNotesChange(index, e.target.value)
                                                            }
                                                            className="notes-textarea"
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" style={{ textAlign: 'center' }}>
                                                    No line items available.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="button-container">
                                <button
                                    type="button"
                                    className="stock-button"
                                    onClick={() => openPasswordModal(formData.stkTransNo)} // Open PasswordModal with the transfer number
                                    disabled={formData.lineItems.length === 0}
                                >
                                    Confirm Receipt
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <MessageModal
                isOpen={messageModalOpen}
                onClose={() => setMessageModalOpen(false)}
                message={message}
            />
            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                onConfirm={handlePasswordConfirm}
                username={loggedInUsername}
            />
        </div>
    );
}

export default StockReceiveModal;
