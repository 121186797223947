import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SideNavigation.css';
import { FaEye, FaTrash, FaTruck, FaCheckCircle } from 'react-icons/fa';
import { FcMenu, FcPaid, FcPackage, FcPortraitMode, FcFactory, FcComboChart, FcConferenceCall, FcDepartment, FcShipped, FcPositiveDynamic } from "react-icons/fc";

function SideNavigation({ isSidebarOpen, setIsSidebarOpen, expandedModule, setExpandedModule, handleLogout, children }) {
    const [userType, setUserType] = useState('');
    const [empPosition, setEmpPosition] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        setUserType(localStorage.getItem('userType'));
        setEmpPosition(localStorage.getItem('empPosition'));
    }, []);

    const toggleSidebar = () => {
        if (isSidebarOpen) {
            // Collapse any open module when closing the sidebar
            setExpandedModule(null);
        }
        // Toggle the sidebar open/closed
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleModuleClick = (module, path) => {
        if (!isSidebarOpen) {
            setIsSidebarOpen(true); // Open the sidebar if it’s closed
        }
        
        setExpandedModule(expandedModule === module ? null : module); // Expand/collapse module
        
        if (path) {
            navigate(path); // Navigate to the specified path immediately
        }
    };


    const renderModule = (module, icon, link, label, subModules = []) => (
        <li onClick={() => handleModuleClick(module, link)}
            className={expandedModule === module ? 'active' : ''}
            aria-expanded={expandedModule === module}>
            {isSidebarOpen ? (
                <span className="label">
                    <span className="icon">{icon}</span>
                    <Link to={link}>{label}</Link>
                </span>
            ) : (
                <span className="icon">{icon}</span>
            )}
            {expandedModule === module && subModules.length > 0 && isSidebarOpen && (
                <ul className="submodule-list">
                    {subModules.map(({ path, name }) => (
                        <li key={name} onClick={() => navigate(path)}><Link to={path}>{name}</Link></li>
                    ))}
                </ul>
            )}
        </li>
    );
    const allowedModules = () => {
        if (userType === 'Admin') {
            return (
                <>
                    {renderModule('dashboard', <FcPositiveDynamic />, '/dashboard', 'Dashboard')}
                    {renderModule('sales', <FcPaid />, '', 'Sales', [
                        { path: '/sales/new', name: 'New Cart' },
                        { path: '/sales/history', name: 'Sales History' }
                    ])}
                    {renderModule('inventory', <FcPackage />, '', 'Inventory', [
                        { path: '/inventory/products', name: 'Product List' },
                        { path: '/inventory/stocks', name: 'Stocks' },
                        { path: '/inventory/request', name: 'Stock Request' },
                        { path: '/inventory/transfer', name: 'Stock Transfer' },
                    ])}
                    {renderModule('branches', <FcDepartment />, '', 'Branch', [
                        { path: '/branch/information', name: 'Branch Information' },
                        { path: '/branch/employees', name: 'Branch Employees' }
                    ])}
                    {renderModule('customers', <FcConferenceCall />, '', 'Customer', [
                        { path: '/customer/information', name: 'Customer Information' },
                        { path: '/customer/balance', name: 'Customer Balance' }
                    ])}
                    {renderModule('suppliers', <FcFactory />, '/supplier/information', 'Supplier')}
                    {renderModule('users', <FcPortraitMode />, '', 'User', [
                        { path: '/user/information', name: 'User Accounts' },
                        { path: '/employee/information', name: 'Employee Information' },
                        { path: '/employee/attendance', name: 'Attendance' },
                        { path: '/employee/pay', name: 'Payroll' }
                    ])}
                    {renderModule('purchases', <FcShipped />, '', 'Procurement', [
                        { path: '/purchase/transaction', name: 'Purchase Transactions' },
                        { path: '/purchase/order', name: 'Purchase Orders' },
                        { path: '/purchase/suggestion', name: 'Purchase Suggestions' }
                    ])}
                    {renderModule('reports', <FcComboChart />, '', 'Report', [
                        { path: '/report/sales', name: 'Sales Report' },
                        { path: '/report/inventory', name: 'Inventory Report' },
                        { path: '/report/purchases', name: 'Purchase Report' },
                        { path: '/report/payroll', name: 'Salary Report' }
                    ])}
                </>
            );
        }

        if (userType === 'Employee') {
            switch (empPosition) {
                case 'Purchase Officer':
                    return (
                        <>
                            {renderModule('inventory', <FcPackage />, '', 'Inventory', [
                                { path: '/inventory/products', name: 'Product List' },
                                { path: '/inventory/stocks', name: 'Stocks' }
                            ])}
                            {renderModule('suppliers', <FcFactory />, '/supplier/information', 'Supplier')}
                            {renderModule('purchases', <FcShipped />, '', 'Procurement', [
                                { path: '/purchase/transaction', name: 'Purchase Transactions' },
                                { path: '/purchase/order', name: 'Purchase Orders' },
                                { path: '/purchase/suggestion', name: 'Purchase Suggestions' }
                            ])}
                        </>
                    );


                case 'Salesperson':
                    return (
                        <>
                            {renderModule('inventory', <FcPackage />, '', 'Inventory', [
                                { path: '/inventory/products', name: 'Product List' },
                                { path: '/inventory/stocks', name: 'Stocks' },
                                { path: '/inventory/request', name: 'Stock Request' },
                                { path: '/inventory/transfer', name: 'Stock Transfer' }
                            ])}
                            {renderModule('suppliers', <FcFactory />, '/supplier/information', 'Supplier')}
                        </>
                    );


                case 'Cashier':
                    return renderModule('sales', <FcPaid />, '', 'Sales', [
                        { path: '/sales/new', name: 'New Cart' }
                    ]);

                case 'Branch Manager':
                    return (
                        <>
                            {renderModule('dashboard', <FcPositiveDynamic />, '/dashboard', 'Dashboard')}
                            {renderModule('sales', <FcPaid />, '', 'Sales', [
                                { path: '/sales/new', name: 'New Cart' },
                                { path: '/sales/history', name: 'Sales History' }
                            ])}
                            {renderModule('inventory', <FcPackage />, '', 'Inventory', [
                                { path: '/inventory/products', name: 'Product List' },
                                { path: '/inventory/stocks', name: 'Stocks' },
                                { path: '/inventory/request', name: 'Stock Request' },
                                { path: '/inventory/transfer', name: 'Stock Transfer' },
                            ])}
                            {renderModule('branches', <FcDepartment />, '', 'Branch', [
                                { path: '/branch/information', name: 'Branch Information' },
                                { path: '/branch/employees', name: 'Branch Employees' }
                            ])}
                            {renderModule('customers', <FcConferenceCall />, '', 'Customer', [
                                { path: '/customer/information', name: 'Customer Information' },
                                { path: '/customer/balance', name: 'Customer Balance' }
                            ])}
                            {renderModule('suppliers', <FcFactory />, '/supplier/information', 'Supplier')}
                            {renderModule('users', <FcPortraitMode />, '', 'User', [
                                { path: '/user/information', name: 'User Accounts' },
                                { path: '/employee/information', name: 'Employee Information' },
                                { path: '/employee/attendance', name: 'Attendance' },
                                { path: '/employee/pay', name: 'Payroll' }
                            ])}
                            {renderModule('purchases', <FcShipped />, '', 'Procurement', [
                                { path: '/purchase/transaction', name: 'Purchase Transactions' },
                                { path: '/purchase/order', name: 'Purchase Orders' },
                                { path: '/purchase/suggestion', name: 'Purchase Suggestions' }
                            ])}
                            {renderModule('reports', <FcComboChart />, '', 'Report', [
                                { path: '/report/sales', name: 'Sales Report' },
                                { path: '/report/inventory', name: 'Inventory Report' },
                                { path: '/report/purchases', name: 'Purchase Report' },
                                { path: '/report/payroll', name: 'Salary Report' }
                            ])}
                        </>
                    );
                    
                default:
                    return <li>No access to modules</li>;
            }
        }
    };

    return (
        <div className="sidebar-container">
            <button className="hamburger-icon" onClick={toggleSidebar}>
                <FcMenu />
            </button>
            <div className="icon-container">
                <div className="icon"><FcPositiveDynamic /></div>
                <div className="icon"><FcPaid /></div>
                <div className="icon"><FcPackage /></div>
                <div className="icon"><FcDepartment /></div>
                <div className="icon"><FcConferenceCall /></div>
                <div className="icon"><FcFactory /></div>
                <div className="icon"><FcPortraitMode /></div>
                <div className="icon"><FcShipped /></div>
                <div className="icon"><FcComboChart /></div>
            </div>
            <div className={`side-nav ${isSidebarOpen ? 'open' : ''}`}>
                <ul>{allowedModules()}</ul>
            </div>

            {/* Main Content goes here */}
            <div className={`main-content-container ${isSidebarOpen ? 'shifted' : ''}`}>
                {children}
            </div>
        </div>
    );
}

export default SideNavigation;
