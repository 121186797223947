import React, { useState, useEffect } from 'react';
import { FaMoneyBillAlt } from 'react-icons/fa';
import './PaymentModal.css';
import MessageModal from './MessageModal';

function PaymentModal({ totalDue, onConfirmPayment, onClose }) {
    const [tenderedAmount, setTenderedAmount] = useState('');
    const [change, setChange] = useState(0);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [exactSelected, setExactSelected] = useState(false);

    const dynamicLimit = totalDue + 1000; // Dynamic limit set to Total Due + 1000

    useEffect(() => {
        const calculatedChange = Math.max(0, parseFloat(tenderedAmount) - totalDue);
        setChange(calculatedChange);
    }, [tenderedAmount, totalDue]);

    const handleTenderedAmountChange = (e) => {
        const amount = parseFloat(e.target.value) || 0;

        if (amount > dynamicLimit) {
            setShowMessageModal(true); // Show confirmation modal for excessive amount
        } else {
            setTenderedAmount(e.target.value);  // Keep as plain input to avoid restricting user input
            setErrorMessage('');
        }
    };

    const handleConfirm = () => {
        if (tenderedAmount >= totalDue) {
            onConfirmPayment(parseFloat(tenderedAmount).toFixed(2), change.toFixed(2));
            onClose();
        }
    };

    const handleQuickAdd = (amount) => {
        const newAmount = parseFloat(tenderedAmount || 0) + amount;
        if (newAmount <= dynamicLimit) {
            setTenderedAmount(newAmount.toFixed(2)); // Format to two decimals after adding
            setErrorMessage('');
        } else {
            setShowMessageModal(true); // Show confirmation modal for excessive amount
        }
    };

    const confirmExcessiveAmount = () => {
        setErrorMessage(''); // Clear error message
        setShowMessageModal(false); // Close MessageModal
    };

    const cancelExcessiveAmount = () => {
        setTenderedAmount(''); // Reset tendered amount
        setShowMessageModal(false); // Close MessageModal
        setErrorMessage('Amount exceeds suggested limit. Please review.');
    };

    const handleExactAmount = () => {
        setTenderedAmount(totalDue.toFixed(2));
        setExactSelected(true);
    };

    return (
        <div className="payment-modal-overlay">
            <div className="payment-modal-content">
                <button className="payment-modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-payment-section">
                        <h3>Payment</h3>
                        <div className="input-group">
                            <label>Total Due:</label>
                            <input type="text" value={`₱${totalDue.toFixed(2)}`} readOnly />
                        </div>

                        <div className="input-group">
                            <label>Tendered Amount:</label>
                            <input 
                                type="text" 
                                value={tenderedAmount} 
                                onChange={handleTenderedAmountChange} 
                            />
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>

                        <div className="input-group">
                            <label>Change:</label>
                            <input 
                                type="text" 
                                value={
                                    !tenderedAmount
                                        ? 'Please enter an amount.'
                                        : tenderedAmount < totalDue
                                        ? 'Invalid amount.'
                                        : `₱${change.toFixed(2)}`
                                }
                                readOnly
                                className={`change-display ${
                                    !tenderedAmount || tenderedAmount < totalDue ? 'error' : ''
                                }`}
                            />
                        </div>



                        <button 
                            onClick={handleConfirm} 
                            className={`payment-confirm-button ${
                                parseFloat(tenderedAmount) >= totalDue && !errorMessage ? 'active' : ''
                            }`}
                            disabled={parseFloat(tenderedAmount) < totalDue || !!errorMessage}
                        >
                            Confirm Payment
                        </button>
                    </div>

                    <div className="right-payment-section">
                        <h3>Quick Add</h3>
                        <div className="quick-add-buttons">
                            <button onClick={() => handleQuickAdd(50)}>
                                <FaMoneyBillAlt className="button-icon" /> +50
                            </button>
                            <button onClick={() => handleQuickAdd(100)}>
                                <FaMoneyBillAlt className="button-icon" /> +100
                            </button>
                            <button onClick={() => handleQuickAdd(200)}>
                                <FaMoneyBillAlt className="button-icon" /> +200
                            </button>
                            <button onClick={() => handleQuickAdd(500)}>
                                <FaMoneyBillAlt className="button-icon" /> +500
                            </button>
                            <button 
                                onClick={handleExactAmount}
                                className={`exact-button ${exactSelected ? 'selected' : ''}`}
                            >
                                Exact
                            </button>
                        </div>
                    </div>
                </div>
                {/* MessageModal for Excessive Amount Confirmation */}
                <MessageModal
                    isOpen={showMessageModal}
                    onClose={cancelExcessiveAmount}
                    message="The amount entered is unusually high. Are you sure you want to proceed?"
                    isConfirmation={true}
                    onConfirm={confirmExcessiveAmount}
                />
            </div>
        </div>
    );
}

export default PaymentModal;
