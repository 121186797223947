import React, { useState } from 'react';
import './AuthorizeModal.css'; // Reuse the same styling as AuthorizeModal
import MessageModal from './MessageModal';

function PurchasePaymentType({ isOpen, onClose, onComplete }) {
    const [paymentDetails, setPaymentDetails] = useState({
        paymentType: '',
        externalDocument: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);

    const clearFields = () => {
        setPaymentDetails({ paymentType: '', externalDocument: '' });
        setErrorMessage('');
        setMessage('');
        setIsMessageOpen(false);
    };

    const handleClose = () => {
        clearFields(); // Clear fields before closing
        onClose(); // Execute the passed onClose function
    };

    const handleComplete = () => {
        if (!paymentDetails.paymentType) {
            setMessage('Please select a payment type.');
            setIsMessageOpen(true);
            return;
        }

        if ((paymentDetails.paymentType === 'Bank Transfer' || paymentDetails.paymentType === 'Cheque' || paymentDetails.paymentType === 'E-Wallet') && !paymentDetails.externalDocument) {
            setMessage('External Document is required for the selected payment type.');
            setIsMessageOpen(true);
            return;
        }

        // Pass the payment details to the parent component
        onComplete(paymentDetails);
        handleClose(); // Close the modal after completion
    };

    if (!isOpen) return null;

    return (
        <div className="auth-modal-overlay">
            <div className="auth-modal-content">
                <h3>Payment Type</h3>
                <p>Select the payment type and provide any required details.</p>

                <div className="form-group">
                    <label htmlFor="paymentType">Payment Type</label>
                    <select
                        id="paymentType"
                        value={paymentDetails.paymentType}
                        onChange={(e) => setPaymentDetails({ ...paymentDetails, paymentType: e.target.value })}
                    >
                        <option value="">Select Payment Type</option>
                        <option value="Cash">Cash</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Cheque">Cheque</option>
                        <option value="E-Wallet">E-Wallet</option>
                    </select>
                </div>

                {(paymentDetails.paymentType === 'Bank Transfer' ||
                    paymentDetails.paymentType === 'Cheque' ||
                    paymentDetails.paymentType === 'E-Wallet') && (
                    <div className="form-group">
                        <label htmlFor="externalDocument">External Document</label>
                        <input
                            type="text"
                            id="externalDocument"
                            placeholder="Enter reference or external document"
                            value={paymentDetails.externalDocument}
                            onChange={(e) =>
                                setPaymentDetails({ ...paymentDetails, externalDocument: e.target.value })
                            }
                        />
                    </div>
                )}

                <div className="auth-modal-actions">
                    <button className="authorize-button" onClick={handleComplete}>
                        Complete Payment
                    </button>
                    <button className="cancel-auth-button" onClick={handleClose}>
                        Cancel
                    </button>
                </div>
            </div>
            {/* MessageModal */}
            <MessageModal
                isOpen={isMessageOpen}
                onClose={() => setIsMessageOpen(false)}
                message={message}
                isConfirmation={isConfirmation}
                onConfirm={() => {}}
            />
        </div>
    );
}

export default PurchasePaymentType;
