import React, { useRef, useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash, FaCalendar } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import Datepicker styles
import moment from 'moment';
import 'moment-timezone'; // Ensure you import moment-timezone
import MessageModal from './MessageModal'; // Import the modal
import PayrollModal from './PayrollModal'; // Import the PayrollModal
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function EmployeePayroll() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(null); // For start date filter
    const [endDate, setEndDate] = useState(null); // For end date filter
    const [attendanceLogs, setAttendanceLogs] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [salaryTransactions, setSalaryTransactions] = useState([]);
    const [salaries, setSalaries] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState(''); // New state for modal message
    const [refreshTable, setRefreshTable] = useState(false);
    const [currentEmpID, setCurrentEmpID] = useState(null);
    const [isPayrollModalOpen, setIsPayrollModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [salaryToDelete, setsalaryToDelete] = useState(null);
    const [activeTab, setActiveTab] = useState('paybook'); // Default tab
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page
    
    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        const fetchData = async () => {
            try {
                const [employeesResponse, attendanceLogsResponse, salaryTransactionsResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/employees'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/attendance_logs'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/salaries') // Fetch salary transactions
                ]);
                setEmployees(employeesResponse.data);
                setAttendanceLogs(attendanceLogsResponse.data);
                setSalaryTransactions(salaryTransactionsResponse.data); // Set salary transactions
            } catch (error) {
                console.error('Error fetching data!', error.response || error.message || error);
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
        // Fetch attendance logs from MySQL database
        axios.get('https://www.capstonelimenerp.xyz:8082/api/salaries')
            .then(response => {
                console.log('Salary data refreshed successfully:', response.data);
                setSalaryTransactions(response.data);
                setRefreshTable(false);  // Reset the refreshTable state
            })
            .catch(error => {
                console.error('There was an error fetching the salary_transactions!', error.response || error.message || error);
            });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentEmpID(e.target.value); // Store empID in state
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalMessage(''); // Clear the message
    };

    const openPayrollModal = () => {
        setIsPayrollModalOpen(true);
    };

    const handlePayrollSubmit = (data) => {
        console.log('Add Salary Form Data:', data);
        setIsModalOpen(false);
        
        // Set message based on whether it's an edit or add
        const successMessage = 'Salary added successfully!';
        setMessage(successMessage);
        
        setShowCloseButton(true); // Ensure the close button is shown
        setIsMessageModalOpen(true);
        setIsConfirmation(false); // Set to false for success messages
        setRefreshTable(true);
    };

    const confirmDeleteSalary= (salaryID) => {
        console.log("Delete button clicked for salary ID:", salaryID);
        setsalaryToDelete(salaryID);
        setMessage('Are you sure you want to delete this Salary?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };

    const handleDeleteSalary = () => {
        if (salaryToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/salaries/${salaryToDelete}`)
                .then(() => {
                    setSalaries(salaries.filter(salary => salary.salaryID !== salaryToDelete));
                    setMessage('Employee has been successfully deleted.');
                    setDeletionConfirmed(true); // This should trigger the close button

                    setRefreshTable(true);
                })
                .catch(error => {
                    console.error('There was an error deleting the salary!', error);
                    setMessage('Error deleting salary');
                    setDeletionConfirmed(true);
                });
        }
    };
   
    // Date format function using moment.js
    const formatDateToMMDDYYYY = (date) => {
        return moment(date).format('MM/DD/YYYY');
    };

    const handleStartDateChange = (date) => {
        setStartDate(date); // Set the date directly from the Datepicker
    };

    const handleEndDateChange = (date) => {
        setEndDate(date); // Set the date directly from the Datepicker
    };

    const formatEmployeeName = (employee) => {
        const middleInitial = employee.empMName ? `${employee.empMName.charAt(0)}.` : '';
        return `${employee.empLName}, ${employee.empFName} ${middleInitial}`;
    };

    // Map user_id to empName
    const employeeMap = new Map(employees.map(employee => [employee.empID, formatEmployeeName(employee)]));

    const filteredSalaryTransactions = salaryTransactions.filter(transaction => {
        const employeeName = employeeMap.get(transaction.emp_id_fk) || '';
        const matchesSearchQuery = employeeName.toLowerCase().includes(searchQuery.toLowerCase()); // Search is case-insensitive
        const transactionDate = new Date(transaction.paymentDate);
        const isWithinDateRange = (!startDate || transactionDate >= new Date(startDate)) &&
                                  (!endDate || transactionDate <= new Date(endDate));
        return matchesSearchQuery && isWithinDateRange;
    });

    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedTransactions = filteredSalaryTransactions.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredSalaryTransactions.length / recordsPerPage);


    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <PayrollModal
                        isOpen={isPayrollModalOpen}
                        onClose={() => setIsPayrollModalOpen(false)}
                        onSubmit={handlePayrollSubmit}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteSalary(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <Link to="/user/information" className={activeTab === 'users' ? 'active' : ''} onClick={() => setActiveTab('users')}>
                            Users
                        </Link>
                        <Link to="/employee/information" className={activeTab === 'employees' ? 'active' : ''} onClick={() => setActiveTab('employees')}>
                            Employees
                        </Link>
                        <Link to="/employee/attendance" className={activeTab === 'attendances' ? 'active' : ''} onClick={() => setActiveTab('attendances')}>
                            Attendances
                        </Link>
                        <Link to="/employee/pay" className={activeTab === 'paybook' ? 'active' : ''} onClick={() => setActiveTab('paybook')}>
                            Paybook
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search employee name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <div className='date-range-picker'>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="MM/dd/yyyy" // Display format
                                    placeholderText="Start Date"
                                    className="date-picker"
                                />
                                <h6 style={{marginTop: '5px'}}>thru</h6>
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="MM/dd/yyyy" // Display format
                                    placeholderText="End Date"
                                    className="date-picker"
                                />
                                <FaCalendar style={{width: '30px', height: '40px', marginRight: '20px'}}/>
                            </div>
                            <button className="action-button" onClick={openPayrollModal}>Generate Pay</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Salary ID</th>
                                    <th>Employee ID</th>
                                    <th>Payment Date</th>
                                    <th>Pay Period Start</th>
                                    <th>Pay Period End</th>
                                    <th>Total Hours</th>
                                    <th>Total Salary</th>
                                    <th style={{width: '120px'}}>Handled By</th>
                                    <th style={{width: '100px'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedTransactions.length > 0 ? (
                                    displayedTransactions.map((transaction, index) => (
                                        <tr key={transaction.salaryID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{transaction.salaryID || 'N/A'}</td>
                                            <td>{employeeMap.get(transaction.emp_id_fk) || 'N/A'}</td>
                                            <td>{transaction.paymentDate ? new Date(transaction.paymentDate).toLocaleDateString() : 'N/A'}</td>
                                            <td>{transaction.payPeriodStart ? new Date(transaction.payPeriodStart).toLocaleDateString() : 'N/A'}</td>
                                            <td>{transaction.payPeriodEnd ? new Date(transaction.payPeriodEnd).toLocaleDateString() : 'N/A'}</td>
                                            <td>{transaction.totalHours || 'N/A'}</td>
                                            <td>{transaction.totalSalary !== null ? transaction.totalSalary.toFixed(2) : 'N/A'}</td>
                                            <td style={{width: '120px'}}>{transaction.handledBy || 'N/A'}</td>
                                            <td style={{width: '100px'}}>
                                                <button className="delete-button" onClick={() => confirmDeleteSalary(transaction.salaryID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9" style={{ textAlign: 'center' }}>No records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>        
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default EmployeePayroll;
