import React, { useState, useEffect, useRef } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import ProductModal from './ProductModal';
import Pagination from './Pagination'; // Adjust the path as needed
import MessageModal from './MessageModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function InventoryProduct() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('Active');
    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [productToDelete, setproductToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('products'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const tableRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch products data
        const fetchData = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/products');
                setProducts(response.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch products again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/products')
                .then(response => {
                    setProducts(response.data);
                    setRefreshTable(false);
                })
                .catch(error => {
                    console.error('There was an error refreshing the product data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    // Function to handle column resize
    const enableResize = () => {
        const table = tableRef.current;
        const cols = table.querySelectorAll('th');
        let startX, startWidth;

        cols.forEach((col, i) => {
            const grip = document.createElement('div');
            grip.className = 'resize-handle';
            col.appendChild(grip);

            grip.addEventListener('mousedown', function (e) {
                startX = e.pageX;
                startWidth = col.offsetWidth; // Store initial width of the header
                document.documentElement.addEventListener('mousemove', mouseMoveHandler);
                document.documentElement.addEventListener('mouseup', mouseUpHandler);
            });

            function mouseMoveHandler(e) {
                const width = startWidth + e.pageX - startX;
                col.style.width = `${width}px`; // Adjust only the header width
                table.querySelectorAll(`td:nth-child(${i + 1})`).forEach((td) => {
                    td.style.width = `${width}px`; // Make tbody column widths follow the header
                });
            }

            function mouseUpHandler() {
                document.documentElement.removeEventListener('mousemove', mouseMoveHandler);
                document.documentElement.removeEventListener('mouseup', mouseUpHandler);
            }
        });
    };

    useEffect(() => {
        enableResize(); // Enable column resize when the component is mounted
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddProduct = () => {
        setSelectedProduct(null); // Clear any selected product
        setIsModalOpen(true);
    };

    const handleEditProduct = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true); // Open update modal
    };

    const confirmDeleteProduct= (prodNo) => {
        console.log("Delete button clicked for emp ID:", prodNo);
        setproductToDelete(prodNo);
        setMessage('Are you sure you want to delete this product?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };

    const handleDeleteProduct = () => {
        if (productToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/products/${productToDelete}`)
                .then(() => {
                    setProducts(products.filter(product => product.prodNo !== productToDelete));
                    setMessage('Product has been successfully deleted.');
                    setDeletionConfirmed(true); // This should trigger the close button

                    // Trigger a refresh
                    setRefreshTable(true);
                })
                .catch(error => {
                    console.error('There was an error deleting the product!', error);
                    setMessage('Error deleting product');
                    setDeletionConfirmed(true);
                });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        console.log('Add Product Form Data:', data);
        setIsModalOpen(false);
        
        // Set message based on whether it's an edit or add
        const successMessage = isEditing ? 'Product updated successfully!' : 'Product added successfully!';
        setMessage(successMessage);
        
        setShowCloseButton(true); // Ensure the close button is shown
        setIsMessageModalOpen(true);
        setIsConfirmation(false); // Set to false for success messages
        setRefreshTable(true);
    };

    const filteredProducts = products.filter(product => {
        const searchQueryLower = searchQuery.toLowerCase();
    
        // Check if any field matches the search query
        const matchesSearchQuery =
            product.prodName.toLowerCase().includes(searchQueryLower) ||
            product.prodSKU?.toLowerCase().includes(searchQueryLower) ||
            product.prodBarcode?.toLowerCase().includes(searchQueryLower);
    
        // Check if the product matches the status filter
        const matchesFilter = filter === 'all' || product.prodStatus === filter;
    
        return matchesSearchQuery && matchesFilter;
    });
    

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedProducts = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <ProductModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        product={selectedProduct}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteProduct(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/inventory/products" className={activeTab === 'products' ? 'active' : ''} onClick={() => setActiveTab('products')}>
                            Product List
                        </Link>
                        <Link to="/inventory/stocks" className={activeTab === 'stocks' ? 'active' : ''} onClick={() => setActiveTab('stocks')}>
                            Stocks
                        </Link>
                        <Link to="/inventory/request" className={activeTab === 'request' ? 'active' : ''} onClick={() => setActiveTab('request')}>
                            Stock Request
                        </Link>
                        <Link to="/inventory/transfer" className={activeTab === 'transfer' ? 'active' : ''} onClick={() => setActiveTab('transfer')}>
                            Stock Transfer
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search by product name, SKU, or barcode" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            <button className="action-button" onClick={handleAddProduct}>Add Product</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th style={{width: '50px'}}>#</th>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                        <th>Brand</th>
                                        <th>SKU</th>
                                        <th>Barcode</th>
                                        <th>Price</th>
                                        <th>UOM</th>
                                        <th>Status</th>
                                        <th style={{width: '100px'}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedProducts.length > 0 ? (
                                        displayedProducts.map((product, index) => (
                                            <tr key={product.prodNo}>
                                                <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                                <td>{product.prodName}</td>
                                                <td>{product.prodCategory || 'No Category'}</td>
                                                <td>{product.prodBrand || 'No Brand'}</td>
                                                <td>{product.prodSKU}</td>
                                                <td>{product.prodBarcode}</td>
                                                <td>{product.prodPrice.toFixed(2)}</td>
                                                <td>{product.prodUOM}</td>
                                                <td>{product.prodStatus}</td>
                                                <td style={{width: '100px'}}>
                                                    <button className="edit-button" onClick={() => handleEditProduct(product)}>
                                                        <FaEdit />
                                                    </button>
                                                    <button className="delete-button" onClick={() => confirmDeleteProduct(product.prodNo)}>
                                                        <FaTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10" style={{ textAlign: 'center' }}>No products found</td>
                                        </tr>
                                    )}
                                </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>      

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default InventoryProduct;
