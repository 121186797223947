import React, { useRef, useState, useEffect } from 'react';
import './GlobalStyles.css';
import './EmployeeAttendance.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaCalendar } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import Datepicker styles
import moment from 'moment';
import 'moment-timezone'; // Ensure you import moment-timezone
import MessageModal from './MessageModal'; // Import the modal
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function EmployeeAttendance() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(null); // For start date filter
    const [endDate, setEndDate] = useState(null); // For end date filter
    const [attendanceLogs, setAttendanceLogs] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState(''); // New state for modal message
    const [refreshTable, setRefreshTable] = useState(false);
    const [currentEmpID, setCurrentEmpID] = useState(null);
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [activeTab, setActiveTab] = useState('attendances'); // Default tab
    const [employeeSearchResults, setEmployeeSearchResults] = useState([]); // Dropdown results
    const searchContainerRef = useRef(null); // Ref for the search container

    const [isClockingIn, setIsClockingIn] = useState(false); // Track clock-in state
    const [isClockingOut, setIsClockingOut] = useState(false); // Track clock-out state
    const [isConfirmation, setIsConfirmation] = useState(false); // Track confirmation state
    
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch employees, and branches data
        const fetchData = async () => {
            try {
                const [employeesResponse, attendanceLogsResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/employees'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/attendance_logs')
                ]);
                setEmployees(employeesResponse.data);
                setAttendanceLogs(attendanceLogsResponse.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setEmployeeSearchResults([]); // Close the dropdown
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    useEffect(() => {
        // Fetch attendance logs from MySQL database
        axios.get('https://www.capstonelimenerp.xyz:8082/api/attendance_logs')
            .then(response => {
                setAttendanceLogs(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the attendance logs!', error.response || error.message || error);
            });
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
    
        if (query.trim()) {
            try {
                const response = await axios.get(
                    `https://www.capstonelimenerp.xyz:8082/api/employees/search?q=${query}`
                );
                setEmployeeSearchResults(response.data); // Update dropdown results
            } catch (error) {
                console.error("Error searching for employees:", error);
                setEmployeeSearchResults([]); // Clear results on error
            }
        } else {
            setEmployeeSearchResults([]); // Clear results if input is empty
        }
    };
    
    const handleSelectEmployee = (employee) => {
        const middleInitial = employee.empMName ? `${employee.empMName.charAt(0)}.` : ''; // Extract middle initial if exists
        setSearchQuery(`${employee.empFName} ${middleInitial} ${employee.empLName}`); // Display the full name in the search bar
        setCurrentEmpID(employee.empID); // Set empID for backend operations
        setEmployeeSearchResults([]); // Clear the dropdown
    };
    
    

    const handleTimeInOut = async () => {
        if (!currentEmpID) {
            setModalMessage("Please select a valid employee.");
            setIsModalOpen(true);
            return;
        }
    
        const empID = currentEmpID.trim();
        const attendanceDate = moment().tz("Asia/Manila").format('YYYY-MM-DD');
    
        try {
            const response = await axios.get(
                `https://www.capstonelimenerp.xyz:8082/api/attendance_logs/${empID}/${attendanceDate}`
            );
            const existingLog = response.data;
    
            if (!existingLog || existingLog.length === 0) {
                // Confirm clock-in if no log exists for today
                setModalMessage("Are you sure you want to clock in?");
                setIsClockingIn(true);
                setIsClockingOut(false);
                setIsConfirmation(true);
                setIsModalOpen(true); // Open confirmation modal
            } else {
                const log = existingLog[0];
    
                if (!log.timeOut) {
                    // Confirm clock-out if timeOut is not set
                    setModalMessage("Are you sure you want to clock out?");
                    setIsClockingOut(true);
                    setIsClockingIn(false);
                    setIsConfirmation(true);
                    setIsModalOpen(true); // Open confirmation modal
                } else {
                    setModalMessage("You have already clocked out for today.");
                    setIsModalOpen(true);
                }
            }
        } catch (error) {
            setModalMessage("An error occurred. Please try again.");
            setIsModalOpen(true);
            console.error("Error recording attendance:", error);
        }
    };
    
    const handleConfirmTimeInOut = async () => {
        const empID = currentEmpID.trim();
        const currentTimestamp = moment.tz("Asia/Manila").format('YYYY-MM-DD HH:mm:ss');
        const attendanceDate = moment().tz("Asia/Manila").format('YYYY-MM-DD');
    
        try {
            if (isClockingIn) {
                // Clock in
                const attendanceData = {
                    emp_id_fk: empID,
                    timeIn: currentTimestamp,
                    timeOut: null,
                    attendanceDate: attendanceDate,
                };
                await axios.post('https://www.capstonelimenerp.xyz:8082/api/attendance_logs', attendanceData);
                setModalMessage("You have clocked in successfully.");
            } else if (isClockingOut) {
                // Clock out
                const response = await axios.get(
                    `https://www.capstonelimenerp.xyz:8082/api/attendance_logs/${empID}/${attendanceDate}`
                );
                const log = response.data[0];
    
                const attendanceData = {
                    emp_id_fk: empID,
                    timeIn: log.timeIn,
                    timeOut: currentTimestamp,
                    attendanceDate: attendanceDate,
                };
                await axios.put(`https://www.capstonelimenerp.xyz:8082/api/attendance_logs/${log.attendanceID}`, attendanceData);
                setModalMessage("You have clocked out successfully.");
            }
    
            // Refresh the table and reset states
            setRefreshTable((prev) => !prev);
            setSearchQuery('');
            setCurrentEmpID(null);
            setIsClockingIn(false);
            setIsClockingOut(false);
            setIsConfirmation(false);
    
            setTimeout(() => {
                setIsModalOpen(false); // Close the modal after a short delay
            }, 2000);
        } catch (error) {
            setModalMessage("An error occurred. Please try again.");
            setIsModalOpen(true);
            console.error("Error confirming time-in/out:", error);
        }
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        setIsClockingIn(false);
        setIsClockingOut(false);
        setIsConfirmation(false);
        setModalMessage(''); // Clear the message
    };
    

    // Date format function using moment.js
    const formatDateToMMDDYYYY = (date) => {
        return moment(date).format('MM/DD/YYYY');
    };

    const handleStartDateChange = (date) => {
        setStartDate(date); // Set the date directly from the Datepicker
    };

    const handleEndDateChange = (date) => {
        setEndDate(date); // Set the date directly from the Datepicker
    };



    const formatEmployeeName = (employee) => {
        const middleInitial = employee.empMName ? `${employee.empMName.charAt(0)}.` : '';
        return `${employee.empFName} ${middleInitial} ${employee.empLName}`;
    };

    // Map user_id to empName
    const employeeMap = new Map(employees.map(employee => [employee.empID, formatEmployeeName(employee)]));

    // Filter attendance logs based on search and date range
    const filteredAttendanceLogs = attendanceLogs.filter(log => {
        // Get the employee name from the employeeMap
        const employeeName = employeeMap.get(log.emp_id_fk) || '';

        // Check if employee name matches the search query
        const matchesSearchQuery = employeeName.toLowerCase().includes(searchQuery.toLowerCase());
        
        const logDate = new Date(log.attendanceDate);
        const isWithinDateRange = (!startDate || logDate >= new Date(startDate)) &&
                                (!endDate || logDate <= new Date(endDate));

        return matchesSearchQuery && isWithinDateRange;
    });

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedAttendances = filteredAttendanceLogs.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredAttendanceLogs.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <MessageModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        message={modalMessage}
                        isConfirmation={isConfirmation} // Show confirmation buttons if true
                        onConfirm={handleConfirmTimeInOut} // Handle confirmed clock-in/out action
                    />
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <Link to="/user/information" className={activeTab === 'users' ? 'active' : ''} onClick={() => setActiveTab('users')}>
                            Users
                        </Link>
                        <Link to="/employee/information" className={activeTab === 'employees' ? 'active' : ''} onClick={() => setActiveTab('employees')}>
                            Employees
                        </Link>
                        <Link to="/employee/attendance" className={activeTab === 'attendances' ? 'active' : ''} onClick={() => setActiveTab('attendances')}>
                            Attendances
                        </Link>
                        <Link to="/employee/pay" className={activeTab === 'paybook' ? 'active' : ''} onClick={() => setActiveTab('paybook')}>
                            Paybook
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <div className="employee-search-container" ref={searchContainerRef}>
                                <input
                                    type="text"
                                    placeholder="Enter Employee ID or Name"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    onFocus={() => searchQuery && setEmployeeSearchResults(employeeSearchResults)}
                                    style={{width: '100%', height: '100%'}}
                                />
                                {employeeSearchResults.length > 0 && (
                                    <div className="employee-search-dropdown">
                                        {employeeSearchResults.map((employee) => (
                                            <div
                                                key={employee.empID}
                                                onClick={() => handleSelectEmployee(employee)}
                                                className="employee-search-item"
                                            >
                                                <div className="employee-details">
                                                    <span>{`${employee.empFName} ${employee.empLName}`}</span>
                                                    <span className="emp-id">{employee.empID}</span>
                                                </div>
                                                <div className="employee-position">
                                                    <span>{employee.empPosition}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>


                            {/* Date Picker for Start Date */}
                            <div className='date-range-picker'>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleStartDateChange}
                                    dateFormat="MM/dd/yyyy" // Display format
                                    placeholderText="Start Date"
                                    className="date-picker"
                                />
                                <h6 style={{marginTop: '5px'}}>thru</h6>
                                {/* Date Picker for End Date */}
                                <DatePicker
                                    selected={endDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="MM/dd/yyyy" // Display format
                                    placeholderText="End Date"
                                    className="date-picker"
                                />
                                <FaCalendar style={{width: '30px', height: '40px', marginRight: '20px'}}/>
                            </div>
                            <div style={{height: '100%'}}>
                                <button className="action-button" onClick={handleTimeInOut}>Time In/Out</button>
                            </div>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>ID</th>
                                    <th>Employee Name</th>
                                    <th>Date</th>
                                    <th>Time In</th>
                                    <th>Time Out</th>
                                    <th>Total Hours</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedAttendances.length > 0 ? (
                                    displayedAttendances.map((log, index) => {
                                        const formattedDate = formatDateToMMDDYYYY(log.attendanceDate); // Format date as MM/DD/YYYY
                                        
                                        // Check if timeIn or timeOut is NULL before creating a Date object
                                        const timeInDate = log.timeIn ? new Date(log.timeIn) : null;
                                        const timeOutDate = log.timeOut ? new Date(log.timeOut) : null;

                                        // Format Time In and Time Out to AM/PM using toLocaleTimeString, handle NULL case
                                        const formattedTimeIn = timeInDate
                                            ? timeInDate.toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true, // For AM/PM format
                                            })
                                            : 'N/A'; // If timeIn is null, show N/A

                                        const formattedTimeOut = timeOutDate
                                            ? timeOutDate.toLocaleTimeString([], {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true, // For AM/PM format
                                            })
                                            : 'N/A'; // If timeOut is null, show N/A

                                        return (
                                            <tr key={log.attendanceID}>
                                                <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                                <td>{log.attendanceID}</td>
                                                <td>{employeeMap.get(log.emp_id_fk)}</td>
                                                <td>{formattedDate}</td>
                                                <td>{formattedTimeIn}</td>
                                                <td>{formattedTimeOut}</td>
                                                <td>{log.noOfHours}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center' }}>No attendance logs found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default EmployeeAttendance;
