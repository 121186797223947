import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PurchaseOrderModal.css';
import MessageModal from './MessageModal';
import { FaTrash} from 'react-icons/fa';
function PurchaseOrderModal({ isOpen, onClose, onSubmit, lineItems, order, isViewMode, branchName, branchID }) {
    const [formData, setFormData] = useState({
        branchID: branchID || '',
        orderDate: '',
        orderStatus: 'Pending',
        lineItems: []
    });
    const [isModalOpen, setIsModalOpen] = useState(isOpen);  // Control the purchase order modal visibility

    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);  // Control the message modal visibility
    const [showCloseButton, setShowCloseButton] = useState(false);  // Control whether the close button is shown
    const [suggestedProducts, setSuggestedProducts] = useState([]);  
    const [orderSuggestions, setOrderSuggestions] = useState([]);  
    const [purchaseSuggestions, setPurchaseSuggestions] = useState([]);
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);  
    const [suggestionsVisible, setSuggestionsVisible] = useState({});

    const formatDateToMMDDYYYY = (date) => {
        const [year, month, day] = date.split('-');  // Split the date assuming it's in YYYY-MM-DD format
        return `${month}-${day}-${year}`;  // Return the formatted date as MM-DD-YYYY
    };

    useEffect(() => {
        const fetchLineItems = async () => {
            if (order && isViewMode) {  
                try {
                    const response = await axios.get(
                        `https://www.capstonelimenerp.xyz:8082/api/purchaseorder/${order.purReqID}/lineitems`
                    );
                    console.log('Fetched Line Items:', response.data);
    
                    setFormData((prevState) => ({
                        ...prevState,
                        lineItems: response.data,
                    }));
                } catch (error) {
                    console.error('Error fetching line items:', error);
                }
            }
        };
    
        fetchLineItems();
    }, [order, isViewMode]);
    
    

    
    useEffect(() => {
        const fetchInitialData = () => {
            const storedBranchID = localStorage.getItem('branchID');
    
            if (order && isViewMode) {
                setFormData({
                    branchID: order.purRequester || storedBranchID,
                    orderDate: formatDateToMMDDYYYY(new Date().toISOString().split('T')[0]), // Ensure MM-DD-YYYY format
                    orderStatus: order.requestStatus || 'Pending',
                    lineItems: lineItems
                });
            } else {
                setFormData({
                    branchID: storedBranchID,
                    orderDate: formatDateToMMDDYYYY(new Date().toISOString().split('T')[0]), // Use formatted today's date
                    orderStatus: 'Pending',
                    lineItems: []
                });
            }
        };
    
        fetchInitialData();
    }, [order, lineItems, isViewMode]);
    
    useEffect(() => {
        const fetchPurchaseSuggestions = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/purchase-suggestions');
                console.log('Fetched Purchase Suggestions:', response.data); // Verify the structure of response data
                setPurchaseSuggestions(response.data);
            } catch (error) {
                console.error('Error fetching purchase suggestions:', error);
            }
        };
    
        fetchPurchaseSuggestions();
    }, []);
    
    
    useEffect(() => {
        if (isOpen && !isViewMode) {
            axios.get('https://www.capstonelimenerp.xyz:8082/api/inventory/suggestions')
                .then(response => {
                    setOrderSuggestions(response.data);
                })
                .catch(error => console.error('Error fetching order suggestions:', error));
        }
    }, [isOpen, isViewMode]);

    // Fetch product suggestions based on search input
    const fetchProductSuggestions = (query, index) => {
        if (query.length > 2) { // Fetch only if query is longer than 2 characters
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/search?q=${query}`)
                .then(response => {
                    setSuggestedProducts(response.data); 
                    setActiveIndex(index); // Show suggestions for the active line item
                    console.log('Fetched Suggestions:', response.data); // Debugging
                })
                .catch(error => console.error('Error fetching product suggestions:', error));
        } else {
            setSuggestedProducts([]);
            setActiveIndex(null); // Hide suggestions if query is too short
        }
    };

    const handleProductSearch = (e, index) => {
        const { value } = e.target;
    
        console.log('Product Search Input:', value); // Debugging
    
        // Update the product name field directly
        handleLineItemChange(index, 'prodName', value);
    
        // Trigger suggestions only if input length exceeds 2 characters
        if (value.length > 2) {
            fetchProductSuggestions(value, index);
        } else {
            setSuggestedProducts([]);
            setActiveIndex(null); // Hide suggestions if input is too short
        }
    };
    
    const selectProduct = (product, index) => {
        console.log('Selected Product:', product); // Debugging
    
        const newLineItems = [...formData.lineItems];
        newLineItems[index] = {
            ...newLineItems[index],
            prodNo: product.prodNo,
            prodName: product.prodName,
        };
    
        setFormData(prevState => ({
            ...prevState,
            lineItems: newLineItems,
        }));
    
        setActiveIndex(null);
        setSuggestedProducts([]); // Clear suggestions after selection
    };
    
    const addLineItem = () => {
        const newLineItem = { prodNo: '', prodName: '', qtyRequested: 1 };
        setFormData(prevState => ({
            ...prevState,
            lineItems: [...prevState.lineItems, newLineItem]
        }));
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleLineItemChange = (index, field, value) => {
        const newLineItems = [...formData.lineItems];
        newLineItems[index] = { ...newLineItems[index], [field]: value };
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    };

    const addProductToLineItems = (product) => {
        console.log('Adding product:', product); // Debug log
    
        // Prevent duplicate entries
        if (formData.lineItems.some(item => item.prodNo === product.prod_No_fk)) {
            console.warn('Product already exists in line items');
            return;
        }
    
        const newLineItems = [
            ...formData.lineItems,
            {
                prodNo: product.prod_No_fk,  // Use prod_No_fk as prodNo
                prodName: product.prodName,
                qtyRequested: product.suggestedOrderQty || 1,  // Default to suggested qty or 1
                totalStockOnHand: product.totalStockOnHand,
                suggestedOrderQty: product.suggestedOrderQty  // Include suggested qty for reference
            }
        ];
    
        setFormData(prevState => ({
            ...prevState,
            lineItems: newLineItems
        }));
    
        // Remove the product from the suggestions list
        setPurchaseSuggestions(prevSuggestions =>
            prevSuggestions.filter(suggestion => suggestion.prod_No_fk !== product.prod_No_fk)
        );
    };
    
    
    const removeLineItem = (index) => {
        const removedItem = formData.lineItems[index];
    
        console.log('Removing product from line items:', removedItem); // Debugging log
    
        const newLineItems = formData.lineItems.filter((_, i) => i !== index);
    
        setFormData(prevState => ({
            ...prevState,
            lineItems: newLineItems
        }));
    
        // Restore the product back to the suggestions list with correct field mapping
        setPurchaseSuggestions(prevSuggestions => {
            if (prevSuggestions.some(suggestion => suggestion.prod_No_fk === removedItem.prodNo)) {
                return prevSuggestions; // Prevent duplicates
            }
    
            return [
                ...prevSuggestions,
                {
                    prod_No_fk: removedItem.prodNo,  // Correct mapping back to prod_No_fk
                    prodName: removedItem.prodName,
                    totalStockOnHand: removedItem.totalStockOnHand,
                    suggestedOrderQty: removedItem.suggestedOrderQty
                }
            ];
        });
    };
    
    const convertDateToYYYYMMDD = (date) => {
        if (!date) return '';
        const [month, day, year] = date.split('-');
        return `${year}-${month}-${day}`;
    };

    const openMessageModal = (msg) => {
        console.log('Opening message modal...');
        setMessage(msg);
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
    };

    const closeMessageModal = () => {
        console.log('Closing message modal...'); // Debugging
        setIsMessageModalOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
    
        console.log('Handling form submission...'); // Debugging log
    
        // Validation: Check for empty fields
        const hasEmptyFields = formData.lineItems.some(
            (item) => !item.prodName || item.qtyRequested <= 0
        );
    
        console.log('Has empty fields:', hasEmptyFields); // Debugging
    
        if (hasEmptyFields) {
            openMessageModal('All line items must have a product name and a valid quantity.');
            console.log('Validation failed. Message modal opened.'); // Debugging
            return; // Stop execution if validation fails
        }
    
        const lineItems = formData.lineItems.map(item => ({
            qtyRequested: item.qtyRequested,
            prodNo: item.prodNo || null,
            prodName: item.prodName,
        }));
    
        const orderData = {
            branchID: formData.branchID,
            reqDate: convertDateToYYYYMMDD(formData.orderDate),
            requestStatus: formData.orderStatus,
            lineItems: lineItems,
        };
    
        console.log('Submitting order:', orderData); // Debugging log
    
        try {
            const response = await axios.post(
                'https://www.capstonelimenerp.xyz:8082/api/purchaseorder',
                orderData
            );
    
            onSubmit(orderData); // Notify parent
            setIsModalOpen(false); // Close the order modal
        } catch (error) {
            console.error('Error submitting order:', error);
    
            const errorMsg = error.response?.data?.message || 'Error: Unable to submit the purchase order.';
            openMessageModal(errorMsg);
        }
    };
    
    
    
    if (!isOpen) return null;

    return (
        <div className="order-modal-overlay">
            <div className="order-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section">
                            <h2>{isViewMode ? 'View Purchase Order' : 'Create Purchase Order'}</h2>
                            <div className="top-left-section" style={{height: '30%'}}>
                                <div className="depth">
                                    {/* Order Date Form */}
                                    <div className="form-group date">
                                        <label htmlFor="orderDate"><strong>Order Date</strong></label>
                                        <input
                                            type="text"
                                            name="orderDate"
                                            value={formData.orderDate}  // Display formatted date
                                            disabled
                                        />
                                    </div>
                                    {/* Order Status Form */}
                                    <div className="form-group status">
                                        <label htmlFor="orderStatus"><strong>Status</strong></label>
                                        <select
                                            name="orderStatus"
                                            value={formData.orderStatus}
                                            onChange={handleChange}
                                            disabled
                                        >
                                            <option value="Pending">Pending</option>
                                            <option value="Partially Fulfilled">Partially Fulfilled</option>
                                            <option value="Fulfilled">Fulfilled</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="depth">
                                    {/* Requester Form */}
                                    <div className="form-group requester">
                                        <label><strong>Requester (Branch)</strong></label>
                                        <input
                                            type="text"
                                            value={localStorage.getItem('branchName') || 'N/A'}  // Display branch name from localStorage
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="bottom-left-section" style={{height: '60%'}}>
                                <div className="suggestion-section">
                                    <h3>Suggested Orders</h3>
                                    <div className="suggestion-list-section">
                                    {!isViewMode && (
                                        <ul className="suggestion-list" style={{minWidth: '100%'}}>
                                            {purchaseSuggestions.map((suggestion, index) => (
                                                <li key={`${suggestion.prodNo}-${index}`}>
                                                    {suggestion.prodName} 
                                                    <br></br>
                                                    (Stock: {suggestion.totalStockOnHand}, Suggested Qty: {suggestion.suggestedOrderQty})
                                                    <button
                                                        className="add-button"
                                                        onClick={() => addProductToLineItems(suggestion)}
                                                    >
                                                        Add
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="right-section">
                        <div className="order-line-items-section">
                            <div style={{ display: 'flex', alignItems:'end', justifyContent: 'space-between', gap: '10px'}}>
                                <h3 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>Line Items</h3>
                                    {/* Add Product Button */}
                                    {!isViewMode && (
                                        <div className="add-product-container">
                                            <button 
                                                type="button" 
                                                className="add-line-item" 
                                                onClick={addLineItem}
                                            >
                                                Add Product
                                            </button>
                                        </div>
                                    )}
                            </div>
                            <table className="order-line-items-table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Qty</th>
                                        {!isViewMode && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.lineItems.length > 0 ? (
                                        formData.lineItems.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="input-suggestion-container">
                                                        <input
                                                            style={{ textAlign: 'left', width: '100%' }}  // Ensure full width
                                                            type="text"
                                                            name="prodName"
                                                            value={item.prodName}
                                                            onChange={(e) => handleProductSearch(e, index)}
                                                            onBlur={() => {
                                                                setTimeout(() => {
                                                                    setActiveIndex(null);
                                                                    setSuggestedProducts([]);
                                                                }, 200); // Allow time for click events
                                                            }}
                                                            required
                                                            disabled={isViewMode}
                                                        />
                                                        {activeIndex === index && suggestedProducts.length > 0 && (
                                                                <ul className="transfer-suggestion-list">
                                                                    {suggestedProducts.map((product, i) => (
                                                                        <li key={i} onClick={() => selectProduct(product, index)} className="suggestion-item">
                                                                            <span className="suggestion-product-name">{product.prodName}</span>
                                                                            <span className="suggestion-product-no">{product.prodNo}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={item.qtyRequested}
                                                        onChange={(e) =>
                                                            handleLineItemChange(index, 'qtyRequested', e.target.value)
                                                        }
                                                        min="1"
                                                        required
                                                        disabled={isViewMode}
                                                        style={{ width: '100%' }}  // Ensure consistency in width
                                                    />
                                                </td>
                                                {!isViewMode && (
                                                    <td>
                                                        <button
                                                            className="remove-line-item"
                                                            onClick={() => removeLineItem(index)}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={isViewMode ? 2 : 3} style={{ textAlign: 'center' }}>
                                                No line items found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {/* Button Container */}
                        {!isViewMode && (
                            <div className="button-container">
                                <button
                                    type="button"
                                    className="order-button"
                                    onClick={handleSubmit}
                                >
                                    Create Order
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <MessageModal
                    isOpen={isMessageModalOpen}
                    onClose={() => setIsMessageModalOpen(false)}
                    message={message}
                    showCloseButton={showCloseButton}
                />
            </div>
        </div>
    );
}

export default PurchaseOrderModal;
