import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditModal.css'; 
import { FaTimes } from 'react-icons/fa'; // Import close icon
import MessageModal from './MessageModal';

function EditModal({ isOpen, onClose, itemType, itemData, onSuccess }) {
    const [name, setName] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Capitalize the first letter of the item type
    const capitalizeItemType = (type) => type.charAt(0).toUpperCase() + type.slice(1);

    useEffect(() => {
        if (itemData) {
            setName(itemData.name);
        }
    }, [itemData]);

    const isEditing = !!itemData?.id; // Check if it’s an edit operation

    const handleUpdate = async () => {
        try {
            await axios.post(`https://www.capstonelimenerp.xyz:8082/api/${itemType}/update`, {
                id: itemData.id,
                name
            });
            onSuccess(); // Refresh the dropdowns
            onClose();
        } catch (error) {
            console.error(`Error updating ${itemType}:`, error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.post(`https://www.capstonelimenerp.xyz:8082/api/${itemType}/delete`, {
                id: itemData.id
            });
            onSuccess(); // Refresh the dropdowns
            onClose();
        } catch (error) {
            const errorMessage =
                error.response?.data?.error ||
                'Cannot delete this item because it is being referenced in other records.';
            setErrorMessage(errorMessage);
            setMessageModalOpen(true); // Open the MessageModal
        }
    };

    if (!isOpen) return null;

    return (
        <div className="edit-modal-overlay">
            <div className="edit-modal-content">
                <button className="dropdown-close-button" onClick={onClose}>
                    <FaTimes />
                </button>

                <h2 style={{marginTop: '60px'}}>
                    {isEditing ? (
                        <>
                            Edit <strong>{capitalizeItemType(itemType)}</strong>
                        </>
                    ) : (
                        <>
                            Add New <strong>{capitalizeItemType(itemType)}</strong>
                        </>
                    )}
                </h2>
                <input 
                    type="text" 
                    className="edit-modal-input" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                    style={{ padding: '10px', marginTop: '20px' }}
                />
                <div className="edit-button-container" style={{marginTop: '20px'}}>
                    <button
                        onClick={handleUpdate}
                        className="edit-dropdown-button"
                    >
                        {isEditing ? 'Update' : 'Add'}
                    </button>
                    {isEditing && (
                        <button 
                            onClick={handleDelete} 
                            className="delete-dropdown-button"
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
            <MessageModal
                isOpen={messageModalOpen}
                onClose={() => setMessageModalOpen(false)}
                message={errorMessage}
            />
        </div>
    );
}

export default EditModal;
