import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import BranchModal from './BranchModal';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import MessageModal from './MessageModal';
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function Branch() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [branches, setBranches] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [branchToDelete, setbranchToDelete] = useState(null);

    const [activeTab, setActiveTab] = useState('branches'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch employees, and branches data
        const fetchData = async () => {
            try {
                const [employeesResponse, branchesResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/employees'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                ]);
                setEmployees(employeesResponse.data);
                setBranches(branchesResponse.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch branches again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                .then(response => {
                    console.log('Branch data refreshed successfully:', response.data);
                    setBranches(response.data);
                    setRefreshTable(false);  // Reset the refreshTable state
                })
                .catch(error => {
                    console.error('There was an error refreshing the branch data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const formatEmployeeName = (employee) => {
        const middleInitial = employee.empMName ? `${employee.empMName.charAt(0)}.` : '';
        return `${employee.empLName}, ${employee.empFName} ${middleInitial}`;
    };

    const handleAddBranch = () => {
        console.log('Add Branch button clicked');
        setSelectedBranch(null); // Clear any selected branch
        setIsModalOpen(true);
    };

    const handleEditBranch = (branch) => {
        setSelectedBranch(branch); // Set the branch to be edited
        setIsModalOpen(true);
    };

    const confirmDeleteBranch= (branchID) => {
        console.log("Delete button clicked for branch ID:", branchID);
        setbranchToDelete(branchID);
        setMessage('Are you sure you want to delete this branch?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };

    const handleDeleteBranch = () => {
        if (branchToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/branches/${branchToDelete}`)
                .then(() => {
                    setBranches(branches.filter(branch => branch.branchID !== branchToDelete));
                    setMessage('Branch has been successfully deleted.');
                    setDeletionConfirmed(true); // This should trigger the close button

                    // Trigger a refresh
                    setRefreshTable(true);
                })
                .catch(error => {
                    console.error('There was an error deleting the branch!', error);
                    setMessage('Error deleting branch');
                    setDeletionConfirmed(true);
                });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        console.log('Add Branch Form Data:', data);
        setIsModalOpen(false);
        
        // Set message based on whether it's an edit or add
        const successMessage = isEditing ? 'Branch updated successfully!' : 'Branch added successfully!';
        setMessage(successMessage);
        
        setShowCloseButton(true); // Ensure the close button is shown
        setIsMessageModalOpen(true);
        setIsConfirmation(false); // Set to false for success messages
        setRefreshTable(true);
    };

    // Filter and search logic
    const filteredBranches = branches
        .filter(branch => 
            (filter === 'all' || branch.branchType === filter) &&
            (branch.branchName || '').toLowerCase().includes(searchQuery.toLowerCase())
        );

        const handleRecordsChange = (event) => {
            const selectedValue = parseInt(event.target.value, 10);
            setRecordsPerPage(selectedValue);
            setCurrentPage(1); // Reset to first page on records change
        };
    
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const displayedBranches = filteredBranches.slice(indexOfFirstRecord, indexOfLastRecord);
    
        const totalPages = Math.ceil(filteredBranches.length / recordsPerPage);
    // Map user_id to empName
    const employeeMap = new Map(employees.map(employee => [employee.empID, formatEmployeeName(employee)]));

    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <BranchModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        branch={selectedBranch}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteBranch(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/branch/information" className={activeTab === 'branches' ? 'active' : ''} onClick={() => setActiveTab('branches')}>
                            Branch Information
                        </Link>
                        <Link to="/branch/employees" className={activeTab === 'branch-employees' ? 'active' : ''} onClick={() => setActiveTab('branch-employees')}>
                            Branch Employees
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search branch name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="MAIN">MAIN</option>
                                <option value="SUB">SUB</option>
                            </select>
                            <button className="action-button" onClick={handleAddBranch}>Add Branch</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th style={{width: '120px'}}>Branch ID</th>
                                    <th>Branch Name</th>
                                    <th>Address</th>
                                    <th style={{width: '200px'}}>Contact No.</th>
                                    <th style={{width: '300px'}}>Email</th>
                                    <th style={{width: '100px'}}>Type</th>
                                    <th style={{width: '200px'}}>Branch Manager</th>
                                    <th style={{width: '100px'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedBranches.length > 0 ? (
                                    displayedBranches.map((branch, index) => (
                                        <tr key={branch.branchID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td style={{width: '120px'}}>{branch.branchID}</td>
                                            <td>{branch.branchName}</td>
                                            <td>{branch.branchAddr}</td>
                                            <td style={{width: '200px'}}>{branch.branchContNo}</td>
                                            <td style={{width: '300px'}}>{branch.branchEmail}</td>
                                            <td style={{width: '100px'}}>{branch.branchType}</td>
                                            <td style={{
                                                width: '200px',
                                                color: employeeMap.get(branch.branchManager) ? 'inherit' : 'red' // Set color to red if no branch manager
                                            }}>
                                                {employeeMap.get(branch.branchManager) || 'No Branch Manager'}
                                            </td>
                                            <td style={{width: '100px'}}>
                                                <button className="edit-button" onClick={() => handleEditBranch(branch)}>
                                                    <FaEdit />
                                                </button>
                                                <button className="delete-button" onClick={() => confirmDeleteBranch(branch.branchID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" style={{ textAlign: 'center' }}>No branches found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default Branch;
