import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';   
import axios from 'axios';
import { FaEye, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import MessageModal from './MessageModal';
import SuggestionInventoryModal from './SuggestionInventoryModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';

function PurchaseSuggestion() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [suggestionData, setSuggestionData] = useState([]);
    const [orderData, setOrderData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [isViewInventoryModalOpen, setIsViewInventoryModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isViewMode, setIsViewMode] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('suggestions'); // Default tab

    const [recordsCount, setRecordsCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
    
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
    
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }
    
        // Fetch purchase suggestion data
        const fetchSuggestions = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/purchase-suggestions');
                console.log(response.data);  // Log the fetched data
                setSuggestionData(response.data); // Set the suggestion data
            } catch (error) {
                console.error('There was an error fetching purchase suggestions!', error.response || error.message || error);
            }
        };
        
        fetchSuggestions();
    }, [navigate]);
    

    useEffect(() => {
        if (refreshTable) {
            // Fetch purchase orders again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/purchaseorders')
                .then(response => {
                    setOrderData(response.data); // Set updated purchase order data
                    setRefreshTable(false); // Reset refresh trigger
                })
                .catch(error => {
                    console.error('There was an error refreshing the purchase order data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const handleViewInventory = async (prod_No_fk) => {
        console.log('prod_No_fk:', prod_No_fk); // Ensure prod_No_fk is logged
        try {
            // Make sure prod_No_fk is passed to the API
            const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/inventory/${prod_No_fk}`);
            setSelectedInventory(response.data);
            const productName = response.data[0]?.prodName || 'Unknown Product';
            setSelectedProduct({
                prod_No_fk,
                prodName: productName,
            });
            setIsViewInventoryModalOpen(true);
        } catch (error) {
            console.error('Error fetching inventory details:', error.response ? error.response.data : error.message);
        }
    };
    
    
    
    
    
    
    

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddOrder = () => {
        setSelectedOrder(null); // Clear selected order
        setLineItems([]); // Clear line items
        setIsModalOpen(true);     // Open the modal for adding a new order
        setIsViewMode(false);     // Ensure the mode is set to add
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
        const successMessage = isEditing ? 'Purchase order updated successfully!' : 'Order created successfully!';
        setMessage(successMessage);
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
        setIsConfirmation(false);
        setRefreshTable(true);
    };

    const filteredSuggestions = suggestionData.filter((suggestion) => {
        const matchesSearchQuery = suggestion.prodName?.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesSearchQuery;
    });

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedSuggestions = filteredSuggestions.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(filteredSuggestions.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <SuggestionInventoryModal
                        isOpen={isViewInventoryModalOpen}
                        onClose={() => {
                            console.log('Closing SuggestionInventoryModal');
                            setIsViewInventoryModalOpen(false);
                        }}
                        product={selectedProduct}
                        inventory={selectedInventory}
                    />
                    <div className="tab-navigation">
                        <Link to="/purchase/transaction" className={activeTab === 'transactions' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Purchase Transaction
                        </Link>
                        <Link to="/purchase/order" className={activeTab === 'orders' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                            Purchase Order
                        </Link>
                        <Link to="/purchase/suggestion" className={activeTab === 'suggestions' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Suggestion
                        </Link>
                    </div>

                    <div className='form-actions'>
                    <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search product name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                        </div>
                    </div>

                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>

                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{ width: '3%' }}>#</th>
                                    <th style={{ width: '29%' }}>Product Name</th>
                                    <th style={{ width: '10%' }}>Category</th>
                                    <th style={{ width: '10%' }}>Brand</th>
                                    <th style={{ width: '8%' }}>Stock Available</th>
                                    <th style={{ width: '8%' }}>Reorder Qty</th>
                                    <th style={{ width: '8%' }}>Preferred Qty</th>
                                    <th style={{ width: '8%' }}>Sold Last Month</th>
                                    <th style={{ width: '8%' }}>Suggested Qty</th>
                                    <th style={{ width: '8%' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedSuggestions.length > 0 ? (
                                    displayedSuggestions.map((suggestion, index) => (
                                        <tr key={suggestion.prod_No_fk}>
                                            <td style={{ width: '3%' }}>{index + 1 + indexOfFirstRecord}</td>
                                            <td style={{ width: '29%' }}>{suggestion.prodName}</td>
                                            <td style={{ width: '10%' }}>{suggestion.categoryName}</td>
                                            <td style={{ width: '10%' }}>{suggestion.brandName}</td>
                                            <td style={{ width: '8%' }}>{suggestion.totalStockOnHand}</td>
                                            <td style={{ width: '8%' }}>{suggestion.totalReorderQty}</td>
                                            <td style={{ width: '8%' }}>{suggestion.totalPreferredQty}</td>
                                            <td style={{ width: '8%' }}>{suggestion.totalSoldLastMonth}</td>
                                            <td style={{ width: '8%' }}>{suggestion.suggestedOrderQty}</td>
                                            <td style={{ width: '8%' }}>
                                                <button className="view-button" onClick={() => handleViewInventory(suggestion.prod_No_fk)}>
                                                    <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: 'center' }}>No purchase suggestions found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>



                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default PurchaseSuggestion;
