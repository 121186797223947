import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles = [], allowedPositions = [], children }) => {
    const userType = localStorage.getItem('userType');
    const empPosition = localStorage.getItem('empPosition');

    console.log('userType:', userType);
    console.log('empPosition:', empPosition);

    const isAllowed = 
    allowedRoles.includes(userType) || allowedPositions.includes(empPosition);
  
    console.log('userType:', userType, 'empPosition:', empPosition);
    console.log('allowedRoles:', allowedRoles, 'allowedPositions:', allowedPositions);
    console.log('isAllowed:', isAllowed);
    
    return isAllowed ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;
