import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserModal.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import MessageModal from './MessageModal';

function UserModal({ isOpen, onClose, onSubmit, user }) {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        confirmPassword: '',
        userType: 'Admin'
    });
    const [showPassword, setShowPassword] = useState(false);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false); // New state for username check
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [originalUsername, setOriginalUsername] = useState('');

    useEffect(() => {
        if (user) {
            setIsEditing(true);
            setOriginalUsername(user.userName); // Save original username
            setFormData({
                username: user.userName || '',
                password: user.userPassword || '',
                confirmPassword: user.userPassword || '',
                userType: user.userType || 'Admin'
            });
        } else {
            setIsEditing(false);
            setFormData({
                username: '',
                password: '',
                confirmPassword: '',
                userType: 'Admin'
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, confirmPassword } = formData;
    
        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            console.log('Message set:', message); // Check if the message updates
            setMessageModalOpen(true);
            return;
        }
    
    // Check if username exists before submitting (only if it's changed)
    if (formData.username && formData.username !== originalUsername) {
        try {
            const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/users/check-username`, {
                params: { userName: formData.username }
            });
            if (response.data.exists) {
                setMessage('Username is already taken.');
                setMessageModalOpen(true);
                setShowCloseButton(true);
                return;
            }
        } catch (error) {
            console.error('Error checking username:', error);
            setMessage('Error checking username. Please try again.');
            setMessageModalOpen(true);
            setShowCloseButton(true);
        }
    }
    
        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/users/${user.userID}` 
            : 'https://www.capstonelimenerp.xyz:8082/api/users';
    
        const method = isEditing ? 'put' : 'post';
    
        const data = { 
            userName: formData.username, 
            userType: formData.userType 
        };
    
        if (password) {
            data.userPassword = password;
        }
    
        axios({ method, url, data })
            .then(response => {
                setMessage(isEditing ? 'User updated successfully!' : 'User added successfully!');
                setFormData({
                    username: '',
                    password: '',
                    confirmPassword: '',
                    userType: 'Admin'
                });
                onClose(); // Close the modal
                onSubmit(data, isEditing);
            })
            .catch(error => {
                console.error(isEditing ? 'Error updating user:' : 'Error adding user:', error.response || error.message);
                setMessage((isEditing ? 'Error updating user: ' : 'Error adding user: ') + (error.response?.data?.message || error.message));
                setMessageModalOpen(true);
            });
    };
    

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="user-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{isEditing ? 'Update Existing User' : 'Add New User'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label htmlFor="username"><strong>Username <span style={{ color: 'red' }}>*</span></strong></label>
                        <input
                            type="text"
                            placeholder="Enter Username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            pattern="^[a-zA-Z0-9_]{3,15}$"
                            title="Username must be 3-15 characters long and can include letters, numbers, and underscores."
                            required
                        />
                    </div>
                    <div className='form-group password-container'>
                        <label htmlFor="password"><strong>Password <span style={{ color: 'red' }}>*</span></strong></label>
                        <div className="input-group">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Enter Password'
                                name='password'
                                onChange={handleChange}
                                value={formData.password}
                                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$"
                                title="Password must be 6-20 characters long and include at least one number, one lowercase letter, and one uppercase letter."
                                required={!isEditing}
                            />
                            <span className="input-group-text" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>
                    <div className='form-group password-container'>
                        <label htmlFor="confirmPassword"><strong>Confirm Password <span style={{ color: 'red' }}>*</span></strong></label>
                        <div className="input-group">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Confirm Password'
                                name='confirmPassword'
                                onChange={handleChange}
                                value={formData.confirmPassword}
                                required={!isEditing}
                            />
                            <span className="input-group-text" onClick={toggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label htmlFor="userType"><strong>User Type <span style={{ color: 'red' }}>*</span></strong></label>
                        <select
                            name="userType"
                            value={formData.userType}
                            onChange={handleChange}
                            required
                        >
                            <option value="Admin">Admin</option>
                            <option value="Employee">Employee</option>
                        </select>
                    </div>
                    <div className='button-container'>
                        <button type="submit" className='user-button'>{isEditing ? 'Save User' : 'Add User'}</button>
                    </div>
                </form>
            </div>
            <MessageModal 
                isOpen={messageModalOpen} 
                onClose={closeMessageModal} 
                message={message} 
                showCloseButton={showCloseButton} // Pass showCloseButton to MessageModal
            />
        </div>
        
    );
}

export default UserModal;
