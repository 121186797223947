import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination';
import MessageModal from './MessageModal';
import PurchaseTransactionModal from './PurchaseTransactionModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';

function PurchaseTransaction() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [transactionData, setTransactionData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [transactionToDelete, setTransactionToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('transactions');

    const [recordsCount, setRecordsCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');

        if (storedUsername) setUsername(storedUsername);
        if (storedBranchName) setBranchName(storedBranchName);
        if (storedBranchID) setBranchID(storedBranchID);
        else navigate('/'); // Redirect to login if branch is not set

        fetchTransactions();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            fetchTransactions();
            setRefreshTable(false);
        }
    }, [refreshTable]);

    
    const fetchTransactions = async () => {
        try {
            const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/purchasetransactions');
            setTransactionData(response.data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };
    
    
    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };


    const handleAddTransaction = () => {
        setSelectedTransaction(null);
        setLineItems([]);
        setIsModalOpen(true);
        setIsViewMode(false);
    };

    const handleViewTransaction = (transaction) => {
        axios.get(`https://www.capstonelimenerp.xyz:8082/api/purchasetransaction/${transaction.purTransNo}`)
            .then(async (response) => {
                const items = response.data.lineItems;
    
                // Fetch product names for each line item
                const itemsWithNames = await Promise.all(items.map(async (item) => {
                    try {
                        const productResponse = await axios.get(
                            `https://www.capstonelimenerp.xyz:8082/api/products/${item.prodNo}`
                        );
                        return { ...item, prodName: productResponse.data.prodName };
                    } catch (error) {
                        console.error(`Error fetching product: ${item.prodNo}`, error);
                        return { ...item, prodName: '' };
                    }
                }));
    
                setLineItems(itemsWithNames);
                setSelectedTransaction(response.data);
                setIsModalOpen(true);
                setIsViewMode(true);
            })
            .catch((error) => {
                console.error('Error fetching transaction:', error);
            });
    };
    
    

    const confirmDeleteTransaction = (purTransNo) => {
        setTransactionToDelete(purTransNo);
        setMessage('Are you sure you want to delete this transaction?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false);
        setIsConfirmation(true);
    };

    const handleDeleteTransaction = () => {
        if (!transactionToDelete) return;

        axios
            .delete(`https://www.capstonelimenerp.xyz:8082/api/purchasetransaction/${transactionToDelete}`)
            .then(() => {
                setTransactionData(transactionData.filter(t => t.purTransNo !== transactionToDelete));
                setMessage('Transaction successfully deleted.');
                setIsMessageModalOpen(true);
                setRefreshTable(true);
            })
            .catch(error => {
                console.error('Error deleting transaction:', error);
                setMessage('Error deleting transaction.');
                setIsMessageModalOpen(true);
            });
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setRefreshTable(true);
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
        setIsConfirmation(false);
        const successMessage = isEditing ? 'Transaction updated successfully!' : 'Transaction created successfully!';
        setMessage(successMessage);
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
        setRefreshTable(true);
    };

    const filteredTransactions = transactionData.filter(transaction => {
        const supId = transaction.sup_id_fk || ''; // Ensure it's a string
        const transNo = transaction.purTransNo || ''; // Ensure it's a string
        const supplierName = transaction.supplierName || ''; // Ensure it's a string
        const isPaidStatus = transaction.invoiceStatus || '';
    
        const matchesSearch =
            supId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            transNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
            supplierName.toLowerCase().includes(searchQuery.toLowerCase()); // Add supplier name to search
    
        const matchesFilter = filter === 'all' || isPaidStatus === filter; // Compare paid/unpaid correctly
    
        return matchesSearch && matchesFilter;
    });

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedTransactions = filteredTransactions.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredTransactions.length / recordsPerPage);

    const handleRecordsChange = (e) => {
        setRecordsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    return (
        <div className="main-container">
            <TopContainer />

            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <PurchaseTransactionModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        lineItems={lineItems}
                        transaction={selectedTransaction}
                        isViewMode={isViewMode}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => setIsMessageModalOpen(false)}
                        message={message}
                        isConfirmation={isConfirmation}
                        onConfirm={handleDeleteTransaction}
                    />

                    <div className="tab-navigation">
                        <Link to="/purchase/transaction" className={activeTab === 'transactions' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Purchase Transaction
                        </Link>
                        <Link to="/purchase/order" className={activeTab === 'orders' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                            Purchase Order
                        </Link>
                        <Link to="/purchase/suggestion" className={activeTab === 'suggestions' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Suggestion
                        </Link>
                    </div>

                    <div className="form-actions">
                        <div className="action-container">
                            <input
                                type="text"
                                placeholder="Search supplier name"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Paid">Paid</option>
                            </select>
                            <button className="action-button" onClick={handleAddTransaction}>
                                Add Transaction
                            </button>
                        </div>
                    </div>

                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>

                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{ width: '50px' }}>#</th>
                                    <th style={{ width: '200px' }}>Transaction No</th>
                                    <th style={{ width: '180px' }}>Invoice Date</th>
                                    <th>Supplier</th>
                                    <th style={{width: '150px'}}>Total Amount</th>
                                    <th style={{width: '200px'}}>Reference No</th>
                                    <th style={{width: '150px'}}>Received By</th>
                                    <th style={{width: '150px'}}>Status</th>
                                    <th style={{width: '150px'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedTransactions.map((transaction, index) => (
                                    <tr key={transaction.purTransNo}>
                                        <td style={{ width: '50px' }}>{index + 1 + indexOfFirstRecord}</td>
                                        <td style={{ width: '200px' }}>{transaction.purTransNo}</td>
                                        <td style={{ width: '180px' }}>{new Date(transaction.purDate).toLocaleDateString()}</td>
                                        <td>{transaction.supplierName}</td>
                                        <td style={{width: '150px'}}>{transaction.purTotal.toFixed(2)}</td>
                                        <td style={{width: '200px'}}>{transaction.referenceNo || 'N/A'}</td>
                                        <td style={{width: '150px'}}>{transaction.receivedBy}</td>
                                        <td style={{width: '150px'}}>{transaction.invoiceStatus}</td>
                                        <td style={{width: '150px'}}>
                                            <button className="view-button" onClick={() => handleViewTransaction(transaction)}>
                                            <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} />
                                            </button>
                                            <button className="delete-button" onClick={() => confirmDeleteTransaction(transaction.purTransNo)}>
                                            <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                        </table>
                    </div>


                    <div className="pagination-container">
                        <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default PurchaseTransaction;
