import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from './Pagination';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function BranchEmployee() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [branches, setBranches] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('branch-employees'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch employees by branch and branches data
        const fetchData = async (selectedBranchId) => {
            try {
                const [branchesResponse, employeesResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches'),
                    axios.get(`https://www.capstonelimenerp.xyz:8082/api/branchemployees?branchId=${selectedBranchId}`) // Use the selectedBranchId
                ]);
                setBranches(branchesResponse.data);
                setEmployees(employeesResponse.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };


        fetchData();
    }, [navigate]);

    useEffect(() => {
        const fetchBranchEmployees = async () => {
            if (branchID) {
                try {
                    const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/branchemployees?branchID=${branchID}`);
                    setEmployees(response.data);
                } catch (error) {
                    console.error('Error fetching branch employees:', error);
                }
            }
        };
    
        fetchBranchEmployees();
    }, [branchID]);
    
    useEffect(() => {
        const fetchBranchEmployees = async () => {
            if (filter !== 'all') {
                try {
                    const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/branchemployees?branchID=${filter}`);
                    setEmployees(response.data);
                } catch (error) {
                    console.error('Error fetching branch employees:', error);
                }
            } else {
                // Fetch all employees if "All" is selected
                const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/branchemployees?branchID=${branchID}`);
                setEmployees(response.data);
            }
        };
    
        fetchBranchEmployees();
    }, [filter, branchID]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        const selectedBranchID = e.target.value;
        setFilter(selectedBranchID); // This sets the filter to the selected branch ID
    };

    // Filtering Employees
    const filteredEmployees = employees.filter(employee => {
        const matchesSearch = employee.empName && employee.empName.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesBranch = filter === 'all' || employee.branchID === filter;
        return matchesSearch && matchesBranch;
    });

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedEmployees  = filteredEmployees.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredEmployees.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <div className="tab-navigation">
                        <Link to="/branch/information" className={activeTab === 'branches' ? 'active' : ''} onClick={() => setActiveTab('users')}>
                            Branch Information
                        </Link>
                        <Link to="/branch/employees" className={activeTab === 'branch-employees' ? 'active' : ''} onClick={() => setActiveTab('branch-employees')}>
                            Branch Employees
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search employee name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                {branches.map(branch => (
                                    <option key={branch.branchID} value={branch.branchID}>{branch.branchName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Employee ID</th>
                                    <th>Employee Name</th>
                                    <th>Position</th>
                                    <th>Contact No.</th>
                                    <th>Branch Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedEmployees.length > 0 ? (
                                    displayedEmployees.map((employee, index) => (
                                        <tr key={employee.empID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{employee.empID}</td>
                                            <td>{employee.empName}</td>
                                            <td>{employee.empPosition}</td>
                                            <td>{employee.empContNo}</td>
                                            <td>{employee.branchName}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center' }}>No employees found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default BranchEmployee;
