import React, { useState, useEffect } from 'react';
import './CustomerPaymentModal.css';
import axios from 'axios';
import MessageModal from './MessageModal';
import EwalletReferenceModal from './EwalletReferenceModal';

function CustomerPaymentModal({ isOpen, transactionID, onClose, sale, updateTransactionStatus, fetchSalesHistory, refreshParent   }) {
    // Log the sale object to confirm it's being passed correctly
    console.log("Sale object received in CustomerPaymentModal:", sale);

    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentType, setPaymentType] = useState('Cash');
    const [outstandingBalance, setOutstandingBalance] = useState(0);
    const [previousPayments, setPreviousPayments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');  // State to store error message
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false); // State to control MessageModal visibility
    const [isEwalletModalOpen, setIsEwalletModalOpen] = useState(false);
    const [ewalletReferenceNo, setEwalletReferenceNo] = useState('');

    // Attempt to access customer ID
    const customerID = sale ? sale.cust_No_fk : null;  // Explicit check to ensure sale exists
    console.log("Customer ID:", customerID);  // Log to check if it's accessible
    const storedUserID = localStorage.getItem('userID');

    // Define state for sale status
    const [updatedSale, setUpdatedSale] = useState(sale); // Add state for updating sale status

    useEffect(() => {
        console.log("Sale data received:", sale); // Log the sale object
        const fetchPaymentDetails = async () => {
            try {
                const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/payments/${transactionID}`);
                setOutstandingBalance(response.data.outstandingBalance || 0);
                setPreviousPayments(response.data.previousPayments || []);
            } catch (error) {
                console.error("Error fetching payment details:", error);
            }
        };
    
        if (isOpen) fetchPaymentDetails();
    }, [isOpen, transactionID, sale]);

    const handlePaymentSubmit = async () => {
        // Check if payment type is Ewallet
        if (paymentType === 'Ewallet') {
            setIsEwalletModalOpen(true); // Open the Ewallet modal
            return; // Stop further processing until referenceNo is provided
        }

    
        try {
            // Check if the customerID exists
            if (!customerID) {
                setErrorMessage("Customer ID is missing.");
                setIsMessageModalOpen(true); // Open MessageModal
                return;
            }
    
            // Check if payment amount exceeds the remaining outstanding balance
            if (paymentAmount > outstandingBalance) {
                setErrorMessage("Payment amount exceeds the remaining balance.");
                setIsMessageModalOpen(true); // Open MessageModal
                return;
            }
    
            // Reset the error message if payment is valid
            setErrorMessage('');
    
            // Ensure that userID_fk is passed in the request body
            if (!storedUserID) {
                setErrorMessage("User is not logged in. Please log in first.");
                setIsMessageModalOpen(true); // Open MessageModal
                return;
            }
    
            // Make the API request to record the payment
            const response = await axios.post('https://www.capstonelimenerp.xyz:8082/api/payments', {
                transactionID,  // Assuming transactionID is correctly passed to this modal
                paymentAmount,   // Payment amount from user input
                paymentType,     // Payment type (e.g., 'Cash', 'Credit')
                custNo_fk: customerID,
                userID_fk: storedUserID, // Use the customerID extracted from the sale
            });
    
            console.log(response.data);
    
            // Check if payment is fully made and update the payment status
            if (response.data.remainingBalance === 0) {


            // Update the sale status with 'Paid' locally
            const updatedSale = {
                ...sale, // Use the current sale object as a base
                paymentStatus: 'Paid',
                paymentType: paymentType,
            };

            // Update local state
            setUpdatedSale(updatedSale);

            // Propagate the updated transaction to the parent
            updateTransactionStatus(updatedSale);

            // Optionally, refresh sales history
            fetchSalesHistory(sale.cust_No_fk);

                setErrorMessage("Payment recorded successfully. The transaction is now fully paid.");
            } else {
                setErrorMessage(`Payment recorded successfully. Remaining balance is ₱${response.data.remainingBalance.toFixed(2)}`);
            }
    
            setIsMessageModalOpen(true);

        } catch (error) {
            console.error('Error recording payment:', error.response?.data);
    
            // Show the error message to the user
            if (error.response?.data?.error) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage("Error: " + error.message);
            }
    
            setIsMessageModalOpen(true); // Open MessageModal with error message
        }
    };
    
    const handleEwalletConfirm = async (referenceNo) => {
        try {
            const response = await axios.post('https://www.capstonelimenerp.xyz:8082/api/payments', {
                transactionID,
                paymentAmount,
                paymentType,
                custNo_fk: customerID,
                userID_fk: storedUserID,
                externalDocument: referenceNo, // Add the reference number
            });
    
            // Handle response success
            if (response.data.remainingBalance === 0) {
                const updatedSale = {
                    ...sale,
                    paymentStatus: 'Paid',
                    paymentType: paymentType,
                };
                setUpdatedSale(updatedSale);
                updateTransactionStatus(updatedSale);
                fetchSalesHistory(sale.cust_No_fk);
                setErrorMessage("Payment recorded successfully. The transaction is now fully paid.");
            } else {
                setErrorMessage(`Payment recorded successfully. Remaining balance is ₱${response.data.remainingBalance.toFixed(2)}`);
            }
            setIsMessageModalOpen(true);
        } catch (error) {
            console.error('Error recording payment:', error.response?.data);
            setErrorMessage(error.response?.data?.error || `Error: ${error.message}`);
            setIsMessageModalOpen(true);
        } finally {
            setIsEwalletModalOpen(false); // Close the Ewallet modal
        }
    };
    
    
    // Close CustomerPaymentModal after MessageModal is closed
    const handleCloseMessageModal = () => {
        setIsMessageModalOpen(false);
    
        // Refresh parent before closing
        if (typeof refreshParent === 'function') {
            console.log("Refreshing parent before closing modal...");
            refreshParent();
        }
        onClose(); // Close the modal
    };
    if (!isOpen) return null;

    return (
        <div className="customer-payment-modal-overlay">
            <div className="customer-payment-modal-content">
                <button className="customer-payment-modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section" style={{width: '60%'}}>
                    <h3 style={{textAlign:'center'}}>Previous Payments</h3>
                        <div className="previous-payments-section">
                            <table className="previous-payments-table">
                                <thead>
                                    <tr>
                                        <th>Date and Time</th>
                                        <th style={{textAlign:'center'}}>Amount</th>
                                        <th style={{textAlign:'center'}}>Type</th>
                                        <th style={{ textAlign: 'center' }}>Reference</th> {/* Add this column */}
                                        <th style={{textAlign:'center'}}>Balance</th>
                                        <th style={{textAlign:'center'}}>Received By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previousPayments.length > 0 ? (
                                        previousPayments.map((payment, index) => (
                                            <tr key={index}>
                                                <td style={{textAlign:'left'}}>{new Date(payment.paymentDate).toLocaleString()}</td>
                                                <td>₱{(payment.paymentAmount || 0).toFixed(2)}</td>
                                                <td>{payment.paymentType || 'N/A'}</td>
                                                <td>{payment.externalDocument || 'N/A'}</td> {/* Display externalDocument */}
                                                <td>₱{(payment.outstandingBalance || 0).toFixed(2)}</td>
                                                <td>{payment.receivedByName || 'N/A'}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: 'center' }}>No previous payments found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                <div className="right-section" style={{width: '40%'}}>
                    <h2>Make Payment</h2>

                    <div className="form-group">
                        <label htmlFor="outstandingBalance"><strong>Outstanding Balance:</strong></label>
                        <input
                            type="text"
                            name="outstandingBalance"
                            value={`₱${(outstandingBalance || 0).toFixed(2)}`}
                            disabled
                            className="input-disabled"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="paymentAmount"><strong>Payment Amount:</strong></label>
                        <input
                            type="number"
                            name="paymentAmount"
                            value={paymentAmount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                            min="0"
                            placeholder="Enter amount"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="paymentType"><strong>Payment Type:</strong></label>
                        <select name="paymentType" value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                            <option value="Cash">Cash</option>
                            <option value="Ewallet">E-Wallet</option>   
                        </select>
                    </div>

                    {outstandingBalance > 0 && (
                        <button 
                            type="button" 
                            className="submit-button" 
                            onClick={handlePaymentSubmit}
                        >
                            Record Payment
                        </button>
                    )}

                </div>
                </div>
            </div>
            <MessageModal 
                isOpen={isMessageModalOpen}
                onClose={handleCloseMessageModal} // Close CustomerPaymentModal after MessageModal is closed
                message={errorMessage}
                isConfirmation={false} // Only display the close button
            />
<EwalletReferenceModal
    isOpen={isEwalletModalOpen}
    onClose={() => setIsEwalletModalOpen(false)} // Close the modal
    onConfirm={(referenceNo) => handleEwalletConfirm(referenceNo)} // Handle the reference number
/>



        </div>
    );
}

export default CustomerPaymentModal;
