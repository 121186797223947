import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PurchaseTransactionModal.css'; // Reusing the same CSS
import MessageModal from './MessageModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS for styling
import ProductModal from './ProductModal';
import { FaTrash} from 'react-icons/fa';
import InvoicePaymentModal from './InvoicePaymentModal';
import PurchasePaymentType from './PurchasePaymentType';

function PurchaseTransactionModal({ isOpen, onClose, onSubmit, transaction, isViewMode }) {
    const [formData, setFormData] = useState({
        purDate: '',
        purTotal: 0.00,
        purDesc: 'No remark.',
        referenceNo: '',
        invoiceStatus: 'Pending',
        receivedBy: localStorage.getItem('userID') || '',
        sup_id_fk: '',
        pur_req_id_fk: '',
        lineItems: []
    });

    const [lineItems, setLineItems] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [discountType, setDiscountType] = useState('percentage');
    const [pendingRequests, setPendingRequests] = useState([]);
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false); // Controls the confirmation modal
    const [isPaymentTypeModalOpen, setIsPaymentTypeModalOpen] = useState(false);
    const [pendingPaymentData, setPendingPaymentData] = useState(null);
    const [pendingTransactionData, setPendingTransactionData] = useState(null); // Holds transaction data for confirmation
    const [isInvoicePaymentModalOpen, setIsInvoicePaymentModalOpen] = useState(false);
    const basicUnitsOfMeasure = ['Piece', 'Kilogram', 'Liter', 'Box', 'Packet', 'Dozen'];

    // **Function to Reset Form Data**
    const resetFormData = () => {
        setFormData({
            purDate: '',
            purTotal: 0.00,
            purDesc: '',
            referenceNo: '',
            invoiceStatus: 'Pending',
            receivedBy: localStorage.getItem('userID') || '',
            sup_id_fk: '',
            pur_req_id_fk: '',
            lineItems: []
        });
    };

    // **Effect to Handle Modal Opening**
    useEffect(() => {
        if (isOpen) {
            if (isViewMode && transaction) {
                fetchTransactionData(); // Fetch data if viewing a record
            } else {
                resetFormData(); // Clear form if creating a new record
            }
            fetchSuppliers();
            fetchPendingRequests();
        }
    }, [isOpen, transaction, isViewMode]);

    // Fetch transaction data when the modal opens
    useEffect(() => {
        if (isOpen && transaction && isViewMode) {
            fetchTransactionData();
        }
    }, [isOpen, transaction, isViewMode]);

    // Fetch pending requests and suppliers when the modal opens
    useEffect(() => {
        if (isOpen) {
            fetchSuppliers();
            fetchPendingRequests();
        }
    }, [isOpen]);

    const fetchTransactionData = async () => {
        try {
            const response = await axios.get(
                `https://www.capstonelimenerp.xyz:8082/api/purchasetransaction/${transaction.purTransNo}`
            );
    
            const transactionData = response.data;
    
            // Fetch product names for line items
            const lineItemsWithNames = await Promise.all(
                transactionData.lineItems.map(async (item) => {
                    const productResponse = await axios.get(
                        `https://www.capstonelimenerp.xyz:8082/api/products/${item.prodNo}`
                    );
                    return { ...item, prodName: productResponse.data.prodName };
                })
            );
    
            setFormData((prevState) => ({
                ...prevState,
                ...transactionData,
                lineItems: lineItemsWithNames,
            }));
        } catch (error) {
            console.error('Error fetching transaction data:', error);
            setMessage('Failed to load transaction data.');
            setMessageModalOpen(true);
        }
    };
    
    
    

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/suppliers');
            setSuppliers(response.data);
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    };

    const fetchPendingRequests = async () => {
        try {
            const response = await axios.get(
                'https://www.capstonelimenerp.xyz:8082/api/purchaserequests'
            );
            setPendingRequests(response.data); // Store the pending requests
        } catch (error) {
            console.error('Error fetching purchase requests:', error);
        }
    };
    
    // Call fetchPendingRequests when the modal opens
    useEffect(() => {
        if (isOpen) {
            fetchPendingRequests();
        }
    }, [isOpen]);
    
    const toggleDiscountType = () => {
        setDiscountType(prevType => (prevType === 'percentage' ? 'peso' : 'percentage'));
    };

    const handleDiscountChange = (index, value) => {
        const newLineItems = [...formData.lineItems];
    
        // Ensure the line item exists before setting the discount
        if (!newLineItems[index]) {
            console.error(`Line item at index ${index} is undefined`);
            return;
        }
    
        newLineItems[index].discount = parseFloat(value) || 0;
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    
        calculateAmount(index, newLineItems);
    };
    

    const handleDiscountBlur = (index) => {
        const item = formData.lineItems[index];
        if (!item) return; // Prevent errors if the item is undefined.
    
        if (discountType === 'percentage') {
            // Store discount as a number, and display it as a percentage only in the UI.
            item.displayDiscount = `${item.discount}%`; // Display value for UI.
        } else {
            item.displayDiscount = `₱${item.discount}`; // Peso value for UI.
        }
    
        const updatedLineItems = [...formData.lineItems];
        updatedLineItems[index] = item;
    
        setFormData(prevState => ({ ...prevState, lineItems: updatedLineItems }));
    };
    

    const calculateAmount = (index) => {
        const item = formData.lineItems[index];
        
        // Ensure values are parsed as numbers
        const unitPrice = parseFloat(item.unitPrice) || 0;
        const qty = parseInt(item.qty) || 0;
        const discount = parseFloat(item.discount) || 0;
    
        const discountValue = 
            item.discountType === 'percentage' 
                ? (unitPrice * qty) * (discount / 100) // Apply percentage discount to the total
                : discount; // Use peso discount directly
    
        const amount = (unitPrice * qty) - discountValue;
        item.amount = parseFloat(amount.toFixed(2)); // Round to 2 decimal places
    
        setFormData(prevState => ({ ...prevState, lineItems: [...formData.lineItems] }));
        calculateTotal(); // Update total
    };
    
    
    
    const calculateTotal = () => {
        console.log('Line Items Before Total Calculation:', formData.lineItems); // Debug
        const total = formData.lineItems.reduce((sum, item) => sum + (item.amount || 0), 0);
        console.log('Calculated Total:', total); // Debugging
        return parseFloat(total.toFixed(2)); // Return the total
    };
    
    
    
    
    const handleRefresh = (index) => {
        const prodNo = formData.lineItems[index].prodNo;
    
        if (prodNo && prodNo.length > 2) {
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/${prodNo}`)
                .then((response) => {
                    const product = response.data;
    
                    if (product && product.prodName) {
                        handleLineItemChange(index, 'selectedProductName', product.prodName);
                        console.log(`Product Name Set: ${product.prodName}`); // Debugging
                    } else {
                        handleLineItemChange(index, 'selectedProductName', '');
                        setMessage(`Product with ProdNo ${prodNo} not found.`);
                        setMessageModalOpen(true);
                    }
                })
                .catch((error) => {
                    console.error(`Error fetching product with ProdNo ${prodNo}:`, error);
                    handleLineItemChange(index, 'selectedProductName', '');
                    setMessage(`Error fetching product with ProdNo ${prodNo}.`);
                    setMessageModalOpen(true);
                });
        } else {
            setMessage('Please enter at least 3 characters for Product No.');
            setMessageModalOpen(true);
        }
    };
    
    
    // Fetch product suggestions based on search input
    const fetchProductSuggestions = (query, index) => {
        if (query.length > 2) { // Fetch only if query is longer than 2 characters
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/search?q=${query}`)
                .then(response => {
                    setSuggestedProducts(response.data); 
                    setActiveIndex(index); // Show suggestions for the active line item
                    console.log('Fetched Suggestions:', response.data); // Debugging
                })
                .catch(error => console.error('Error fetching product suggestions:', error));
        } else {
            setSuggestedProducts([]);
            setActiveIndex(null); // Hide suggestions if query is too short
        }
    };

    const handleProductSearch = (e, index) => {
        const { value } = e.target;
    
        console.log('Product Search Input:', value); // Debugging
    
        // Update the product name field directly
        handleLineItemChange(index, 'prodName', value);
    
        // Trigger suggestions only if input length exceeds 2 characters
        if (value.length > 2) {
            fetchProductSuggestions(value, index);
        } else {
            setSuggestedProducts([]);
            setActiveIndex(null); // Hide suggestions if input is too short
        }
    };

    const selectProduct = (product, index) => {
        console.log('Selected Product:', product); // Debugging
    
        const newLineItems = [...formData.lineItems];
        newLineItems[index] = {
            ...newLineItems[index],
            prodNo: product.prodNo,
            prodName: product.prodName,
        };
    
        setFormData(prevState => ({
            ...prevState,
            lineItems: newLineItems,
        }));
    
        setActiveIndex(null);
        setSuggestedProducts([]); // Clear suggestions after selection
    };
    
    

    const addLineItem = () => {
        const newLineItem = { prodNo: '', prodName: '', qty: 1, unitPrice: 0, discount: 0, amount: 0, discountType: 'peso' };
        setFormData(prevState => ({
            ...prevState,
            lineItems: [...prevState.lineItems, newLineItem]
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleLineItemChange = (index, field, value) => {
        const newLineItems = [...formData.lineItems];
        const currentItem = newLineItems[index];
    
        // Prevent changes to qty, unitPrice, and discount unless both prodNo and prodName are filled
        if (
            (field === 'qty' || field === 'unitPrice' || field === 'discount') &&
            (!currentItem.prodNo || !currentItem.prodName)
        ) {
            return; // Block the change if prodNo or prodName is empty
        }
    
        newLineItems[index] = {
            ...currentItem,
            [field]: value,
        };
    
        // If 'qty', 'unitPrice', or 'discount' changes, recalculate the amount
        if (field === 'qty' || field === 'unitPrice' || field === 'discount') {
            const { qty = 0, unitPrice = 0, discount = 0, discountType = 'peso' } = newLineItems[index];
    
            const discountValue =
                discountType === 'percentage'
                    ? (unitPrice * discount) / 100
                    : discount;
    
            const amount = (unitPrice - discountValue) * qty;
            newLineItems[index].amount = parseFloat(amount.toFixed(2));
        }
    
        setFormData((prevState) => ({ ...prevState, lineItems: newLineItems }));
    };
    
    
    

    const removeLineItem = (index) => {
        const newLineItems = formData.lineItems.filter((_, i) => i !== index);
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    };

    const openProductModal = () => {
        setIsProductModalOpen(true);  // Open the product modal
    };

    const handleProductModalClose = (product) => {
        setIsProductModalOpen(false);  // Close the product modal

        if (product) {
            // Add the new product to the line items
            addLineItem(product);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Calculate the total and explicitly set it in formData
        const total = calculateTotal();
        const updatedFormData = { ...formData, purTotal: total };
    
        console.log('Submitting Payload:', updatedFormData); // Log the payload
    
        // Validation for zero unit prices
        if (updatedFormData.lineItems.some((item) => parseFloat(item.unitPrice) === 0)) {
            setPendingTransactionData({
                ...updatedFormData,
                purDesc: updatedFormData.purDesc.trim() || "No remark.",
            });
            setMessage("Some items have a unit price of 0. Are you sure you want to proceed?");
            setIsConfirmation(true); // Open confirmation modal
            return;
        }
    
        // If the invoice status is "Paid", open the Payment Type modal
        if (updatedFormData.invoiceStatus === 'Paid') {
            setPendingPaymentData(updatedFormData);
            setIsPaymentTypeModalOpen(true);
            return;
        }
    
        // Submit transaction with updatedFormData
        submitTransaction(updatedFormData);
    };
    
    
    
    
    const submitTransaction = (data) => {
        console.log('Submitting Transaction Data:', data); // Debugging
        if (!data.purTotal || data.purTotal === "") {
            console.error('purTotal is empty or invalid. Aborting submission.');
            setMessage('Transaction cannot be submitted. Total is invalid.');
            setMessageModalOpen(true);
            return;
        }
    
        axios
            .post('https://www.capstonelimenerp.xyz:8082/api/purchasetransaction', data, {
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then(() => {
            onSubmit(data);
            onClose();
            })
            .catch((error) => {
            console.error('Error submitting transaction:', error);
            console.error('Response:', error.response);
            setMessage('Failed to submit the transaction.');
            setMessageModalOpen(true);
            });
    };
    
    
    const handlePaymentTypeCompletion = (paymentDetails) => {
        if (!paymentDetails?.paymentType) {
            setMessage('Payment type is required for Paid transactions.');
            setMessageModalOpen(true);
            return;
        }
    
        const updatedFormData = { ...pendingPaymentData, paymentDetails };
        console.log('Final Payload with Payment Details:', updatedFormData); // Debug payload
    
        submitTransaction(updatedFormData); // Submit with payment details
        setIsPaymentTypeModalOpen(false);
        setPendingPaymentData(null);
    };
    
    
    const closePaymentTypeModal = () => {
        setIsPaymentTypeModalOpen(false);
        setPendingPaymentData(null);
    };
    
    const handleConfirm = () => {
        if (pendingTransactionData) {
            submitTransaction(pendingTransactionData);
        }
        setIsConfirmation(false); // Close confirmation modal
    };
    
    const handleCancel = () => {
        setPendingTransactionData(null); // Clear pending data
        setIsConfirmation(false); // Close confirmation modal
    };

    const isLineItemEditable = (item) => item.prodNo && item.prodName;

    const handleDiscountTypeChange = (index) => {
        const newLineItems = [...formData.lineItems];
    
        // Ensure the line item exists and discountType is properly initialized
        if (!newLineItems[index]) {
            console.error(`Line item at index ${index} is undefined.`);
            return;
        }
    
        const currentType = newLineItems[index].discountType || 'peso';
        newLineItems[index].discountType = currentType === 'percentage' ? 'peso' : 'percentage';
    
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
        calculateAmount(index); // Refresh amount on type change
    };
    
    const openPaymentModal = () => {
        setIsInvoicePaymentModalOpen(true);
    };

    const closePaymentModal = () => {
        setIsInvoicePaymentModalOpen(false);
    };

    const handlePaymentCompletion = () => {
        closePaymentModal(); // Close the payment modal
        onClose(); // Close the transaction modal
    };

    if (!isOpen) return null;

    return (
        <div className="transaction-modal-overlay">
            <div className="transaction-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>

                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <div className="left-section" style={{width: '30%'}}>
                            <h2>{isViewMode ? 'View Transaction' : 'Record Transaction'}</h2>
                            <div className="depth">
                                {/* Transaction Date Form */}
                                <div className="form-group">
                                    <label>Transaction Date <span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                        selected={formData.purDate ? new Date(formData.purDate) : null}
                                        onChange={(date) => {
                                            const formattedDate = date ? date.toISOString().split('T')[0] : '';
                                            handleChange({ target: { name: 'purDate', value: formattedDate } });
                                        }}
                                        dateFormat="MM-dd-yyyy"
                                        disabled={isViewMode}
                                        className="date-picker-input"
                                        placeholderText="MM-DD-YYYY"
                                        required
                                    />
                                </div>
                                {/* Reference No. Form */}
                                <div className="form-group">
                                    <label>Reference No. <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        type="text"
                                        name="referenceNo"
                                        value={formData.referenceNo}
                                        onChange={handleChange}
                                        disabled={isViewMode}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                {/* Supplier Form */}
                                <div className="form-group">
                                    <label>Supplier <span style={{ color: 'red' }}>*</span></label>
                                    <select
                                        name="sup_id_fk"
                                        value={formData.sup_id_fk}
                                        onChange={handleChange}
                                        disabled={isViewMode}
                                        required
                                    >
                                        <option value="">Select Supplier</option>
                                        {suppliers.map((supplier) => (
                                            <option key={supplier.supID} value={supplier.supID}>
                                                {supplier.supName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="depth">
                                {/* Purchase Request Form */}
                                <div className="form-group">
                                    <label>Purchase Request</label>
                                    <select
                                        name="pur_req_id_fk"
                                        value={formData.pur_req_id_fk || ''}  // Bind value to formData
                                        onChange={handleChange}
                                        disabled={isViewMode}
                                    >
                                        <option value="">Select Request</option>
                                        {pendingRequests.map((request) => (
                                            <option key={request.purReqID} value={request.purReqID}>
                                                {request.purReqID} - {new Date(request.reqDate).toLocaleDateString()}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* Received By User Form */}
                                <div className="form-group">
                                    <label>Received By (User ID)</label>
                                    <input
                                        type="text"
                                        name="receivedBy"
                                        value={formData.receivedBy}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                {/* Status Form */}
                                <div className="form-group">
                                    <label>Status <span style={{ color: 'red' }}>*</span></label>
                                    <select
                                        name="invoiceStatus"
                                        value={formData.invoiceStatus}
                                        onChange={(e) => handleChange(e)}
                                        disabled={isViewMode}
                                        required
                                        >
                                        <option value="Pending">Pending</option>
                                        <option value="Paid">Paid</option>
                                    </select>
                                </div>
                            </div>
                            <div className="depth">
                                {/* Description Form */}
                                <div className="description-section" style={{width: '100%'}}>
                                    <label>Purchase Description / Remarks</label>
                                    <textarea
                                        name="purDesc"
                                        value={formData.purDesc || ''}
                                        onChange={(e) => setFormData({ ...formData, purDesc: e.target.value })}
                                        disabled={isViewMode}
                                        placeholder="Enter description or remarks"
                                        rows="3"
                                        className="textArea"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="right-section" style={{width: '70%'}}>
                            <div className="transaction-line-items-section">
                                <div style={{ display: 'flex', alignItems:'end', justifyContent: 'space-between', gap: '10px'}}>
                                    <h3 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>Line Items</h3>
                                        {/* Add Product Button */}
                                        {!isViewMode && (
                                            <div className="add-product-container">
                                                <button 
                                                    type="button" 
                                                    className="add-line-item" 
                                                    onClick={addLineItem}
                                                    style={{marginTop: '-5px'}}
                                                >
                                                    Add Product
                                                </button>
                                            </div>
                                        )}
                                </div>
                                <table className="transaction-line-items-table">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Qty</th>
                                            <th>Unit Price</th>
                                            <th>Discount</th>
                                            <th>Amount</th>
                                            <th>Unit</th>
                                            {!isViewMode && <th>Actions</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.lineItems.length > 0 ? (
                                            formData.lineItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="input-suggestion-container">
                                                            <input
                                                                style={{ textAlign: 'left' }}
                                                                type="text"
                                                                name="prodName"
                                                                value={item.prodName}
                                                                onChange={e => handleProductSearch(e, index)}
                                                                onBlur={() => {
                                                                    setTimeout(() => {
                                                                        setActiveIndex(null);
                                                                        setSuggestedProducts([]);
                                                                    }, 200);
                                                                }}
                                                                required
                                                                disabled={isViewMode}
                                                            />
                                                            {activeIndex === index && suggestedProducts.length > 0 && (
                                                                <ul className="transfer-suggestion-list">
                                                                    {suggestedProducts.map((product, i) => (
                                                                        <li key={i} onClick={() => selectProduct(product, index)} className="suggestion-item">
                                                                            <span className="suggestion-product-name">{product.prodName}</span>
                                                                            <span className="suggestion-product-no">{product.prodNo}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            min={1}
                                                            value={item.qty}
                                                            onChange={(e) => handleLineItemChange(index, 'qty', e.target.value)}
                                                            required
                                                            disabled={!isLineItemEditable(item) || isViewMode}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={item.unitPrice}
                                                            min="0"
                                                            onChange={(e) => handleLineItemChange(index, 'unitPrice', e.target.value)}
                                                            required
                                                            disabled={!isLineItemEditable(item) || isViewMode}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="discount-container">
                                                            <button
                                                                type="button"
                                                                onClick={() => handleDiscountTypeChange(index)}
                                                                disabled={!isLineItemEditable(item) || isViewMode}
                                                            >
                                                                {item.discountType === 'percentage' ? '%' : '₱'}
                                                            </button>
                                                            <input
                                                                type="text"
                                                                value={item.discount}
                                                                min="0"
                                                                onChange={(e) => handleDiscountChange(index, e.target.value)}
                                                                onBlur={() => handleDiscountBlur(index)}
                                                                required
                                                                disabled={!isLineItemEditable(item) || isViewMode}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={item.amount}
                                                            readOnly
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={item.unitOfMeasure}
                                                            onChange={(e) => handleLineItemChange(index, 'unitOfMeasure', e.target.value)}
                                                            disabled={isViewMode}
                                                        >
                                                            {basicUnitsOfMeasure.map((unit) => (
                                                                <option key={unit} value={unit}>
                                                                    {unit}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    {!isViewMode && (
                                                        <td>
                                                            <button type="button" className="remove-line-item" onClick={() => removeLineItem(index)}>
                                                                <FaTrash/>
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={isViewMode ? 6 : 7} style={{ textAlign: 'center' }}>
                                                    No line items found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {!isViewMode && (
                                <div className="button-container">
                                    <button type="submit" className="order-button">
                                        Submit Transaction
                                    </button>
                                </div>
                            )}
                            {isViewMode && (
                                <div className="button-container">
                                    {formData.invoiceStatus === 'Pending' ? (
                                        <button type="button" className="stock-button" onClick={openPaymentModal}>
                                            Make Payment
                                        </button>
                                    ) : (
                                        <button type="button" className="stock-button" onClick={openPaymentModal}>
                                            View Payments
                                        </button>
                                    )}
                                </div>
                            )}

                        </div>
                    </div>
                </form>
                <MessageModal
                    isOpen={isConfirmation}
                    onClose={handleCancel}
                    message={message}
                    isConfirmation={true}
                    onConfirm={handleConfirm}
                />
                <ProductModal
                    isOpen={isProductModalOpen}
                    onClose={handleProductModalClose}
                />
                <PurchasePaymentType
                    isOpen={isPaymentTypeModalOpen}
                    onClose={closePaymentTypeModal}
                    onComplete={handlePaymentTypeCompletion}
                />
                {/* InvoicePayment Modal */}
                {isInvoicePaymentModalOpen && (
                    <InvoicePaymentModal
                        isOpen={isInvoicePaymentModalOpen}
                        onClose={handlePaymentCompletion}
                        purTransNo={transaction.purTransNo}
                    />
                )}
            </div>
        </div>
    );
}

export default PurchaseTransactionModal;
