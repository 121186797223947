// ReportComponents.js

import React, { useState } from 'react';
import './ReportComponents.css';

// Define the helper functions at the top of the file
const getBranchName = (branchID, branches = []) => {
    const branch = branches.find(b => b.branchID === branchID);
    return branch ? branch.branchName : 'All';
};

const getUserName = (userID, users = []) => {
    const user = users.find((u) => u.userID === userID);
    return user ? user.userName : 'All';
};

const getUserNameByID = (userID, users = []) => {
    const user = users.find((u) => u.userID === userID);
    return user ? user.userName : 'Unknown User';
};

// Helper functions
const getSupplierName = (supID, suppliers = []) => {
    const supplier = suppliers.find(s => s.supID === supID);
    return supplier ? supplier.supName : 'All';
};

const getEmployeeName = (employeeID, employees = []) => {
    const employee = employees.find(emp => emp.empID === employeeID);
    return employee ? `${employee.empFName} ${employee.empLName}` : 'All';
};

const getCustomerName = (custNo, customers) => {
    const customer = customers.find(c => c.custNo === custNo);
    return customer ? `${customer.custFName} ${customer.custLName}` : 'All';
};

const getProductName = (prodNo, products = []) => {
    const product = products.find(p => p.prodNo === prodNo);
    return product ? product.prodName : 'All';
};

// Helper function to get product category by product number
const getProductCategory = (prodNo, products) => {
    const product = products.find((p) => p.prodNo === prodNo);
    return product ? product.prodCategory : 'Unknown Category';
};

// Daily Sales Report
export const DailySalesReport = ({
    sales,
    startDate,
    endDate,
    selectedBranch,
    branches,
    selectedStatus
}) => {
    console.log("Daily Sales Report Data:", sales);

    const branchName = getBranchName(selectedBranch, branches);

    // Aggregate sales by date
    const dailySales = sales.reduce((acc, sale) => {
        const saleDate = new Date(sale.dateOfSale).toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
        acc[saleDate] = (acc[saleDate] || 0) + sale.grandTotal;
        return acc;
    }, {});

    // Convert the aggregated sales object to an array
    const dailySalesArray = Object.entries(dailySales).map(([date, total]) => ({
        date,
        total,
    }));

    // Sort the array by date
    dailySalesArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Calculate total sales for the period
    const totalSales = dailySalesArray.reduce((acc, { total }) => acc + total, 0);

    return (
        <div className="report-table-container">
            <h3>Daily Sales Report</h3>
            <div className="report-header">
                <p>
                    Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}
                </p>
                <p>Branch: {branchName}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            {dailySalesArray.length === 0 ? (
                <p>No sales data available for the selected period.</p>
            ) : (
                <table className="report-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Total Sales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dailySalesArray.map(({ date, total }) => (
                            <tr key={date}>
                                <td>{new Date(date).toLocaleDateString()}</td>
                                <td>₱{total.toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr className="total-row">
                            <td><strong>Total</strong></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

// Utility function to format hours to proper AM/PM format
const formatHourRange = (hour) => {
    const startHour = hour % 12 || 12; // Convert 0 hour to 12-hour format
    const endHour = (hour + 1) % 12 || 12; // Calculate the next hour correctly
    const ampm = hour < 12 ? 'AM' : 'PM'; // Determine AM/PM for the start hour
    const nextAmpm = hour + 1 < 12 || hour + 1 === 24 ? 'AM' : 'PM'; // Determine AM/PM for the next hour

    return `${startHour}:00 - ${startHour}:59 ${ampm}`; // Ensure correct hour range
};

export const HourlySalesReport = ({
    sales,
    selectedBranch,
    selectedEmployee,
    startDate,
    endDate,
    branches,
    employees,
}) => {
    const start = startDate || new Date();
    const end = endDate || new Date();

    const branchName = getBranchName(selectedBranch, branches);
    const employeeName = getEmployeeName(selectedEmployee, employees);

    console.log('Hourly Sales Report Data:', sales);

    const filteredSales = sales.filter((sale) => {
        const saleDate = new Date(sale.dateOfSale);
        const isInDateRange = saleDate >= startDate && saleDate <= endDate;
    
        const isBranchMatch = selectedBranch === 'all' || sale.branch_ID_fk === selectedBranch;
        const isEmployeeMatch = selectedEmployee === 'all' || sale.emp_ID_fk === selectedEmployee;
    
        return isInDateRange && isBranchMatch && isEmployeeMatch;
    });
    

    const hourlySales = filteredSales.reduce((acc, sale) => {
        const saleHour = new Date(sale.dateOfSale).getUTCHours(); // Use getUTCHours() instead
        console.log('This is the sale hour', saleHour);
        
        const formattedHour = formatHourRange(saleHour); // Format hour correctly
    
        if (!acc[formattedHour]) {
            acc[formattedHour] = { totalSales: 0, salesCount: 0 };
        }
    
        acc[formattedHour].totalSales += sale.grandTotal;
        acc[formattedHour].salesCount += 1;
    
        return acc;
    }, {});

    const hourlySalesArray = Object.entries(hourlySales).map(([hour, data]) => ({
        hour,
        totalSales: data.totalSales,
        salesCount: data.salesCount,
        averageSale: (data.totalSales / data.salesCount).toFixed(2),
    }));

    // Sort the array by the hour correctly
    hourlySalesArray.sort((a, b) => {
        const extractHourAndPeriod = (timeRange) => {
            const [hourPart] = timeRange.split(':'); // Extract the hour part
            const period = timeRange.includes('AM') ? 'AM' : 'PM'; // Determine AM/PM
            return { hour: parseInt(hourPart), period };
        };
    
        let { hour: aHour, period: aPeriod } = extractHourAndPeriod(a.hour);
        let { hour: bHour, period: bPeriod } = extractHourAndPeriod(b.hour);
    
        if (aPeriod !== bPeriod) {
            // If periods are different, AM should come before PM
            return aPeriod === 'AM' ? -1 : 1;
        }
    
        // Handle 12 AM/PM correctly by treating it as 0 for sorting
        if (aHour === 12) aHour = 0;
        if (bHour === 12) bHour = 0;
    
        // Sort numerically within the same period (AM or PM)
        return aHour - bHour;
    });
    
    // Calculate totals for the bottom row
    const totalSales = hourlySalesArray.reduce((acc, { totalSales }) => acc + totalSales, 0);
    const totalCount = hourlySalesArray.reduce((acc, { salesCount }) => acc + salesCount, 0);
    const totalAverage = totalCount ? (totalSales / totalCount).toFixed(2) : 0;

    return (
        <div className="report-table-container">
            <h3>Hourly Sales Report</h3>
            <div className="report-header">
                <p>Period: {`${start.toLocaleDateString()} - ${end.toLocaleDateString()}`}</p>
                <p>Branch: {branchName}</p>
            </div>
            {hourlySalesArray.length === 0 ? (
                <p>No sales data available for the selected period.</p>
            ) : (
                <table className="report-table">
                    <thead>
                        <tr>
                            <th>Hours</th>
                            <th>Total Sales</th>
                            <th>Sales Count</th>
                            <th>Average Sale</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hourlySalesArray.map(({ hour, totalSales, salesCount, averageSale }) => (
                            <tr key={hour}>
                                <td>{hour}</td>
                                <td>₱{totalSales.toFixed(2)}</td>
                                <td>{salesCount}</td>
                                <td>₱{averageSale}</td>
                            </tr>
                        ))}
                        <tr className="total-row">
                            <td><strong>Total</strong></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                            <td><strong>{totalCount}</strong></td>
                            <td><strong>₱{totalAverage}</strong></td>
                        </tr>                 
                    </tbody>
                </table>
            )}
        </div>
    );
};

// Sales by Product Report
export const SalesByProductReport = ({
    sales,
    branches,
    startDate,
    endDate,
    selectedBranch,
    selectedCustomer,
    customers,
    selectedProduct,
    products,
    selectedStatus
}) => {
    console.log("Sales Data:", sales); // Log sales data for debugging

    // Aggregate sales by product
    const productSales = sales.reduce((acc, sale) => {
        const { lineItems, branch_ID_fk, cust_No_fk } = sale; // Access line items and branch ID from each sale

        lineItems.forEach(item => {
            const { prodName, prodQty, amount } = item; // Destructure item properties to include quantity

            // Create a unique key for the product and branch
            const key = `${prodName}-${branch_ID_fk}`;

            if (!acc[key]) {
                acc[key] = {
                    prodName,
                    branchName: getBranchName(branch_ID_fk, branches), // Get branch name from ID
                    totalSales: 0,
                    salesCount: 0,
                    quantitySold: 0, // Initialize quantity sold
                };
            }

            acc[key].totalSales += amount; // Accumulate total sales
            acc[key].salesCount += 1; // Count the number of sales transactions
            acc[key].quantitySold += prodQty; // Accumulate quantity sold
        });

        return acc;
    }, {});

    // Convert aggregated data into an array
    const productSalesArray = Object.values(productSales);

    // Filter by selected branch and customer
    const filteredProductSales = productSalesArray.filter(productSale => {
        const matchesBranch = selectedBranch === 'all' || productSale.branchName === getBranchName(selectedBranch, branches);
        const matchesCustomer = selectedCustomer === 'all' || sales.some(sale => 
            sale.cust_No_fk === selectedCustomer && sale.lineItems.some(item => item.prodName === productSale.prodName)
        ); // Correctly check customer
        const matchesProduct = selectedProduct === 'all' || productSale.prodName === getProductName(selectedProduct, products); // Check if product matches

        return matchesBranch && matchesCustomer && matchesProduct; // Add matchesProduct to the condition
    });

    // Sort the filtered products alphabetically by product name
    filteredProductSales.sort((a, b) => a.prodName.localeCompare(b.prodName));

    const totalSales = filteredProductSales.reduce((sum, item) => sum + item.totalSales, 0);

    return (
        <div className="report-table-container">
            <h3>Sales by Product Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>Branch: {selectedBranch ? getBranchName(selectedBranch, branches) : 'All'}</p>
                <p>Customer: {selectedCustomer ? getCustomerName(selectedCustomer, customers) : 'All'}</p>
            </div>
            <div className="report-header">
                <p>Product: {selectedProduct ? getProductName(selectedProduct, products) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '40%'}}>Product</th>
                        <th style={{width: '25%'}}>Branch</th>
                        <th style={{width: '15%'}}>Total Sales</th>
                        <th style={{width: '10%'}}>Count</th>
                        <th style={{width: '10%'}}>Qty Sold</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredProductSales.length > 0 ? (
                        filteredProductSales.map(({ prodName, branchName, totalSales, salesCount, quantitySold }) => (
                            <tr key={`${prodName}-${branchName}`}>
                                <td style={{ width: '40%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{prodName}</td>
                                <td style={{width: '25%'}}>{branchName}</td>
                                <td style={{width: '15%'}}>₱{totalSales.toFixed(2)}</td>
                                <td style={{width: '10%'}}>{salesCount}</td>
                                <td style={{width: '10%'}}>{quantitySold}</td> {/* Display quantity sold */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>No sales data available.</td>
                        </tr>
                    )}
                    {filteredProductSales.length > 0 && (
                        <tr>
                            <td style={{width: '40%'}}><strong>Total</strong></td>
                            <td style={{width: '25%'}}></td>
                            <td style={{width: '15%'}}><strong>₱{totalSales.toFixed(2)}</strong></td>
                            <td style={{width: '10%'}}><strong>{filteredProductSales.reduce((sum, item) => sum + item.salesCount, 0)}</strong></td> {/* Total Sales Count */}
                            <td style={{width: '10%'}}><strong>{filteredProductSales.reduce((sum, item) => sum + item.quantitySold, 0)}</strong></td> {/* Total Quantity Sold */}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Sales by Branch Report
export const SalesByBranchReport = ({ sales, branches, startDate, endDate, selectedStatus }) => {
    console.log("Sales Data:", sales);

    // Aggregate sales by branch
    const branchSales = sales.reduce((acc, sale) => {
        acc[sale.branch_ID_fk] = (acc[sale.branch_ID_fk] || 0) + sale.grandTotal;
        return acc;
    }, {});

    // Convert aggregated data into an array
    const branchSalesArray = Object.entries(branchSales).map(([branchID, totalSales]) => {
        const branchName = branches.find((branch) => branch.branchID === branchID)?.branchName || branchID;
        return { branchName, totalSales };
    });

    // Calculate total sales across all branches
    const totalSales = branchSalesArray.reduce((sum, branch) => sum + branch.totalSales, 0);

    return (
        <div className="report-table-container">
            <h3>Sales by Branch Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th>Total Sales</th>
                    </tr>
                </thead>
                <tbody>
                    {branchSalesArray.length > 0 ? (
                        branchSalesArray.map(({ branchName, totalSales }) => (
                            <tr key={branchName}>
                                <td>{branchName}</td>
                                <td>₱{totalSales.toFixed(2)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                No sales data available.
                            </td>
                        </tr>
                    )}
                    {branchSalesArray.length > 0 && (
                        <tr>
                            <td><strong>Total</strong></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Sales by Employee Report
export const SalesByUserReport = ({ sales, users, startDate, endDate, selectedUser, selectedStatus }) => {
    console.log("Sales Data:", sales);

    // Aggregate sales by employee
    const userSales = sales.reduce((acc, sale) => {
        acc[sale.userID_fk] = (acc[sale.userID_fk] || 0) + sale.grandTotal;
        return acc;
    }, {});

    // Convert aggregated data into an array
    const userSalesArray = Object.entries(userSales).map(([userID, totalSales]) => {
        const userName = users.find(user => user.userID === userID)?.userName || userID;
        return { userName, totalSales };
    });

    // Calculate total sales across all employees
    const totalSales = userSalesArray.reduce((sum, user) => sum + user.totalSales, 0);

    return (
        <div className="report-table-container">
            <h3>Sales by User Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>User: {selectedUser ? getUserName(selectedUser, users) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Total Sales</th>
                    </tr>
                </thead>
                <tbody>
                    {userSalesArray.length > 0 ? (
                        userSalesArray.map(({ userName, totalSales }) => (
                            <tr key={userName}>
                                <td>{userName}</td>
                                <td>₱{totalSales.toFixed(2)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={2} style={{ textAlign: "center" }}>
                                No sales data available.
                            </td>
                        </tr>
                    )}
                    {userSalesArray.length > 0 && (
                        <tr>
                            <td><strong>Total</strong></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Sales by Payment Status Report
export const SalesByPaymentStatusReport = ({
    sales,
    startDate,
    endDate,
    selectedBranch,
    selectedUser,
    selectedCustomer,
    branches,
    users,
    customers,
    selectedStatus
}) => {
    console.log("Sales Data:", sales);
    console.log("Branch Data:", branches);

    const branchName = getBranchName(selectedBranch, branches);
    const userName = getUserName(selectedUser, users);
    const customerName = getCustomerName(selectedCustomer, customers); // Get the customer name

    // Aggregate sales by payment status and branch
    const paymentStatusSales = sales.reduce((acc, sale) => {
        const branchName = getBranchName(sale.branch_ID_fk, branches); // Get branch name

        const key = `${sale.paymentStatus}-${branchName}`; // Unique key for aggregation

        if (!acc[key]) {
            acc[key] = { 
                totalSales: 0, 
                salesCount: 0, 
                branchName, 
                paymentStatus: sale.paymentStatus 
            };
        }

        acc[key].totalSales += sale.grandTotal;
        acc[key].salesCount += 1;

        return acc;
    }, {});

    const paymentStatusArray = Object.values(paymentStatusSales); // Convert to array for rendering
    const totalSales = paymentStatusArray.reduce((sum, item) => sum + item.totalSales, 0);

    return (
        <div className="report-table-container">
            <h3>Sales by Payment Status Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>User: {userName}</p>
                <p>Customer: {customerName}</p> {/* Update to show the customer name */}
            </div>
            <div className="report-header">
                <p>Branch: {branchName}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th>Payment Status</th>
                        <th>Total Sales</th>
                        <th>Sales Count</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentStatusArray.length > 0 ? (
                        paymentStatusArray.map(({ paymentStatus, branchName, totalSales, salesCount }) => (
                            <tr key={`${paymentStatus}-${branchName}`}>
                                <td>{branchName}</td>
                                <td>{paymentStatus}</td>
                                <td>₱{totalSales.toFixed(2)}</td>
                                <td>{salesCount}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>No sales data available.</td>
                        </tr>
                    )}
                    {paymentStatusArray.length > 0 && (
                        <tr>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Sales by Customer Report
export const SalesByCustomerReport = ({ sales, branches, startDate, endDate, selectedBranch, selectedCustomer, selectedStatus, regularCustomers, customers }) => {
    console.log("Sales Data:", sales);
    console.log("Branch Data:", branches);

    // Get the branch name based on the selected branch ID
    const branchName = getBranchName(selectedBranch, branches); 

    // Aggregate sales by customer, branch, and payment status
    const customerSales = sales.reduce((acc, sale) => {
        const customer = regularCustomers.find(c => c.custNo === sale.cust_No_fk);
        const customerName = customer ? `${customer.custFName} ${customer.custLName}` : 'Walk-In';
        const paymentStatus = sale.paymentStatus;

        // Add an entry for each sale transaction, categorized by payment status
        acc.push({
            customerName,
            branchName: getBranchName(sale.branch_ID_fk, branches), // Get branch name for each sale
            totalSales: sale.grandTotal,
            salesCount: 1,
            paymentStatus
        });

        return acc;
    }, []);

    // Filter by selected status
    const filteredSales = customerSales.filter(sale => 
        selectedStatus === 'all' || sale.paymentStatus === selectedStatus
    );

    // Aggregate data for the report
    const aggregatedSales = filteredSales.reduce((acc, sale) => {
        const key = `${sale.customerName}-${sale.paymentStatus}`;
        
        if (!acc[key]) {
            acc[key] = {
                customerName: sale.customerName,
                branchName: sale.branchName,
                totalSales: 0,
                salesCount: 0,
                paymentStatus: sale.paymentStatus
            };
        }

        acc[key].totalSales += sale.totalSales;
        acc[key].salesCount += sale.salesCount;

        return acc;
    }, {});

    const salesArray = Object.values(aggregatedSales);
    const totalSales = salesArray.reduce((sum, sale) => sum + sale.totalSales, 0);

    return (
        <div className="report-table-container">
            <h3>Sales by Customer Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>Branch: {branchName || 'All'}</p> {/* Use branch name here */}
                <p>Customer: {selectedCustomer ? getCustomerName(selectedCustomer, customers) : 'All'}</p>
                <p>Status: {selectedStatus || 'All'}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Branch</th>
                        <th>Payment Status</th>
                        <th>Total Sales</th>
                        <th>Sales Count</th>
                    </tr>
                </thead>
                <tbody>
                    {salesArray.length > 0 ? (
                        salesArray.map(({ customerName, branchName, totalSales, salesCount, paymentStatus }) => (
                            <tr key={`${customerName}-${paymentStatus}`}>
                                <td>{customerName}</td>
                                <td>{branchName}</td>
                                <td>{paymentStatus}</td>
                                <td>₱{totalSales.toFixed(2)}</td>
                                <td>{salesCount}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>No sales data available.</td>
                        </tr>
                    )}
                    {salesArray.length > 0 && (
                        <tr>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Sales by Payment Type Report
export const SalesByPaymentTypeReport = ({ sales, branches, customers, startDate, endDate, selectedBranch, selectedCustomer, selectedStatus }) => {
    // Log sales and branches data
    console.log("Sales Data:", sales);
    console.log("Branch Data:", branches);

    // Aggregate sales by payment type
    const paymentTypeSales = sales.reduce((acc, sale) => {
        const branchName = getBranchName(sale.branch_ID_fk, branches); // Get branch name
        const key = `${sale.paymentType}-${branchName}`; // Create unique key for payment type and branch

        if (!acc[key]) {
            acc[key] = { 
                totalSales: 0, 
                salesCount: 0, 
                branchName, 
                paymentType: sale.paymentType 
            };
        }

        acc[key].totalSales += sale.grandTotal;
        acc[key].salesCount += 1;

        return acc;
    }, {});

    const paymentTypeArray = Object.values(paymentTypeSales);
    const totalSales = paymentTypeArray.reduce((sum, item) => sum + item.totalSales, 0);

    return (
        <div className="report-table-container">
            <h3>Sales by Payment Type Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>Customer: {selectedCustomer ? getCustomerName(selectedCustomer, customers) : 'All'}</p>
            </div>
            <div className="report-header">
                <p>Branch: {selectedBranch ? getBranchName(selectedBranch, branches) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th>Payment Type</th>
                        <th>Total Sales</th>
                        <th>Sales Count</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentTypeArray.length > 0 ? (
                        paymentTypeArray.map(({ paymentType, branchName, totalSales, salesCount }) => (
                            <tr key={`${paymentType}-${branchName}`}>
                                <td>{branchName}</td>
                                <td>{paymentType}</td>
                                <td>₱{totalSales.toFixed(2)}</td>
                                <td>{salesCount}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>No sales data available.</td>
                        </tr>
                    )}
                    {paymentTypeArray.length > 0 && (
                        <tr>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td><strong>₱{totalSales.toFixed(2)}</strong></td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};



/*
Inventory Reports
*/
// Inventory Summary Report
export const InventorySummaryReport = ({
    inventory,
    branches,
    products,
    startDate,
    endDate,
    selectedBranch,
    selectedProduct,
}) => {



    return (
        <div className="report-table-container">
            <h3>Inventory Summary Report</h3>
            <div className="report-header">
                <p>Branch: {selectedProduct ? getProductName(selectedProduct, products) : 'All'}</p>
                <p>Branch: {selectedBranch ? getBranchName(selectedBranch, branches) : 'All'}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '60%'}}>Product Name</th>
                        <th style={{width: '15%'}}>Stock Available</th>
                        <th style={{width: '25%'}}>Branch</th>
                    </tr>
                </thead>
                <tbody>
                    {inventory.length > 0 ? (
                        inventory.map((item) => (
                            <tr key={`${item.invID}-${item.branch_id_fk}-${item.prod_No_fk}`}>
                                <td style={{ width: '60%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.prodName}</td>
                                <td style={{width: '15%'}}>{item.stockOnHand}</td>
                                <td style={{width: '25%'}}>{item.branchName}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={3} style={{ textAlign: "center" }}>
                                No inventory data available.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Low Stock Report
export const LowStockReport = ({ inventory, branches, startDate, endDate, products, selectedBranch, selectedProduct }) => {
    const lowStockItems = inventory.filter(item => item.stockOnHand < item.reorderQty);

    return (
        <div className="report-table-container">
            <h3>Low Stock Report</h3>
            <div className="report-header">
                <p>Product: {selectedProduct ? getProductName(selectedProduct, products) : 'All'}</p>
                <p>Branch: {selectedBranch ? getBranchName(selectedBranch, branches) : 'All'}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '60%'}}>Product</th>
                        <th style={{width: '20%'}}>Branch</th>
                        <th style={{width: '10%'}}>Stock On Hand</th>
                        <th style={{width: '10%'}}>Reorder Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {lowStockItems.map(item => (
                        <tr key={`${item.prod_No_fk}-${item.branch_id_fk}`}>
                            <td style={{ width: '60%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getProductName(item.prod_No_fk, inventory)}</td>
                            <td style={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getBranchName(item.branch_id_fk, branches)}</td>
                            <td style={{width: '10%'}}>{item.stockOnHand}</td>
                            <td style={{width: '10%'}}>{item.reorderQty}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// Inventory Movement Log Report
export const InventoryMovementLogReport = ({
    inventory,
    users,
    branches,
    products,
    startDate,
    endDate,
    selectedBranch,
    selectedProduct,
    selectedUser
}) => {
    // Filter the movement log based on the date range
    const filteredMovementLog = inventory.filter(log => {
        const logDate = new Date(log.logDateTime);
        const withinDateRange = logDate >= startDate && logDate <= endDate;
        return withinDateRange;
    });

    console.log("Filtered Movement Log:", filteredMovementLog);

    return (
        <div className="report-table-container">
            <h3>Inventory Movement Log Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>User: {selectedUser ? getUserName(selectedUser, users) : 'All'}</p>
                <p>Branch: {selectedBranch ? getBranchName(selectedBranch, branches) : 'All'}</p>
            </div>
            <div className="report-header">
                <p>Product: {selectedProduct ? getProductName(selectedProduct, products) : 'All'}</p>           
            </div>
            {filteredMovementLog.length > 0 ? (
                <table className="report-table">
                    <thead>
                        <tr>
                            <th style={{width: '40%'}}>Product</th>
                            <th style={{width: '20%'}}>Branch</th>
                            <th style={{width: '8%'}}>Qty</th>
                            <th style={{width: '10%'}}>Type</th>
                            <th style={{width: '10%'}}>Date</th>
                            <th style={{width: '12%'}}>Logged By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMovementLog.map((log) => (
                            <tr key={log.invLogNo}>
                                <td style={{ width: '40%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getProductName(log.prod_No_fk, products)}</td>
                                <td style={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getBranchName(log.branch_id_fk, branches)}</td>
                                <td style={{width: '8%'}}>{log.logQty}</td>
                                <td style={{width: '10%'}}>{log.logType}</td>
                                <td style={{width: '10%'}}>{new Date(log.logDateTime).toLocaleDateString()}</td>
                                <td style={{width: '12%'}}>{getUserNameByID(log.createdBy, users)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No inventory movement data available for the selected period.</p>
            )}
        </div>
    );
};

// Stock Transfer Report
export const StockTransferReport = ({
    stockTransfers = [], // Default to an empty array to avoid errors
    branches = [],
    employees = [],
    users,
    startDate,
    endDate,
}) => {
    // Filter stock transfers within the date range
    const filteredTransfers = stockTransfers.filter((transfer) => {
        const transferDate = new Date(transfer.stkTransDate);
        return transferDate >= startDate && transferDate <= endDate;
    });

    return (
        <div className="report-table-container">
            <h3>Stock Transfer Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '20%'}}>Transfer No</th>
                        <th style={{width: '15%'}}>From Branch</th>
                        <th style={{width: '15%'}}>To Branch</th>
                        <th style={{width: '10%'}}>Sender</th>
                        <th style={{width: '10%'}}>Receiver</th>
                        <th style={{width: '15%'}}>Status</th>
                        <th style={{width: '15%'}}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTransfers.length > 0 ? (
                        filteredTransfers.map((transfer) => (
                            <tr key={transfer.stkTransNo}>
                                <td style={{width: '20%'}}>{transfer.stkTransNo}</td>
                                <td style={{width: '15%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getBranchName(transfer.fromBranch, branches)}</td>
                                <td style={{width: '15%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getBranchName(transfer.toBranch, branches)}</td>
                                <td style={{width: '10%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getUserName(transfer.senderEmpID, users)}</td>
                                <td style={{width: '10%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getUserNameByID(transfer.receiverEmpID, users)}</td>
                                <td style={{width: '15%'}}>{transfer.transferStatus}</td>
                                <td style={{width: '15%'}}>{new Date(transfer.stkTransDate).toLocaleDateString()}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>
                                No stock transfer data available for the selected period.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Reorder Report Component
export const ReorderReport = ({
    inventory, 
    branches,
    products,
    startDate, 
    endDate,
    selectedBranch,
    selectedProduct
}) => {

    // Filter items for the report: stockOnHand < reorderQty
    const reorderItems = inventory.filter(item => item.stockOnHand < item.reorderQty);

    console.log("Reorder Items:", reorderItems);

    return (
        <div className="report-table-container">
            <h3>Reorder Report</h3>
            <div className="report-header">
                <p>Branch: {selectedBranch ? getBranchName(selectedBranch, branches) : 'All'}</p>
                <p>Product: {selectedProduct ? getProductName(selectedProduct, products) : 'All'}</p>
            </div>
            {reorderItems.length > 0 ? (
                <table className="report-table">
                    <thead>
                        <tr>
                            <th style={{width: '40%'}}>Product</th>
                            <th style={{width: '20%'}}>Branch</th>
                            <th style={{width: '8%'}}>Stock</th>
                            <th style={{width: '10%'}}>Reorder Quantity</th>
                            <th style={{width: '10%'}}>Preferred Quantity</th>
                            <th style={{width: '12%'}}>Quantity to Reorder</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reorderItems.map(item => {
                            const quantityToReorder = item.preferredQty - item.stockOnHand;

                            return (
                                <tr key={`${item.prod_No_fk}-${item.branch_id_fk}`}>
                                    <td style={{width: '40%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{item.prodName}</td>
                                    <td style={{width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{getBranchName(item.branch_id_fk, branches)}</td>
                                    <td style={{width: '8%'}}>{item.stockOnHand}</td>
                                    <td style={{width: '10%'}}>{item.reorderQty}</td>
                                    <td style={{width: '10%'}}>{item.preferredQty}</td>
                                    <td style={{width: '12%'}}>{quantityToReorder > 0 ? quantityToReorder : 0}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p style={{ textAlign: 'center' }}>
                    No items need to be reordered for the selected period.
                </p>
            )}
        </div>
    );
};




/*
Purchase Reports
*/

// Purchase Summary Report
export const PurchaseSummaryReport = ({ transactions, products, suppliers, startDate, endDate, selectedStatus, selectedSupplier }) => {
    // Filter transactions within the selected date range
    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.purDate);
        return transactionDate >= startDate && transactionDate <= endDate;
    });

    // Initialize totals and summaries
    let totalProductsPurchased = 0;
    const categorySummary = {};
    const productTotals = {};

    // Aggregate data from filtered transactions
    filteredTransactions.forEach((transaction) => {
        if (!transaction.lineItems || transaction.lineItems.length === 0) {
            console.warn(`Missing line items for transaction: ${transaction.purTransNo}`);
            return; // Skip this transaction if no line items are available
        }
    
        transaction.lineItems.forEach((item) => {
            // Default to 'Unknown Category' if categoryName is missing
            const category = item.categoryName || 'Unknown Category'; 

            // Sum total quantities
            totalProductsPurchased += item.qty || 0;

            // Aggregate by category
            if (!categorySummary[category]) {
                categorySummary[category] = { totalQty: 0, totalAmount: 0, products: {} };
            }
            categorySummary[category].totalQty += item.qty || 0;
            categorySummary[category].totalAmount += item.amount || 0;

            // Aggregate product data within the category
            const productName = item.prodName || 'Unknown Product';
            if (!categorySummary[category].products[productName]) {
                categorySummary[category].products[productName] = { qty: 0 };
            }
            categorySummary[category].products[productName].qty += item.qty || 0;

            // Aggregate product totals (top product)
            productTotals[productName] = (productTotals[productName] || 0) + item.qty;
        });
    });

    // Determine the top supplier
    const supplierTotals = {};
    filteredTransactions.forEach((transaction) => {
        const supplierName = transaction.supplier.supName || 'Unknown Supplier';
        supplierTotals[supplierName] = (supplierTotals[supplierName] || 0) + transaction.purTotal;
    });

    const topSupplier = Object.entries(supplierTotals).reduce((a, b) => (b[1] > a[1] ? b : a), ['', 0])[0];

    // Determine the top product
    const topProduct = Object.entries(productTotals).reduce((a, b) => (b[1] > a[1] ? b : a), ['', 0]);

    // Convert category summary to an array for rendering
    const categoryArray = Object.entries(categorySummary).map(([category, data]) => {
        const topProductInCategory = Object.entries(data.products).reduce(
            (a, b) => (b[1].qty > a[1].qty ? b : a),
            ['', { qty: 0 }]
        );
        return {
            category,
            totalQty: data.totalQty,
            totalAmount: data.totalAmount,
            topProduct: `${topProductInCategory[0]} (${topProductInCategory[1].qty} units)`,
        };
    });

    return (
        <div className="report-table-container">
            <h3>Purchase Summary Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
            </div>
            <div className="report-header">
                <p>Supplier: {selectedSupplier ? getSupplierName(selectedSupplier, suppliers) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '5%'}}>#</th>
                        <th style={{width: '15%'}}>Total Amount</th>
                        <th style={{width: '15%'}}>Avg. Value</th>
                        <th style={{width: '10%'}}>Total Qty</th>
                        <th style={{width: '20%'}}>Top Supplier</th>
                        <th style={{width: '35%'}}>Top Product</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{width: '5%'}}>{filteredTransactions.length}</td>
                        <td style={{width: '15%'}}>₱{filteredTransactions.reduce((sum, t) => sum + t.purTotal, 0).toFixed(2)}</td>
                        <td style={{width: '15%'}}>₱{(filteredTransactions.reduce((sum, t) => sum + t.purTotal, 0) / (filteredTransactions.length || 1)).toFixed(2)}</td>
                        <td style={{width: '10%'}}>{totalProductsPurchased}</td>
                        <td style={{ width: '20%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{topSupplier}</td>
                        <td style={{ width: '35%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{`${topProduct[0]} (${topProduct[1]} units)`}</td>
                    </tr>
                </tbody>
            </table>

            <h3 style={{ marginTop: '50px' }}>Category Summary</h3>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '30%'}}>Category</th>
                        <th style={{width: '15%'}}>Total Qty Purchased</th>
                        <th style={{width: '15%'}}>Total Product Value</th>
                        <th style={{width: '40%'}}>Top Product</th>
                    </tr>
                </thead>
                <tbody>
                    {categoryArray.length > 0 ? (
                        categoryArray.map(({ category, totalQty, totalAmount, topProduct }) => (
                            <tr key={category}>
                                <td style={{ width: '30%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{category}</td>
                                <td style={{width: '15%'}}>{totalQty}</td>
                                <td style={{width: '15%'}}>₱{totalAmount.toFixed(2)}</td>
                                <td style={{ width: '40%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{topProduct}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>
                                No category data available for the selected period.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// 2. Purchase by Supplier Report
export const PurchaseBySupplierReport = ({
    transactions,
    suppliers,
    startDate,
    endDate,
    selectedSupplier,
    selectedStatus
}) => {
    // Filter transactions within the selected date range
    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.purDate);
        return transactionDate >= startDate && transactionDate <= endDate;
    });

    // Apply status filter and supplier filter
    let statusFilteredTransactions = filteredTransactions.filter((transaction) => {
        // Filter by selectedStatus if it's not 'all'
        if (selectedStatus && selectedStatus !== 'all') {
            return transaction.invoiceStatus === selectedStatus;
        }
        return true;
    });

    const supplierFilteredTransactions = statusFilteredTransactions.filter((transaction) => {
        // Filter by selectedSupplier if it's not 'all'
        if (selectedSupplier && selectedSupplier !== 'all') {
            return transaction.supplier?.supID === selectedSupplier;
        }
        return true;
    });

    // Debugging log to check filtered transactions after status and supplier filter
    console.log('Status and Supplier Filtered Transactions:', supplierFilteredTransactions);

    // Initialize aggregated data with all suppliers (whether or not they have transactions)
    const aggregatedBySupplier = suppliers.reduce((acc, supplier) => {
        acc[supplier.supID] = { 
            supplierName: supplier.supName, 
            totalAmount: 0, 
            transactionCount: 0 
        };
        return acc;
    }, {});

    // Process each filtered transaction and aggregate by supplier
    supplierFilteredTransactions.forEach((transaction) => {
        const supplierID = transaction.supplier?.supID;

        // Debugging: Check transaction supplier ID
        console.log(`Processing Transaction ID: ${transaction.purTransNo}, Supplier ID: ${supplierID}`);

        // Ensure supplier ID exists before processing
        if (!supplierID) {
            console.warn(`Transaction ID ${transaction.purTransNo} does not have a valid supplier ID (sup_id_fk).`);
            return; // Skip if no supplier ID is available
        }

        // Find supplier by supID
        const supplier = suppliers.find(s => s.supID === supplierID);
        
        // If supplier exists, update totals
        if (supplier) {
            aggregatedBySupplier[supplierID].totalAmount += transaction.purTotal;
            aggregatedBySupplier[supplierID].transactionCount += 1;
        } else {
            console.warn(`Supplier not found for Transaction ID: ${transaction.purTransNo} with Supplier ID: ${supplierID}`);
        }
    });

    // Display **all suppliers** by default, including those with no transactions
    // If the filter is applied and it's not "all", we exclude suppliers without transactions
    let supplierArray = Object.values(aggregatedBySupplier).filter(supplier => {
        // Only show suppliers with transactions if a specific status or supplier filter is applied
        if ((selectedSupplier !== 'all' || selectedStatus !== 'all') && supplier.transactionCount === 0) {
            return false; // Exclude suppliers with no transactions when a filter is applied
        }
        return true;
    });

    // Sort suppliers by totalAmount (descending order), then by transactionCount (descending)
    supplierArray = supplierArray.sort((a, b) => b.totalAmount - a.totalAmount || b.transactionCount - a.transactionCount);

    console.log('Sorted Supplier Array:', supplierArray);

    // Calculate the overall totals for the total row
    const totalTransactions = supplierArray.reduce((sum, item) => sum + item.transactionCount, 0);
    const totalAmount = supplierArray.reduce((sum, item) => sum + item.totalAmount, 0);

    return (
        <div className="report-table-container">
            <h3>Purchase by Supplier Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
            </div>
            <div className="report-header">
                <p>Supplier: {selectedSupplier ? getSupplierName(selectedSupplier, suppliers) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{width: '60%'}}>Supplier</th>
                        <th style={{width: '20%'}}>Total Transactions</th>
                        <th style={{width: '20%'}}>Total Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {supplierArray.length > 0 ? (
                        <>
                            {supplierArray.map(({ supplierName, totalAmount, transactionCount }) => (
                                <tr key={supplierName}>
                                    <td style={{ width: '60%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{supplierName}</td>
                                    <td style={{width: '20%'}}>{transactionCount}</td>
                                    <td style={{width: '20%'}}>₱{totalAmount.toFixed(2)}</td>
                                </tr>
                            ))}
                            {/* Total Row */}
                            <tr className="total-row">
                                <td style={{width: '60%'}}><strong>Total</strong></td>
                                <td style={{width: '20%'}}><strong>{totalTransactions}</strong></td>
                                <td style={{width: '20%'}}><strong>₱{totalAmount.toFixed(2)}</strong></td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td colSpan={3} style={{ textAlign: 'center' }}>
                                No purchase data available for the selected period.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Invoice Status Report
export const InvoiceStatusReport = ({ transactions, suppliers, startDate, endDate, users, selectedSupplier, selectedStatus, selectedUser }) => {
    // Filter transactions within the selected date range
    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.purDate);
        return transactionDate >= startDate && transactionDate <= endDate;
    });

    // Calculate the total purchase amount
    const totalPurchaseAmount = filteredTransactions.reduce((sum, transaction) => sum + transaction.purTotal, 0);

    return (
        <div className="report-table-container">
            <h3>Invoice Status Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>User: {selectedUser ? getUserName(selectedUser, users) : 'All'}</p>
            </div>
            <div className="report-header">
                <p>Supplier: {selectedSupplier ? getSupplierName(selectedSupplier, suppliers) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{ width: '20%'}}>Transaction No.</th>
                        <th style={{ width: '15%'}}>Purchase Date</th>
                        <th style={{ width: '30%'}}>Supplier</th>
                        <th style={{ width: '12%'}}>Receiver</th>
                        <th style={{ width: '10%'}}>Status</th>
                        <th style={{ width: '13%'}}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTransactions.length > 0 ? (
                        filteredTransactions.map((transaction) => {
                            const supplier = getSupplierName(transaction.supplier.supID, suppliers); // Accessing the correct supplier info
                            const receivedBy = getUserName(transaction.receivedBy, users);

                            return (
                                <tr key={transaction.purTransNo}>
                                    <td style={{ width: '20%'}}>{transaction.purTransNo}</td>
                                    <td style={{ width: '15%'}}>{new Date(transaction.purDate).toLocaleDateString()}</td>
                                    <td style={{ width: '30%'}}>{supplier}</td>
                                    <td style={{ width: '12%'}}>{receivedBy}</td>
                                    <td style={{ width: '10%'}}>{transaction.invoiceStatus}</td>
                                    <td style={{ width: '13%'}}>₱{transaction.purTotal.toFixed(2)}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={6} style={{ textAlign: 'center' }}>
                                No purchase transactions found for the selected period.
                            </td>
                        </tr>
                    )}
                    {filteredTransactions.length > 0 && (
                        <tr className="total-row">
                            <td colSpan={5} style={{ textAlign: 'left' }}><strong>Total Purchase Value:</strong></td>
                            <td style={{ width: '13%'}}><strong>₱{totalPurchaseAmount.toFixed(2)}</strong></td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export const TopProductsPurchasedReport = ({ transactions, startDate, endDate, suppliers, selectedSupplier, selectedStatus }) => {
    console.log('Transactions:', transactions); // Debugging log

    // Filter transactions within the date range
    const filteredTransactions = transactions.filter(transaction => {
        const transactionDate = new Date(transaction.purDate);
        return transactionDate >= startDate && transactionDate <= endDate;
    });

    console.log('Filtered Transactions:', filteredTransactions);

    // Flatten all line items from filtered transactions
    const lineItems = filteredTransactions.flatMap(transaction =>
        transaction.lineItems.map(item => ({
            prodNo: item.prodNo || 'Unknown',
            prodName: item.prodName || 'Unknown Product',
            qty: item.qty || 0,
            amount: item.amount || 0
        }))
    );

    console.log('Line Items:', lineItems); // Debugging log

    // Aggregate line items by product
    const productSummary = lineItems.reduce((acc, item) => {
        const { prodNo, prodName, qty, amount } = item;

        if (!acc[prodNo]) {
            acc[prodNo] = { prodName, totalQty: 0, totalAmount: 0 };
        }

        acc[prodNo].totalQty += qty;
        acc[prodNo].totalAmount += amount;

        return acc;
    }, {});

    // Convert aggregated data into an array and sort by total quantity in descending order
    const productArray = Object.values(productSummary).sort((a, b) => b.totalQty - a.totalQty);

    return (
        <div className="report-table-container">
            <h3>Top Products Purchased Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
            </div>
            <div className="report-header">
                <p>Supplier: {selectedSupplier ? getSupplierName(selectedSupplier, suppliers) : 'All'}</p>
                <p>Payment Status: {selectedStatus === 'all' ? 'All' : selectedStatus}</p>
            </div>
            {productArray.length > 0 ? (
                <table className="report-table">
                    <thead>
                        <tr>
                            <th style={{width: '60%'}}>Product</th>
                            <th style={{width: '20%'}}>Total Quantity</th>
                            <th style={{width: '20%'}}>Total Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productArray.map(({ prodName, totalQty, totalAmount }) => (
                            <tr key={prodName}>
                                <td style={{ width: '60%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{prodName}</td>
                                <td style={{width: '20%'}}>{totalQty}</td>
                                <td style={{width: '20%'}}>₱{totalAmount.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No product purchases found for the selected period.</p>
            )}
        </div>
    );
};


/* Payroll Reports */
export const PayrollSummaryReport = ({
    salaries, // Salary data from the API
    branches, // Branch data from the API
    startDate, // Start date filter
    endDate, // End date filter
    selectedBranch, // Branch filter
}) => {
    // Debugging log to verify input data
    console.log('Salaries:', salaries);
    console.log('Selected Branch:', selectedBranch);

    // Filter salaries by date range and branch
    const filteredSalaries = salaries.filter((salary) => {
        const paymentDate = new Date(salary.paymentDate);
        const isWithinDateRange = paymentDate >= startDate && paymentDate <= endDate;

        const isBranchMatch = selectedBranch === 'all' || salary.branch?.branchID === selectedBranch;

        // Debugging logs
        console.log('Filtering Salary:', salary.salaryID);
        console.log(' - Payment Date:', salary.paymentDate, 'Within Date Range:', isWithinDateRange);
        console.log(' - Branch ID:', salary.branch?.branchID, 'Matches Branch:', isBranchMatch);

        return isWithinDateRange && isBranchMatch;
    });

    console.log('Filtered Salaries:', filteredSalaries);

    // Aggregate data by branch
    const payrollSummary = branches.map((branch) => {
        const branchSalaries = filteredSalaries.filter(
            (salary) => salary.branch?.branchID === branch.branchID
        );

        const totalHoursWorked = branchSalaries.reduce(
            (sum, salary) => sum + (salary.totalHours || 0),
            0
        );

        const totalSalary = branchSalaries.reduce(
            (sum, salary) => sum + (salary.totalSalary || 0),
            0
        );

        return {
            branchID: branch.branchID,
            branchName: branch.branchName,
            employeeCount: branchSalaries.length, // Count of employees in this branch
            totalHoursWorked,
            totalSalary,
        };
    });

    console.log('Payroll Summary:', payrollSummary);

    // Calculate totals
    const totalEmployeeCount = payrollSummary.reduce((sum, row) => sum + row.employeeCount, 0);
    const totalHoursWorked = payrollSummary.reduce((sum, row) => sum + row.totalHoursWorked, 0);
    const totalSalary = payrollSummary.reduce((sum, row) => sum + row.totalSalary, 0);

    // Filter the branches displayed in the report
    const summaryArray = selectedBranch === 'all'
        ? payrollSummary
        : payrollSummary.filter((summary) => summary.branchID === selectedBranch);

    return (
        <div className="report-table-container">
            <h3>Payroll Summary Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>Branch: {selectedBranch === 'all' ? 'All' : branches.find((branch) => branch.branchID === selectedBranch)?.branchName}</p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th style={{ width: '40%' }}>Branch Name</th>
                        <th style={{ width: '20%' }}>Employee Count</th>
                        <th style={{ width: '20%' }}>Total Hours Worked</th>
                        <th style={{ width: '20%' }}>Total Salary (₱)</th>
                    </tr>
                </thead>
                <tbody>
                    {summaryArray.length > 0 ? (
                        <>
                            {summaryArray.map((row, index) => (
                                <tr key={index}>
                                    <td style={{ width: '40%', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.branchName}</td>
                                    <td style={{ width: '20%' }}>{row.employeeCount}</td>
                                    <td style={{ width: '20%' }}>{row.totalHoursWorked}</td>
                                    <td style={{ width: '20%' }}>₱{row.totalSalary.toFixed(2)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td style={{ width: '40%', textAlign: 'left', fontWeight: 'bold' }}>Total</td>
                                <td style={{ width: '20%', fontWeight: 'bold' }}>{totalEmployeeCount}</td>
                                <td style={{ width: '20%', fontWeight: 'bold' }}>{totalHoursWorked}</td>
                                <td style={{ width: '20%', fontWeight: 'bold' }}>₱{totalSalary.toFixed(2)}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>
                                No payroll data available for the selected period.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export const EmployeePayslipReport = ({
    salaries, // Consolidated salary data
    startDate, // Start date filter
    endDate, // End date filter
    selectedEmployee, // Employee filter
}) => {
    // Filter salaries by date range and selected employee
    const filteredSalaries = salaries.filter((salary) => {
        const paymentDate = new Date(salary.paymentDate);
        const isWithinDateRange = paymentDate >= startDate && paymentDate <= endDate;
        const isEmployeeMatch =
            selectedEmployee === 'all' || salary.employee?.empID === selectedEmployee;

        return isWithinDateRange && isEmployeeMatch;
    });

    // Map salary records to include employee details
    const employeePayslipRecords = filteredSalaries.map((salary) => ({
        employeeName: salary.employee?.empName || 'Unknown Employee',
        payPeriodStart: new Date(salary.payPeriodStart).toLocaleDateString(),
        payPeriodEnd: new Date(salary.payPeriodEnd).toLocaleDateString(),
        totalHours: salary.totalHours,
        totalSalary: salary.totalSalary,
    }));

    // Aggregate total hours and total salary
    const totalHours = employeePayslipRecords.reduce(
        (sum, record) => sum + record.totalHours,
        0
    );

    const totalSalary = employeePayslipRecords.reduce(
        (sum, record) => sum + record.totalSalary,
        0
    );

    return (
        <div className="report-table-container">
            <h3>Employee Payslip Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>
                    Employee: {selectedEmployee === 'all' ? 'All Employees' : employeePayslipRecords[0]?.employeeName || 'Unknown Employee'}
                </p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Employee Name</th>
                        <th>Pay Period Start</th>
                        <th>Pay Period End</th>
                        <th>Total Hours Worked</th>
                        <th>Total Salary (₱)</th>
                    </tr>
                </thead>
                <tbody>
                    {employeePayslipRecords.length > 0 ? (
                        <>
                            {employeePayslipRecords.map((record, index) => (
                                <tr key={index}>
                                    <td>{record.employeeName}</td>
                                    <td>{record.payPeriodStart}</td>
                                    <td>{record.payPeriodEnd}</td>
                                    <td>{record.totalHours}</td>
                                    <td>₱{record.totalSalary.toFixed(2)}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={3} style={{ textAlign: 'left', fontWeight: 'bold' }}>Total</td>
                                <td style={{ fontWeight: 'bold' }}>{totalHours}</td>
                                <td style={{ fontWeight: 'bold' }}>₱{totalSalary.toFixed(2)}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                No payslip data available for the selected period.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};



export const AttendanceReport = ({
    attendanceLogs, // Consolidated attendance logs
    employees, // Employee data
    startDate, // Start date filter
    endDate, // End date filter
    selectedEmployee, // Employee filter
}) => {
    // Filter attendance logs by date range and employee
    const filteredAttendanceLogs = attendanceLogs.filter((log) => {
        const logDate = new Date(log.attendanceDate);
        const isWithinDateRange = logDate >= startDate && logDate <= endDate;
        const isEmployeeMatch =
            selectedEmployee === 'all' || log.emp_id_fk === selectedEmployee;

        return isWithinDateRange && isEmployeeMatch;
    });

    console.log('Filtered Attendance Logs:', filteredAttendanceLogs);

    // Calculate total hours
    const totalHoursWorked = filteredAttendanceLogs.reduce(
        (sum, log) => sum + (log.noOfHours || 0),
        0
    );

    return (
        <div className="report-table-container">
            <h3>Attendance Report</h3>
            <div className="report-header">
                <p>Period: {`${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</p>
                <p>
                    Employee: {selectedEmployee === 'all' ? 'All' : getEmployeeName(selectedEmployee, employees)}
                </p>
            </div>
            <table className="report-table">
                <thead>
                    <tr>
                        <th>Employee Name</th>
                        <th>Attendance Date</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Total Hours</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAttendanceLogs.length > 0 ? (
                        <>
                            {filteredAttendanceLogs.map((log) => (
                                <tr key={log.attendanceID}>
                                    <td>{getEmployeeName(log.emp_id_fk, employees)}</td>
                                    <td>{new Date(log.attendanceDate).toLocaleDateString()}</td>
                                    <td>{log.timeIn ? new Date(log.timeIn).toLocaleTimeString() : 'N/A'}</td>
                                    <td>{log.timeOut ? new Date(log.timeOut).toLocaleTimeString() : 'N/A'}</td>
                                    <td>{log.noOfHours || 0}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'left', fontWeight: 'bold' }}>
                                    Total Hours Worked:
                                </td>
                                <td style={{ fontWeight: 'bold' }}>{totalHoursWorked}</td>
                            </tr>
                        </>
                    ) : (
                        <tr>
                            <td colSpan={5} style={{ textAlign: 'center' }}>
                                No attendance data available for the selected period.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};





