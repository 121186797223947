import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import SupplierModal from './SupplierModal';
import MessageModal from './MessageModal';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';

function Supplier() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [suppliers, setSuppliers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [showCloseButton, setShowCloseButton] = useState(false);
    const [supplierToDelete, setSupplierToDelete] = useState(null);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [deletionMessage, setDeletionMessage] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [activeTab, setActiveTab] = useState('suppliers');

    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch suppliers from MySQL database
        console.log('Fetching suppliers from API...');
        axios.get('https://www.capstonelimenerp.xyz:8082/api/suppliers')
            .then(response => {
                console.log('Supplier data fetched successfully:', response.data);
                setSuppliers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the user data!', error.response || error.message || error);
            });
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch users again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/suppliers')
                .then(response => {
                    console.log('Supplier data refreshed successfully:', response.data);
                    setSuppliers(response.data);
                    setRefreshTable(false);  // Reset the refreshTable state
                })
                .catch(error => {
                    console.error('There was an error refreshing the supplier data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleModuleClick = (module) => {
        setExpandedModule(expandedModule === module ? null : module);
    };

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddSupplier = () => {
        console.log('Add Supplier button clicked');
        setSelectedSupplier(null); // Clear any selected user
        setIsModalOpen(true);
    };

    const handleEditSupplier = (supplier) => {
        setSelectedSupplier(supplier); // Set the user to be edited
        setIsModalOpen(true);
    };

    const confirmDeleteSupplier = (supID) => {
        console.log("Delete button clicked for supplier ID:", supID);
        setSupplierToDelete(supID);
        setMessage('Are you sure you want to delete this supplier?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };

    const handleDeleteSupplier = (supID) => {
        if (supplierToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/suppliers/${supplierToDelete}`)
                .then(() => {
                    setSuppliers(suppliers.filter(supplier => supplier.supID !== supplierToDelete));
                    setMessage('Supplier has been successfully deleted.');
                    setDeletionConfirmed(true); // This should trigger the close button
                })
                .catch(error => {
                    console.error('There was an error deleting the supplier!', error);
                    setMessage('Error deleting supplier');
                    setDeletionConfirmed(true);
                });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        console.log(isEditing ? 'Editing supplier' : 'Adding supplier');
        setIsModalOpen(false);
    
        const successMessage = isEditing ? 'Supplier updated successfully!' : 'Supplier added successfully!';
        setMessage(successMessage);
    
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
        setIsConfirmation(false);
        setRefreshTable(true);
    };
    

    // Filter and search logic
    const filteredSupplier = suppliers
        .filter(supplier => 
            (filter === 'all' || supplier.supName === filter) &&
            (supplier.supName || '').toLowerCase().includes(searchQuery.toLowerCase())
        );

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedSuppliers = filteredSupplier.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredSupplier.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <SupplierModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        supplier={selectedSupplier}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteSupplier(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <Link to="/supplier/information" className={activeTab === 'suppliers' ? 'active' : ''} onClick={() => setActiveTab('users')}>
                            Supplier Information
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search supplier name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            
                            <button className="action-button" onClick={handleAddSupplier}>Add Supplier</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Contact No.</th>
                                    <th>Contact Person</th>
                                    <th>Address</th>
                                    <th>Description</th>
                                    <th style={{width: '100px'}}>Actions</th> {/* New Actions Column */} 
                                </tr>
                            </thead>
                            <tbody>
                                {displayedSuppliers.length > 0 ? (
                                    displayedSuppliers.map((supplier,index) => (
                                        <tr key={supplier.supID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{supplier.supID}</td>
                                            <td>{supplier.supName}</td>
                                            <td>{supplier.supContNo}</td>
                                            <td>{supplier.supPerson}</td>
                                            <td>{supplier.supAddr}</td>
                                            <td>{supplier.supDesc}</td>
                                            <td style={{width: '100px'}}>
                                                <button className="edit-button" onClick={() => handleEditSupplier(supplier)}>
                                                    <FaEdit />
                                                </button>
                                                <button className="delete-button" onClick={() => confirmDeleteSupplier(supplier.supID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">No suppliers found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            
            </SideNavigation>
            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default Supplier;