import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaTimes } from 'react-icons/fa';
import CustomerModal from './CustomerModal'; 
import './SalesCustomer.css'; 
import DiscountModal from './DiscountModal';
import MessageModal from './MessageModal';
import { useCart } from './CartContext'; // Import useCart from CartContext

function SalesCustomer() {
    const { cartItems } = useCart(); // Access cart items from context
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]); 
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

    const [message, setMessage] = useState(''); // For storing success or error messages
    const [showCloseButton, setShowCloseButton] = useState(false); // Controls visibility of the close button in the modal
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false); // Controls visibility of the message modal
    const [isConfirmation, setIsConfirmation] = useState(false);

    // Discount and Total State
    const [originalTotalDue, setOriginalTotalDue] = useState(0);
    const [discountData, setDiscountData] = useState({ discount: 0, isPercentage: false });
    const [discountedTotal, setDiscountedTotal] = useState(0);

    const searchContainerRef = useRef(null);
    const navigate = useNavigate();

    const fetchCustomers = async () => {
        try {
            const response = await fetch('https://www.capstonelimenerp.xyz:8082/api/regularcustomers');
            const data = await response.json();
            setCustomers(data);
            setFilteredCustomers(data);
        } catch (error) {
            console.error("Error fetching customers:", error);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setSearchResults([]); // Clear search results
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Load previously selected customer from localStorage if it exists
        const storedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));
        const savedDiscountData = JSON.parse(localStorage.getItem('discountData')) || { discount: 0, isPercentage: false };
        const savedDiscountedTotal = parseFloat(localStorage.getItem('discountedTotal')) || calculateTotal();

        if (storedCustomer) {
            setSelectedCustomer(storedCustomer);
            setSearchTerm(`${storedCustomer.custFName} ${storedCustomer.custLName}`); // Display in search bar
        }

        // Fetch customers on component mount
        fetchCustomers();

        // Set initial totals and discount from localStorage
        setDiscountData(savedDiscountData);
        setDiscountedTotal(savedDiscountedTotal);
        setOriginalTotalDue(calculateTotal());
    }, [cartItems]);

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        // Filter customers based on search term
        const filtered = customers.filter(customer => {
            const fullName = `${customer.custFName} ${customer.custLName}`.toLowerCase();
            return fullName.includes(term.toLowerCase());
        });
        
        setSearchResults(filtered);
        
        // Clear selectedCustomer if the search changes without confirmation
        setSelectedCustomer(null);
    };

    // Handle selecting a customer from search results
    const handleSelectCustomer = (customer) => {
        if (customer) { // Ensure the customer is defined
            setSelectedCustomer(customer); // Set the selected customer
            setSearchTerm(`${customer.custFName} ${customer.custLName}`); // Display selected name in search bar
            setSearchResults([]); // Clear search results after selection
        }
    };

    // Ensure customer is set only on button click
    const confirmCustomerSelection = () => {
        if (selectedCustomer) {
            localStorage.setItem('selectedCustomer', JSON.stringify(selectedCustomer)); // Store customer in localStorage
            navigate('/sales/new');
        } else {
            localStorage.removeItem('selectedCustomer'); // Clear if no customer is selected
            navigate('/sales/new');
        }
    };

    const calculateTotal = () => {
        return cartItems.reduce((acc, item) => acc + (item.prodPrice || 0) * (item.quantity || 0), 0);
    };

    const calculateDiscountedTotal = () => {
        const discountAmount = discountData.isPercentage 
            ? originalTotalDue * (discountData.discount / 100)
            : discountData.discount;
        return originalTotalDue - discountAmount;
    };


    useEffect(() => {
        setDiscountedTotal(calculateDiscountedTotal());
    }, [discountData]);

    const handleSaveDiscount = (data) => {
        setDiscountData(data);
        const newDiscountedTotal = calculateDiscountedTotal();
        setDiscountedTotal(newDiscountedTotal);
        
        // Save discount data to localStorage
        localStorage.setItem('discountData', JSON.stringify(data));
        localStorage.setItem('discountedTotal', newDiscountedTotal);
    };

    const handleOpenDiscountModal = () => {
        setIsDiscountModalOpen(true);
    };

    const handleCloseDiscountModal = () => {
        setIsDiscountModalOpen(false);
    };



    // Function to handle opening the CustomerModal
    const handleAddCustomer = () => {
        setIsCustomerModalOpen(true);
    };
    

    const handleModalSubmit = (data, isEditing) => {
        setIsCustomerModalOpen(false);
        const successMessage = isEditing ? 'Customer updated successfully!' : 'Customer added successfully!';
        setMessage(successMessage);
        setShowCloseButton(true);
        setIsMessageModalOpen(true);
        setIsConfirmation(false);
        // Refetch customers after adding or updating a customer
        fetchCustomers();
    };
    
    

    // Ensure onClose correctly sets this modal state to false
    const handleCloseCustomerModal = () => {
        setIsCustomerModalOpen(false); // Set to false to close the modal
    };

    const resetCustomerSelection = () => {
        setSelectedCustomer(null);
        setSearchTerm('');
        localStorage.removeItem('selectedCustomer');
    };
    
    const handleClearCustomer = () => {
        setSelectedCustomer(null);
        setSearchTerm('');
        localStorage.removeItem('selectedCustomer');
    };

    // Handles closing the cart
    function handleClose() {
        const empPosition = localStorage.getItem('empPosition'); // Retrieve empPosition from localStorage
        const userType = localStorage.getItem('userType');

        if (empPosition === 'Cashier') {
            navigate('/'); // Navigate to home if the user is a cashier
        } else if (userType === 'Admin') {
            navigate('/inventory/products'); // Navigate to products page if the user is an admin
        } else if (empPosition === 'Salesperson') {
            navigate('/inventory/products'); // Salesperson navigates to products page
        } else if (empPosition === 'Purchase Officer') {
            navigate('/purchase/order'); // Purchase Officer navigates to purchase order page
        } else if (empPosition === 'Branch Manager') {
            navigate('/dashboard'); // Branch Manager navigates to dashboard
        }
    }

    return (
        <div className="sales-cart-container">
            <div className="sales-tab-container">
                <div className="sales-tab-navigation">
                    <button 
                        className="tab" 
                        onClick={confirmCustomerSelection} // Redirects to checkout
                    >
                        Checkout
                    </button>
                    <button 
                        className="tab active" 
                        onClick={() => navigate('/sales/customer')}
                    >
                        Customer
                    </button>
                    <button 
                        className="tab" 
                        onClick={handleOpenDiscountModal} 
                    >
                        Discount
                    </button>
                </div>
                <button className="close-button" onClick={handleClose}>
                    <FaTimes />
                </button>
            </div>

            <div className="product-details-container" ref={searchContainerRef}>
                <div className="product-search-bar">
                    <input 
                        type="text" 
                        placeholder="Search customer by name" 
                        value={searchTerm} 
                        onChange={handleSearchChange} 
                        onFocus={() => searchResults.length > 0 && setSearchResults(searchResults)} // Show results if focus returns to input
                    />
                    {/* Display search results dropdown */}
                    {searchResults.length > 0 && (
                        <div className="search-results-dropdown">
                            {searchResults.map(customer => (
                                <div
                                    key={customer.custNo}
                                    onClick={() => handleSelectCustomer(customer)} // Pass the customer directly
                                    className="search-result-item"
                                >
                                    <span>{customer.custFName} {customer.custLName}</span>
                                    <span className="email">{customer.custEmail}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="customer-button-container">
                    <div className="customer-action-button">
                        <button 
                            className="select-button" 
                            onClick={confirmCustomerSelection} 
                            disabled={!selectedCustomer} // Disable if no customer is selected
                        >
                            Select
                        </button>
                    </div>
                    <div className="customer-action-button">
                        <button className="clear-button" onClick={handleClearCustomer}>
                            Clear
                        </button>
                    </div>
                    <div className="customer-action-button">
                        <button className="customer-tab-button" onClick={handleAddCustomer}>
                            Add Customer
                        </button>
                    </div>
                </div>
            </div>

            {/* Render the CustomerModal if showModal is true */}
            {showModal && (
                <CustomerModal onClose={() => setShowModal(false)} />
            )}
            {isDiscountModalOpen && (
                <DiscountModal
                    subtotal={originalTotalDue} // Use the discounted total
                    onClose={handleCloseDiscountModal}
                    onSave={handleSaveDiscount}
                    discount={discountData.discount} // Pass saved discount value
                    isPercentage={discountData.isPercentage} // Pass percentage mode
                />
            )}
            {isCustomerModalOpen && (
                <CustomerModal
                    isOpen={isCustomerModalOpen}
                    onClose={() => setIsCustomerModalOpen(false)}
                    onSubmit={handleModalSubmit}
                />
            )}
            {isMessageModalOpen && (
                <MessageModal
                    isOpen={isMessageModalOpen}
                    onClose={() => setIsMessageModalOpen(false)}
                    message={message}
                    showCloseButton={showCloseButton}
                />
            )}
        </div>
    );
}

export default SalesCustomer;
