import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InventoryLogModal.css';
import MessageModal from './MessageModal';

function DiscrepancyLogModal({ isOpen, onClose, transfer }) {
    const [logData, setLogData] = useState([]);
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);

    useEffect(() => {
        if (isOpen && transfer?.stkTransNo) {
            const fetchLogData = async () => {
                try {
                    const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/discrepancylogs/${transfer.stkTransNo}`);
                    setLogData(response.data);
                } catch (error) {
                    console.error('Error fetching discrepancy logs:', error);
                    setMessage('Error fetching discrepancy logs.');
                    setMessageModalOpen(true);
                }
            };

            fetchLogData();
        } else {
            setLogData([]);
        }
    }, [isOpen, transfer?.stkTransNo]);

    if (!isOpen) return null;

    return (
        <div className="log-modal-overlay">
            <div className="log-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>Discrepancy Log - Transfer {transfer?.stkTransNo || 'N/A'}</h2>
                <div className="log-table-section">
                    <table className="log-table">
                        <thead>
                            <tr>
                                <th>Log No</th>
                                <th>Product</th>
                                <th>Expected Qty</th>
                                <th>Actual Qty</th>
                                <th>Missing Qty</th>
                                <th>Reason</th>
                                <th>Recorded By</th>
                                <th>Date & Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logData.length > 0 ? (
                                logData.map((log, index) => (
                                    <tr key={index}>
                                        <td>{log.discLogID}</td>
                                        <td>{log.prodName || 'Unknown Product'}</td>
                                        <td>{log.expectedQty}</td>
                                        <td>{log.actualQty}</td>
                                        <td>{log.missingQty}</td>
                                        <td>{log.reason}</td>
                                        <td>{log.recordedBy}</td>
                                        <td>{new Date(log.loggedDateTime).toLocaleString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center' }}>No discrepancies found for this transfer.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <MessageModal
                    isOpen={messageModalOpen}
                    onClose={() => setMessageModalOpen(false)}
                    message={message}
                />
            </div>
        </div>
    );
}

export default DiscrepancyLogModal;
