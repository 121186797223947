import React, { useState, useEffect } from 'react';
import './CustomerPaymentModal.css'; // Reuse the same styling
import axios from 'axios';
import MessageModal from './MessageModal';

function InvoicePaymentModal({ isOpen, purTransNo, onClose, refreshParent }) {

    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentType, setPaymentType] = useState('Cash');
    const [externalDocument, setExternalDocument] = useState('');
    const [outstandingBalance, setOutstandingBalance] = useState(0);
    const [previousPayments, setPreviousPayments] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

    const storedUserID = localStorage.getItem('userID');

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            try {
                const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/invoice-payments/${purTransNo}`);
                setOutstandingBalance(response.data.outstandingBalance || 0);
                setPreviousPayments(response.data.previousPayments || []); // Use previousPayments from API
            } catch (error) {
                console.error("Error fetching payment details:", error);
            }
        };
        
    
        if (isOpen) fetchPaymentDetails();
    }, [isOpen, purTransNo]);

    const handlePaymentSubmit = async () => {
        try {
            // Check if payment amount exceeds outstanding balance
            if (paymentAmount > outstandingBalance) {
                setErrorMessage("Payment amount exceeds the remaining balance.");
                setIsMessageModalOpen(true);
                return;
            }

            // Ensure user is logged in
            if (!storedUserID) {
                setErrorMessage("User is not logged in. Please log in first.");
                setIsMessageModalOpen(true);
                return;
            }

            // Ensure externalDocument is filled for specific payment types
            if ((paymentType === 'Cheque' || paymentType === 'Bank Transfer' || paymentType === 'Ewallet') && !externalDocument) {
                setErrorMessage("External Document is required for Ewallet, Cheque, or Bank Transfer.");
                setIsMessageModalOpen(true);
                return;
            }

            const response = await axios.post('https://www.capstonelimenerp.xyz:8082/api/invoice-payments', {
                purTransNo,
                paymentAmount,
                paymentType,
                externalDocument,
                handledBy: storedUserID,
            });

            console.log(response.data);

            if (response.data.remainingBalance === 0) {
                setErrorMessage("Payment recorded successfully. The invoice is now fully paid.");
            } else {
                setErrorMessage(`Payment recorded successfully. Remaining balance is ₱${response.data.remainingBalance.toFixed(2)}`);
            }

            setIsMessageModalOpen(true);
        } catch (error) {
            console.error('Error recording payment:', error.response?.data);

            if (error.response?.data?.error) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage("Error: " + error.message);
            }

            setIsMessageModalOpen(true);
        }
    };

    const handleCloseMessageModal = () => {
        setIsMessageModalOpen(false);

        if (typeof refreshParent === 'function') {
            console.log("Refreshing parent before closing modal...");
            refreshParent();
        }
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="customer-payment-modal-overlay">
            <div className="customer-payment-modal-content">
                <button className="customer-payment-modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section" style={{ width: '60%' }}>
                        <h3 style={{ textAlign: 'center' }}>Previous Payments</h3>
                        <div className="previous-payments-section">
                            <table className="previous-payments-table">
                                <thead>
                                    <tr>
                                        <th>Date and Time</th>
                                        <th style={{ textAlign: 'center' }}>Amount</th>
                                        <th style={{ textAlign: 'center' }}>Type</th>
                                        <th style={{ textAlign: 'center' }}>Balance</th>
                                        <th style={{ textAlign: 'center' }}>External Document</th>
                                        <th style={{ textAlign: 'center' }}>Handled By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previousPayments.length > 0 ? (
                                        previousPayments.map((payment, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: 'left' }}>{new Date(payment.paymentDate).toLocaleString()}</td>
                                                <td>₱{(payment.paymentAmount || 0).toFixed(2)}</td>
                                                <td>{payment.paymentType || 'N/A'}</td>
                                                <td>₱{(payment.outstandingBalance || 0).toFixed(2)}</td>
                                                <td>{payment.externalDocument || 'None'}</td>
                                                <td>{payment.handledByName  || 'Unknown User'}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: 'center' }}>No previous payments found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="right-section" style={{ width: '40%' }}>
                        <h2>Make Payment</h2>

                        <div className="form-group">
                            <label htmlFor="outstandingBalance"><strong>Outstanding Balance:</strong></label>
                            <input
                                type="text"
                                name="outstandingBalance"
                                value={`₱${(outstandingBalance || 0).toFixed(2)}`}
                                disabled
                                className="input-disabled"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="paymentAmount"><strong>Payment Amount:</strong></label>
                            <input
                                type="number"
                                name="paymentAmount"
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                                min="0"
                                placeholder="Enter amount"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="paymentType"><strong>Payment Type:</strong></label>
                            <select name="paymentType" value={paymentType} onChange={(e) => setPaymentType(e.target.value)}>
                                <option value="Cash">Cash</option>
                                <option value="Ewallet">E-Wallet</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                            </select>
                        </div>

                        {(paymentType === 'Cheque' || paymentType === 'Bank Transfer' || paymentType === 'Ewallet') && (
                            <div className="form-group">
                                <label htmlFor="externalDocument"><strong>External Document:</strong></label>
                                <input
                                    type="text"
                                    name="externalDocument"
                                    value={externalDocument}
                                    onChange={(e) => setExternalDocument(e.target.value)}
                                    placeholder="Enter external document (e.g., receipt number)"
                                />
                            </div>
                        )}

                        {outstandingBalance > 0 && (
                            <button
                                type="button"
                                className="submit-button"
                                onClick={handlePaymentSubmit}
                            >
                                Record Payment
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <MessageModal
                isOpen={isMessageModalOpen}
                onClose={handleCloseMessageModal}
                message={errorMessage}
                isConfirmation={false}
            />
        </div>
    );
}

export default InvoicePaymentModal;
