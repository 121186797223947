import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEye } from 'react-icons/fa';
import Pagination from './Pagination';
import SalesHistoryModal from './SalesHistoryModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';

function SalesHistory() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [salesData, setSalesData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [filter, setFilter] = useState('all');
    const [refreshTable, setRefreshTable] = useState(false);

    const [activeTab, setActiveTab] = useState('sales');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    const navigate = useNavigate();

    const handleNewSaleClick = () => {
        navigate('/sales/new'); // Navigate to the New Sale page
    };
    
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
        
        if (storedUsername) setUsername(storedUsername);
        if (storedBranchName) setBranchName(storedBranchName);
        if (storedBranchID) setBranchID(storedBranchID);
        else navigate('/'); // Redirect to login if no branch found

        const fetchData = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/sales');
                console.log('API Response:', response.data);  // Log the response to verify structure

                const mappedData = response.data.map(sale => {
                    const isRefunded = sale.paymentStatus === 'Refunded';
                    const custName = sale.cust_No_fk && sale.cust_No_fk.startsWith('CUST') && sale.custName
                        ? sale.custName
                        : 'Walk-In';
        
                    return {
                        ...sale,
                        custName,
                        userName: sale.userName || 'Unknown User',
                        branchName: sale.branchName || 'Unknown Branch',
                        paymentType: sale.paymentType || 'Unknown',
                        isRefunded,
                        transactionType: sale.transactionType || 'Unknown', // Map transactionType
                        externalDocument: sale.externalDocument || 'N/A' // Map externalDocument
                    };
                });
                
                // Sort by dateOfSale in descending order (latest date first)
                mappedData.sort((a, b) => new Date(b.dateOfSale) - new Date(a.dateOfSale));
                
                setSalesData(mappedData);  // Update the state with mapped data
            } catch (error) {
                console.error('Error fetching sales data:', error);
            }
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            axios.get('https://www.capstonelimenerp.xyz:8082/api/sales')
                .then(response => {
                    console.log('API Response:', response.data);  // Log the response to verify structure

                    const mappedData = response.data.map(sale => {
                        const isRefunded = sale.transactionType === 'Refund'; // Determine if refunded
                        const custName = sale.cust_No_fk && sale.cust_No_fk.startsWith('CUST') && sale.custName
                            ? sale.custName
                            : 'Walk-In';
    
                        return {
                            ...sale,
                            custName, // Correctly mapped customer name
                            userName: sale.userName || 'Unknown User', // Handle missing user names
                            branchName: sale.branchName || 'Unknown Branch', // Handle missing branch names
                            paymentType: sale.paymentType || 'Unknown', // Handle missing payment types
                            isRefunded, // Add refunded flag
                            transactionType: sale.transactionType || 'Unknown', // Map transaction type
                            externalDocument: sale.externalDocument || 'N/A', // Map external document
                        };
                    });
        
                    setSalesData(mappedData);  // Update the state with mapped data
                    setRefreshTable(false);
                })
                .catch(error => {
                    console.error('Error refreshing sales data:', error);
                });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleViewSale = (sale) => {
        setSelectedSale(sale);
        axios.get(`https://www.capstonelimenerp.xyz:8082/api/sales/${sale.oRNo}/lineitems`)
            .then(response => {
                setLineItems(response.data);
                setIsModalOpen(true);
            })
            .catch(error => {
                console.error('Error fetching line items:', error);
            });
    };


    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredSales = salesData.filter((sale) => {
        const lowerQuery = searchQuery.toLowerCase();
    
        // Filter by search query (customer name, branch name, or username)
        const matchesSearchQuery = 
            sale.custName.toLowerCase().includes(lowerQuery) || 
            sale.branchName.toLowerCase().includes(lowerQuery) || 
            sale.userName.toLowerCase().includes(lowerQuery); // Changed from empFName and empLName to userName
    
        // Filter by payment status (Pending, Paid, or All)
        const matchesStatus = filter === 'all' || sale.paymentStatus === filter;
    
        // Return true if both conditions are met
        return matchesSearchQuery && matchesStatus;
    });
    

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedSales = filteredSales.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredSales.length / recordsPerPage);


    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <SalesHistoryModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        lineItems={lineItems}
                        sale={selectedSale}
                    />
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <Link to="/sales/history" className={activeTab === 'sales' ? 'active' : ''} onClick={() => setActiveTab('sales')}>
                            Sales History
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search by branch, customer, or username"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Paid">Paid</option>
                                <option value="Refunded">Refunded</option>
                            </select>
                            <button className="action-button" onClick={handleNewSaleClick}>New Sale</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{ width: '3%' }}>#</th>
                                    <th style={{ width: '10%' }}>Transaction No.</th>
                                    <th style={{ width: '7%' }}>Date of Sale</th>
                                    <th style={{ width: '20%' }}>Branch</th>
                                    <th style={{ width: '10%' }}>Customer</th>
                                    <th style={{ width: '10%' }}>Handled By</th>
                                    <th style={{ width: '10%' }}>Total Amount</th>
                                    <th style={{ width: '5%' }}>Payment</th>
                                    <th style={{ width: '5%' }}>Status</th>
                                    <th style={{ width: '5%' }}>Type</th>
                                    <th style={{ width: '10%' }}>External Document</th>
                                    <th style={{ width: '5%' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedSales.length > 0 ? (
                                    displayedSales.map((sale, index) => (
                                        <tr key={sale.oRNo} className={sale.isRefunded ? 'refunded-row' : ''}>
                                            <td style={{ width: '3%' }}>{index + 1 + indexOfFirstRecord}</td>
                                            <td style={{ width: '10%' }}>{sale.oRNo}</td>
                                            <td style={{ width: '7%' }}>{new Date(sale.dateOfSale).toLocaleDateString()}</td>
                                            <td style={{ width: '20%' }}>{sale.branchName}</td>
                                            <td style={{ width: '10%' }}>{sale.custName}</td>
                                            <td style={{ width: '10%' }}>{sale.userName}</td>
                                            <td style={{ width: '10%' }}>Php {sale.grandTotal.toFixed(2)}</td>
                                            <td style={{ width: '5%' }}>{sale.paymentType}</td>
                                            <td style={{ width: '5%' }}>{sale.paymentStatus}</td>
                                            <td style={{ width: '5%' }}>{sale.transactionType}</td>
                                            <td style={{ width: '10%' }}>{sale.externalDocument}</td>
                                            <td style={{ width: '5%' }}>
                                                <button className="view-button" onClick={() => handleViewSale(sale)}>
                                                    <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="12" style={{ textAlign: 'center' }}>No sales records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </SideNavigation>
            <BottomContainer
                username={username}
                branchID={branchID}
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default SalesHistory;
 