import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StockTransferModal.css';
import './StylesModal.css';
import MessageModal from './MessageModal';
import DiscrepancyLogModal from './DiscrepancyLogModal';
import { FaTrash} from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


function StockTransferModal({ isOpen, onClose, onSubmit, lineItems, transfer, isViewMode }) {
    const [formData, setFormData] = useState({
        fromBranchID: '',
        toBranchID: '',
        stkTransDate: '',
        transferStatus: 'Pending',
        senderEmpID: '',
        receiverEmpID: '',
        lineItems: []
    });
    const [branches, setBranches] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [stockRequests, setStockRequests] = useState([]);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [suggestionsVisible, setSuggestionsVisible] = useState({});
    const [activeIndex, setActiveIndex] = useState(null); // Active index for which line item is active
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [isToBranchEditable, setIsToBranchEditable] = useState(true); // State to manage To Branch's editability
    const loggedInUsername = localStorage.getItem('username');

    const [isDiscrepancyModalOpen, setDiscrepancyModalOpen] = useState(false); // State for opening/closing the modal

    

    useEffect(() => {
        if (isViewMode || !isOpen) {
            setActiveIndex(null);
            setSuggestionsVisible({});
            setSuggestedProducts([]);
        }
    }, [isViewMode, isOpen]);

    useEffect(() => {
        
        const fetchBranchesAndStockRequests = async () => {
            try {
                const [branchesResponse, stockRequestsResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/stockrequests')  // Fetch stock requests
                ]);
                
                setBranches(branchesResponse.data);

                // Filter stock requests to only include Pending or Partially Fulfilled requests
                const filteredRequests = stockRequestsResponse.data.filter(
                    (request) =>
                        (request.requestStatus === 'Pending' || request.requestStatus === 'Partially Fulfilled') &&
                        request.ordRequester !== formData.fromBranchID // Exclude same-branch requests
                );
                setStockRequests(filteredRequests);
            } catch (error) {
                console.error('Error fetching branches or stock requests:', error);
            }
        };

        const fetchInitialData = async () => {
            const loggedInUsername = localStorage.getItem('username'); // Get username
            const loggedInUserID = localStorage.getItem('userID'); // Get user ID
            const loggedInBranchID = localStorage.getItem('branchID');  // Get branchID from localStorage
            const loggedInBranchName = localStorage.getItem('branchName');  // Get branch name from localStorage
            
            if (transfer && isViewMode) {
                setFormData({
                    fromBranchID: transfer.fromBranch || loggedInBranchID,
                    toBranchID: transfer.toBranch || '',
                    stkTransDate: new Date(transfer.stkTransDate).toISOString().split('T')[0],
                    transferStatus: transfer.transferStatus || 'Pending',
                    senderEmpID: transfer.senderEmpID || '',
                    receiverEmpID: transfer.receiverEmpID || '',
                    referenceOrder: transfer.referenceOrder || '',
                    lineItems: lineItems
                });
            } else {
                setFormData({
                    fromBranchID: loggedInBranchID,
                    toBranchID: '',
                    stkTransDate: new Date().toISOString().split('T')[0],
                    transferStatus: 'Pending',
                    senderEmpID: loggedInUserID,  // Auto-fill logged-in user as sender
                    receiverEmpID: '',
                    referenceOrder: '',
                    lineItems: []
                });
            }
        };

        fetchBranchesAndStockRequests();
        fetchInitialData();
    }, [transfer, lineItems, isViewMode, loggedInUsername]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleReferenceOrderChange = (e) => {
        const selectedReferenceOrder = e.target.value;
    
        // Update the formData with the selected referenceOrder
        setFormData((prevState) => ({
            ...prevState,
            referenceOrder: selectedReferenceOrder
        }));
    
        // Fetch stock request details for the selected referenceOrder
        if (selectedReferenceOrder) {
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/stockrequest/${selectedReferenceOrder}`)
                .then((response) => {
                    const stockRequest = response.data;
                    
                    // Check if the requester branch is the same as the current branch
                    if (stockRequest.ordRequester === formData.fromBranchID) {
                        setMessage('Cannot select a stock request from the same branch.');
                        setMessageModalOpen(true);
                        return;
                    }

                    // Automatically update the To Branch (toBranchID) with the ordRequester
                    setFormData((prevState) => ({
                        ...prevState,
                        toBranchID: stockRequest.ordRequester  // Automatically set To Branch
                    }));
                    setIsToBranchEditable(false);  // Make To Branch read-only
                })
                .catch((error) => {
                    console.error('Error fetching stock request:', error);
                    setMessage('Error fetching stock request.');
                    setMessageModalOpen(true);
                });
        } else {
            setIsToBranchEditable(true);  // Make To Branch editable again if no referenceOrder is selected
        }
    };
    

    const handleRefresh = (index) => {
        const prodNo = formData.lineItems[index].prodNo;
        const prodName = formData.lineItems[index].selectedProductName;

        setActiveIndex(null); // Hide suggestion box immediately
        setSuggestedProducts([]); // Clear any existing suggestions

        if (prodNo.length > 2) {
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/${prodNo}`)
                .then(response => {
                    const product = response.data;
                    if (product && product.prodName) {
                        handleLineItemChange(index, 'selectedProductName', product.prodName);
                    } else {
                        handleLineItemChange(index, 'selectedProductName', '');
                        setMessage(`Product with ProdNo ${prodNo} not found.`);
                        setMessageModalOpen(true);
                    }
                })
                .catch(error => {
                    console.error(`Error fetching product by prodNo: ${prodNo}`, error);
                    handleLineItemChange(index, 'selectedProductName', '');
                    setMessage(`Error fetching product with ProdNo ${prodNo}.`);
                    setMessageModalOpen(true);
                });
        } else if (prodName.length > 2) {
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/search?query=${prodName}`)
                .then(response => {
                    // Perform exact matching of the prodName instead of partial matches
                    const product = response.data.find(p => p.prodName.toLowerCase() === prodName.toLowerCase());
                    if (product && product.prodNo) {
                        handleLineItemChange(index, 'prodNo', product.prodNo);
                    } else {
                        handleLineItemChange(index, 'prodNo', '');
                        setMessage(`Product with name ${prodName} not found.`);
                        setMessageModalOpen(true);
                    }
                })
                .catch(error => {
                    console.error(`Error fetching product by prodName: ${prodName}`, error);
                    handleLineItemChange(index, 'prodNo', '');
                    setMessage(`Error fetching product with name ${prodName}.`);
                    setMessageModalOpen(true);
                });
        }
    };

    const handleLineItemChange = (index, field, value) => {
        const newLineItems = [...formData.lineItems];
        newLineItems[index] = { ...newLineItems[index], [field]: value };
    
        console.log('Updated Line Item:', newLineItems[index]); // Log the updated line item
    
        // Delay consolidation until both prodNo and selectedProductName are settled
        let isConsolidated = false;
    
        // Function to consolidate line items only after selecting a product
        const consolidateLineItems = () => {
            if (isConsolidated) return;  // Prevent double consolidation
            const prodNo = newLineItems[index].prodNo;
            const prodName = newLineItems[index].selectedProductName;
    
            console.log('Checking for consolidation with prodNo:', prodNo, 'and prodName:', prodName); // Log the field and value being consolidated
    
            // Find any existing product with the same prodNo or selectedProductName (case-insensitive)
            const existingIndex = newLineItems.findIndex(
                (item, i) =>
                    (item.prodNo === prodNo || item.selectedProductName.toLowerCase() === prodName.toLowerCase()) &&
                    i !== index
            );
    
            if (existingIndex !== -1) {
                console.log('Duplicate found at index:', existingIndex); // Log where the duplicate is found
    
                // Ensure the quantities are treated as numbers
                const existingQty = parseInt(newLineItems[existingIndex].qtyTransferred, 10) || 0;
                const currentQty = parseInt(newLineItems[index].qtyTransferred, 10) || 0;
    
                console.log('Existing Quantity:', existingQty, 'Current Quantity:', currentQty); // Log the quantities before addition
    
                newLineItems[existingIndex].qtyTransferred = existingQty + currentQty;
    
                console.log('Consolidated Quantity:', newLineItems[existingIndex].qtyTransferred); // Log the consolidated quantity
    
                // Remove the duplicate from the array
                newLineItems.splice(index, 1);
    
                console.log('Line Items after removal of duplicate:', newLineItems); // Log the updated line items array
    
                // Immediately update the state to ensure the UI refreshes after splicing
                setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    
                isConsolidated = true; // Set flag to indicate consolidation happened
    
                return true; // Indicate that a consolidation happened
            }
            return false;
        };
    
        // Delay consolidation by a short period to ensure both fields are updated
        if (field === 'prodNo' || field === 'selectedProductName') {
            setTimeout(() => {
                if (value.length > 2 || field === 'prodNo') {
                    const consolidated = consolidateLineItems();
                    console.log('Consolidation happened:', consolidated); // Log whether consolidation happened or not
                }
            }, 0);  // Delay to ensure the state is updated before consolidating
        }
    
        // Update the formData state to allow typing
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    
        console.log('Updated Line Items after typing:', newLineItems); // Log the line items after every typing change
    
        // Trigger suggestions only when typing in selectedProductName
        if (field === 'selectedProductName' && value.length > 2) {
            fetchProductSuggestions(value, index);
        } else if (field === 'selectedProductName') {
            // If value is too short, clear suggestions
            setSuggestedProducts([]);
        }
    };
    
    const selectProduct = (product, index) => {
        console.log('Selected Product:', product); // Log the selected product to see the correct product
    
        // Set both prodNo and selectedProductName directly in the input fields
        const newLineItems = [...formData.lineItems];
        newLineItems[index].selectedProductName = product.prodName;  // Update the selected product name
        newLineItems[index].prodNo = product.prodNo;  // Ensure prodNo is also updated
    
        // Add a log to ensure line items are updated
        console.log('Updated Line Items after selecting product:', newLineItems); // Log the entire line items array
    
        // Update the formData with the new line items
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    
        // Trigger handleRefresh to make sure prodNo is correctly handled and trigger consolidation
        setTimeout(() => {
            handleRefresh(index);  // Trigger refresh after setting the product details
        }, 0);
    
        // Hide suggestions after selection
        setActiveIndex(null);
        setSuggestedProducts([]); // Clear suggestions
    };
    
    
    

    const addLineItem = () => {
        // Clear all suggestions and hide suggestion box on adding new product
        setActiveIndex(null);
        setSuggestedProducts([]);
        setFormData(prevState => ({
            ...prevState,
            lineItems: [...prevState.lineItems, { prodNo: '', selectedProductName: '', qtyTransferred: 1 }]
        }));
    };

    const removeLineItem = (index) => {
        const newLineItems = formData.lineItems.filter((_, i) => i !== index);
        setFormData(prevState => ({ ...prevState, lineItems: newLineItems }));
    };

    // Modify the function to fetch suggestions
    const fetchProductSuggestions = (query, index) => {
        if (query.length > 2) { // Only search if query is longer than 2 characters
            axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/search?q=${query}`)
                .then(response => {
                    setSuggestedProducts(response.data);
                    setActiveIndex(index); // Only show suggestions for the active line item
                    console.log('Suggested Products:', response.data); // Log suggestions
                })
                .catch(error => console.error('Error fetching product suggestions:', error));
        } else {
            setSuggestedProducts([]); // Clear suggestions if query is too short
            setActiveIndex(null);
        }
    };

    // Modify handleProductSearch to reflect changes immediately in the input field
    const handleProductSearch = (e, index) => {
        const { value, name } = e.target;

        console.log('Product Search Input:', value); // Log user input

        // Update the field first so typing is reflected immediately
        handleLineItemChange(index, name, value);

        // Only fetch suggestions if the input value is longer than 2 characters
        if (value.length > 2) {
            if (name === 'selectedProductName') {
                fetchProductSuggestions(value, index);
            }
        } else {
            // Hide suggestions if input is too short
            setActiveIndex(null);
            setSuggestedProducts([]);
        }
    };
      

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const hasEmptyProdNo = formData.lineItems.some(item => !item.prodNo);
        if (hasEmptyProdNo) {
            setMessage('Error: All line items must have a product selected.');
            setMessageModalOpen(true);
            return;
        }
    
        // Consolidate line items with the same prodNo before submitting
        const consolidatedLineItems = formData.lineItems.reduce((acc, current) => {
            const existingItem = acc.find(item => item.prodNo === current.prodNo);
            if (existingItem) {
                existingItem.qtyTransferred += current.qtyTransferred;
            } else {
                acc.push(current);
            }
            return acc;
        }, []);
    
        const transferData = {
            fromBranch: formData.fromBranchID,  // Update to match API
            toBranch: formData.toBranchID,      // Update to match API
            stkTransDate: formData.stkTransDate,
            transferStatus: formData.transferStatus,
            senderEmpID: formData.senderEmpID,
            receiverEmpID: formData.receiverEmpID || null,
            referenceOrder: formData.referenceOrder || null,
            lineItems: consolidatedLineItems.map(item => ({
                qtyTransferred: item.qtyTransferred,
                prod_No_fk: item.prodNo // Ensure this matches API
            }))
        };
        
        // Log the transferData before sending it to the backend
        console.log('Transfer Data:', transferData);
        console.log('Line Items:', formData.lineItems);
        axios.post('https://www.capstonelimenerp.xyz:8082/api/stocktransfer', transferData)
            .then(response => {
                onSubmit(transferData);
                onClose();
            })
            .catch(error => console.error('Error submitting stock transfer:', error));
    };

    if (!isOpen) return null;

    return (
        <div className="transfer-modal-overlay">
            <div className="transfer-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <div className="left-section">
                        <h2>{isViewMode ? 'View Stock Transfer' : 'Create Stock Transfer'}</h2>
                            <div className="depth">
                                <div className="form-group">
                                    <label>Transfer Date <span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                        selected={formData.stkTransDate ? new Date(formData.stkTransDate) : null}
                                        onChange={(date) => {
                                            const formattedDate = date ? date.toISOString().split('T')[0] : '';
                                            handleChange({ target: { name: 'stkTransDate', value: formattedDate } });
                                        }}
                                        dateFormat="MM-dd-yyyy"
                                        disabled
                                        className="date-picker-input"
                                        placeholderText="MM-DD-YYYY"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="transferStatus"><strong>Transfer Status</strong></label>
                                    <input
                                        type="text"
                                        name="transferStatus"
                                        value={formData.transferStatus}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="fromBranchID"><strong>From Branch</strong></label>
                                    <input
                                        type="text"
                                        name="fromBranchID"
                                        value={localStorage.getItem('branchName')}  // Use branch name here
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="toBranchID"><strong>To Branch <span style={{ color: 'red' }}>*</span></strong></label>
                                    {formData.referenceOrder ? (
                                        // If referenceOrder is selected, show a read-only input field
                                        <input
                                            type="text"
                                            name="toBranchID"
                                            value={branches.find(branch => branch.branchID === formData.toBranchID)?.branchName || ''}
                                            readOnly
                                            required
                                        />
                                    ) : (
                                        // If referenceOrder is empty, show a dropdown to select a branch
                                        <select
                                            name="toBranchID"
                                            value={formData.toBranchID}
                                            onChange={handleChange}
                                            disabled={isViewMode}
                                            required
                                        >
                                            <option value="">Select Branch</option>
                                            {branches
                                                .filter(branch => branch.branchID !== formData.fromBranchID) // Exclude the current branch
                                                .map(branch => (
                                                    <option key={branch.branchID} value={branch.branchID}>
                                                        {branch.branchName}
                                                    </option>
                                                ))}
                                        </select>
                                    )}
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="senderEmpID"><strong>Sender Employee</strong></label>
                                    <input
                                        type="text"
                                        name="senderEmpID"
                                        value={loggedInUsername}  // Auto-filled with logged-in user
                                        disabled
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="receiverEmpID"><strong>Receiver Employee</strong></label>
                                    <input
                                        type="text"
                                        name="receiverEmpID"
                                        value={formData.receiverEmpID}
                                        onChange={handleChange}
                                        disabled  // Allow editing if not in view mode
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="referenceOrder"><strong>Reference Order</strong></label>
                                    <select
                                        name="referenceOrder"
                                        value={formData.referenceOrder}
                                        onChange={handleReferenceOrderChange}
                                        disabled={isViewMode}
                                    >
                                        <option value="">Select Reference Order</option>
                                        {stockRequests.map(request => {
                                            // Safely find the branch using branches array
                                            const matchedBranch = branches.find(b => b.branchID === request.ordRequester);

                                            return (
                                                <option key={request.ordReqID} value={request.ordReqID}>
                                                    {`${request.ordReqID} - ${matchedBranch?.branchName || "Unknown Branch"} - ${new Date(request.reqDate).toLocaleDateString()}`}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="right-section">
                            <div className="inventory-line-items-section">
                                <div style={{ display: 'flex', alignItems:'end', justifyContent: 'space-between', gap: '10px'}}>
                                    <h3 style={{fontFamily: 'sans-serif', fontWeight: 'bold'}}>Line Items</h3>
                                        {/* Add Product Button */}
                                        {!isViewMode && (
                                            <div className="add-product-container">
                                                <button 
                                                    type="button" 
                                                    className="add-line-item" 
                                                    onClick={addLineItem}
                                                    style={{marginTop: '-5px'}}
                                                >
                                                    Add Product
                                                </button>
                                            </div>
                                        )}
                                </div>
                                <table className="inventory-line-items-table">
                                    <thead>
                                        <tr>
                                            <th>Product No</th>
                                            <th>Product Name</th>
                                            <th>Qty</th>
                                            {!isViewMode && <th>Actions</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.lineItems.length > 0 ? (
                                            formData.lineItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <button
                                                                type="button"
                                                                className="refresh-button"
                                                                onClick={() => handleRefresh(index)}
                                                                disabled={isViewMode}
                                                            >
                                                                &#x21bb;
                                                            </button>
                                                            <input
                                                                style={{ textAlign: 'left' }}
                                                                type="text"
                                                                name="prodNo"
                                                                value={item.prodNo || ''}
                                                                onChange={e => handleLineItemChange(index, 'prodNo', e.target.value)}
                                                                required
                                                                disabled={isViewMode}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="input-suggestion-container">
                                                            <input
                                                                style={{textAlign: 'left', position: 'relative' }}
                                                                type="text"
                                                                name="selectedProductName"
                                                                value={item.selectedProductName}
                                                                onChange={e => handleProductSearch(e, index)}
                                                                // Use a setTimeout to delay hiding the suggestions, allowing the onClick event to fire first
                                                                onBlur={() => {
                                                                    setTimeout(() => {
                                                                        setActiveIndex(null);
                                                                        setSuggestedProducts([]);
                                                                    }, 200); // Delay hiding by 200ms to allow click event
                                                                }}
                                                                required
                                                                disabled={isViewMode}
                                                            />
                                                            {activeIndex === index && suggestedProducts.length > 0 && (
                                                                <ul className="transfer-suggestion-list">
                                                                    {suggestedProducts.map((product, i) => (
                                                                        <li
                                                                            key={i}
                                                                            onClick={() => selectProduct(product, index)}
                                                                            className="suggestion-item"
                                                                        >
                                                                            <span className="suggestion-product-name">{product.prodName}</span>
                                                                            <span className="suggestion-product-no">{product.prodNo}</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            name="qtyTransferred"
                                                            value={item.qtyTransferred}
                                                            min="1"
                                                            onChange={e => handleLineItemChange(index, 'qtyTransferred', e.target.value)}
                                                            disabled={isViewMode}
                                                            required
                                                        />
                                                    </td>
                                                    {!isViewMode && (
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="remove-line-item"
                                                                onClick={() => removeLineItem(index)}
                                                            >
                                                                <FaTrash/>
                                                            </button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={isViewMode ? 3 : 4} style={{ textAlign: 'center' }}>
                                                    No line items found.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {!isViewMode && (
                                <div className="button-container">
                                    <button type="submit" className="stock-button">Create Transfer</button>
                                </div>
                            )}
                            {isViewMode && (
                                <div className="button-container">
                                    <button
                                        type="button"
                                        className="stock-button"
                                        onClick={() => setDiscrepancyModalOpen(true)}
                                    >
                                        View Any Discrepancies
                                    </button>
                                </div>
                            )}  
                        </div>
                    </div>


                    <MessageModal
                        isOpen={messageModalOpen}
                        onClose={() => setMessageModalOpen(false)}
                        message={message}
                    />
                    {/* DiscrepancyLogModal */}
                    {isDiscrepancyModalOpen && transfer?.stkTransNo && (
                        <DiscrepancyLogModal
                            isOpen={isDiscrepancyModalOpen}
                            onClose={() => setDiscrepancyModalOpen(false)}
                            transfer={transfer} // Pass the transfer data
                        />
                    )}
                </form>
            </div>
        </div>
    );
}

export default StockTransferModal;
