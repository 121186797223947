import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import EmployeeModal from './EmployeeModal';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';
import MessageModal from './MessageModal';
import Pagination from './Pagination'; // Adjust the path as needed
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function Employee() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [employees, setEmployees] = useState([]);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [employeeToDelete, setemployeeToDelete] = useState(null);
    const [activeTab, setActiveTab] = useState('employees'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch employees, users, and branches data
        const fetchData = async () => {
            try {
                const [employeesResponse, usersResponse, branchesResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/employees'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/users'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                ]);

                // Process dateHired to strip time component
                const employeesData = employeesResponse.data.map(employee => ({
                    ...employee,
                    dateHired: employee.dateHired ? employee.dateHired.split('T')[0] : null
                }));
                
                setEmployees(employeesResponse.data);
                setUsers(usersResponse.data);
                setBranches(branchesResponse.data);
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };
        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (refreshTable) {
            // Fetch employees again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/employees')
                .then(response => {
                    console.log('Employee data refreshed successfully:', response.data);
                    setEmployees(response.data);
                    setRefreshTable(false);  // Reset the refreshTable state
                })
                .catch(error => {
                    console.error('There was an error refreshing the employee data!', error.response || error.message || error);
                });
        }
    }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const formatEmployeeName = (employee) => {
        const middleInitial = employee.empMName ? `${employee.empMName.charAt(0)}.` : '';
        return `${employee.empFName} ${middleInitial} ${employee.empLName} `;
    };

    const handleAddEmployee = () => {
        console.log('Add User button clicked');
        setSelectedEmployee(null); // Clear any selected user
        setIsModalOpen(true);
    };

    const handleEditEmployee = (employee) => {
        setSelectedEmployee(employee);
        setIsModalOpen(true); // Open update modal
    };

    const confirmDeleteEmployee= (empID) => {
        console.log("Delete button clicked for emp ID:", empID);
        setemployeeToDelete(empID);
        setMessage('Are you sure you want to delete this employee?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); // Show confirmation buttons for deletion
        setIsConfirmation(true); // Set to true for deletion confirmation
    };

    const handleDeleteEmployee = () => {
        if (employeeToDelete !== null) {
            axios.delete(`https://www.capstonelimenerp.xyz:8082/api/employees/${employeeToDelete}`)
                .then(() => {
                    setEmployees(employees.filter(employee => employee.empID !== employeeToDelete));
                    setMessage('Employee has been successfully deleted.');
                    setDeletionConfirmed(true); // This should trigger the close button

                    // Trigger a refresh
                    setRefreshTable(true);
                })
                .catch(error => {
                    console.error('There was an error deleting the employee!', error);
                    setMessage('Error deleting employee');
                    setDeletionConfirmed(true);
                });
        }
    };
    

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        console.log('Add Employee Form Data:', data);
        setIsModalOpen(false);
        
        // Set message based on whether it's an edit or add
        const successMessage = isEditing ? 'Employee updated successfully!' : 'Employee added successfully!';
        setMessage(successMessage);
        
        setShowCloseButton(true); // Ensure the close button is shown
        setIsMessageModalOpen(true);
        setIsConfirmation(false); // Set to false for success messages
        setRefreshTable(true);
    };

    // Filter and search logic
    const filteredEmployees = employees
        .filter(employee => 
            (filter === 'all' || employee.empPosition === filter) &&
            (employee.empFName || '').toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        const handleRecordsChange = (event) => {
            const selectedValue = parseInt(event.target.value, 10);
            setRecordsPerPage(selectedValue);
            setCurrentPage(1); // Reset to first page on records change
        };
    
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const displayedEmployees = filteredEmployees.slice(indexOfFirstRecord, indexOfLastRecord);
    
        const totalPages = Math.ceil(filteredEmployees.length / recordsPerPage);

        const formatDate = (dateString) => {
            if (!dateString) return 'N/A'; // Check for missing date
        
            const date = new Date(dateString);
            if (isNaN(date.getTime())) return 'N/A'; // Check for invalid date
        
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}-${day}-${year}`;
        };

    // Map user_id to userName
    const userMap = new Map(users.map(user => [user.userID, user.userName]));

    // Map branch_id to branchName
    const branchMap = new Map(branches.map(branch => [branch.branchID, branch.branchName]));

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <EmployeeModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        employee={selectedEmployee}
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteEmployee(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <Link to="/user/information" className={activeTab === 'users' ? 'active' : ''} onClick={() => setActiveTab('users')}>
                            Users
                        </Link>
                        <Link to="/employee/information" className={activeTab === 'employees' ? 'active' : ''} onClick={() => setActiveTab('employees')}>
                            Employees
                        </Link>
                        <Link to="/employee/attendance" className={activeTab === 'attendances' ? 'active' : ''} onClick={() => setActiveTab('attendances')}>
                            Attendances
                        </Link>
                        <Link to="/employee/pay" className={activeTab === 'paybook' ? 'active' : ''} onClick={() => setActiveTab('paybook')}>
                            Paybook
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search employee name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Cashier">Cashier</option>
                                <option value="Salesperson">Sales Person</option>
                                <option value="Purchase Officer">Purchase Officer</option>
                                <option value="Branch Manager">Branch Manager</option>
                            </select>
                            <button className="action-button" onClick={handleAddEmployee}>Add Employee</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container">
                        <table className="content-table">
                                <thead>
                                    <tr>
                                        <th style={{width: '50px'}}>#</th>
                                        <th style={{width: '130px'}}>ID</th>
                                        <th>Employee Name</th>
                                        <th style={{width: '60px'}}>Age</th>
                                        <th style={{width: '60px'}}>Sex</th>
                                        <th>Address</th>
                                        <th style={{width: '150px'}}>Position</th>
                                        <th style={{width: '130px'}}>Date Hired</th>
                                        <th style={{width: '130px'}}>Contact No.</th>
                                        <th>Email</th>
                                        <th style={{width: '100px'}}>User</th>
                                        <th>Branch</th>
                                        <th style={{width: '100px'}}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedEmployees.length > 0 ? (
                                        displayedEmployees.map((employee, index) => (
                                            <tr key={employee.empID}>
                                                <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                                <td style={{width: '130px'}}>{employee.empID}</td>
                                                <td>{formatEmployeeName(employee)}</td>
                                                <td style={{width: '60px'}}>{employee.empAge}</td>
                                                <td style={{width: '60px'}}>{employee.empSex.charAt(0)}</td>
                                                <td>{employee.empAddr}</td>
                                                <td style={{width: '150px'}}>{employee.empPosition}</td>
                                                <td style={{width: '130px'}}>{formatDate(employee.dateHired)}</td>
                                                <td style={{width: '130px'}}>{employee.empContNo}</td>
                                                <td>{employee.empEmail}</td>
                                                <td style={{width: '100px'}}>{userMap.get(employee.user_id_fk) || 'No User Account'}</td>
                                                <td>{branchMap.get(employee.branch_id_fk)}</td>
                                                <td style={{width: '100px'}}>
                                                    <button className="edit-button" onClick={() => handleEditEmployee(employee)}>
                                                        <FaEdit />
                                                    </button>
                                                    <button className="delete-button" onClick={() => confirmDeleteEmployee(employee.empID)}>
                                                        <FaTrash />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="14" style={{ textAlign: 'center' }}>No employees found</td>
                                        </tr>
                                    )}
                                </tbody>
                        </table>
                    </div>
                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default Employee;
