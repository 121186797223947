import React, { useState, useEffect } from 'react';
import './MessageModal.css';

function MessageModal({ isOpen, onClose, message, isConfirmation, onConfirm }) {
    const [showCloseButton, setShowCloseButton] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setShowCloseButton(!isConfirmation);
        }
    }, [isOpen, isConfirmation]);

    const handleYesClick = () => {
        onConfirm();
        setShowCloseButton(true);
    };

    const handleClose = () => {
        setShowCloseButton(false);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="message-modal-overlay">
            <div className="message-modal-content">
                <h2>Message</h2>
                <div style={{ whiteSpace: 'pre-line' }}><p>{message}</p></div>
                <div className="modal-actions">
                    {isConfirmation && !showCloseButton ? (
                        <div className='confirmation-buttons'>
                            <button className="message-yes-button" onClick={handleYesClick}>Yes</button>
                            <button className="message-no-button" onClick={handleClose}>No</button>
                        </div>
                    ) : (
                        showCloseButton && (
                            <button className="message-close-button" onClick={handleClose}>Close</button>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default MessageModal;
