import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SuggestionInventoryModal.css';
import MessageModal from './MessageModal';

function SuggestionInventoryModal({ isOpen, onClose, product, inventory }) {
    const [inventoryData, setInventoryData] = useState([]);
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);

    useEffect(() => {
        console.log('SuggestionInventoryModal isOpen:', isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && product) {
            // Fetch inventory details for the selected product
            const fetchInventoryData = async () => {
                try {
                    const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/inventory/${product.prod_No_fk}`);
                    setInventoryData(response.data);
                } catch (error) {
                    console.error('Error fetching inventory details:', error);
                    setMessage('Error fetching inventory details.');
                    setMessageModalOpen(true);
                }
            };

            fetchInventoryData();
        } else {
            setInventoryData([]);
        }
    }, [isOpen, product]);

    const calculateTotals = () => {
        let totalStockOnHand = 0;
        let totalReorderQty = 0;
        let totalPreferredQty = 0;
    
        // Include negative values in the totals calculation
        inventory.forEach((item) => {
            console.log(`Item stock: ${item.stockOnHand}, reorderQty: ${item.reorderQty}, preferredQty: ${item.preferredQty}`);
            totalStockOnHand += item.stockOnHand;  // Sum of stock, including negative values
            totalReorderQty += item.reorderQty;   // Sum of reorder quantities, including negative values
            totalPreferredQty += item.preferredQty; // Sum of preferred quantities, including negative values
        });
    
        console.log(`Total Stock On Hand: ${totalStockOnHand}, Total Reorder Quantity: ${totalReorderQty}, Total Preferred Quantity: ${totalPreferredQty}`);
    
        return {
            totalStockOnHand,
            totalReorderQty,
            totalPreferredQty,
        };
    };
    

    const totals = calculateTotals();

    if (!isOpen) return null;

    return (
        <div className="suggestion-inventory-modal-overlay">
            <div className="suggestion-inventory-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>Inventory Details: {product?.prodName || 'Product'}</h2>
                <div className="suggestion-inventory-table-section">
                    <table className="suggestion-inventory-table">
                        <thead> 
                            <tr>
                                <th>#</th>
                                <th>Inventory ID</th>
                                <th>Stock On Hand</th>
                                <th>Reorder Quantity</th>
                                <th>Preferred Quantity</th>
                                <th>Stock Location</th>
                                <th>Branch</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inventory.length > 0 ? (
                                inventory.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.invID}</td>
                                        <td>{item.stockOnHand}</td>
                                        <td>{item.reorderQty}</td>
                                        <td>{item.preferredQty}</td>
                                        <td>{item.stockLocation}</td>
                                        <td>{item.branchName}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" style={{ textAlign: 'center' }}>No inventory records found for this product.</td>
                                </tr>
                            )}
                            <tr className="totals-row">
                                <td><strong>Total</strong></td>
                                <td></td>
                                <td><strong>{totals.totalStockOnHand}</strong></td>
                                <td><strong>{totals.totalReorderQty}</strong></td>
                                <td><strong>{totals.totalPreferredQty}</strong></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <MessageModal
                    isOpen={messageModalOpen}
                    onClose={() => setMessageModalOpen(false)}
                    message={message}
                />
            </div>
        </div>
    );
}

export default SuggestionInventoryModal;
