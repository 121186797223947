import React from 'react';
import './TopContainer.css'; // You can keep the styles in a separate CSS file or adjust as needed
import LimenLogo from './LimenLogo.png';

function TopContainer({ title }) {
    return (
        <div className="top-container">
            <div className="top-container-logo">
                <img src={LimenLogo} alt="Limen ERP Logo" className="top-image-logo" />
            </div>
            <h1 style={{marginLeft: '20px'}}>LIMEN ERP SYSTEM</h1>
        </div>
    );
}

export default TopContainer;