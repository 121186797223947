import React, { useState, useEffect } from 'react';
import './ReportSales.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from './Pagination';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';
import DatePickerModal from './DatePickerModal';
import { FaCalendarAlt } from 'react-icons/fa';

import {
    PayrollSummaryReport,
    EmployeePayslipReport,
    AttendanceReport
} from './ReportComponents';
import DatePicker from 'react-datepicker';  // Install: npm install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

function ReportPayroll() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [branches, setBranches] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [payrollData, setPayrollData] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState('all');
    const [selectedBranch, setSelectedBranch] = useState('all');
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('payroll');

    const navigate = useNavigate();

    const fetchData = async (url, setter) => {
        try {
            const response = await axios.get(url);
            setter(response.data);
            console.log(`Fetched data from ${url}:`, response.data);
        } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
        }
    };

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchID = localStorage.getItem('branchID');
        const storedBranchName = localStorage.getItem('branchName');
    
        if (storedUsername) setUsername(storedUsername);
        if (storedBranchID) setBranchID(storedBranchID);
        if (storedBranchName) setBranchName(storedBranchName);
    
        const fetchData = async () => {
            try {
                const [payrollReportsRes, attendanceRes, branchesRes, employeesRes] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/payrollreports'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/attendance_logs'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/employees')
                ]);
    
                setPayrollData(payrollReportsRes.data);
                setAttendanceData(attendanceRes.data);
                setBranches(branchesRes.data);
                setEmployees(employeesRes.data);

                console.log('Fetched Attendance Date:', attendanceRes.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleDateRangeApply = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleReportSelection = (reportType) => {
        setSelectedReport(reportType);

        setSelectedBranch('all');
        setSelectedEmployee('all');
    };

    // Filter payroll data based on user selections
    const filteredPayrollData = payrollData.filter((record) => {
        const recordDate = new Date(record.paymentDate);
        const isWithinDateRange = recordDate >= startDate && recordDate <= endDate;

        const isBranchMatch =
            selectedBranch === 'all' || record.branchID === selectedBranch;

        const isEmployeeMatch =
            selectedEmployee === 'all' || record.emp_id_fk === selectedEmployee;

        return isWithinDateRange && isBranchMatch && isEmployeeMatch;
    });

    console.log('Filtered Payroll Data:', filteredPayrollData);

    const generateReportContent = () => {
        switch (selectedReport) {
            case 'summary':
                return (
                    <PayrollSummaryReport
                        salaries={payrollData}
                        branches={branches}
                        employees={employees}
                        startDate={startDate}
                        endDate={endDate}
                        selectedBranch={selectedBranch}
                    />
                );
            case 'payslip':
                return (
                    <EmployeePayslipReport
                        salaries={payrollData}
                        employees={employees}
                        startDate={startDate}
                        endDate={endDate}
                        selectedEmployee={selectedEmployee}
                    />
                );
            case 'attendance':
                return (
                    <AttendanceReport
                        attendanceLogs={attendanceData}
                        employees={employees}
                        startDate={startDate}
                        endDate={endDate}
                        selectedEmployee={selectedEmployee}
                    />
                );
            default:
                return <div className="select-report-message">Please select a report to display.</div>;
        }
    };

    // Identify the selected report
    const isPayrollSummary = selectedReport === 'summary';
    const isEmployeePayslip = selectedReport === 'payslip';
    const isAttendanceLogs = selectedReport === 'attendance';

    const isBranchDisabled = 
        isEmployeePayslip || isAttendanceLogs;
    ;

    const isEmployeeDisabled = 
        isPayrollSummary;
    ;


    return (
        <div className="report-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* DatePicker Modal */}
                    <DatePickerModal 
                        isOpen={isDatePickerModalOpen} 
                        onClose={() => setIsDatePickerModalOpen(false)} 
                        onApply={handleDateRangeApply} 
                    />
                    <div className="tab-navigation">
                        <Link to="/report/sales" className={activeTab === 'sales' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Sales Reports
                        </Link>
                        <Link to="/report/inventory" className={activeTab === 'inventory' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                        Inventory Reports
                        </Link>
                        <Link to="/report/purchases" className={activeTab === 'purchases' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Reports
                        </Link>
                        <Link to="/report/payroll" className={activeTab === 'payroll' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Salary Reports
                        </Link>
                    </div>
                    
                    <div className="page-content">
                        <div className="report-section"> 
                            <div className="left-panel">
                                <div className="first-left-panel">
                                    <div className="filter-section">
                                        <h3>Filter</h3>
                                        <div className="filter-group">
                                            <label>Branch:</label>
                                            <select
                                                value={selectedBranch}
                                                onChange={(e) => setSelectedBranch(e.target.value)}
                                                disabled={isBranchDisabled}
                                            >
                                                <option value="all">All</option>
                                                {branches.map((branch) => (
                                                    <option key={branch.branchID} value={branch.branchID}>
                                                        {branch.branchName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Employee:</label>
                                            <select
                                                value={selectedEmployee}
                                                onChange={(e) => setSelectedEmployee(e.target.value)}
                                                disabled={isEmployeeDisabled}
                                            >
                                                <option value="all">All</option>
                                                {employees.map((employee) => (
                                                    <option key={employee.empID} value={employee.empID}>
                                                        {`${employee.empFName} ${employee.empLName}`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Date Range:</label>
                                            <button className="date-range-button" onClick={() => setIsDatePickerModalOpen(true)}>
                                                <FaCalendarAlt className="calendar-icon" /> {/* Calendar Icon */}
                                                {`${startDate ? startDate.toLocaleDateString() : 'Select Start Date'} - ${endDate ? endDate.toLocaleDateString() : 'Select End Date'}`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="second-left-panel">
                                    <div className="report-selection">
                                        <h3>Select Report</h3>
                                        <ul>
                                            <li
                                                onClick={() => handleReportSelection('summary')}
                                                className={selectedReport === 'summary' ? 'active' : ''}
                                            >
                                                Payroll Summary
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('payslip')}
                                                className={selectedReport === 'payslip' ? 'active' : ''}
                                            >
                                                Employee Payslip
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('attendance')}
                                                className={selectedReport === 'attendance' ? 'active' : ''}
                                            >
                                                Attendance
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="right-panel">
                                {/* Require date range selection */}
                                {(!startDate || !endDate) ? (
                                    <div className="select-report-message">
                                        <p>Please select a date range to view reports.</p>
                                    </div>
                                ) : (
                                    generateReportContent()
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer
                username={username}
                branchID={branchID}
                branchName={branchName}
                handleLogout={handleLogout}
            />
        </div>
    );
}

export default ReportPayroll;
