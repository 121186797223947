import React from 'react';
import './SalesHistoryModal.css';
import './StylesModal.css';

function SalesHistoryModal({ isOpen, onClose, lineItems, sale }) {
    if (!isOpen) return null;

    // Calculate totals
    const totalAmount = lineItems.reduce((sum, item) => sum + item.amount, 0);
    const discountAmount = totalAmount - sale.grandTotal; // Calculate the discount

    const formattedTotalAmount = totalAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    
    const formattedDiscountAmount = discountAmount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    const formattedGrandTotal = sale.grandTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    
    return (
        <div className="request-modal-overlay">
            <div className="request-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section">
                    <h2>Sale Details</h2>
                        <div className="depth">
                            <div className="form-group">
                                <label htmlFor="oRNo"><strong>Transaction No:</strong></label>
                                <input
                                    type="text"
                                    name="oRNo"
                                    value={sale.oRNo}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dateOfSale"><strong>Date of Sale:</strong></label>
                                <input
                                    type="text"
                                    name="dateOfSale"
                                    value={new Date(sale.dateOfSale).toLocaleString()}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="depth">
                            <div className="form-group">
                                <label htmlFor="custNo"><strong>Customer No:</strong></label>
                                <input
                                    type="text"
                                    name="custNo"
                                    value={sale.cust_No_fk}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                    <label htmlFor="custName"><strong>Customer Name:</strong></label>
                                    <input type="text" name="custName" value={sale.custName || 'Walk-In'} disabled />
                                </div>
                        </div>
                        <div className="depth">
                            <div className="form-group">
                                <label htmlFor="paymentStatus"><strong>Payment Status:</strong></label>
                                <input
                                    type="text"
                                    name="paymentStatus"
                                    value={sale.paymentStatus}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="grandTotal"><strong>Total Amount:</strong></label>
                                <input
                                    type="text"
                                    name="grandTotal"
                                    value={`₱${sale.grandTotal.toFixed(2)}`}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="depth">
                            <div className="form-group">
                                <label htmlFor="userName"><strong>Handled By:</strong></label>
                                <input 
                                    type="text" 
                                    name="userName" 
                                    value={`${sale.userName}`} 
                                    disabled 
                                />
                            </div>
                        </div>
                        <div className="depth">
                            <div className="form-group">
                                <label htmlFor="branchName"><strong>Branch:</strong></label>
                                <input type="text" name="branchName" value={sale.branchName} disabled />
                            </div>
                        </div>
                    </div>

                    <div className="right-section">
                        <h3>Line Items</h3>
                        <div className="inventory-line-items-section">          
                            <table className="inventory-line-items-table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '3%' }}>#</th>
                                        <th style={{width: '62%', textAlign: 'left' }}>Product Name</th>
                                        <th style={{width: '10%', textAlign: 'center' }}>Qty</th>
                                        <th style={{width: '12%', textAlign: 'right' }}>Price</th>
                                        <th style={{width: '13%', textAlign: 'right' }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lineItems.length > 0 ? (
                                        lineItems.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ width: '3%' }}>{index + 1}</td>
                                                <td style={{width: '62%', textAlign: 'left' }}>{item.prodName}</td>
                                                <td style={{width: '10%', textAlign: 'center' }}>{item.prodQty}</td>
                                                <td style={{width: '12%', textAlign: 'right'}}>{item.prodPrice.toFixed(2)}</td>
                                                <td style={{width: '13%', textAlign: 'right' }}>{item.amount.toFixed(2)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: 'center' }}>No line items found</td>
                                        </tr>
                                    )}
                                    {/* Subtotal row */}
                                    <tr className="line-items-subtotal">
                                        <td colSpan="3" style={{borderBottom: 'none'}}></td>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold', borderBottom: 'none'}}>Subtotal:</td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold'}}>{formattedTotalAmount}</td>
                                    </tr>
                                    {/* Less row */}
                                    {discountAmount > 0 && (
                                        <tr className="line-items-less">
                                            <td colSpan="3" style={{borderBottom: 'none'}}></td>
                                            <td style={{ textAlign: 'left', fontWeight: 'bold', borderBottom: 'none'}}>Less Discount:</td>
                                            <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'red', borderBottom: '2px solid black' }}>- {formattedDiscountAmount}</td>
                                        </tr>
                                    )}
                                    {/* Total row */}
                                    <tr className="line-items-total">
                                        <td colSpan="3" style={{borderBottom: 'none'}}></td>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold', borderBottom: 'none'}}>Total:</td>
                                        <td style={{ textAlign: 'right', fontWeight: 'bold', borderBottom: '5px double black'}}>₱ {formattedGrandTotal}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SalesHistoryModal;
