import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EmployeeModal.css';
import MessageModal from './MessageModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function EmployeeModal({ isOpen, onClose, onSubmit, employee, user, branch }) {
    const [formData, setFormData] = useState({
        empLName: '',
        empFName: '',
        empMName: '',
        empAge: '',
        empSex: 'Male',
        empAddr: '',
        empPosition: 'Cashier',
        empDailySalary: '',
        dateHired: '',
        empContNo: '',
        empEmail: '',
        photo: null,
        user_id_fk: '',
        branch_id_fk: ''
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    useEffect(() => {
        // Fetch users and branches
        axios.get('https://www.capstonelimenerp.xyz:8082/api/available-users')
            .then(response => {
                const employeeUsers = response.data.filter(user => user.userType === 'Employee');
                setUsers(employeeUsers);
            })
            .catch(error => console.error('Error fetching users:', error));

        axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
            .then(response => setBranches(response.data))
            .catch(error => console.error('Error fetching branches:', error));

        
    }, []);
    useEffect(() => {
        if (isOpen && employee) {
            // Only set if there's an employee and the modal is opened
            setIsEditing(true);
            setFormData((prevFormData) => ({
                ...prevFormData,
                ...employee, // Spread existing employee data into formData
                empSex: employee.empSex || 'Male',
                dateHired: employee.dateHired ? employee.dateHired.split('T')[0] : ''
            }));
        }
    }, [isOpen, employee]);
    useEffect(() => {
        if (employee) {
            setIsEditing(true);
            setFormData({
                empLName: employee.empLName || '',
                empFName: employee.empFName || '',
                empMName: employee.empMName || '',
                empAge: employee.empAge || '',
                empSex: employee.empSex || '',
                empAddr: employee.empAddr || '',
                empPosition: employee.empPosition || 'Cashier',
                empDailySalary: employee.empDailySalary || '',
                dateHired: employee.dateHired || '',
                empContNo: employee.empContNo || '',
                empEmail: employee.empEmail || '',
                photo: null,
                user_id_fk: employee.user_id_fk || '',
                branch_id_fk: employee.branch_id_fk || ''
            });
        } else {
            setIsEditing(false);
            setFormData({
                empLName: '',
                empFName: '',
                empMName: '',
                empAge: '',
                empSex: 'Male',
                empAddr: '',
                empPosition: 'Cashier',
                empDailySalary: '',
                dateHired: '',
                empContNo: '',
                empEmail: '',
                photo: null,
                user_id_fk: '',
                branch_id_fk: ''
            });
            
        }
        console.log('FormData:', formData);
        console.log('Selected User ID:', formData.user_id_fk);
        console.log('Selected Branch ID:', formData.branch_id_fk);
    }, [employee]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value // Store the date as YYYY-MM-DD for input compatibility
        }));
    };
    
    
    const formatDateForDisplay = (dateString) => {
        if (!dateString) return '';
        const [year, month, day] = dateString.split('-');
        return `${month}-${day}-${year}`; // Convert to MM-DD-YYYY
    };
    

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JS
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };
    

    const handleSubmit = (e) => {
        e.preventDefault(); 

        const formattedDateHired = formData.dateHired 
        ? new Date(formData.dateHired).toISOString().split('T')[0] 
        : '';

        // URL and HTTP method based on whether you are editing or adding
        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/employees/${employee.empID}` // Use empID for updating
            : 'https://www.capstonelimenerp.xyz:8082/api/employees';
        const method = isEditing ? 'put' : 'post';
    
        // Prepare data to send
        const data = {
            empLName: formData.empLName,
            empFName: formData.empFName,
            empMName: formData.empMName,
            empAge: formData.empAge,
            empSex: formData.empSex,
            empAddr: formData.empAddr,
            empPosition: formData.empPosition,
            empDailySalary: formData.empDailySalary,
            dateHired: formattedDateHired,
            empContNo: formData.empContNo,
            empEmail: formData.empEmail || null,
            user_id_fk: formData.user_id_fk || null,
            branch_id_fk: formData.branch_id_fk
        };
    
    
        // Log the data to be sent
        console.log('Sending data:', data);
    
        axios({ method, url, data })
            .then(response => {
                setMessage(isEditing ? 'Employee updated successfully!' : 'Employee added successfully!');
                setFormData({
                    empLName: '',
                    empFName: '',
                    empMName: '',
                    empAge: '',
                    empSex: 'Male',
                    empAddr: '',
                    empPosition: 'Cashier',
                    empDailySalary: '',
                    dateHired: '',
                    empContNo: '',
                    empEmail: '',
                    photo: null,
                    user_id_fk: '',
                    branch_id_fk: ''
                });
                onClose();
                onSubmit(data, isEditing);
            })
            .catch(error => {
                console.error(isEditing ? 'Error updating employee:' : 'Error adding employee:', error.response || error.message);
                setMessage((isEditing ? 'Error updating employee: ' : 'Error adding employee: ') + (error.response?.data?.message || error.message));
            });
    };
    
    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="employee-modal-overlay">
            <div className="employee-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{isEditing ? 'Update Existing Employee' : 'Add New Employee'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-container">
                        <div className="left-section" style={{width: '60%', height:'100%'}}>
                            <div className="section">
                                <h4 className="section-title">Personal Information</h4>
                                <div className="depth">
                                    <div className="form-group">
                                        <label htmlFor="empFName"><strong>First Name <span style={{ color: 'red' }}>*</span></strong></label>
                                        <input
                                            type="text"
                                            name="empFName"
                                            value={formData.empFName}
                                            onChange={handleChange}
                                            required={!isEditing}
                                        />
                                    </div>
                                </div>
                                <div className="depth">
                                    <div className="form-group">
                                        <label htmlFor="empMName"><strong>Middle Name</strong></label>
                                        <input
                                            type="text"
                                            name="empMName"
                                            value={formData.empMName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="depth">
                                    <div className="form-group">
                                        <label htmlFor="empLName"><strong>Last Name <span style={{ color: 'red' }}>*</span></strong></label>
                                        <input
                                            type="text"
                                            name="empLName"
                                            value={formData.empLName}
                                            onChange={handleChange}
                                            required={!isEditing}
                                        />
                                        {errors.empLName && <p className="error-message">{errors.empLName}</p>}
                                    </div>
                                </div>
                                <div className="depth">
                                    <div className="form-group">
                                        <label htmlFor="empAge"><strong>Age <span style={{ color: 'red' }}>*</span></strong></label>
                                        <input
                                            type="number"
                                            name="empAge"
                                            value={formData.empAge}
                                            onChange={handleChange}
                                            required={!isEditing}
                                            min="18"
                                            max="65"
                                        />
                                    </div>
                                </div>
                                <div className="depth">
                                    <div className="form-group">
                                        <label><strong>Sex <span style={{ color: 'red' }}>*</span></strong></label>
                                        <select
                                            name="empSex"
                                            value={formData.empSex}
                                            onChange={handleChange}
                                            className="sex-dropdown"
                                            required
                                        >
                                            <option value="">Select</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="left-section" style={{width: '60%', height:'100%'}}>
                            <div className="section">
                                <h4 className="section-title">Contact Information</h4>
                                <div className="form-group">
                                    <label htmlFor="empAddr"><strong>Address <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        name="empAddr"
                                        value={formData.empAddr}
                                        onChange={handleChange}
                                        required={!isEditing}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="empContNo"><strong>Contact Number <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        name="empContNo"
                                        value={formData.empContNo}
                                        onChange={handleChange}
                                        required={!isEditing}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="empEmail"><strong>Email</strong></label>
                                    <input
                                        type="email"
                                        name="empEmail"
                                        value={formData.empEmail}
                                        onChange={handleChange}
                                    />
                                    {formData.empEmail && !/\S+@\S+\.\S+/.test(formData.empEmail) && (
                                        <p className="error-message">Please enter a valid email.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="left-section" style={{width: '60%', height:'100%'}}>
                            <div className="section">
                                <h3 className="section-title">Job Details</h3>
                                <div className="form-group">
                                    <label htmlFor="empPosition"><strong>Position <span style={{ color: 'red' }}>*</span></strong></label>
                                    <select
                                        name="empPosition"
                                        value={formData.empPosition}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="Cashier">Cashier</option>
                                        <option value="Salesperson">Sales Person</option>
                                        <option value="Purchase Officer">Purchase Officer</option>
                                        <option value="Branch Manager">Branch Manager</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="empDailySalary"><strong>Daily Salary <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="number"
                                        name="empDailySalary"
                                        value={formData.empDailySalary}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group date">
                                    <label>Date Hired <span style={{ color: 'red' }}>*</span></label>
                                    <DatePicker
                                        selected={formData.dateHired ? new Date(formData.dateHired) : null}
                                        onChange={(date) => {
                                            const formattedDate = date ? date.toISOString().split('T')[0] : '';
                                            handleChange({ target: { name: 'dateHired', value: formattedDate } });
                                        }}
                                        dateFormat="MM-dd-yyyy"
                                        className="date-picker-input"
                                        placeholderText="MM-DD-YYYY"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="user_id_fk"><strong>User</strong></label>
                                    <select
                                        name="user_id_fk"
                                        value={formData.user_id_fk}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select a User</option>
                                        {users.map(user => (
                                            <option key={user.userID} value={user.userID}>
                                                {user.userName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="branch_id_fk"><strong>Branch <span style={{ color: 'red' }}>*</span></strong></label>
                                    <select
                                        name="branch_id_fk"
                                        value={formData.branch_id_fk}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select a Branch</option> {/* Default placeholder */}
                                        {branches.map(branch => (
                                            <option key={branch.branchID} value={branch.branchID}>
                                                {branch.branchName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='button-container'>
                                <button type="submit" className="employee-button">{isEditing ? 'Save Employee' : 'Add Employee'}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <MessageModal 
                isOpen={messageModalOpen} 
                onClose={closeMessageModal} 
                message={message} 
                showCloseButton={showCloseButton} // Pass showCloseButton to MessageModal
            />
        </div>
    );
}

export default EmployeeModal;
