import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InventoryLogModal.css';
import MessageModal from './MessageModal';

function InventoryLogModal({ isOpen, onClose, inventory }) {
    const [logData, setLogData] = useState([]);
    const [message, setMessage] = useState('');
    const [messageModalOpen, setMessageModalOpen] = useState(false);

    useEffect(() => {
        if (isOpen && inventory) {
            // Fetch inventory logs for the selected inventory item using invID as inv_id_fk
            const fetchLogData = async () => {
                try {
                    const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/inventory/logs/${inventory.invID}`);
                    setLogData(response.data);
                } catch (error) {
                    console.error('Error fetching inventory logs:', error);
                    setMessage('Error fetching inventory logs.');
                    setMessageModalOpen(true);
                }
            };

            fetchLogData();
        } else {
            setLogData([]);
        }
    }, [isOpen, inventory]);

    if (!isOpen) return null;

    return (
        <div className="log-modal-overlay">
            <div className="log-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>Inventory Log - {inventory.prodName} ({inventory.branchName})</h2>
                <div className="log-table-section">
                    <table className="log-table">
                        <thead> 
                            <tr>
                                <th>Log No</th>
                                <th>Log Type</th>
                                <th>Quantity</th>
                                <th>Date & Time</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logData.length > 0 ? (
                                logData.map((log, index) => (
                                    <tr key={index}>
                                        <td>{log.invLogNo}</td>
                                        <td>{log.logType}</td>
                                        <td>{log.qty}</td>
                                        <td>{new Date(log.logDateTime).toLocaleString()}</td>
                                        <td>{log.createdByUser || log.createdBy}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>No logs found for this inventory.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <MessageModal
                    isOpen={messageModalOpen}
                    onClose={() => setMessageModalOpen(false)}
                    message={message}
                />
            </div>
        </div>
    );
}

export default InventoryLogModal;
