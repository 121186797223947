import React, { useState, useEffect } from 'react';
import './ReportSales.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from './Pagination';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';
import DatePickerModal from './DatePickerModal';
import { FaCalendarAlt } from 'react-icons/fa';

import {
    PurchaseSummaryReport,
    PurchaseBySupplierReport,
    InvoiceStatusReport,
    TopProductsPurchasedReport,
} from './ReportComponents';
import DatePicker from 'react-datepicker';  // Install: npm install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

function ReportPurchases() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [sales, setSales] = useState([]);
    const [users, setUsers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [regularCustomers, setRegularCustomers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [products, setProducts] = useState([]);
    const [branches, setBranches] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedReport, setSelectedReport] = useState('daily');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState('all');
    const [selectedCustomer, setSelectedCustomer] = useState('all');
    const [selectedSupplier, setSelectedSupplier] = useState('all');
    const [selectedProduct, setSelectedProduct] = useState('all');
    const [selectedBranch, setSelectedBranch] = useState('all');
    const [selectedUser, setSelectedUser] = useState('all');
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [transactions, setTransactions] = useState([]);
    const [activeTab, setActiveTab] = useState('purchases');

    const navigate = useNavigate();

    const fetchData = async (url, setter) => {
        try {
            const response = await axios.get(url);
            setter(response.data);
            console.log(`Fetched data from ${url}:`, response.data);
        } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
        }
    };

    useEffect(() => {
        // Fetching data from local storage
        const storedUsername = localStorage.getItem('username');
        const storedBranchID = localStorage.getItem('branchID');
        const storedBranchName = localStorage.getItem('branchName');
    
        if (storedUsername) setUsername(storedUsername);
        if (storedBranchID) setBranchID(storedBranchID);
        if (storedBranchName) setBranchName(storedBranchName);
    
        // Fetching data from APIs
        fetchData('https://www.capstonelimenerp.xyz:8082/api/users', setUsers);
        fetchData('https://www.capstonelimenerp.xyz:8082/api/suppliers', setSuppliers);
        fetchData('https://www.capstonelimenerp.xyz:8082/api/products', setProducts);
        fetchData('https://www.capstonelimenerp.xyz:8082/api/branches', setBranches);
        fetchData('https://www.capstonelimenerp.xyz:8082/api/purchasereports', setTransactions);
        // Fetching categories
        fetchData('https://www.capstonelimenerp.xyz:8082/api/categories', setCategories);

        // Fetching brands
        fetchData('https://www.capstonelimenerp.xyz:8082/api/brands', setBrands);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleDateRangeApply = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleReportSelection = (reportType) => {
        setSelectedReport(reportType);
    
        setSelectedReport(reportType);
        setSelectedBranch('all');
        setSelectedSupplier('all');
    };

    // Group transactions by purTransNo to avoid duplicates
    const groupedTransactions = transactions.reduce((acc, transaction) => {
        const { purTransNo, lineItemNo, qty, unitPrice, amount, prodNo, prodName } = transaction;
    
        // Ensure that lineItems is initialized as an array if it's not already
        if (!acc[purTransNo]) {
            acc[purTransNo] = {
                ...transaction,
                lineItems: [], // Initialize lineItems if not already initialized
            };
        }
    
        // Add line item to transaction ensuring all values are valid
        if (transaction.lineItems && Array.isArray(transaction.lineItems)) {
            transaction.lineItems.forEach((item) => {
                acc[purTransNo].lineItems.push({
                    lineItemNo: item.lineItemNo || 'Unknown',
                    qty: item.qty || 0, // Default to 0 if undefined
                    unitPrice: item.unitPrice || 0, // Default to 0 if undefined
                    amount: item.amount || 0, // Default to 0 if undefined
                    prodNo: item.prodNo || 'Unknown', // Default to 'Unknown' if undefined
                    prodName: item.prodName || 'Unknown Product', // Default to 'Unknown Product' if undefined
                    categoryName: item.categoryName || 'Unknown Category', // Added categoryName
                });
            });
        }
    
        return acc;
    }, {});
    
    

    // Convert the grouped transactions object into an array
    const groupedTransactionsArray = Object.values(groupedTransactions);

    // Filter based on user selections (branch, supplier, date range, etc.)
    const filteredTransactions = groupedTransactionsArray.filter(transaction => {
        const transactionDate = new Date(transaction.purDate);
    
        const withinDateRange = transactionDate >= startDate && transactionDate <= endDate;
        const matchesBranch = selectedBranch === 'all' || transaction.branch?.branchID === selectedBranch;
        const matchesSupplier = selectedSupplier === 'all' || transaction.supplier?.supID === selectedSupplier;
        const matchesStatus = selectedStatus === 'all' || transaction.invoiceStatus === selectedStatus;
        const matchesUser = selectedUser === 'all' || transaction.receivedBy === selectedUser;
        console.log(
            `Filtering: Transaction=${transaction.purTransNo}, Branch=${transaction.branch?.branchID}, ` +
            `Supplier=${transaction.supplier?.supID}, MatchesBranch=${matchesBranch}, ` +
            `MatchesSupplier=${matchesSupplier}, MatchesStatus=${matchesStatus}`
        );
    
        return withinDateRange && matchesBranch && matchesSupplier && matchesStatus && matchesUser;
    });
    

    console.log('Filtered Transactions:', filteredTransactions);

    
    
    
    

    const generateReportContent = () => {
        switch (selectedReport) {
            case 'summary':
                return (
                    <PurchaseSummaryReport
                        transactions={filteredTransactions}
                        startDate={startDate}
                        endDate={endDate}
                        suppliers={suppliers} // Ensure suppliers are passed correctly
                        products={products}
                        selectedStatus={selectedStatus}
                        selectedSupplier={selectedSupplier}
                    />
                );
            case 'supplier':
                return (
                    <PurchaseBySupplierReport
                        transactions={filteredTransactions}
                        startDate={startDate}
                        endDate={endDate}
                        suppliers={suppliers}
                        selectedStatus={selectedStatus}
                        selectedSupplier={selectedSupplier}
                    />
                );
            case 'invoiceStatus':
                return (
                    <InvoiceStatusReport
                        transactions={filteredTransactions}
                        startDate={startDate}
                        endDate={endDate}
                        branches={branches}
                        suppliers={suppliers} // Ensure suppliers are passed correctly
                        users={users}
                        selectedStatus={selectedStatus}
                        selectedSupplier={selectedSupplier}
                        selectedUser={selectedUser}
                    />
                );
            case 'topProducts':
                return (
                    <TopProductsPurchasedReport
                        transactions={filteredTransactions}
                        products={products}
                        startDate={startDate}
                        endDate={endDate}
                        suppliers={suppliers}
                        selectedStatus={selectedStatus}
                        selectedSupplier={selectedSupplier}
                    />
                );
            default:
                return <div className="select-report-message">Please select a report to display.</div>;
        }
    };

    // Identify the selected report
    const isPurchaseSummary = selectedReport === 'summary';
    const isPurchaseBySupplier = selectedReport === 'supplier';
    const isInvoiceStatus = selectedReport === 'invoiceStatus';
    const isTopProducts = selectedReport === 'topProducts';

    const isProductDisabled = 
        isPurchaseSummary ||
        isPurchaseBySupplier ||
        isInvoiceStatus;

    const isUserDisabled = 
        isPurchaseSummary ||
        isPurchaseBySupplier ||
        isTopProducts;



    ;
    return (
        <div className="report-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* DatePicker Modal */}
                    <DatePickerModal 
                        isOpen={isDatePickerModalOpen} 
                        onClose={() => setIsDatePickerModalOpen(false)} 
                        onApply={handleDateRangeApply} 
                    />
                    <div className="tab-navigation">
                        <Link to="/report/sales" className={activeTab === 'sales' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Sales Reports
                        </Link>
                        <Link to="/report/inventory" className={activeTab === 'inventory' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                        Inventory Reports
                        </Link>
                        <Link to="/report/purchases" className={activeTab === 'purchases' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Reports
                        </Link>
                        <Link to="/report/payroll" className={activeTab === 'payroll' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Salary Reports
                        </Link>
                    </div>
                    
                    <div className="page-content">
                        <div className="report-section"> 
                            <div className="left-panel">
                                <div className="first-left-panel">
                                    <div className="filter-section">
                                        <h3>Filter</h3>
                                        <div className="filter-group">
                                            <label>User:</label>
                                            <select
                                                value={selectedUser}
                                                onChange={(e) => setSelectedUser(e.target.value)}
                                                disabled={isUserDisabled}
                                            >
                                                <option value="all">All</option>
                                                {users.map((user) => (
                                                    <option key={user.userID} value={user.userID}>
                                                        {user.userName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Supplier:</label>
                                            <select
                                                value={selectedSupplier}
                                                onChange={(e) => setSelectedSupplier(e.target.value)} // Update with selectedSupplier state
                                            >
                                                <option value="all">All</option>
                                                {suppliers.map((supplier) => (
                                                    <option key={supplier.supID} value={supplier.supID}>
                                                        {supplier.supName} {/* Display Supplier Name */}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Product:</label>
                                            <select
                                                value={selectedProduct}
                                                onChange={(e) => setSelectedProduct(e.target.value)}
                                                disabled={isProductDisabled}
                                            >
                                                <option value="all">All</option>
                                                {products.map((prod) => (
                                                    <option key={prod.prodNo} value={prod.prodNo}>
                                                        {prod.prodName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Payment Status:</label>
                                            <select value={selectedStatus} onChange={handleStatusChange}>
                                                <option value="all">All</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Paid">Paid</option>
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Date Range:</label>
                                            <button className="date-range-button" onClick={() => setIsDatePickerModalOpen(true)}>
                                                <FaCalendarAlt className="calendar-icon" /> {/* Calendar Icon */}
                                                {`${startDate ? startDate.toLocaleDateString() : 'Select Start Date'} - ${endDate ? endDate.toLocaleDateString() : 'Select End Date'}`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="second-left-panel">
                                    <div className="report-selection">
                                        <h3>Select Report</h3>
                                        <ul>
                                            <li
                                                onClick={() => handleReportSelection('summary')}
                                                className={selectedReport === 'summary' ? 'active' : ''}
                                            >
                                                Purchase Summary
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('supplier')}
                                                className={selectedReport === 'supplier' ? 'active' : ''}
                                            >
                                                Purchase by Supplier
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('invoiceStatus')}
                                                className={selectedReport === 'invoiceStatus' ? 'active' : ''}
                                            >
                                                Invoice Status
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('topProducts')}
                                                className={selectedReport === 'topProducts' ? 'active' : ''}
                                            >
                                                Top Products Purchased
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="right-panel">
                                {/* Require date range selection */}
                                {(!startDate || !endDate) ? (
                                    <div className="select-report-message">
                                        <p>Please select a date range to view reports.</p>
                                    </div>
                                ) : (
                                    generateReportContent()
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer
                username={username}
                branchID={branchID}
                branchName={branchName}
                handleLogout={handleLogout}
            />
        </div>
    );
}

export default ReportPurchases;
