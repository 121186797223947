import React from 'react';
import './BottomContainer.css'; // Add necessary styling in a separate file or the same file

const BottomContainer = ({ username, branchID, branchName, handleLogout }) => {
    return (
        <div className="bottom-container">
            <div className='bottom-container-info'>
                <div>Logged in as: <span className='bottom-info-values'>{username}</span></div>
                <div>Branch ID: <span className='bottom-info-values'>{branchID}</span></div>
                <div>Branch Name: <span className='bottom-info-values'>{branchName}</span></div>
            </div>
            <button className="logout-button" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
};

export default BottomContainer;
