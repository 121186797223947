import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CustomerModal.css';
import MessageModal from './MessageModal';

function CustomerModal({ isOpen, onClose, onSubmit, employee, regular, user, branch }) {
    const [formData, setFormData] = useState({
        custLName: '',
        custFName: '',
        custMName: '',
        custAddr: '',
        custEmail: ''
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [customer, setCustomers] = useState([]);
    const [messageModalOpen, setMessageModalOpen] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);

    useEffect(() => {
        // Fetch customer information
        axios.get('http://localhost:8081/api/regularcustomers')
            .then(response => setCustomers(response.data))
            .catch(error => console.error('Error fetching customers:', error));
        
    }, []);
    
    useEffect(() => {
        if (regular) {
            setIsEditing(true);
            setFormData({
                custLName: regular.custLName || '',
                custFName: regular.custFName || '',
                custMName: regular.custMName || '',
                custAddr: regular.custAddr || '',
                custEmail: regular.custEmail || ''
            });
        } else {
            setIsEditing(false);
            setFormData({
                custLName: '',
                custFName: '',
                custMName: '',
                custAddr: '',
                custEmail: ''
            });
        }
    }, [regular]);
    
    useEffect(() => {
        console.log('FormData updated:', formData);
    }, [formData]);
    
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'photo') {
            setFormData(prevState => ({
                ...prevState,
                photo: files[0]
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const removePhoto = () => {
        setFormData(prevState => ({ ...prevState, photo: null }));
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${month}/${day}/${year}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Use appropriate URL and HTTP method based on the operation
        const url = isEditing
            ? `https://www.capstonelimenerp.xyz:8082/api/customers/${regular.custNo}` 
            : 'https://www.capstonelimenerp.xyz:8082/api/customers';
        
        const method = isEditing ? 'put' : 'post';
    
        // Prepare the data to send, with custBalance defaulted to 0.00 for new customers
        const data = {
            custLName: formData.custLName,
            custFName: formData.custFName,
            custMName: formData.custMName,
            custAddr: formData.custAddr,
            custEmail: formData.custEmail,
            custBalance: isEditing ? regular.custBalance : 0.00, // Default balance to 0.00 when adding
        };
    
        console.log('Sending data:', data);
    
        try {
            const response = await axios({ method, url, data });
            console.log('Response:', response.data);
    
            setMessage(isEditing ? 'Customer updated successfully!' : 'Customer added successfully!');
            setFormData({
                custLName: '',
                custFName: '',
                custMName: '',
                custAddr: '',
                custEmail: '',
            });
            onClose();
            onSubmit(data, isEditing);
        } catch (error) {
            console.error(isEditing ? 'Error updating customer:' : 'Error adding customer:', error);
            setMessage(
                (isEditing ? 'Error updating customer: ' : 'Error adding customer: ') + 
                (error.response?.data?.message || error.message)
            );
            setMessageModalOpen(true);
        }
    };
    
    
    
    const closeMessageModal = () => {
        setMessageModalOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="customer-modal-overlay">
            <div className="customer-modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <div className="form-container">
                    <div className="left-section" style={{width: '100%', height: '100%'}}>
                        <h2>{isEditing ? 'Update Customer' : 'Add New Customer'}</h2>
                        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="custFName"><strong>First Name <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        name="custFName"
                                        value={formData.custFName}
                                        onChange={handleChange}
                                        required={!isEditing}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="custMName"><strong>Middle Name</strong></label>
                                    <input
                                        type="text"
                                        name="custMName"
                                        value={formData.custMName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="custLName"><strong>Last Name <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        name="custLName"
                                        value={formData.custLName}
                                        onChange={handleChange}
                                        required={!isEditing}
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="custAddr"><strong>Address <span style={{ color: 'red' }}>*</span></strong></label>
                                    <input
                                        type="text"
                                        name="custAddr"
                                        value={formData.custAddr}
                                        onChange={handleChange}
                                        required={!isEditing}
                                    />
                                </div>
                            </div>
                            <div className="depth">
                                <div className="form-group">
                                    <label htmlFor="custEmail"><strong>Email</strong></label>
                                    <input
                                        type="email"
                                        name="custEmail"
                                        value={formData.custEmail}
                                        onChange={handleChange}
                                    />
                                    {formData.custEmail && !/\S+@\S+\.\S+/.test(formData.custEmail) && (
                                        <p className="error-message">Please enter a valid email.</p>
                                    )}
                                </div>
                            </div>
                        <div className='button-container'>
                            <button type="submit" className="customer-button">{isEditing ? 'Save Customer' : 'Add Customer'}</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <MessageModal 
                isOpen={messageModalOpen} 
                onClose={closeMessageModal} 
                message={message} 
                showCloseButton={showCloseButton} // Pass showCloseButton to MessageModal
            />
        </div>
    );
}
export default CustomerModal;