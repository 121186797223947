import React, { useState } from 'react';
import './AuthorizeModal.css';
import MessageModal from './MessageModal';

function AuthorizeModal({ isOpen, onClose, onAuthorize }) {
    const [authCredentials, setAuthCredentials] = useState({ username: '', password: '' });
    const [authError, setAuthError] = useState('');
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);

    const clearFields = () => {
        setAuthCredentials({ username: '', password: '' });
        setAuthError('');
        setMessage('');
        setIsMessageOpen(false);
    };

    const handleClose = () => {
        clearFields(); // Clear fields before closing
        onClose(); // Execute the passed onClose function
    };

    const handleAuthorize = async () => {
        try {
            const response = await fetch('https://www.capstonelimenerp.xyz:8082/api/authorize', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(authCredentials),
            });
            
            if (response.ok) {
                const data = await response.json();
                if (data.isAuthorized) {
                    setMessage('Authorization successful!');
                    setIsConfirmation(false); // Show only the close button
                    setIsMessageOpen(true);
                    onAuthorize(); // Proceed with the authorized action
                    onClose(); // Close the modal
                } else {
                    setMessage('Authorization failed. Only Admin or Branch Manager can approve.');
                    setIsConfirmation(false); // Show only the close button
                    setIsMessageOpen(true);
                }
            } else {
                setMessage('Error verifying authorization. Please try again.');
                setIsConfirmation(false); // Show only the close button
                setIsMessageOpen(true);
            }
        } catch (error) {
            console.error('Error during authorization:', error);
            setMessage('An error occurred. Please try again.');
            setIsConfirmation(false); // Show only the close button
            setIsMessageOpen(true);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="auth-modal-overlay">
            <div className="auth-modal-content">
                <h3>Authorization Required</h3>
                <p>Only Admin or Branch Manager can approve this action.</p>
                <input 
                    type="text" 
                    placeholder="Enter your username" 
                    value={authCredentials.username} 
                    onChange={(e) => setAuthCredentials({ ...authCredentials, username: e.target.value })} 
                />
                <input 
                    type="password" 
                    placeholder="Enter your password" 
                    value={authCredentials.password} 
                    onChange={(e) => setAuthCredentials({ ...authCredentials, password: e.target.value })} 
                />
                <div className="auth-modal-actions">
                    <button className="authorize-button" onClick={handleAuthorize}>
                        Authorize
                    </button>
                    <button className="cancel-auth-button" onClick={handleClose}>
                        Cancel
                    </button>
                </div>
            </div>
            {/* MessageModal */}
            <MessageModal
                isOpen={isMessageOpen}
                onClose={() => setIsMessageOpen(false)}
                message={message}
                isConfirmation={isConfirmation}
                onConfirm={() => {}}
            />
        </div>
    );
}

export default AuthorizeModal;
