import React, { useState, useEffect } from 'react';
import './CheckOutModal.css';
import DiscountModal from './DiscountModal';
import PaymentModal from './PaymentModal';
import EwalletReferenceModal from './EwalletReferenceModal';
import MessageModal from './MessageModal';
import axios from 'axios';

function CheckOutModal({ totalDue, originalTotal, onClose, onCheckout, onSaveDiscount, discountData, loggedInUserID, branchID, customer, setCustomer, cartItems, clearCart, resetCustomerSelection }) {
    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [originalPrice, setOriginalPrice] = useState(totalDue);
    const [discountApplied, setDiscountApplied] = useState(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null); // New state for payment type
    const [saveAsDebt, setSaveAsDebt] = useState(false); // State for "Save as debt" checkbox
    const [shouldCloseCheckoutModal, setShouldCloseCheckoutModal] = useState(false);
    
    const [isEwalletModalOpen, setIsEwalletModalOpen] = useState(false); // State for Ewallet modal

    console.log('Total due in CheckOutModal:', totalDue);

    const handleOpenDiscountModal = () => {
        setIsDiscountModalOpen(true);
    };

    const handleCloseDiscountModal = () => {
        console.log('Closing discount modal');
        setIsDiscountModalOpen(false);
    };

    const handleCloseMessageModal = () => {
        setIsMessageModalOpen(false); // Close the message modal
        if (shouldCloseCheckoutModal) {
            onClose(); // Close the checkout modal if flag is set
            setShouldCloseCheckoutModal(false); // Reset flag
        }
    };
    
    
    const handleSaveDiscount = (data) => {
        onSaveDiscount(data); // Update discount in SalesNewCart
        setIsDiscountModalOpen(false);
    };
    
    
    
    const handlePaymentOptionSelect = (paymentType) => {
        if (saveAsDebt) {
            setMessage("You cannot select a payment method when saving as debt.");
            setIsMessageModalOpen(true);
            return;  // Prevent selection if saving as debt
        }
        setSelectedPaymentType(paymentType);
    };
    
    
    
    const handleSaveAsDebtChange = (e) => {
        if (!customer || customer.custType === 'Walk-In') {
            setMessage("Please select a registered customer to save this sale as debt.");
            setIsMessageModalOpen(true);
            setSaveAsDebt(false); // Ensure state remains false
            return;
        }
        
        setSaveAsDebt(e.target.checked);
        if (e.target.checked) {
            setSelectedPaymentType(null); // Clear payment type if debt is selected
        }
    };
    
    
    useEffect(() => {
        if (customer?.custType === 'Walk-In') {
            console.log("Customer type is walk-in. Resetting saveAsDebt to false.");
            setSaveAsDebt(false); // Reset saveAsDebt if the customer changes to walk-in
        }
    }, [customer]);
    
    
    
    
    // Function to initiate checkout and open PaymentModal if needed
    const handleCheckout = async () => {
        console.log("Starting checkout process...");
        console.log("Cart items before checkout:", cartItems);
        if (!cartItems || cartItems.length === 0) {
            setMessage("Your cart is empty. Please add items to the cart before checking out.");
            setIsMessageModalOpen(true);
            return;
        }
    
        // Skip payment option check if 'Save as Debt' is selected
        if (!saveAsDebt && !selectedPaymentType) {
            setMessage("Please select a payment option.");
            setIsMessageModalOpen(true);
            console.log("Checkout prevented: No payment option selected.");
            return;
        }
    
        let customerID = customer?.custNo;
    
        // If no customer is selected, create a new walk-in customer
        if (!customerID) {
            try {
                const response = await axios.post('https://www.capstonelimenerp.xyz:8082/api/defaultcustomer', { 
                    custType: 'Walk-In', 
                    custContNo: null 
                });
                customerID = response.data.custNo;
                console.log("New walk-in customer created with ID:", customerID);
    
                // Update customer state with new walk-in customer
                setCustomer({ custNo: customerID, custType: 'Walk-In' });
            } catch (error) {
                console.error("Error creating walk-in customer:", error);
                setMessage("Error creating walk-in customer. Please try again.");
                setIsMessageModalOpen(true);
                return;
            }
        }
    
        if (saveAsDebt) {
            // Directly commit the sale as debt without opening PaymentModal
            const salesData = {
                dateOfSale: new Date().toISOString(),
                grandTotal: totalDue,
                paymentStatus: 'Pending',
                paymentType: null,
                cust_No_fk: customerID,
                userID_fk: loggedInUserID,
                branch_ID_fk: branchID,
                items: cartItems.map(item => ({
                    prodNo: item.prodNo,
                    quantity: item.quantity,
                    prodPrice: item.prodPrice,
                    amount: (item.prodPrice * item.quantity),
                    prodCost: item.prodCost
                }))
            };
            const success = await commitSale(salesData); // Commit directly when saving as debt

            if (success) {
                setMessage("Sale saved as debt successfully.");
                setIsMessageModalOpen(true);
                resetCustomerSelection();
                setShouldCloseCheckoutModal(true); // Set flag to close modal after message is viewed

                // Reset discount after successful payment
                onSaveDiscount({ discount: 0, isPercentage: false });
            }
        } else if (selectedPaymentType === 'Ewallet') {
            // Open EwalletReferenceModal for Ewallet payments
            setIsEwalletModalOpen(true);
            console.log("Opening Ewallet Reference Modal for transaction.");
        } else {
            // Open PaymentModal for immediate payment if not saving as debt
            setIsPaymentModalOpen(true);
            console.log("Opening Payment Modal for transaction.");
        }
    };
    
    const handleEwalletPaymentCompletion = async (referenceNo) => {
        setIsEwalletModalOpen(false); // Close EwalletReferenceModal
    
        const salesData = {
            dateOfSale: new Date().toISOString(),
            grandTotal: totalDue,
            paymentStatus: 'Paid',
            paymentType: 'Ewallet',
            externalDocument: referenceNo, // Store reference number as external document
            cust_No_fk: customer?.custNo,
            userID_fk: loggedInUserID,
            branch_ID_fk: branchID,
            items: cartItems.map(item => ({
                prodNo: item.prodNo,
                quantity: item.quantity,
                prodPrice: item.prodPrice,
                amount: (item.prodPrice * item.quantity),
                prodCost: item.prodCost
            }))
        };
    
        const success = await commitSale(salesData);
        if (success) {
            setMessage("Ewallet payment completed successfully.");
            setIsMessageModalOpen(true); // Show confirmation message
            resetCustomerSelection();
            setShouldCloseCheckoutModal(true); // Set flag to close checkout modal after message
    
            // Reset discount after successful payment
            onSaveDiscount({ discount: 0, isPercentage: false });
        }
    };
    
    
    // Function to finalize sale after confirming payment
    const handlePaymentCompletion = async (tenderedAmount) => {
        setIsPaymentModalOpen(false); // Close PaymentModal
        const salesData = {
            dateOfSale: new Date().toISOString(),
            grandTotal: totalDue,
            paymentStatus: 'Paid',
            paymentType: selectedPaymentType,
            cust_No_fk: customer?.custNo,
            userID_fk: loggedInUserID,
            branch_ID_fk: branchID,
            items: cartItems.map(item => ({
                prodNo: item.prodNo,
                quantity: item.quantity,
                prodPrice: item.prodPrice,
                amount: (item.prodPrice * item.quantity),
                prodCost: item.prodCost
            }))
        };
    
        const success = await commitSale(salesData);
        if (success) {
            setMessage("Payment completed successfully.");
            setIsMessageModalOpen(true); // Show confirmation message
            resetCustomerSelection();
            setShouldCloseCheckoutModal(true); // Set flag to close checkout modal after message

            // Reset discount after successful payment
            onSaveDiscount({ discount: 0, isPercentage: false });
        }
    };
    

    // Function to commit sale data to API
    const commitSale = async (salesData) => {
        console.log("Attempting to commit sale with data:", salesData);
        try {
            const response = await axios.post('https://www.capstonelimenerp.xyz:8082/api/sales/commit', salesData);
            clearCart();
            setCustomer(null);
            setMessage("Sale committed successfully.");
            setIsMessageModalOpen(true);
            console.log("Sale committed successfully.");
            return true; // Indicate success
        } catch (error) {
            setMessage("Error committing sale. Please try again.");
            setIsMessageModalOpen(true);
            console.error("Error committing sale:", error);
            return false; // Indicate failure
        }
    };
    
        

    return (
        <div className="checkout-modal-overlay">
            <div className="checkout-modal">
                {isDiscountModalOpen && (
                    <DiscountModal
                        subtotal={originalTotal} // Use the discounted total
                        onClose={handleCloseDiscountModal}
                        onSave={handleSaveDiscount}
                        discount={discountData.discount} // Pass saved discount value
                        isPercentage={discountData.isPercentage} // Pass percentage mode
                    />
                )}
                {isPaymentModalOpen && (
                    <PaymentModal
                        totalDue={totalDue}
                        onClose={() => setIsPaymentModalOpen(false)}
                        onConfirmPayment={handlePaymentCompletion}
                    />
                )}
                {isEwalletModalOpen && (
                    <EwalletReferenceModal
                        isOpen={isEwalletModalOpen}
                        onClose={() => setIsEwalletModalOpen(false)}
                        onConfirm={handleEwalletPaymentCompletion} // Pass the handler for Ewallet payment
                    />
                )}
                {isMessageModalOpen && (
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={handleCloseMessageModal} // Use the modified handler
                        message={message}
                    />
                )}
                <button className="close-button" onClick={onClose}>✕</button>
                <label className="checkout-label">Total Due:</label>

                {/* Display original and discounted totals */}
                {discountData.discount > 0 ? (
                    <>
                        <p className="total-amount">₱{totalDue.toFixed(2)}</p>
                        <p className="original-amount" style={{ textDecoration: 'line-through', color: 'red' }}>
                            ₱{originalTotal.toFixed(2)}
                        </p>
                    </>
                ) : (
                    <p className="total-amount">₱{totalDue.toFixed(2)}</p>
                )}

                <span onClick={handleOpenDiscountModal} className="discount-text">
                    Discount
                </span>


                <div className="payment-options">
                    <button
                        className={`payment-option ${selectedPaymentType === 'Cash' ? 'selected' : ''}`}
                        onClick={() => handlePaymentOptionSelect('Cash')}
                        disabled={saveAsDebt}  // Disable if saving as debt
                    >
                        <span className="icon">💵</span>
                        <label className="payment-options-label">Cash</label>
                    </button>

                    <button
                        className={`payment-option ${selectedPaymentType === 'Ewallet' ? 'selected' : ''}`}
                        onClick={() => handlePaymentOptionSelect('Ewallet')}
                        disabled={saveAsDebt}  // Disable if saving as debt
                    >
                        <span className="icon">📱</span>
                        <label className="payment-options-label">E-Wallet</label>
                    </button>
                </div>

                <div className="additional-options">
                    <label>
                        <input
                            type="checkbox"
                            checked={customer?.custType === 'Walk-In' ? false : saveAsDebt}
                            onChange={(e) => handleSaveAsDebtChange(e)}
                            disabled={customer?.custType === 'Walk-In'}
                        /> Save as debt
                    </label>
                </div>


                <button className="checkout-button" onClick={handleCheckout}>
                    Checkout
                </button>
            </div>
        </div>
    );
}

export default CheckOutModal;
