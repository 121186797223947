import React, { useState } from 'react';
import MessageModal from './MessageModal';

function EwalletReferenceModal({ isOpen, onClose, onConfirm }) {
    const [referenceNo, setReferenceNo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isMessageOpen, setIsMessageOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);

    const clearFields = () => {
        setReferenceNo('');
        setErrorMessage('');
        setMessage('');
        setIsMessageOpen(false);
    };

    const handleClose = () => {
        clearFields(); // Clear fields before closing
        onClose(); // Execute the passed onClose function
    };

    const handleConfirm = () => {
        if (!referenceNo.trim()) {
            setMessage('Reference number cannot be empty.');
            setIsMessageOpen(true); // Open the MessageModal with the error
            return;
        }
        onConfirm(referenceNo); // Pass the reference number back to the parent
        handleClose(); // Close the modal after confirming
    };

    if (!isOpen) return null;

    return (
        <div className="auth-modal-overlay">
            <div className="auth-modal-content">
                <h3>Ewallet Reference Number</h3>
                <p>Please provide the reference number for the Ewallet payment.</p>
                <input
                    type="text"
                    placeholder="Enter Ewallet reference number"
                    value={referenceNo}
                    onChange={(e) => setReferenceNo(e.target.value)}
                />
                <div className="auth-modal-actions">
                    <button className="authorize-button" onClick={handleConfirm}>
                        Confirm
                    </button>
                    <button className="cancel-auth-button" onClick={handleClose}>
                        Cancel
                    </button>
                </div>
            </div>
            {/* MessageModal */}
            <MessageModal
                isOpen={isMessageOpen}
                onClose={() => setIsMessageOpen(false)}
                message={message}
                isConfirmation={isConfirmation}
                onConfirm={() => {}}
            />
        </div>
    );
}

export default EwalletReferenceModal;
