import React, { useState, useEffect } from 'react';
import './Login.css';
import { useNavigate, Link } from 'react-router-dom';
import Validation from './LoginValidation';
import axios from 'axios';
import { FaEye, FaEyeSlash, FaUser, FaKey } from 'react-icons/fa';
import LimenLogo from './LimenLogo.png';
import moment from 'moment-timezone';
import MessageModal from './MessageModal';

function Login() {
    const [values, setValues] = useState({ userName: '', userPassword: '' });
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('login');

    const [searchQuery, setSearchQuery] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [refreshTable, setRefreshTable] = useState(false);
    const [manilaTime, setManilaTime] = useState(moment.tz("Asia/Manila").format('HH:mm:ss')); 

    const [isClockingOut, setIsClockingOut] = useState(false); // To track clock-out action
    const [isClockingIn, setIsClockingIn] = useState(false); // To track clock-in action
    const [timeInOutMessage, setTimeInOutMessage] = useState(''); // Confirmation message

    const navigate = useNavigate();

    // Update the time every second
    useEffect(() => {
        const timer = setInterval(() => {
            setManilaTime(moment.tz("Asia/Manila").format('HH:mm:ss'));
        }, 1000);
        return () => clearInterval(timer); // Clean up the timer when the component is unmounted
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleInput = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = Validation(values);
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true); // Start loading
            axios.post('https://www.capstonelimenerp.xyz:8082/login', values)
                .then(res => {
                    setIsLoading(false); 
                    if (res.data.message === "Success") {
                        localStorage.setItem('userID', res.data.userID);
                        localStorage.setItem('userType', res.data.userType);
                        localStorage.setItem('branchID', res.data.branchID);
                        localStorage.setItem('branchName', res.data.branchName);
                        localStorage.setItem('username', values.userName);
            
                        if (res.data.userType === 'Employee') {
                            localStorage.setItem('empPosition', res.data.empPosition);
                        }
    
                        // Redirect based on empPosition
                        const empPosition = res.data.empPosition;
                        if (empPosition === 'Cashier') {
                            navigate('/sales/new');
                        } else if (empPosition === 'Salesperson') {
                            navigate('/inventory/products');
                        } else if (empPosition === 'Purchase Officer') {
                            navigate('/purchase/order');
                        } else if (empPosition === 'Branch Manager') {
                            navigate('/dashboard');
                        } else if (res.data.userType === 'Admin') {
                            navigate('/dashboard');
                        } else {
                            // Default route in case no specific empPosition is found
                            navigate('/');
                        }
                    } else {
                        setModalMessage("No user found with the provided credentials.");
                        setIsModalOpen(true);
                    }
                })
                .catch(err => {
                    setIsLoading(false);
                
                    // Check if the response contains a specific error message
                    if (err.response && err.response.data && err.response.data.message) {
                        setModalMessage(err.response.data.message); // Use the API-provided message
                    } else if (err.response && err.response.status === 401) {
                        setModalMessage("The username or password you entered is incorrect. Please try again.");
                    } else {
                        setModalMessage("An error occurred during login. Please try again.");
                    }
                
                    setIsModalOpen(true);
                });
        }
    };
    
    
    // In your return statement, conditionally render the button:
    <button type='submit' className='btn-submit' disabled={isLoading}>
        {isLoading ? 'Logging in...' : 'Log in'}
    </button>

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Store the search input in state
    };

    const handleCheckEmployee = async () => {
        const empID = searchQuery.trim(); // Use the entered employee ID
        if (!empID) {
            setModalMessage("Please enter an Employee ID.");
            setIsModalOpen(true);
            return;
        }
        
        try {
            // Make a request to check if the employee exists
            const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/employees/${empID}`);
            
            if (response.status === 200 && response.data) {
                // Employee exists
                return true;
            } else {
                // If no data is found, employee doesn't exist
                setModalMessage("Employee not found.");
                setIsModalOpen(true);
                return false;
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Handle 404 error (employee not found)
                setModalMessage("Employee not found.");
                setIsModalOpen(true);
                return false;
            } else {
                // Handle other errors
                setModalMessage("An error occurred while checking the employee.");
                setIsModalOpen(true);
                console.error("Error checking employee:", error);
                return false;
            }
        }
    };
    

    const handleTimeInOut = async () => {
        const empID = searchQuery.trim();
    
        // Check if employee exists before proceeding with clock in/out
        const employeeExists = await handleCheckEmployee(); 
        if (!employeeExists) {
            // If employee does not exist, stop the function
            return;
        }

        const currentTimestamp = moment.tz("Asia/Manila").format('YYYY-MM-DD HH:mm:ss');
        const attendanceDate = moment().tz("Asia/Manila").format('YYYY-MM-DD');

        try {
            const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/attendance_logs/${empID}/${attendanceDate}`);
            const existingLog = response.data;

            if (!existingLog || existingLog.length === 0) {
                // Confirm clock-in if no log exists for today
                setIsClockingIn(true);
                setIsConfirmation(true);
                setModalMessage("Are you sure you want to clock in?");
                setIsModalOpen(true); // Show confirmation modal
            } else {
                const log = existingLog[0];

                if (!log.timeOut) {
                    // Confirm clock-out if timeOut is not set
                    setIsClockingOut(true);
                    setIsConfirmation(true);
                    setModalMessage("Are you sure you want to clock out?");
                    setIsModalOpen(true); // Show confirmation modal
                } else {
                    setModalMessage("You have already clocked out for today.");
                    setIsModalOpen(true); // Show error message
                }
            }
        } catch (error) {
            setModalMessage("An error occurred. Please try again.");
            setIsModalOpen(true);
            console.error('Error recording attendance:', error);
        }
    };

    const handleConfirmTimeInOut = async () => {
        const empID = searchQuery.trim();
        const currentTimestamp = moment.tz("Asia/Manila").format('YYYY-MM-DD HH:mm:ss');
        const attendanceDate = moment().tz("Asia/Manila").format('YYYY-MM-DD');
    
        if (isClockingIn) {
            // Set modal message to "You have clocked in" immediately
            setModalMessage("You have clocked in.");
        } else if (isClockingOut) {
            // Set modal message to "You have clocked out" immediately
            setModalMessage("You have clocked out.");
        }
    
        // Update the modal first, then handle the API call in the background
        setIsConfirmation(false); // Disable confirmation buttons
        setTimeout(() => {
            setIsModalOpen(false); // Close modal after a short delay
            setSearchQuery(''); // Clear search input
            setIsClockingIn(false); // Reset clocking states
            setIsClockingOut(false);
        }, 2000); // Keep modal open for 2 seconds to display success message
    
        // Handle the API call
        try {
            if (isClockingIn) {
                // Clock in
                const attendanceData = {
                    emp_id_fk: empID,
                    timeIn: currentTimestamp,
                    timeOut: null,
                    attendanceDate: attendanceDate,
                };
                await axios.post('https://www.capstonelimenerp.xyz:8082/api/attendance_logs', attendanceData);
            } else if (isClockingOut) {
                // Clock out
                const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/attendance_logs/${empID}/${attendanceDate}`);
                const log = response.data[0];
    
                const attendanceData = {
                    emp_id_fk: empID,
                    timeIn: log.timeIn,
                    timeOut: currentTimestamp,
                    attendanceDate: attendanceDate,
                };
                await axios.put(`https://www.capstonelimenerp.xyz:8082/api/attendance_logs/${log.attendanceID}`, attendanceData);
            }
        } catch (error) {
            // If there's an error, update the modal with the error message
            setModalMessage("An error occurred. Please try again.");
            console.error('Error confirming time-in/out:', error);
        }
    };
    

    const closeModal = () => {
        setIsModalOpen(false);
        setIsClockingIn(false);
        setIsClockingOut(false);
        setModalMessage(''); // Clear the message
    };

    return (
        <div className='login-page'>
            <div className='login-container'>
                <MessageModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    message={modalMessage}
                    isConfirmation={isConfirmation} // Pass confirmation state
                    onConfirm={handleConfirmTimeInOut} // Confirm clock-in/out action
                />
                <div className="tab-navigation">
                    <div 
                        className={activeTab === 'login' ? 'tab active' : 'tab'}
                        onClick={() => handleTabChange('login')}>
                        Login
                    </div>
                    <div 
                        className={activeTab === 'time-in-out' ? 'tab active' : 'tab'}
                        onClick={() => handleTabChange('time-in-out')}>
                        Time In/Out
                    </div>
                </div>
                <div className='login-content'>
                    <div className='login-logo'>
                        <div className="logo-placeholder">
                            <img src={LimenLogo} alt="Limen ERP Logo" className="logo-image" />
                        </div>
                    </div>
                    {activeTab === 'login' && (
                        <div className='login-form'>
                            <h2 className='form-title'>Login</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group'>
                                    <label htmlFor="userName" className='form-label'>Username</label>
                                    <div className='input-group'>
                                        <input
                                            type="text"
                                            placeholder='Enter Username'
                                            name='userName'
                                            onChange={handleInput}
                                            value={values.userName}
                                            className={`form-control ${errors.userName ? 'is-invalid' : ''}`}
                                            autoComplete="username"  // Add this attribute
                                        />
                                        <span className="form-icons">
                                            <FaUser />
                                        </span>
                                    </div>
                                    {errors.userName && <span className='error-text'>{errors.userName}</span>}
                                </div>
                                <div className='form-group'>
                                    <label htmlFor="userPassword" className='form-label'>Password</label>
                                    <div className="input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder='Enter Password'
                                            name='userPassword'
                                            onChange={handleInput}
                                            value={values.userPassword}
                                            className={`form-control ${errors.userPassword ? 'is-invalid' : ''}`}
                                            autoComplete="current-password"  // Add this attribute
                                        />
                                        <span className="form-icons" onClick={toggleShowPassword}>
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    {errors.userPassword && <span className='error-text'>{errors.userPassword}</span>}
                                </div>
                                {loginError && <div className='error-alert'>{loginError}</div>}
                                <button type='submit' className='btn-submit'>
                                    Log in
                                </button>
                            </form>
                        </div>
                    )}

                    {activeTab === 'time-in-out' && (
                        <div className='login-form'>
                            <div className='manila-time'>
                                <p>Current Time (Manila): {manilaTime}</p>
                            </div>
                            <h2 className='form-title'>Attendance</h2>
                                <div className='attendance-form-group'>
                                    <label htmlFor="empID" className='form-label'>Employee ID</label>
                                    <div className='input-group'>
                                        <input
                                            type="text"
                                            placeholder='Enter Employee ID'
                                            name='empID'
                                            className='form-control'
                                            value={searchQuery} 
                                            onChange={handleSearchChange} 
                                        />
                                        <span className="form-icons" onClick={handleCheckEmployee}>
                                            <FaKey />
                                        </span>
                                    </div>
                                </div>
                                <button className='btn-submit' onClick={handleTimeInOut}>Clock In/Out</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Login;
