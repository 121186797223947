import React, { useState, useEffect, useRef } from 'react';
import './CustomerPayment.css';
import { Link, useNavigate } from 'react-router-dom';
import MessageModal from './MessageModal';
import Pagination from './Pagination';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';
import CustomerTransactionModal from './CustomerTransactionModal';
import axios from 'axios';
import { FaEye } from 'react-icons/fa';
function CustomerPayment() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [filter, setFilter] = useState('all');
    const [customers, setCustomers] = useState([]);
    const [salesHistory, setSalesHistory] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [activeTab, setActiveTab] = useState('payments');
    const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
    const [selectedTransactionID, setSelectedTransactionID] = useState(null);
    const [selectedSale, setSelectedSale] = useState(null); // To hold the selected sale details
    const [lineItems, setLineItems] = useState([]); // To hold the line items for the selected sale
    
    const searchContainerRef = useRef(null);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setSearchResults([]); // Clear search results
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');

        if (storedUsername) setUsername(storedUsername);
        if (storedBranchName) setBranchName(storedBranchName);
        if (storedBranchID) setBranchID(storedBranchID);
    }, []);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/regularcustomers');
                setCustomers(response.data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };
        fetchCustomers();
    }, []);

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
    
        // Filter customers based on search term
        const filtered = customers.filter(customer => {
            const fullName = `${customer.custFName} ${customer.custLName}`.toLowerCase();
            return fullName.includes(term.toLowerCase());
        });
    
        setSearchResults(filtered);
        setSelectedCustomer(null); // Clear selectedCustomer if search changes
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleSelectCustomer = (customer) => {
        if (customer) {
            setSelectedCustomer(customer);
            setSearchTerm(`${customer.custFName} ${customer.custLName}`);
            setSearchResults([]); // Clear search results
            fetchSalesHistory(customer.custNo); // Fetch sales history for the selected customer
        }
    };
    
    // Check for correct data flow and API response in the console for debugging
    console.log('Selected customer:', selectedCustomer);
    console.log('Sales history data:', salesHistory);


    const fetchSalesHistory = async (custNo) => {
        try {
            const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/sales_history/${custNo}`);
            setSalesHistory(response.data);
        } catch (error) {
            console.error('Error fetching sales history:', error);
            setMessage('Failed to load sales history.');
            setIsMessageModalOpen(true);
        }
    };
    
    const updateTransactionStatus = (updatedSale) => {
        setSalesHistory((prevSalesHistory) =>
            prevSalesHistory.map((transaction) =>
                transaction.oRNo === updatedSale.oRNo ? updatedSale : transaction
            )
        );
    
        // Update the currently selected transaction (if open in CustomerTransactionModal)
        if (selectedTransactionID === updatedSale.oRNo) {
            setSelectedSale(updatedSale); // Update the selected sale
        }
    };
    const refreshParent = () => {
        if (selectedCustomer) {
            fetchSalesHistory(selectedCustomer.custNo);
        }
    };
    
    const viewTransaction = async (transaction) => {
        try {
            console.log("Viewing transaction:", transaction); // Log the sale being viewed
            setSelectedSale(transaction); // Set the selected sale
    
            // Fetch line items for the sale
            const response = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/sales/${transaction.oRNo}/lineitems`);
            console.log("Fetched line items:", response.data); // Log line items from API
    
            setLineItems(response.data || []); // Store line items separately in state
            setIsTransactionModalOpen(true); // Open modal only after line items are fetched
        } catch (error) {
            console.error("Error fetching line items:", error);
        }
    };
    
    
    
    
    
    const closeTransactionModal = () => {
        setIsTransactionModalOpen(false);
        setSelectedSale(null); // Clear the selected sale
        setLineItems([]); // Clear line items
    };
    


    // Filter and search logic
    const filteredCustomers = customers
        .filter(customer => 
            (filter === 'all' || customer.custType === filter) &&
            (`${customer.custFName} ${customer.custLName}`.toLowerCase().includes(searchTerm.toLowerCase()))
        );

    const filteredSalesHistory = salesHistory.filter((transaction) => {
        return filter === 'all' || transaction.paymentStatus === filter;
    });
        
    const handleRecordsChange = (event) => {
        setRecordsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    // Pagination logic for sales history
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedSales = filteredSalesHistory.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(salesHistory.length / recordsPerPage);

    const totalAmount = salesHistory.reduce((acc, transaction) => acc + parseFloat(transaction.grandTotal), 0);
    
    return (
        <div className="main-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => setIsMessageModalOpen(false)}
                        message={message}
                    />
                    {/* Payment Modal */}
                    {isTransactionModalOpen && (
                        <CustomerTransactionModal
                            isOpen={isTransactionModalOpen}
                            onClose={closeTransactionModal}
                            lineItems={lineItems}  // Pass line items separately
                            sale={selectedSale}
                            updateTransactionStatus={updateTransactionStatus}
                            fetchSalesHistory={fetchSalesHistory}
                            refreshParent={refreshParent}
                        />
                    )}

                    <div className="tab-navigation">
                        <Link to="/customer/information" className={activeTab === 'customers' ? 'active' : ''} onClick={() => setActiveTab('customers')}>
                            Customer Information
                        </Link>
                        <Link to="/customer/payments" className={activeTab === 'payments' ? 'active' : ''} onClick={() => setActiveTab('payments')}>
                            Customer Sales History
                        </Link>
                    </div>
                    <div className="form-actions" style={{display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                        <div className="product-details-container" style={{width: '100%'}}>
                            <div className="customer-search-bar" ref={searchContainerRef}>
                                <input
                                    type="text"
                                    placeholder="Search customer by name"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    onFocus={() => searchResults.length > 0 && setSearchResults(searchResults)} // Show results if refocused
                                />
                                {searchResults.length > 0 && (
                                    <div className="search-results-dropdown">
                                        {searchResults.map(customer => (
                                            <div
                                                key={customer.custNo}
                                                onClick={() => handleSelectCustomer(customer)}
                                                className="search-result-item"
                                            >
                                                <span>{customer.custFName} {customer.custLName}</span>
                                                <span className="email">{customer.custEmail}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div style={{ display: 'flex', height: '40px' }}> {/* Set a fixed height here */}
                                <select value={filter} onChange={handleFilterChange} style={{ height: '100%', padding: '8px', fontSize: '16px' }}>
                                    <option value="all">All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Refunded">Refunded</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    
                    {/* Outstanding Balances Table */}
                    <div className="content-table-container">
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Date</th>
                                    <th>Grand Total</th>
                                    <th>Payment Status</th>
                                    <th>Payment Type</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedSales.length > 0 ? (
                                    displayedSales.map(transaction => (
                                        <tr key={transaction.oRNo} className={transaction.paymentStatus === 'Refunded' ? 'refunded-row' : ''}>
                                            <td>{transaction.oRNo}</td>
                                            <td>{new Date(transaction.dateOfSale).toLocaleDateString()}</td>
                                            <td>{transaction.grandTotal.toFixed(2)}</td>
                                            <td>{transaction.paymentStatus}</td>
                                            <td>{transaction.paymentType}</td>
                                            <td>
                                                <button className="view-button" onClick={() => viewTransaction(transaction)}>
                                                    <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} /> {/* Larger and colored icon */}
                                                </button>             
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: 'center' }}>Please Select a Customer.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>


                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName} 
            />
        </div>
    );
}

export default CustomerPayment;
