import React, { useState, useEffect } from 'react';
import './ReportSales.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from './Pagination';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation';
import BottomContainer from './BottomContainer';
import DatePickerModal from './DatePickerModal';
import { FaCalendarAlt } from 'react-icons/fa';

import {
    DailySalesReport,
    HourlySalesReport,
    SalesByProductReport,
    SalesByBranchReport,
    SalesByUserReport,
    SalesByPaymentStatusReport,
    SalesByCustomerReport,
    SalesByPaymentTypeReport
} from './ReportComponents';
import DatePicker from 'react-datepicker';  // Install: npm install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

function ReportSales() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchID, setBranchID] = useState('');
    const [sales, setSales] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [regularCustomers, setRegularCustomers] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [users, setUsers] = useState([]);
    const [products, setProducts] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState('all');
    const [selectedUser, setSelectedUser] = useState('all');
    const [selectedCustomer, setSelectedCustomer] = useState('all');
    const [selectedProduct, setSelectedProduct] = useState('all');
    const [selectedBranch, setSelectedBranch] = useState('all');
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [activeTab, setActiveTab] = useState('sales');

    const navigate = useNavigate();

    const fetchData = async (url, setter) => {
        try {
            const response = await axios.get(url);
            setter(response.data);
            console.log(`Fetched data from ${url}:`, response.data);
        } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
        }
    };

    useEffect(() => {
        // Fetching data from the API
        const fetchCustomers = async () => {
            try {
                const response = await axios.get('https://www.capstonelimenerp.xyz:8082/api/customers');
                const customersData = response.data;
    
                // Filter to get only regular customers
                const filteredRegularCustomers = customersData.filter(customer => customer.custType === 'Regular');
                setRegularCustomers(filteredRegularCustomers);
                setCustomers(customersData); // Keep all customers for the "All" filter
            } catch (error) {
                console.error('Error fetching customer data:', error);
            }
        };
    
        fetchCustomers();
    }, []);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchID = localStorage.getItem('branchID');
        const storedBranchName = localStorage.getItem('branchName');
    
        if (storedUsername) setUsername(storedUsername);
        if (storedBranchID) setBranchID(storedBranchID);
        if (storedBranchName) setBranchName(storedBranchName);
    
        // Fetching sales data
        fetchData('https://www.capstonelimenerp.xyz:8082/api/sales', setSales);
        
        // Fetching customers
        fetchData('https://www.capstonelimenerp.xyz:8082/api/customers', setCustomers);
    
        // Fetching employees
        fetchData('https://www.capstonelimenerp.xyz:8082/api/employees', setEmployees);

        // Fetching employees
        fetchData('https://www.capstonelimenerp.xyz:8082/api/users', setUsers);
        
        // Fetching products
        fetchData('https://www.capstonelimenerp.xyz:8082/api/products', setProducts);
        
        // Fetching branches
        fetchData('https://www.capstonelimenerp.xyz:8082/api/branches', setBranches);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleDateRangeApply = (start, end) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleReportSelection = (reportType) => {
        setSelectedReport(reportType);
    
        // Reset all dropdowns to "All" when switching reports
        setSelectedBranch('all');
        setSelectedEmployee('all');
        setSelectedUser('all');
        setSelectedCustomer('all');
        setSelectedProduct('all');
        setSelectedStatus('all');
    };

    const filteredSales = sales.filter(sale => {
        // Convert sale date to Date object
        const saleDate = new Date(sale.dateOfSale);
        
        // Check if the sale date falls within the selected date range
        const withinDateRange = saleDate >= startDate && saleDate <= endDate;
    
        // Check if the selected branch matches or if "All" is selected
        const matchesBranch = selectedBranch === 'all' || sale.branch_ID_fk === selectedBranch;
        
        // Check if the selected employee matches or if "All" is selected
        const matchesEmployee = selectedEmployee === 'all' || sale.emp_ID_fk === selectedEmployee;
        
        // Check if the selected customer matches or if "All" is selected
        const matchesCustomer = selectedCustomer === 'all' || sale.cust_No_fk === selectedCustomer;

        const matchesUser = selectedUser === 'all' || sale.userID_fk === selectedUser;
        
        // Check if the payment status matches
        const matchesStatus =
            selectedStatus === 'all' ||
            (selectedStatus === 'Paid' && (sale.paymentStatus === 'Paid' || sale.paymentStatus === 'Refunded')) || // Combined logic
            sale.paymentStatus === selectedStatus;
    
        // Return true if all conditions are satisfied
        return withinDateRange && matchesBranch && matchesEmployee && matchesCustomer && matchesStatus && matchesUser;
    });
    
    

    const generateReportContent = () => {
        switch (selectedReport) {
            case 'daily':
                return (
                    <DailySalesReport
                        sales={filteredSales}
                        startDate={startDate} 
                        endDate={endDate} 
                        selectedBranch={selectedBranch}
                        branches={branches}  
                        selectedStatus={selectedStatus}
                    />
                );
            case 'hourly':
                return (
                    <HourlySalesReport 
                        sales={filteredSales} 
                        selectedBranch={selectedBranch} 
                        selectedEmployee={selectedEmployee}
                        startDate={startDate} 
                        endDate={endDate} 
                        branches={branches}
                        employees={employees}
                    />
                );
            case 'products':
                return (
                    <SalesByProductReport 
                        sales={filteredSales} 
                        branches={branches} 
                        startDate={startDate} 
                        endDate={endDate} 
                        selectedBranch={selectedBranch} 
                        selectedCustomer={selectedCustomer}
                        customers={customers}
                        selectedProduct={selectedProduct}
                        products={products}
                        selectedStatus={selectedStatus}
                    />
                );      
            case 'branches':
                return (
                    <SalesByBranchReport
                        sales={filteredSales}
                        selectedBranch={selectedBranch} 
                        selectedEmployee={selectedEmployee}
                        startDate={startDate} 
                        endDate={endDate} 
                        branches={branches} 
                        selectedStatus={selectedStatus}
                    />
                );
            case 'users':
                return <SalesByUserReport
                            sales={filteredSales}
                            startDate={startDate} 
                            endDate={endDate} 
                            users={users}
                            selectedUser={selectedUser}
                            selectedStatus={selectedStatus}
                        />;
            case 'paymentStatus':
                return (
                    <SalesByPaymentStatusReport
                        sales={filteredSales}
                        startDate={startDate}
                        endDate={endDate}
                        selectedBranch={selectedBranch} 
                        selectedUser={selectedUser}
                        selectedCustomer={selectedCustomer}
                        branches={branches}
                        users={users}
                        customers={customers}
                        selectedStatus={selectedStatus}
                    />
                );
                case 'customers':
                    return (
                        <SalesByCustomerReport 
                            sales={filteredSales} 
                            branches={branches} 
                            startDate={startDate} 
                            endDate={endDate} 
                            selectedBranch={selectedBranch} 
                            selectedCustomer={selectedCustomer}
                            selectedStatus={selectedStatus}
                            regularCustomers={regularCustomers} // Pass regular customers
                            customers={customers}
                        />
                    );
                case 'paymentType':
                    return (
                        <SalesByPaymentTypeReport 
                            sales={filteredSales} 
                            branches={branches} 
                            customers={customers}
                            startDate={startDate} 
                            endDate={endDate} 
                            selectedBranch={selectedBranch} 
                            selectedCustomer={selectedCustomer}
                            selectedStatus={selectedStatus}
                        />
                    );
            default:
                return <div className="select-report-message">Please select a report to display.</div>;
        }
    };

    // Identify the selected report
    const isDailySales = selectedReport === 'daily';
    const isHourlySales = selectedReport === 'hourly';
    const isSalesByProduct = selectedReport === 'products';
    const isSalesByBranch = selectedReport === 'branches';
    const isSalesByUser = selectedReport === 'users';
    const isSalesByPaymentStatus = selectedReport === 'paymentStatus';
    const isSalesByCustomers = selectedReport === 'customers';
    const isSalesByPaymentType = selectedReport === 'paymentType';

    // Disable dropdowns based on the selected report
    const isBranchDisabled = 
        isSalesByBranch || 
        isSalesByUser;

    const isProductDisabled = 
        isDailySales || 
        isHourlySales || 
        isSalesByBranch || 
        isSalesByUser || 
        isSalesByPaymentStatus ||
        isSalesByCustomers ||
        isSalesByPaymentType;

    const isCustomerDisabled = 
        isDailySales || 
        isHourlySales || 
        isSalesByBranch || 
        isSalesByUser;

    const isUserDisabled = 
        isDailySales || 
        isHourlySales || 
        isSalesByProduct ||
        isSalesByBranch || 
        isSalesByCustomers ||
        isSalesByPaymentType;

    ;

    return (
        <div className="report-container">
            <TopContainer />
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* DatePicker Modal */}
                    <DatePickerModal 
                        isOpen={isDatePickerModalOpen} 
                        onClose={() => setIsDatePickerModalOpen(false)} 
                        onApply={handleDateRangeApply} 
                    />
                    <div className="tab-navigation">
                        <Link to="/report/sales" className={activeTab === 'sales' ? 'active' : ''} onClick={() => setActiveTab('transactions')}>
                            Sales Reports
                        </Link>
                        <Link to="/report/inventory" className={activeTab === 'inventory' ? 'active' : ''} onClick={() => setActiveTab('orders')}>
                        Inventory Reports
                        </Link>
                        <Link to="/report/purchases" className={activeTab === 'purchases' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Purchase Reports
                        </Link>
                        <Link to="/report/payroll" className={activeTab === 'payroll' ? 'active' : ''} onClick={() => setActiveTab('suggestions')}>
                            Salary Reports
                        </Link>
                    </div>
                    
                    <div className="page-content" style ={{border: 'red'}}>
                        <div className="report-section"> 
                            <div className="left-panel">
                                <div className="first-left-panel">
                                    <div className="filter-section">
                                        <h3>Filter</h3>
                                        <div className="filter-group">
                                            <label>Branch:</label>
                                            <select
                                                value={selectedBranch}
                                                onChange={(e) => setSelectedBranch(e.target.value)}
                                                disabled={isBranchDisabled}
                                            >
                                                <option value="all">All</option>
                                                {branches.map((branch) => (
                                                    <option key={branch.branchID} value={branch.branchID}>
                                                        {branch.branchName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>User:</label>
                                            <select
                                                value={selectedUser}
                                                onChange={(e) => setSelectedUser(e.target.value)}
                                                disabled={isUserDisabled}
                                            >
                                                <option value="all">All</option>
                                                {users.map((user) => (
                                                    <option key={user.userID} value={user.userID}>
                                                        {user.userName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Customer:</label>
                                            <select
                                                value={selectedCustomer}
                                                onChange={(e) => setSelectedCustomer(e.target.value)}
                                                disabled={isCustomerDisabled}
                                            >
                                                <option value="all">All</option>
                                                {regularCustomers.map((cust) => (
                                                    <option key={cust.custNo} value={cust.custNo}>
                                                        {`${cust.custFName} ${cust.custLName}`} {/* Display Full Name */}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Product:</label>
                                            <select
                                                value={selectedProduct}
                                                onChange={(e) => setSelectedProduct(e.target.value)}
                                                disabled={isProductDisabled}
                                            >
                                                <option value="all">All</option>
                                                {products.map((prod) => (
                                                    <option key={prod.prodNo} value={prod.prodNo}>
                                                        {prod.prodName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Payment Status:</label>
                                            <select value={selectedStatus} onChange={handleStatusChange}>
                                                <option value="all">All</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Paid">Paid</option>
                                            </select>
                                        </div>
                                        <div className="filter-group">
                                            <label>Date Range:</label>
                                            <button className="date-range-button" onClick={() => setIsDatePickerModalOpen(true)}>
                                                <FaCalendarAlt className="calendar-icon" /> {/* Calendar Icon */}
                                                {`${startDate ? startDate.toLocaleDateString() : 'Select Start Date'} - ${endDate ? endDate.toLocaleDateString() : 'Select End Date'}`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="second-left-panel">
                                    <div className="report-selection">
                                        <h3>Select Report</h3>
                                        <ul>
                                            <li
                                                onClick={() => handleReportSelection('daily')}
                                                className={selectedReport === 'daily' ? 'active' : ''}
                                            >
                                                Daily Sales
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('hourly')}
                                                className={selectedReport === 'hourly' ? 'active' : ''}
                                            >
                                                Hourly Sales
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('products')}
                                                className={selectedReport === 'products' ? 'active' : ''}
                                            >
                                                Sales by Product
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('branches')}
                                                className={selectedReport === 'branches' ? 'active' : ''}
                                            >
                                                Sales by Branch
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('users')}
                                                className={selectedReport === 'users' ? 'active' : ''}
                                            >
                                                Sales by User
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('customers')}
                                                className={selectedReport === 'customers' ? 'active' : ''}
                                            >
                                                Sales by Customer
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('paymentStatus')}
                                                className={selectedReport === 'paymentStatus' ? 'active' : ''}
                                            >
                                                Sales by Payment Status
                                            </li>
                                            <li
                                                onClick={() => handleReportSelection('paymentType')}
                                                className={selectedReport === 'paymentType' ? 'active' : ''}
                                            >
                                                Sales by Payment Type
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="right-panel">
                            {/* Require date range selection */}
                            {(!startDate || !endDate) ? (
                                <div className="select-report-message">
                                    <p>Please select a date range to view reports.</p>
                                </div>
                            ) : (
                                generateReportContent()
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </SideNavigation>
            <BottomContainer
                username={username}
                branchID={branchID}
                branchName={branchName}
                handleLogout={handleLogout}
            />
        </div>
    );
}

export default ReportSales;
