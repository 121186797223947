// CartContext.js
import React, { createContext, useContext, useState } from 'react';

// Create context
const CartContext = createContext();

// Custom hook for accessing the context
export const useCart = () => useContext(CartContext);

// Provider component
export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    // Function to add items to the cart
    const addToCart = (item) => {
        setCartItems((prevItems) => {
            const existingItemIndex = prevItems.findIndex((cartItem) => cartItem.prodNo === item.prodNo);
            if (existingItemIndex >= 0) {
                // Update quantity if item exists
                const updatedItems = [...prevItems];
                updatedItems[existingItemIndex].quantity += item.quantity;
                return updatedItems;
            } else {
                // Add new item
                return [...prevItems, item];
            }
        });
    };

    // Function to remove items from the cart
    const removeFromCart = (index) => {
        setCartItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    // Function to clear the cart
    const clearCart = () => setCartItems([]);

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
