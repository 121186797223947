import React, { useState, useEffect } from 'react';
import './GlobalStyles.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaTrash } from 'react-icons/fa';
import Pagination from './Pagination'; // Adjust the path as needed
import MessageModal from './MessageModal';
import StockRequestModal from './StockRequestModal';
import TopContainer from './TopContainer';
import SideNavigation from './SideNavigation'
import BottomContainer from './BottomContainer';
function InventoryRequest() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [expandedModule, setExpandedModule] = useState(null);
    const [username, setUsername] = useState('');
    const [branchName, setBranchName] = useState(''); // State to store branch name
    const [branchID, setBranchID] = useState(''); // State to store branch ID
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');
    const [requestData, setRequestData] = useState([]);
    const [users, setUsers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewMode, setIsViewMode] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [lineItems, setLineItems] = useState([]);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [deletionConfirmed, setDeletionConfirmed] = useState(false);
    const [requestToDelete, setRequestToDelete] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [activeTab, setActiveTab] = useState('request'); // Default tab
    
    const [recordsCount, setRecordsCount] = useState(10); // Default to showing 10 records
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10); // Default records per page

    const navigate = useNavigate();

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const storedBranchName = localStorage.getItem('branchName');
        const storedBranchID = localStorage.getItem('branchID');
    
        if (storedUsername) {
            setUsername(storedUsername);
        }
        
        if (storedBranchName) {
            setBranchName(storedBranchName);
        }
        
        if (storedBranchID) {
            setBranchID(storedBranchID);
        } else {
            navigate('/'); // Redirect to login if no branch found
        }

        // Fetch stock request data
        const fetchData = async () => {
            try {
                const [requestResponse, productResponse, branchResponse] = await Promise.all([
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/stockrequests'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/products'),
                    axios.get('https://www.capstonelimenerp.xyz:8082/api/branches')
                ]);
                console.log('Stock Request Data:', requestResponse.data); // Log stock request data
                
                setRequestData(requestResponse.data); // Set stock request data
                setProducts(productResponse.data); // Set product data (optional for request)
                setBranches(branchResponse.data); // Set branch data (optional for request)
            } catch (error) {
                console.error('There was an error fetching data!', error.response || error.message || error);
            }
        };
        fetchData();
        }, [navigate]);

        useEffect(() => {
        if (refreshTable) {
            // Fetch stock requests again from MySQL database
            axios.get('https://www.capstonelimenerp.xyz:8082/api/stockrequests')
                .then(response => {
                    console.log('Stock Request API Response:', response.data);
                    setRequestData(response.data); // Set updated stock request data
                    setRefreshTable(false); // Reset refresh trigger
                })
                .catch(error => {
                    console.error('There was an error refreshing the stock request data!', error.response || error.message || error);
                });
        }
        }, [refreshTable]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        navigate('/');
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleAddRequest = () => {
        setSelectedRequest(null); // Clear selected request
        setLineItems([]); // Clear line items
        setIsModalOpen(true);     // Open the modal for adding a new request
        setIsViewMode(false);     // Ensure the mode is set to add
    };
    
    const handleViewRequest = (request) => {
        setSelectedRequest(request); 
        
        // Fetch the line items for the request
        axios.get(`https://www.capstonelimenerp.xyz:8082/api/stockrequest/${request.ordReqID}/lineitems`)
            .then(async (response) => {
                const items = response.data;
    
                // Fetch the product names for each prodNo
                const itemsWithNames = await Promise.all(items.map(async item => {
                    try {
                        const productResponse = await axios.get(`https://www.capstonelimenerp.xyz:8082/api/products/${item.prodNo}`);
                        return {
                            ...item,
                            selectedProductName: productResponse.data.prodName // Set prodName from API response
                        };
                    } catch (error) {
                        console.error(`Error fetching product by prodNo: ${item.prodNo}`, error);
                        return {
                            ...item,
                            selectedProductName: '' // Fallback if product name isn't found
                        };
                    }
                }));
    
                setLineItems(itemsWithNames);
                setIsModalOpen(true);
                setIsViewMode(true);  // Ensure view mode is set
            })
            .catch(error => {
                console.error('Error fetching line items:', error);
            });
    };
    
    
    const confirmDeleteRequest = (ordReqID) => {
        setRequestToDelete(ordReqID); 
        setMessage('Are you sure you want to delete this stock request?');
        setIsMessageModalOpen(true);
        setShowCloseButton(false); 
        setIsConfirmation(true);  
    };
    
    const handleDeleteRequest = () => {
        if (requestToDelete !== null) {
            axios
                .delete(`https://www.capstonelimenerp.xyz:8082/api/stockrequest/${requestToDelete}`)
                .then(() => {
                    setRequestData(requestData.filter(request => request.ordReqID !== requestToDelete));
                    setMessage('Stock request and associated line items successfully deleted.');
                    setDeletionConfirmed(true);
                    setIsMessageModalOpen(true);
                    setRefreshTable(true);
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || 'Error deleting stock request. Please try again later.';
                    setMessage(errorMessage);
                    setIsMessageModalOpen(true);
                });
        }
    };
    
    
    
    

    const handleModalClose = () => {
        setIsModalOpen(false); // Close the modal
        setRefreshTable(true);  // Trigger a refresh after adding
    };

    const handleModalSubmit = (data, isEditing) => {
        setIsModalOpen(false);
    
        const successMessage = isEditing ? 'Inventory updated successfully!' : 'Request created successfully!';
        setMessage(successMessage);
    
        setShowCloseButton(true); 
        setIsMessageModalOpen(true);
        setIsConfirmation(false); 
        setRefreshTable(true);
    };

    const filteredRequest = requestData.filter(request => {
        const requester = request.ordRequester || ''; // Ensure it's a string
        const requestID = request.ordReqID || ''; // Ensure it's a string
        const branchName = request.branchName || ''; // Ensure it's a string
    
        // Check if any field matches the search query
        const matchesSearch =
            requester.toLowerCase().includes(searchQuery.toLowerCase()) ||
            requestID.toLowerCase().includes(searchQuery.toLowerCase()) ||
            branchName.toLowerCase().includes(searchQuery.toLowerCase());
    
        // Check if the request status matches the selected filter
        const matchesFilter = filter === 'all' || request.requestStatus === filter;
    
        return matchesSearch && matchesFilter;
    });
    
    
    

    const handleRecordsChange = (event) => {
        const selectedValue = parseInt(event.target.value, 10);
        setRecordsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page on records change
    };
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const displayedRequest = filteredRequest.slice(indexOfFirstRecord, indexOfLastRecord);

    const totalPages = Math.ceil(filteredRequest.length / recordsPerPage);

    return (
        <div className="main-container">
            <TopContainer />
            
            <SideNavigation
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                expandedModule={expandedModule}
                setExpandedModule={setExpandedModule}
                handleLogout={handleLogout}
            >

                {/* Main Content Area */}
                <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                    {/* Modal Component */}
                    <StockRequestModal
                        isOpen={isModalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        lineItems={lineItems}
                        request={selectedRequest}
                        isViewMode={isViewMode} // Pass the view mode state
                    />
                    <MessageModal
                        isOpen={isMessageModalOpen}
                        onClose={() => {
                            setIsMessageModalOpen(false);
                            setDeletionConfirmed(false); // Reset when closing
                            setIsConfirmation(false); // Reset confirmation state
                        }}
                        message={message} // Show the appropriate message
                        isConfirmation={isConfirmation} // Pass this state
                        onConfirm={() => {
                            if (isConfirmation) {
                                handleDeleteRequest(); // Only call this if it is a confirmation
                            }
                        }}
                    />
                    <div className="tab-navigation">
                        <Link to="/inventory/products" className={activeTab === 'products' ? 'active' : ''} onClick={() => setActiveTab('products')}>
                            Product List
                        </Link>
                        <Link to="/inventory/stocks" className={activeTab === 'stocks' ? 'active' : ''} onClick={() => setActiveTab('stocks')}>
                            Stocks
                        </Link>
                        <Link to="/inventory/request" className={activeTab === 'request' ? 'active' : ''} onClick={() => setActiveTab('request')}>
                            Stock Request
                        </Link>
                        <Link to="/inventory/transfer" className={activeTab === 'transfer' ? 'active' : ''} onClick={() => setActiveTab('transfer')}>
                            Stock Transfer
                        </Link>
                    </div>
                    <div className='form-actions'>
                        <div className="action-container">
                            <input 
                                type="text" 
                                placeholder="Search branch name" 
                                value={searchQuery} 
                                onChange={handleSearchChange} 
                            />
                            <select value={filter} onChange={handleFilterChange}>
                                <option value="all">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Partially Fulfilled">Partially Fulfilled</option>
                                <option value="Fulfilled">Fulfilled</option>
                            </select>
                            <button className="action-button" onClick={handleAddRequest}>Create Request</button>
                        </div>
                    </div>
                    <div className="records-filter">
                        <label htmlFor="recordsCount">Show</label>
                        <select id="recordsCount" onChange={handleRecordsChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <span> records</span>
                    </div>
                    <div className="content-table-container"> 
                        <table className="content-table">
                            <thead>
                                <tr>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Request ID</th>
                                    <th>Requester</th>
                                    <th>Request Date</th>
                                    <th>Status</th>
                                    <th style={{width: '100px'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedRequest.length > 0 ? (
                                    displayedRequest.map((request, index) => (
                                        <tr key={request.ordReqID}>
                                            <td style={{width: '50px'}}>{index + 1 + indexOfFirstRecord}</td>
                                            <td>{request.ordReqID}</td>
                                            <td>{request.branchName}</td> {/* Now using branchName from API */}
                                            <td>{new Date(request.reqDate).toLocaleDateString()}</td>
                                            <td>{request.requestStatus}</td>
                                            <td style={{width: '100px'}}>
                                                <button className="view-button" onClick={() => handleViewRequest(request)}>
                                                    <FaEye style={{ color: '#0056b3', fontSize: '1.2rem' }} /> {/* Larger and colored icon */}
                                                </button>
                                                <button className="delete-button" onClick={() => confirmDeleteRequest(request.ordReqID)}>
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" style={{ textAlign: 'center' }}>No stock request records found</td>
                                    </tr>
                                )}
                            </tbody>


                        </table>
                    </div>

                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </SideNavigation>      

            <BottomContainer 
                username={username} 
                branchID={branchID} 
                branchName={branchName}
                handleLogout={handleLogout} 
            />
        </div>
    );
}

export default InventoryRequest;
